import React from "react";
import {Text, View} from "./Themed";
import Class from "../constants/Style";

const DialogView = (props: any) => {
    const {title, dialogSize, children, actionView} = props;
    return <View style={[Class.dialogViewContainer, Class[dialogSize || "small"]]}>
        {title && <Text style={[Class.dialogTitle]}>{title}</Text>}
        <View>
            {children}
        </View>
        {
            actionView && <View style={[Class.dialogActionView]}>
                {actionView}
            </View>
        }

    </View>
}

export default DialogView;
