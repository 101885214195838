import React, {Component} from "react";
import {connect} from "react-redux";
import {ActivityIndicator, StyleSheet} from "react-native";
import {isIOS} from "../../libs/settings";
import {View} from "./Themed";


class DefaultSpinner extends Component {

    alreadyShow = false;

    render() {
        return (
            <View lightColor={"rgba(0,0,0,0)"} darkColor={"rgba(0,0,0,0)"} style={styles.loader}>
                <View lightColor={"rgba(0,0,0,0)"} darkColor={"rgba(0,0,0,0)"} style={[styles.screenCenter, styles.h_100, styles.transparent]}>
                    <View lightColor={"rgba(0,0,0,0)"} darkColor={"rgba(0,0,0,0)"} style={{width: 80, borderRadius: 50}}>
                        <ActivityIndicator size={"large"} color='#9e9e9e'/>
                    </View>
                </View>
            </View>
        );
    }
}

export default DefaultSpinner;

const styles = StyleSheet.create({
    loader: {position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 999},
    screenCenter: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    h_100: {height: '100%'},
    transparent: {backgroundColor: 'transparent'},
});

