import * as React from "react";
import {ProfileRootStack, UpdateProfileRootStack} from "../StackNav";
import Conversation from "../../screens/Conversation";
import SupportRequest from "../../screens/SupportRequest";
import SubCategory from "../../screens/SubCategory";
import SelectProduct from "../../screens/SelectProduct";
import CreateTicket from "../../screens/CreateTicket";
import SuccessTicket from "../../screens/SuccessTicket";
import UpdateLoginDetails from "../../screens/UpdateLoginDetails";
import Support from "../../screens/Support";
import {CommonNavigation} from "../CommonNavigation";
import UpdateProfile from "../../screens/UpdateProfile";
import Invoices from "../../screens/Invoices";
import ViewInvoice from "../../screens/ViewInvoice";
import {useWindowDimensions} from "react-native";
import ChangePassword from "../../screens/ChangePassword";
import Class from "../../constants/Style";
import {LARGE_SCREEN_WIDTH} from "../../libs/settings";
import {intercomVisibility} from "../../libs/function";


const ProfileRoot = (props) => {

    const dimensions = useWindowDimensions();
    const isLargeScreen = dimensions.width >= LARGE_SCREEN_WIDTH;
    let initialRouteName = "Profile";
    if (isLargeScreen) {
        initialRouteName = "Invoices"
    }
    const {route} = props;
    if (Boolean(route.params) && Boolean(route.params.params) && Boolean(route.params.params.screen)){
        initialRouteName = route.params.params.screens
    }
    intercomVisibility(false)
    return <ProfileRootStack.Navigator>
        <ProfileRootStack.Screen
            name="ProfileNav"
            options={{headerShown: false}}
        >
            {props => <CommonNavigation {...props} initialRouteName={initialRouteName}/>}
        </ProfileRootStack.Screen>
        <ProfileRootStack.Screen name="UpdateProfileScreen" component={UpdateProfile}/>
        <ProfileRootStack.Screen name="InvoicesScreen" component={Invoices}/>
        <ProfileRootStack.Screen name="ChangePasswordScreen" component={ChangePassword}/>
    </ProfileRootStack.Navigator>
}

export default ProfileRoot;



