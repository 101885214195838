import {isWeb} from "../libs/settings";
import {Chip, TextButton, View} from "../components/Themed";
import {DrawerContentScrollView, DrawerItem} from "@react-navigation/drawer";
import {Avatar, Caption, Drawer, Title} from "react-native-paper";
import {browseLink, isAllReseller, isReseller, navigationRef, signOut} from "../libs/function";
import {setLoadingAndToken} from "../libs/Store/actions/app_info";
import {connect} from "react-redux";
import * as React from "react";
import TabBarIcon from "../components/TabBarIcon";
import {StyleSheet} from "react-native";
import Class, {linkColor} from "../constants/Style"
import {allResellerList} from "../libs/static";

class DhruDrawer extends React.Component<any, any> {

    constructor(props) {
        super(props);
        const {navigation, profile,} = this.props;


        navigationRef.nav = {...navigation}

        let items: any = {
            cases: {
                label: "Support Cases",
                onPress: () => navigation.navigate('SupportRoot', {
                    screen: 'SupportNav',
                    params: {
                        screen: 'Cases',
                    },
                })
            },
            products: {
                label: "Products & Services",
                onPress: () => navigation.navigate('ProductRoot', {
                    screen: 'ProductNav',
                    params: {
                        screen: 'Products',
                    },
                })
            },

            orders: {
                label: "Orders",
                onPress: () => navigation.navigate('OrdersRoot', {
                    screen: 'OrdersNav',
                    params: {
                        screen: 'Orders',
                    },
                })
            },

        }
        if (isReseller()) {
            items = {
                ...items,
                seller: {
                    label: "Seller",
                    onPress: () => navigation.navigate('MerchantRoot', {
                        screen: 'MerchantNav',
                        params: {
                            screen: 'Merchant',
                        },
                    })
                }
            }
        }

        if (isAllReseller(allResellerList)){
            items = {
                ...items,
                wallet: {
                    label: `Reseller ( Credits : ${profile?.credit} )`,
                    onPress: () => navigation.navigate('WalletRoot', {
                        screen: 'WalletNav',
                        params: {
                            screen: 'Wallet',
                        },
                    })
                }
            }
        }else {
            items = {
                ...items,
                wallet: {
                    label: `Wallet ( Credits : ${profile?.credit} )`,
                    onPress: () => navigation.navigate('WalletRoot', {
                        screen: 'WalletNav',
                        params: {
                            screen: 'Wallet',
                        },
                    })
                }
            }
        }



        items = {
            ...items,
            // store: {
            //     label: "Banners & Themes Store",
            //     onPress: () => navigation.navigate('StoreRoot', {
            //         screen: 'StoreNav',
            //         params: {
            //             screen: 'Store',
            //         },
            //     })
            // } ,
            otp: {
                label: "OTP Services",
                onPress: () => navigation.navigate('OTPServiceRoot', {
                    screen: 'OTPServiceNav',
                    params: {
                        screen: 'OTPService',
                    },
                })
            },
            workspaces: {
                label: "Workspaces",
                onPress: () => navigation.navigate('WorkspaceRoot', {
                    screen: 'WorkspaceNav',
                    params: {
                        screen: 'Workspaces',
                    },
                })
            },

        }

        if (isAllReseller(allResellerList)) {
            items = {
                ...items,
                resellerworkspaces: {
                    label: "Reseller Workspaces",
                    onPress: () => navigation.navigate('ResellerWorkspaceRoot', {
                        screen: 'ResellerWorkspaceNav',
                        params: {
                            screen: 'ResellerWorkspaces',
                        },
                    })
                },
            }
        }

        this.state = {items}


    }

    render() {
        const {profile, setLoadingAndToken, defaultRedirect, token, navigation, state, linkTo} = this.props;
        if (isWeb) {
            if (Boolean(defaultRedirect) && !defaultRedirect.includes("checkout")) {
                // let spilit = initdata.defaultRedirect.split("http://localhost:19006")
                // if (Boolean(spilit[1])) {
                //     console.log("CALL REDIRECT", spilit[1]);
                //
                // }
                browseLink(defaultRedirect, "_self");
                setLoadingAndToken({loading: false, token, defaultRedirect: ""})
            }
        }
        let {activeItem, items} = this.state;

        if (items?.wallet?.label) {
            if (isAllReseller(allResellerList)){
                items.wallet.label = `Reseller ( Credits : ${profile?.credit} )`
            }else {
                items.wallet.label = `Wallet ( Credits : ${profile?.credit} )`
            }

        }


        return (
            <View style={{
                flex: 1,
                backgroundColor: '#fafbfc'
            }}>
                <DrawerContentScrollView {...this.props}

                >
                    {/*Head*/}
                    <View style={[{
                        padding: 16,
                        backgroundColor: 'transparent'
                    }, Class.borderBottom, Class.marginBottom8]}>
                        <View style={{flexDirection: 'column', alignItems: "center", backgroundColor: 'transparent'}}>
                            {/*<Avatar.Text size={64} label={getProfileLabel(profile.full_name)}/>*/}
                            <Avatar.Image size={64} source={{uri: profile?.avatar_url}}/>

                            <Title style={styles.title}>{profile.full_name}</Title>
                            <Caption style={styles.caption}>{profile.email}</Caption>
                            <View style={[Class.row]}>
                                <Chip style={[Class.marginTop4, Class.userGroup]}>{profile.group}</Chip>
                            </View>

                            <View style={[Class.row, Class.marginTop8, Class.transparent]}>
                                <TextButton
                                    lightColor={linkColor.light}
                                    darkColor={linkColor.dark}
                                    labelStyle={[Class.textSmall, Class.linkBorderStyle]}
                                    onPress={() => navigation.navigate('ProfileRoot', {
                                        screen: 'ProfileNav',
                                        params: {
                                            screen: 'UpdateProfile',
                                            params: {
                                                screen: "UpdateProfileScreen"
                                            }
                                        },
                                    })}
                                >PROFILE</TextButton>
                                <TextButton
                                    lightColor={linkColor.light}
                                    darkColor={linkColor.dark}
                                    labelStyle={[Class.textSmall, Class.linkBorderStyle]}
                                    onPress={() => navigation.navigate('ProfileRoot', {
                                        screen: 'ProfileNav',
                                        params: {
                                            screen: 'UpdateProfile',
                                            params: {
                                                screen: "ChangePasswordScreen"
                                            }
                                        },
                                    })}
                                >PASSWORD</TextButton>
                            </View>
                        </View>
                    </View>


                    <View style={styles.drawerSection}>

                        {
                            Object.keys(items).map((item, index) => {
                                return <DrawerItem
                                    activeBackgroundColor={'#ebecf0'}
                                    activeTintColor={'#1c2a48'}
                                    key={index}
                                    label={items[item].label}
                                    focused={index === state.index}
                                    onPress={() => {
                                        this.setState({activeItem: item}, () => {
                                            items[item].onPress()
                                        })
                                    }}

                                />
                            })
                        }


                        {/*<DrawerItemList {...this.props} />*/}
                    </View>

                </DrawerContentScrollView>
                {/*Foot*/
                }
                <Drawer.Section style={styles.bottomDrawerSection}>
                    {/*<DrawerItem*/}
                    {/*    icon={({color}) => (*/}
                    {/*        <Icon*/}
                    {/*            name="sun-o"*/}
                    {/*            style={{fontSize:28}}*/}
                    {/*        />*/}
                    {/*    )}*/}
                    {/*    focused={true}*/}
                    {/*    label={"Light"}*/}
                    {/*    onPress={() => {*/}
                    {/*    }}/>*/}

                    {/*<DrawerItem*/}
                    {/*    icon={({color}) => (*/}
                    {/*        <Icon*/}
                    {/*            name="moon-o"*/}
                    {/*            style={{fontSize:28}}*/}
                    {/*        />*/}
                    {/*    )}*/}
                    {/*    focused={true}*/}
                    {/*    label={"Dark"}*/}
                    {/*    onPress={() => {*/}
                    {/*    }}/>*/}

                    <DrawerItem
                        icon={({color}) => (
                            <TabBarIcon
                                name="ios-log-out"
                                color={color}
                            />
                        )}
                        label=""
                        onPress={async () => signOut(true)}

                    />
                </Drawer.Section>
            </View>
        )

    }
}

const mapStateToProps = (state: any) => ({
    profile: state.app_info.profile,
    defaultRedirect: state.app_info.defaultRedirect,
    token: state.app_info.token,
});

const mapDispatchToProps = (dispatch: any) => ({
    setLoadingAndToken: (data: any) => dispatch(setLoadingAndToken(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DhruDrawer);

const styles = StyleSheet.create({
    drawerContent: {
        flex: 1,
    },
    userInfoSection: {
        paddingLeft: 16,
    },
    title: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    caption: {
        fontSize: 14,
    },
    row: {
        marginTop: 20,
        flexDirection: 'row',
        alignItems: 'center',
    },
    section: {
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 15,
    },
    paragraph: {
        fontWeight: 'bold',
        marginRight: 3,
    },
    drawerSection: {backgroundColor: 'transparent'},
    bottomDrawerSection: {
        marginBottom: 0,
        borderTopColor: '#f4f4f4',
        borderTopWidth: 0
    },
    signout: {
        marginLeft: -20,
        alignItems: 'center',
        fontSize: 12,
    },
    preference: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingVertical: 12,
        paddingHorizontal: 16,
    },
});
