import React, {Component} from "react";
import {StyleSheet, ScrollView, KeyboardAvoidingView, TouchableOpacity} from "react-native";
import {View, Text, TextInputPaper, ContainedButton} from "../components/Themed";
import {headerHeight, isIOS, isWeb} from "../libs/settings";
import RNPickerSelect from "react-native-picker-select";
import {setInitData} from "../libs/Store/actions/app_info";
import {connect} from "react-redux";
import base64 from "react-native-base64";
import requestApi, {actions, methods, SUCCESS} from "../libs/ServerRequest";
import * as Permissions from "expo-permissions";
import * as DocumentPicker from "expo-document-picker";
import * as mime from "react-native-mime-types";
import * as ImagePicker from "expo-image-picker";
import * as FileSystem from "expo-file-system";
import AttachmentItem from "../components/AttachmentItem";
import {setLoader} from "../libs/Store/actions/components";
import {
    FileRead,
    GetFileData,
    isEmpty,
    uploadFile,
    requestGetUploadUrl,
    logData,
    encodeText,
    decodeText
} from "../libs/function";
import Class, {pickerSelectStyles} from "../constants/Style"
import {Button} from "react-native-paper";
import IconButton from "../components/IconButton";
import TitleAndSubtitle from "../components/TitleAndSubtitle";
import RoundIcon from "../components/RoundIcon";
import RootPage from "../components/RootPage";
import DhruScrollView from "../components/DhruScrollView";
import utf8 from "utf8";
var Buffer = require('buffer/').Buffer

class CreateTicket extends Component {

    constructor(props) {
        super(props);
        const {route, support_priority, support_category, initdata} = props;
        const priorityOption = Object.keys(support_priority).map((k) => {
            return {label: k, value: k}
        });
        let create = {
            category: "",
            subject: "",
            text: "",
            product_id: "",
            priority: priorityOption[0].value
        };
        let productdata = {};
        if (Boolean(route.params.subid)) {
            create.category = route.params.subid;
        }
        if (Boolean(route.params.product_id)) {
            create.product_id = route.params.product_id;
            const product = initdata.products.filter(a => a.product_id === route.params.product_id)[0];
            productdata.content = product.prodcut;
            productdata.license_key = product.license_key;
            if (Boolean(product.domain)) {
                productdata.domain = product.domain;
            }
        }
        const {name: title, categorys, colour} = support_category[route.params.id];
        const {name: subtitle, icon} = categorys[create.category];
        this.state = {
            create,
            error: {},
            title,
            subtitle,
            ...productdata,
            priorityOption,
            icon,
            colour
        }
    }

    setCreate = (data: any) => {
        this.setState({
            create: {
                ...this.state.create,
                ...data
            }
        })
    }

    requestGetUploadUrl = ({file_name, file_type, uri}) => {
        let {setLoader} = this.props;
        requestApi({
            method: methods.get,
            anotherUrl: `https://apigateway.dhru.com/v1/getuploadurl?file_name=${file_name}&file_type=${file_type}`,
            otherheaders: {'Accept': '*/*'}
        }).then((responsegetuploadurl) => {
            if (responsegetuploadurl.status === SUCCESS) {
                setLoader({show: true});
                FileSystem
                    .readAsStringAsync(uri, {encoding: FileSystem.EncodingType.Base64})
                    .then((body) => {
                        let myHeaders = new Headers();
                        myHeaders.append("Content-Type", file_type);
                        let requestOptions = {
                            method: 'PUT',
                            headers: myHeaders,
                            body: Buffer.from(body, "base64"),
                            redirect: 'follow'
                        }, url = responsegetuploadurl.upload_url;

                        fetch(url, requestOptions)
                            .then(response => {
                                if (response.status === 200) {
                                    let {attachments} = this.state.create;
                                    if (!Boolean(attachments)) {
                                        attachments = [];
                                    }
                                    this.setCreate({
                                        attachments: [
                                            ...attachments,
                                            {
                                                download_url: responsegetuploadurl.download_url,
                                                file_name: responsegetuploadurl.original_file_name,
                                            }
                                        ]
                                    })
                                }
                                setLoader({show: false});
                                return response.text()
                            })
                            .catch(error => {
                                setLoader({show: false});
                                console.log('error', error)
                            });
                    })
                    .catch(() => {
                        setLoader({show: false});
                    });
            }
        })
    }

    validate = () => {
        const {create} = this.state;
        let error = {};
        if (!Boolean(create.subject)) {
            error.subject = "Please enter subject";
        }
        if (!Boolean(create.text)) {
            error.text = "Please enter description";
        }
        this.setState({error})
        return isEmpty(error)
    }

    createTicket = () => {
        const {create, content, license_key} = this.state;
        const {setInitData, initdata, navigation} = this.props;

        requestApi({
            method: methods.post,
            action: actions.support,
            body: {...create}
        }).then((response) => {
            if (response.status === SUCCESS) {
                let {subject, created, created_date, ticket_id, priority, status, product_id, display_note, request_login_details} = response.data;

                const Subject = base64.decode(subject);

                setInitData({
                    ...initdata,
                    support_tickets: [
                        {
                            created,
                            created_date,
                            Subject,
                            ticket_id,
                            priority,
                            status
                        },
                        ...initdata.support_tickets,
                    ],
                });

                navigation.replace("SuccessTicketScreen", {
                    product: {
                        product_id,
                        domain: content,
                        license_key,
                        ticket_id,
                        request_login_details,
                        display_note
                    }
                })
            }
        })
    }

    componentDidMount() {
        const {icon, colour} = this.state
        const {navigation} = this.props;
        // navigation.setOptions({
        //     headerRight: () => <RoundIcon name={icon} backgroundColor={`#${colour}`}/>
        // });
    }

    requestFileUpload = async (isDoc) => {
        const {attachment} = this.state;
        const {setLoader} = this.props;
        const fileDataResponse = await GetFileData(isDoc);
        if (fileDataResponse.status === "success") {
            const responseUploadUrl = await requestGetUploadUrl(fileDataResponse);
            setLoader({show: true});
            let readFileResponse;
            if (isWeb) {
                readFileResponse = fileDataResponse.selectedFile;
            } else {
                readFileResponse = await FileRead(fileDataResponse.file_uri);
            }
            uploadFile({
                url: responseUploadUrl.upload_url,
                file_type: fileDataResponse.file_type,
                body: readFileResponse
            }).then((response) => {
                setLoader({show: false});
                if (response.status === 200) {
                    let {attachments} = this.state.create;
                    if (!Boolean(attachments)) {
                        attachments = [];
                    }
                    this.setCreate({
                        attachments: [
                            ...attachments,
                            {
                                download_url: responseUploadUrl.download_url,
                                file_name: responseUploadUrl.original_file_name,
                            }
                        ]
                    })
                }
            })
        }
    }

    uploadCameraPicture = async (uri)=>{
        const file_name_array = uri.toString().split("/");
        const file_name = file_name_array[file_name_array.length - 1];
        const file_uri = uri
        const file_type = mime.lookup(file_uri);
        const {attachment} = this.state;
        const {setLoader} = this.props;
        const responseUploadUrl = await requestGetUploadUrl({file_name, file_type});
        if (responseUploadUrl.status === SUCCESS) {
            setLoader({show: true});
            let readFileResponse;
            readFileResponse = await FileRead(file_uri);
            uploadFile({
                url: responseUploadUrl.upload_url,
                file_type,
                body: readFileResponse
            }).then((response) => {

                setLoader({show: false});
                if (response.status === 200) {
                    let {attachments} = this.state.create;
                    if (!Boolean(attachments)) {
                        attachments = [];
                    }
                    this.setCreate({
                        attachments: [
                            ...attachments,
                            {
                                download_url: responseUploadUrl.download_url,
                                file_name: responseUploadUrl.original_file_name,
                            }
                        ]
                    })
                }
            })
        }
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        const {route} = this.props;
        if (Boolean(route) &&  Boolean(route.params.uri)){
            this.uploadCameraPicture(route.params.uri)
            this.props.route.params.uri = undefined;
        }
    }

    render() {
        const {navigation, route} = this.props;
        const {name, create, error, title, subtitle, content, priorityOption, icon, colour, domain} = this.state;

        let mainContent = content;
        if (Boolean(domain)) {
            mainContent += " - " + domain;
        }

        return (
            <RootPage navigation={navigation} headerTitle={title}>
                <KeyboardAvoidingView
                    keyboardVerticalOffset={headerHeight}
                    style={Class.container} behavior={isIOS ? "padding" : ""}>
                    <View style={[Class.container, Class.alignSelfStretch]}>
                        <DhruScrollView>
                            <TitleAndSubtitle
                                subtitle={subtitle}
                                content={mainContent}
                            />
                            <View style={[Class.alignSelfStretch, Class.container, Class.paddingLeftRight22]}>
                                <TextInputPaper
                                    value={Boolean(create.subject) ? decodeText(create.subject) : ""}
                                    onChangeText={(subject) => {
                                        let data = "";
                                        if (Boolean(subject)) {
                                            data = encodeText(subject)
                                        }
                                        this.setCreate({subject: data})
                                    }}
                                    error={error.subject}
                                    label="Subject"
                                    style={[Class.marginBottom4, Class.marginTop16]}
                                />
                                <Text style={[Class.marginBottom16, Class.textSmall]}>
                                    Brief summary of the question or issue (Max 150 characters)
                                </Text>


                                {/*<RNPickerSelect*/}
                                {/*    placeholder={{*/}
                                {/*        label: 'Select Priority',*/}
                                {/*    }}*/}
                                {/*    style={pickerSelectStyles}*/}
                                {/*    value={create.priority}*/}
                                {/*    onValueChange={(priority) => this.setCreate({priority})}*/}
                                {/*    useNativeAndroidPickerStyle={false}*/}
                                {/*    items={priorityOption}*/}
                                {/*/>*/}

                                <TextInputPaper
                                    value={Boolean(create.text) ? decodeText(create.text) : ""}
                                    onChangeText={(text) => {
                                        let data;
                                        if (Boolean(text)) {
                                            data = encodeText(text)
                                        }
                                        this.setCreate({text: data})
                                    }}
                                    textStyle={{minHeight: isWeb ? 200 : 100}}
                                    label="Description"
                                    multiline={true}
                                    error={error.text}
                                    numberOfLines={3}
                                    style={[Class.marginBottom4]}
                                />

                                <Text style={[Class.marginBottom16, Class.textSmall]}>
                                    Detailed account of the question or issue (Max 2500 characters)
                                </Text>


                                <View style={[Class.rply, Class.marginLeftm16]}>
                                    <View style={[Class.optionsButtons]}>
                                        <TouchableOpacity onPress={async () => {
                                            await this.requestFileUpload(true)
                                        }}>
                                            <View style={[Class.row]}>
                                            <IconButton
                                                name="ios-attach"
                                                style={Class.buttonAttachment1}
                                            />
                                            <Text>Attach Files</Text>
                                            </View>
                                        </TouchableOpacity>


                                        {
                                            !isWeb && <IconButton
                                                name="ios-image"
                                                onPress={async () => {
                                                    await this.requestFileUpload(false)
                                                }}
                                                style={Class.buttonAttachment1}
                                            />
                                        }

                                        {
                                            !isWeb && <IconButton
                                                name="ios-camera"
                                                onPress={async () => navigation.navigate("CameraScreen", {name:route.name})}
                                                style={Class.buttonAttachment1}
                                            />
                                        }

                                    </View>
                                </View>

                                {
                                    (Boolean(create.attachments) && create.attachments.length > 0) &&
                                    <View style={[Class.attachedListView, Class.paddingLeftRight0]}>
                                        {
                                            create.attachments.map((a, index) => {
                                                return <AttachmentItem
                                                    {...a}
                                                    key={index}
                                                    onPress={() => {
                                                        const attachments = create.attachments.filter((a, i) => i !== index);
                                                        this.setCreate({attachments: attachments})
                                                    }}
                                                />
                                            })
                                        }
                                    </View>
                                }
                            </View>
                        </DhruScrollView>
                    </View>
                    <View style={[Class.bottomView]}>
                        <ContainedButton
                            onPress={() => {
                                if (this.validate()) {
                                    this.createTicket()
                                }
                            }}
                        >
                            Create
                        </ContainedButton>
                    </View>
                </KeyboardAvoidingView>
            </RootPage>
        );
    }
}

const mapStateToProps = (state) => ({
    support_priority: state.app_info.static.support_priority,
    support_category: state.app_info.static.support_category,
    initdata: state.app_info
});

const mapDispatchToProps = (dispatch) => ({
    setInitData: (initData) => dispatch(setInitData(initData)),
    setLoader: (loader) => dispatch(setLoader(loader)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateTicket);
