import * as React from 'react';
import {StyleSheet,  Image} from "react-native";
import requestApi, {actions, methods, SUCCESS} from "../libs/ServerRequest";
import {setAuthUser, setInitData, setLoadingAndToken} from "../libs/Store/actions/app_info";
import {connect} from "react-redux";
import AsyncStorage from '@react-native-community/async-storage';
import { Button, HelperText, TextInput} from 'react-native-paper';
import Class from '../constants/Style';
import ReCaptchaV3 from "@haskkor/react-native-recaptchav3";
import {isWeb} from "../libs/settings";
import {GoogleReCaptcha, withGoogleReCaptcha} from "react-google-recaptcha-v3";
import {Link} from "@react-navigation/native";
import CaptchaProvider from "../components/CaptchaProvider";
import {isEmpty} from "../libs/function";
import {ContainedButton, TextInputPaper, View, Text} from "../components/Themed";
import {regExpJson, validEmail, validPassword, validRequired} from "../libs/validate";
import DhruLogo from "../components/DhruLogo";
import RootPage from "../components/RootPage";
import {setLoader} from "../libs/Store/actions/components";
import LinkButton from "../components/LinkButton";
import RecaptchaContent from "../components/RecaptchaContent";


class ResetPassword extends React.Component<any, any> {

    constructor(props) {
        super(props);
        const {route, fromCheckout ,redirectPage} = this.props;
        let key = "", email = "";
        if (!Boolean(fromCheckout) && Boolean(route.params)) {
            if (Boolean(route.params.code)){
                key = route.params.code
            }
            if (Boolean(route.params.email)){
                email = route.params.email
            }
        }
        this.state = {
            email,
            password: "",
            cpassword: "",
            captcha:"",
            captchaCall: false,
            key,
            error: {}
        }
        this.inputRefs = [
            React.createRef(),
            React.createRef(),
            React.createRef(),
        ]
    }

    validate = () => {
        const {email, password, cpassword, key} = this.state;
        let error = {};
        error = {...error, ...validEmail(error, "email", email)};
        error = {...error, ...validPassword(error, "password", password)};

        if (!Boolean(cpassword)) {
            error = {...error, ...validRequired(error, "cpassword", password, "Please enter confirm password")};
        } else if (password !== cpassword) {
            error.cpassword = "Confirm password not match with password";
        }
        error = {...error, ...validRequired(error, "key", key, "Please enter code")};
        this.setState({error})
        return isEmpty(error)
    }

    requestForgotPassword = async () => {
        const {setAuthUser, navigation, fromCheckout, redirectPage} = this.props;
        let {email, password, key, captcha} = this.state;
        if (isWeb) {
           captcha = await this.props.googleReCaptchaProps.executeRecaptcha();
        }
        this.props.setLoader({show: false});
        requestApi({
            method: methods.put,
            action: actions.forgotpassword,
            body: {email, password, key, "g-recaptcha-response": captcha}
        }).then(async (response) => {
            if (response.status === SUCCESS) {
                if (fromCheckout){
                    redirectPage("Login")
                }else {
                    navigation.popToTop();
                }

            }
        })
    }


    onSubmit = ()=>{
        if (this.validate()) {
            if (isWeb){
                this.requestForgotPassword()
            }else {
                this.props.setLoader({show: true});
                this.setState({captchaCall:true},()=>{
                    this._captchaRef.refreshToken()
                });
            }
        }
    }

    render() {
        const {email, password, cpassword, key, onPress, error, captcha, captchaCall} = this.state;
        const {route, navigation, fromCheckout, redirectPage} = this.props;

        let codeVisible = true;
        if (!Boolean(fromCheckout) &&(Boolean(route.params) && Boolean(route.params.code))) {
            codeVisible = false;
        }

        return (
            <RootPage navigation={navigation} title={"Reset Password"}>
                <View style={Class.container}>
                    <View style={Class.formContainer}>
                        <View style={Class.formGroup}>
                            <View style={[Class.alignSelfStretch, Class.marginBottom16]}>
                                <DhruLogo/>
                                <Text style={Class.pageHeader}>Set New Password</Text>
                                <TextInputPaper
                                    label={"Email Address"}
                                    placeholder={"Email Address"}
                                    value={email}
                                    autoCompleteType={"email"}
                                    textContentType={"emailAddress"}
                                    keyboardType={"email-address"}
                                    error={error.email}
                                    onChangeText={(email) => this.setState({email})}
                                    style={Class.textbox}
                                    returnKeyType="next"
                                    onSubmitEditing={() => this.inputRefs[0].focus()}
                                />
                                <TextInputPaper
                                    label={"Password"}
                                    secureTextEntry={true}
                                    placeholder={"Password"}
                                    value={password}
                                    error={error.password}
                                    autoCompleteType={"password"}
                                    textContentType={"newPassword"}
                                    PasswordRules={"required: lower; required: upper; required: digit; required: [-!#$%&()*@^]; minlength: 15; maxlength: 15;"}
                                    keyboardType={"default"}
                                    onChangeText={(password) => this.setState({password})}
                                    style={Class.textbox}
                                    returnKeyType="next"
                                    ref={ref => this.inputRefs[0] = ref}
                                    onSubmitEditing={() => this.inputRefs[1].focus()}
                                />
                                <TextInputPaper
                                    label={"Confirm Password"}
                                    secureTextEntry={true}
                                    placeholder={"Confirm Password"}
                                    value={cpassword}
                                    error={error.cpassword}
                                    autoCompleteType={"password"}
                                    textContentType={"newPassword"}
                                    PasswordRules={"required: lower; required: upper; required: digit; required: [-!#$%&()*@^]; minlength: 15; maxlength: 15;"}
                                    keyboardType={"default"}
                                    onChangeText={(cpassword) => this.setState({cpassword})}
                                    style={Class.textbox}
                                    returnKeyType={codeVisible?"next":"done"}
                                    ref={ref => this.inputRefs[1] = ref}
                                    onSubmitEditing={() => {
                                        if (codeVisible) {
                                            this.inputRefs[2].focus()
                                        }else {
                                            this.onSubmit();
                                        }
                                    }}
                                />
                                {
                                    codeVisible && <TextInputPaper
                                        label={"Reset Code"}
                                        placeholder={"Reset Code"}
                                        value={key}
                                        error={error.key}
                                        onChangeText={(key) => this.setState({key})}
                                        style={Class.textbox}
                                        returnKeyType={"done"}
                                        ref={ref => this.inputRefs[2] = ref}
                                        onSubmitEditing={() =>this.onSubmit()}
                                    />
                                }

                                <View style={Class.buttonRowContainer}>
                                    <LinkButton caption={"Sign In"} onPress={()=> {
                                        if (fromCheckout){
                                            redirectPage("Login")
                                        }else {
                                            navigation.popToTop();
                                        }

                                    }} style={Class.linkStyle}/>


                                    {
                                        (!isWeb && captchaCall) &&
                                        <ReCaptchaV3
                                            ref={(ref) => this._captchaRef = ref}
                                            captchaDomain={'https://api.dhru.com'}
                                            siteKey={'6LebDscZAAAAAEfuz_E-rB6r12xLHekW_XlR-Y5V'}
                                            onReceiveToken={(token: string) => {
                                                this.setState({captcha: token, captchaCall:false},()=>{
                                                    this.requestForgotPassword()
                                                })
                                            }}
                                            action={""}/>
                                    }

                                    <ContainedButton
                                        onPress={() => this.onSubmit()}
                                    >
                                        Reset
                                    </ContainedButton>

                                </View>



                            </View>
                            <RecaptchaContent/>
                        </View>
                    </View>
                </View>
            </RootPage>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    setAuthUser: (authUser) => dispatch(setAuthUser(authUser)),
    setInitData: (initData) => dispatch(setInitData(initData)),
    setLoadingAndToken: (data) => dispatch(setLoadingAndToken(data)),
    setLoader: (loader) => dispatch(setLoader(loader)),
});


export default withGoogleReCaptcha(connect(mapStateToProps, mapDispatchToProps)(ResetPassword));
