import * as React from "react";
import Snack from "./Snack";
import {BackButton2} from "./BackButton";
import {headerOptions} from "../navigation/StackNav/common";
import {TouchableWithoutFeedback} from "react-native";
import {hideKeyboard} from "../libs/function";
import {View} from "./Themed";
import Class from "../constants/Style"

class RootPage extends React.Component<any, any> {
    componentDidMount() {
        const {navigation, noBack, headerLeft, headerTitle, headerLargeTitle, title} = this.props;
        if (Boolean(navigation)) {

            let options = {...headerOptions, headerLargeTitle};

            if (headerLeft) {
                options = {
                    ...options,
                    headerLeft: props => noBack ? <BackButton2 goBack={navigation.goBack} {...props}/> : null
                }
            }
            if (Boolean(headerTitle)) {
                options = {...options, headerTitle, title: headerTitle}
            } else {
                options = {...options, headerLargeTitle: false, headerTitle: ""}
            }
            if (Boolean(title)) {
                options = {...options, title}
            }
            navigation.setOptions({...options});
        }
    }

    render() {
        const {children, ...otherProps} = this.props;
        return (
            /*<TouchableWithoutFeedback onPress={()=>hideKeyboard()} >*/
                <View style={Class.container}>
                    {children}
                    <Snack/>
                </View>
           /* </TouchableWithoutFeedback>*/
        );
    }
}

RootPage.defaultProps = {
    noBack: true,
    headerLargeTitle: true,
    headerLeft: true,
    hide_default_launcher:false
}

export default RootPage;
