import * as React from "react";
import {SearchText, Text, View} from "../components/Themed";
import {connect} from "react-redux";
import Class from "../constants/Style";
import {RefreshControl, ScrollView} from "react-native";
import OrderItem from "../components/OrderItem";
import RootPage from "../components/RootPage";
import InvoiceItem from "../components/InvoiceItem";
import {isWeb} from "../libs/settings";
import * as Linking from 'expo-linking';
import {getSmallWidth, isEmptyArray} from "../libs/function";
import EmptyPage from "../components/EmptyPage";
import requestApi, {actions, methods, SUCCESS} from "../libs/ServerRequest";
import DhruScrollView, {DEFAULT_LOADER_VIEW_TIME} from "../components/DhruScrollView";

class Invoices extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            filterInvoice: "",
            refreshing: false
        }
    }

    getInvoices = () => {
        const {refreshing} = this.state;
        const {setInitDataWithObject} = this.props;

        requestApi({
            method: methods.get,
            action: actions.invoices,
            loader: !refreshing,
        }).then((response) => {

            setTimeout(() => {
                this.setState({
                    refreshing: false
                });
            }, DEFAULT_LOADER_VIEW_TIME)

            if (response.status === SUCCESS) {

                setInitDataWithObject("invoices", response.data)
            }
        })
    }

    render() {
        const {navigation, invoices} = this.props;
        const {filterInvoice, refreshing} = this.state;
        return (
            <RootPage navigation={navigation} noBack={getSmallWidth()} headerTitle={"Invoices"}>
                <View style={Class.container}>

                    <DhruScrollView
                        refreshControl={<RefreshControl
                            tintColor={"#9e9e9e"}
                            refreshing={refreshing}
                            onRefresh={() => this.setState({refreshing: true}, () => this.getInvoices())}
                        />}>
                        {
                            !isEmptyArray(invoices) ? <React.Fragment>
                                <SearchText
                                    placeholder="Search Invoice"
                                    onChangeText={(filterInvoice) => this.setState({filterInvoice})}
                                    value={filterInvoice}
                                />
                                {
                                    invoices
                                        .filter((invoice) => {
                                            return Boolean(filterInvoice) ?
                                                invoice.tax_invoice_id.toString().includes(filterInvoice)
                                                : true
                                        })
                                        .map((invoice, index) => {
                                            return <InvoiceItem onPress={() => Linking.openURL(invoice.download_url)}
                                                                key={index} {...invoice} />
                                        })
                                }
                            </React.Fragment> : <EmptyPage message={"You don't have any invoices"}/>
                        }
                    </DhruScrollView>
                </View>
            </RootPage>
        );
    }
}

const mapStateToProps = (state) => ({
    invoices: state.app_info.invoices
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Invoices)

