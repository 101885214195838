import React, {useEffect, useState} from "react";
import {RefreshControl, Text, View} from "react-native";
import requestApi, {actions, methods, SUCCESS} from "../libs/ServerRequest";
import Class from "../constants/Style";
import DhruScrollView from "../components/DhruScrollView";
import {isEmptyArray, isEmpty, getSmallWidth} from "../libs/function";
import {SearchText, ContainedButton} from "../components/Themed";
import OrderItem from "../components/OrderItem";
import EmptyPage from "../components/EmptyPage";
import RootPage from "../components/RootPage";
import {setInitDataWithObject, setProfileCredit, setWorkspaces} from "../libs/Store/actions/app_info";
import {setSnackbar} from "../libs/Store/actions/components";
import {connect} from "react-redux";
import SquareIcon from "../components/SquareIcon";

const serviceLabel: any = {
    email: "E-Mail",
    sms: "SMS",
    whatsapp: "WhatsApp",
}

const ServiceCard = ({label, value, onPress, serviceCardStyle}: any) => {
    return <View style={serviceCardStyle}>
        <Text style={[Class.textCenter, Class.marginBottom16, {fontSize: 24}]}>{label}</Text>
        <Text style={[Class.textCenter, Class.marginBottom16, {fontSize: 28}]}>
            <Text style={[Class.textBold600, {fontSize: 28}]}> {value}</Text>
            <Text style={[{fontSize: 16}]}>Cr</Text>
        </Text>
        <ContainedButton onPress={onPress}>Buy</ContainedButton>
    </View>
};

const SummaryLabel = ({label, value}: any) => {
    return <View style={[Class.row, Class.justifySpaceBetween]}>
        <Text style={[Class.marginBottom8, {fontSize: 16, width: 220}]}>{label}</Text>
        <Text style={[Class.textCenter, Class.marginBottom8, {fontSize: 16, width: 20,}]}>:</Text>
        <Text
            style={[Class.textRight, Class.marginBottom8, Class.textBold600, {fontSize: 16, width: 100}]}>{value}</Text>
    </View>
}


const OTPServiceScreen = ({navigation, otp_credits_packages, setProfileCredit}: any) => {

    const [service, setService] = useState<any>({});
    const [buyService, setBuyService] = useState<any>({});

    useEffect(() => {
        requestApi({
            method: methods.get,
            action: actions.otpcredit,
        }).then((result: any) => {
            if (result.status === SUCCESS && result?.data) {
                setService(result.data);

            }
        })
    }, []);

    const onPressBuy = (chanel: any) => {
        requestApi({
            method: methods.post,
            action: actions.otpcredit,
            body: {chanel}
        }).then((result: any) => {
            if (result.status === SUCCESS && result?.data) {
                setService(result.data);
                setBuyService({});
                if (result?.data?.wallet_credit) {
                    setProfileCredit(result?.data?.wallet_credit)
                }
            }
        })
    }

    let styleServiceContainer: any = [], styleServiceCard: any = [], styleSummary: any = [],
        styleSummaryLabelContainer: any = [], styleSummaryLabel: any = [], styleSummaryButtonContainer: any = [],
        styleSummaryBuyButton: any = [];

    if (getSmallWidth()) {
        styleServiceContainer = [];
        styleServiceCard = [Class.border, Class.padding16, Class.marginBottom16];
        styleSummary = [Class.border, Class.padding16,];
        styleSummaryLabel = [Class.textCenter];
        styleSummaryButtonContainer = [Class.alignItemCenter];
        styleSummaryBuyButton = [Class.width100]
        styleSummaryLabelContainer = [Class.marginBottom16]
    } else {
        styleServiceContainer = [Class.row, Class.justifySpaceEvenly, Class.marginTop16, Class.marginBottom16];
        styleServiceCard = [Class.border, Class.padding16, {flex: 1}, Class.marginLeft16, Class.marginRight16];
        styleSummary = [Class.row, Class.justifySpaceBetween, Class.border, Class.margin16, Class.padding16];
        styleSummaryButtonContainer = [Class.alignItemFlexEnd];
        styleSummaryBuyButton = [Class.fixWidthOnWebButton]
    }


    return <RootPage navigation={navigation} headerTitle={"OTP Services"} noBack={getSmallWidth()}>
        <View style={Class.container}>
            <View style={[Class.padding16]}>
                <View style={styleServiceContainer}>
                    {!isEmpty(service) && Object.keys(service)
                        .filter((skey: any) => Boolean(skey !== "wallet_credit"))
                        .map((skey) => {
                            return <ServiceCard
                                key={skey}
                                serviceCardStyle={styleServiceCard}
                                label={serviceLabel[skey]}
                                value={service[skey]}
                                onPress={() => {
                                    setBuyService({
                                        key: skey,
                                        ...otp_credits_packages[skey]
                                    })
                                }}
                            />
                        })}
                </View>

                {
                    !isEmpty(buyService) && <View>
                        <View style={styleSummary}>
                            <View style={styleSummaryLabelContainer}>
                                <Text
                                    style={[...styleSummaryLabel, Class.textBold600, {fontSize: 28}]}>{serviceLabel[buyService.key]}</Text>
                                <Text style={styleSummaryLabel}>Channel</Text>
                            </View>
                            <View>
                                <SummaryLabel label={"Required Wallet Credit"} value={buyService["wallet_credit"]}/>
                                <SummaryLabel label={"Channel Credit"} value={buyService["otp_credit"]}/>
                                <View style={[...styleSummaryButtonContainer, Class.marginTop16]}>
                                    <ContainedButton style={styleSummaryBuyButton}
                                                     onPress={() => onPressBuy(buyService.key)}>
                                        Buy
                                    </ContainedButton>
                                </View>
                            </View>
                        </View>
                    </View>
                }

            </View>
        </View>
    </RootPage>
}

const mapStateToProps = (state: any) => ({
    otp_credits_packages: state.app_info.static.otp_credits_packages
});

const mapDispatchToProps = (dispatch: any) => ({
    setProfileCredit: (credit: any) => dispatch(setProfileCredit(credit))
});

export default connect(mapStateToProps, mapDispatchToProps)(OTPServiceScreen);

