import * as React from "react";
import {ContainedButton, Text, TextInputPaper, View} from "../components/Themed";
import RootPage from "../components/RootPage";
import {headerHeight, isIOS, LARGE_SCREEN_WIDTH} from "../libs/settings";
import Class from "../constants/Style";
import DhruScrollView from "../components/DhruScrollView";
import {LargeScreen, SmallScreen} from "../components/ScreenSize";
import {KeyboardAvoidingView} from "react-native";
import CheckBox from "../components/CheckBox";
import {validEmail} from "../libs/validate";
import {isEmpty} from "../libs/function";
import requestApi, {actions, methods, SUCCESS} from "../libs/ServerRequest";
import Icon from "../components/Icon";
import {setInitDataWithObject} from "../libs/Store/actions/app_info";
import {connect} from "react-redux";
import {BackButton2} from "../components/BackButton";

class MoveLicense extends React.Component<any, any> {

    constructor(props) {
        super(props);

        this.state = {
            product_id: props.route.params.product_id,
            transfer_to_email: "",
            error: {},
            verify: false,
            moved: false,
            displayMessage: ""
        }
    }

    componentDidMount() {
        this.call();
        this.props.navigation.addListener('dismiss', e => {
                if (Boolean(this.state.moved)) {
                    this.props.navigation.popToTop()
                }
        });
    }

    // componentWillUnmount() {
    //     const {navigation} = this.props;
    //     const {moved} = this.state;
    //     if (Boolean(moved)) {
    //         navigation.popToTop()
    //     }
    // }

    call = ()=>{
        const {navigation} = this.props;
        const {moved} = this.state;
        if (Boolean(navigation)) {
            navigation.setOptions({
                headerLeft: props => <BackButton2 goBack={() => {
                    Boolean(moved) ? navigation.popToTop() : navigation.goBack()
                }} {...props}/>
            });
        }
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        this.call();
    }

    validate = () => {
        const {transfer_to_email} = this.state;
        let error = {};
        error = {...error, ...validEmail(error, "transfer_to_email", transfer_to_email)};
        this.setState({error})
        return isEmpty(error)
    }

    requestChangeOwner = () => {
        let {navigation, products, setInitDataWithObject} = this.props;
        let {transfer_to_email, product_id} = this.state;
        requestApi({
            method: methods.post,
            action: actions.product, showsucess: true,
            body: {transfer_to_email, product_id}
        }).then(async (response) => {
            if (response.status === SUCCESS) {
                this.setState({displayMessage: response.message, moved: true}, () => {
                    products = products.filter((p) => p.product_id !== product_id)
                    setInitDataWithObject("products", products)

                })
            }
        })
    }

    onSubmit = () => {
        if (this.validate()) {
            this.requestChangeOwner()
        }
    }

    render() {
        const {navigation} = this.props;
        const {transfer_to_email, error, verify, product_id, moved, displayMessage} = this.state;
        return (
            <RootPage navigation={navigation} noBack={false} headerTitle={"Transfer Ownership"}>
                {
                    isIOS && <View style={[Class.row, Class.paddingTopBottom16]}>
                        <View style={[Class.flex, {alignItems:"flex-start"}]}>
                            <BackButton2 goBack={() => {
                                Boolean(moved) ? navigation.popToTop() : navigation.goBack()
                            }}
                                         style={{paddingLeft:18, paddingRight:18}}/>
                        </View>
                        <View style={[Class.flex2,Class.alignItemCenter]}>
                            <Text style={{fontSize:18, fontWeight:"600"}}>Transfer Ownership</Text>
                        </View>
                        <View style={Class.flex}/>
                    </View>
                }

                {
                    Boolean(moved) ? <View style={[Class.container]}>
                        <View style={Class.successContainer}>
                            <View style={[Class.successSubContainer, {marginTop: -64}]}>
                                <Icon
                                    name="check-circle"
                                    style={Class.iconCheck}
                                />
                                <Text
                                    style={[Class.marginBottom4, Class.title, Class.textCenter]}>{displayMessage}</Text>
                            </View>
                        </View>
                    </View> : <KeyboardAvoidingView
                        keyboardVerticalOffset={headerHeight}
                        style={Class.container} behavior={isIOS ? "padding" : ""}>
                        <View style={[Class.container]}>
                            <DhruScrollView contentContainerStyle={Class.paddingLeftRight22}>
                                <View style={[Class.maxFormWidth]}>
                                    <TextInputPaper
                                        label={"Email Address"}
                                        placeholder={"Email Address"}
                                        error={error.transfer_to_email}
                                        value={transfer_to_email}
                                        autoCompleteType={"email"}
                                        textContentType={"emailAddress"}
                                        keyboardType={"email-address"}
                                        onChangeText={(transfer_to_email) => this.setState({transfer_to_email})}
                                        style={[Class.textbox, Class.marginTop16]}
                                        returnKeyType="done"
                                        onSubmitEditing={() => this.onSubmit()}
                                    />

                                    <CheckBox
                                        onPress={() => {
                                            this.setState({verify: !verify})
                                        }}
                                        checked={verify}
                                        label={"After transfer ownership you" +
                                        " will no longer have access to this product in your account."}
                                        style={Class.marginBottom16}
                                    />

                                    <LargeScreen minWidth={LARGE_SCREEN_WIDTH}>
                                        <ContainedButton
                                            disabled={!verify}
                                            style={[Class.maxFormWidth]}
                                            onPress={() => this.onSubmit()}
                                        >
                                            Transfer Ownership
                                        </ContainedButton>
                                    </LargeScreen>
                                </View>
                            </DhruScrollView>
                            <SmallScreen maxWidth={LARGE_SCREEN_WIDTH}>
                                <View style={[Class.bottomView]}>
                                    <ContainedButton
                                        disabled={!verify}
                                        style={[Class.maxFormWidth]}
                                        onPress={() => this.onSubmit()}
                                    >
                                        Transfer Ownership
                                    </ContainedButton>
                                </View>
                            </SmallScreen>
                        </View>
                    </KeyboardAvoidingView>
                }
            </RootPage>
        );
    }
}

const mapStateToProps = (state) => ({
    products: state.app_info.products,
});

const mapDispatchToProps = (dispatch) => ({
    setInitDataWithObject: (objectKey, updateData) => dispatch(setInitDataWithObject(objectKey, updateData))
});

export default connect(mapStateToProps, mapDispatchToProps)(MoveLicense)

