import React from "react";
import {ScrollView, TouchableOpacity} from "react-native";
import Class from "../constants/Style";

export const DEFAULT_LOADER_VIEW_TIME = 1500

export default ({children, refreshControl, contentContainerStyle})=>{
    return <ScrollView
        contentInsetAdjustmentBehavior={'automatic'}
        contentContainerStyle={[{flexGrow: 1}, contentContainerStyle]} refreshControl={refreshControl}>
        {/*<TouchableOpacity style={Class.flex}>*/}
        {children}
        {/*</TouchableOpacity>*/}
    </ScrollView>
}
