import {StyleSheet} from 'react-native';
import {isIOS, isWeb} from "../libs/settings";
import Colors, {colorBorder, errorColor} from "./Colors";

export const linkColor = {light: "#3174de", dark: "#3174de"};

export const pickerSelectStyles = StyleSheet.create({
    inputIOS       : {
        fontSize         : 16, paddingVertical: 12, paddingHorizontal: 0, color: 'black', paddingRight: 30, // to ensure the text is never behind the icon
        borderBottomWidth: 1, borderColor: "#D9D5DC",
    }, inputAndroid: {
        fontSize         : 16, paddingHorizontal: 0, paddingVertical: 8, color: 'black', paddingRight: 30, // to ensure the text is never behind the icon
        borderBottomWidth: 0.2, borderColor: "#D9D5DC",
    },
});

export default StyleSheet.create({
    headerStyle                     : {
        borderWidth      : 0, shadowOpacity: 0, elevation: 0, // remove shadow on Android
        borderBottomWidth: 0
    }, headerStyle2                 : {
        shadowOpacity: 0, elevation: 0, // remove shadow on Android
    }, flex                         : {flex: 1}, flex12: {flex: 12}, flex9: {flex: 9}, flex8: {flex: 8}, flex4: {flex: 4}, flex3: {flex: 3}, flex2: {flex: 2}, container: {
        flex: 1,
    }, width100                     : {
        width: "100%"
    }, display_flex:{
        display: "flex"
    }, row                          : {
        flexDirection: "row", alignItems: "center",
    }, alignItemsFlexStart          : {
        alignItems: "flex-start"
    }, justifySpaceBetween          : {
        justifyContent: "space-between"
    }, justifySpaceEvenly           : {
        justifyContent: "space-evenly"
    }, column                       : {
        flexDirection: "column", alignItems: "center",
    }, logo                         : {
        width: 22, height: 22,
    }, rowWrap                      : {
        flexDirection: "row", alignSelf: "stretch", alignItems: "center", flexWrap: 'wrap'
    }, textbox                      : {
        marginBottom: 16, borderBottomWidth: 0, borderBottomColor: '#CCC',
    }, whatsAppInput                : {
        padding: "16px"
    }, pageHeader                   : {
        fontSize: 35, marginBottom: 16
    }, textSmall                    : {
        fontSize: 10, color: "#CCC",
    }, textDarkGrey                 : {
        color: "#969696"
    }, textCenter                   : {
        textAlign: "center"
    }, textJustify                  : {
        textAlign: "justify"
    }, invoiceAddress               : {
        fontSize: 20
    }, textBold900                  : {
        fontWeight: "900"
    }, textBold600                  : {
        fontWeight: "600"
    }, invoiceNo                    : {
        fontSize: 28, fontWeight: "bold"
    }, textRight                    : {
        textAlign: "right",
    }, alignSelfStretch             : {
        alignSelf: "stretch"
    }, separatormargin              : {
        marginLeft: 68,
    }, separator                    : {
        marginLeft: 56, height: 1, width: '100%',
    }, vSeparator                   : {
        width: 1, height: '100%',
    }, marginRight4                 : {
        marginRight: 4
    }, marginLeft0                  : {
        marginLeft: 0
    }, marginBottom4                : {
        marginBottom: 4
    }, marginBottom16               : {
        marginBottom: 16
    }, invoiceMarginBottom          : {
        marginBottom: 96
    }, marginBottom8                : {
        marginBottom: 8
    }, marginRight8                 : {
        marginRight: 8
    }, marginRight16                : {
        marginRight: 16
    }, marginLeft8                  : {
        marginLeft: 8
    }, marginLeft16                 : {
        marginLeft: 16
    }, marginTop4                   : {
        marginTop: 4
    }, marginTop0                   : {
        marginTop: 0
    }, marginTop8                   : {
        marginTop: 8
    }, marginTop12                  : {
        marginTop: 12
    }, marginTop16                  : {
        marginTop: 16
    }, margin16                     : {
        margin: 16
    }, marginTop20                  : {
        marginTop: 20
    }, margin0                      : {
        margin: 0
    }, marginBottom0                : {
        marginBottom: 0
    }, userGroup                    : {
        backgroundColor: "rgb(235, 236, 240)", fontWeight: "bold"
    }, paddingLeftRight16           : {
        paddingLeft: 16, paddingRight: 16,
    }, paddingLeftRight22           : {
        paddingLeft: 18, paddingRight: 18,
    }, paddingTopBottom8            : {
        paddingTop: 8, paddingBottom: 8,
    }, paddingTopBottom4            : {
        paddingTop: 4, paddingBottom: 4,
    }, paddingBottom4               : {
        paddingBottom: 4,
    }, paddingBottom16              : {
        paddingBottom: 16,
    }, paddingTopBottom16           : {
        paddingTop: 16, paddingBottom: 16,
    }, paddingTop10                 : {
        paddingTop: 10,
    }, dropDownContainer            : {
        borderWidth: 1, borderStyle: "solid", borderColor: "#CCCCCCFF", borderRadius: 5
    }, padding0                     : {
        padding: 0,
    }, paddingBottom0               : {
        paddingBottom: 0,
    }, paddingLeftRight0            : {
        paddingLeft: 0, paddingRight: 0
    }, padding16                    : {
        padding: 16,
    }, padding8                     : {
        padding: 8,
    }, padding4                     : {
        padding: 4,
    }, padding6                     : {
        padding: 6,
    }, iconCircle                   : {
        margin: 8, marginRight: 8, width: 35, height: 35, backgroundColor: "#E6E6E6", borderRadius: 4, alignItems: "center", justifyContent: "center",
    }, iconSquareBg                 : {
        margin: 8, marginRight: 8, width: 25, height: 25, backgroundColor: "#E6E6E6", borderRadius: 4, alignItems: "center", justifyContent: "center",
    }, iconRound                    : {
        borderRadius: 50,
    }, mainIcon                     : {
        fontSize: 14, alignSelf: "center"
    }, title                        : {
        fontSize: 22,
    }, description                  : {
        padding: 12, borderRadius: 10, marginTop: 8
    }, chatContainer                : {
        marginLeft: 16, marginRight: 22, marginBottom: 16, alignSelf: "stretch",
    }, chatContent                  : {
        alignSelf: "stretch", width: "80%", position: "relative",
    }, userIcon                     : {
        fontSize: 14, marginLeft: 4, marginRight: 4
    }, userInfo                     : {
        fontWeight: "300", marginLeft: 4, marginRight: 4
    }, chatUserIcon                 : {
        fontSize: 16, transform: [{
            rotate: "45.00deg"
        }], left: -2, top: 28, position: "absolute", zIndex: -1
    }, chartResponseIcon            : {
        color        : "#3d86f6", fontSize: 16, transform: [{
            rotate: "-140.00deg"
        }], alignSelf: "flex-end", right: -2, top: 28, position: "absolute", zIndex: -1
    }, attachmentContainer          : {
        flexDirection: "row", marginTop: 16, alignItems: "center",
    }, conversationText             : {
        borderRadius: 10, alignSelf: "stretch", padding: 8, overflow: "hidden"
    }, attachmentIcon               : {
        fontSize: 14, marginRight: 4
    }, attachmentText               : {
        fontSize: 12, marginRight: 4
    }, keyboard                     : {
        alignSelf: "stretch", minHeight: 96,
    }, materialUnderlineTextbox1    : {
        paddingLeft: 8, paddingRight: 8, paddingTop: 16, paddingBottom: 16, alignSelf: "stretch", minHeight: 44, maxHeight: 196,
    }, buttonAttachment1            : {
        height: 44, width: 44
    }, cupertinoButtonBlueTextColor1: {
        width: 100, marginRight: -16,
    }, marginLeftm16                : {
        marginLeft: -16
    }, rply                         : {
        height: 50, flexDirection: "row", justifyContent: "space-between", alignItems: "center", alignSelf: "stretch"
    }, headerRightAction            : {
        height: 32, width: isIOS ? "auto" : 32, alignItems: "center", justifyContent: "center", marginRight: isIOS ? 0 : 8, marginLeft: isIOS ? 0 : 8
    }, attachedListView             : {
        paddingLeft: 8, paddingRight: 8, flexDirection: "row", alignSelf: "stretch", flexWrap: 'wrap', overflow: "scroll"
    }, optionsButtons               : {
        flexDirection: "row", alignItems: "center",
    }, dropdownContainer            : {
        position    : "absolute", top: 0, left: 0, right: 0, zIndex: 10, shadowColor: "rgba(128,128,128,0.3)", shadowOffset: {
            width: 0, height: 3
        }, elevation: 15, shadowOpacity: 1, shadowRadius: 5,
    }, loginContainer               : {
        flex: 1, marginTop: 40
    }, loginView                    : {
        justifyContent: "space-between", flex: 1, padding: 16, paddingRight: 16, paddingLeft: 16, paddingTop: 32, paddingBottom: 32, width: 375, alignSelf: "center"
    }, loginTopView                 : {
        alignSelf: "stretch", marginBottom: 16
    }, loginlogo                    : {
        width: 22, height: 22, marginRight: 8
    }, productimage                 : {
        width: 32, height: 32, marginRight: 8
    }, dhru                         : {
        fontSize: 22, marginRight: 8,
    }, newUser                      : {
        color: "rgba(23,43,77,1)", marginRight: 8
    }, createAnAccount              : {
        color: "rgba(19,84,197,1)", marginRight: 8
    }, loginButtonView              : {
        flexDirection: "row", justifyContent: "space-between", alignSelf: "stretch", marginBottom: 16, marginTop: 24,
    }, resetYouPassword             : {
        color: "rgba(19,84,197,1)"
    }, attachContainer              : {
        flexDirection: "row", alignSelf: "flex-start", alignItems: "center", padding: 4, borderWidth: 1, borderColor: "rgb(200,200,200)", borderRadius: 8, marginRight: 4, marginTop: 4, marginBottom: 4
    }, clipicon                     : {
        color: "rgb(128,128,128)", fontSize: 18
    }, attachementtext              : {
        color: "rgb(128,128,128)", marginRight: 4, marginLeft: 4, maxWidth: 250, overflow: "hidden",
    }, close                        : {
        color: "rgb(128,128,128)", fontSize: 22
    }, headerDropView               : {
        flexDirection: "row", alignItems: "center", paddingRight: isIOS ? 0 : 16, paddingLeft: isIOS ? 0 : 16
    }, headerStatusText             : {
        marginRight: 8, fontSize: 16,
    }, iconButtonContainer          : {
        backgroundColor: "transparent", justifyContent: "center", alignItems: "center", flexDirection: "row", borderRadius: 5
    }, buttonIcon                   : {
        color: "rgba(167,174,183,1)", fontSize: 24
    }, bigtext                      : {
        color: "rgb(25,43,75)", fontWeight: "700", fontSize: 30,
    }, subtext                      : {
        color: "rgba(167,174,183,1)", fontSize: 18,
    }, contenttext                  : {
        color: "rgba(167,174,183,1)", paddingTop: 8
    }, successContainer             : {
        alignItems: "center", justifyContent: "center", flex: 1, marginTop: 40
    }, successSubContainer          : {
        alignSelf: "stretch", alignItems: "center", justifyContent: "center", marginTop: 0, padding: 16, marginBottom: 48
    }, iconCheck                    : {
        color: "rgba(133,182,93,1)", fontSize: 40, margin: 16
    }, displayNote                  : {
        alignSelf: "stretch", marginTop: 48, marginBottom: 48
    }, multiline                    : {
        marginBottom: 16, alignSelf: "stretch",
    }, loginLabel                   : {
        fontSize: 22, marginBottom: 8
    }, errorStyle                   : {
        paddingLeft: 12, paddingRight: 12, paddingTop: 4, paddingBottom: 4
    }, errorText                    : {
        color: errorColor
    }, formContainer                : {
        flex: 1, marginTop: 40
    }, formGroup                    : {
        justifyContent: "space-between", flex: 1, padding: 16, paddingRight: 16, paddingLeft: 16, paddingTop: 32, paddingBottom: 32, width: 375, alignSelf: "center"
    }, linkStyle                    : {
        color: linkColor.light, fontWeight: "400", marginRight: 8,
    }, linkBorderStyle              : {
        color: linkColor.light, fontWeight: "400", borderBottomColor: linkColor.light, borderBottomWidth: "1px", borderStyle: "1px",
    }, buttonRowContainer           : {
        flexDirection: "row", justifyContent: "space-between", alignSelf: "stretch", marginBottom: 16, marginTop: 24,
    }, bothCenter                   : {
        alignItems: "center", justifyContent: "center", flex: 1, padding: 16
    }, icon                         : {
        color: "rgba(128,128,128,1)", fontSize: 56
    }, message                      : {
        marginTop: 16, fontSize: 18, textAlign: "center"
    }, profileHeader                : {
        height: 184, alignItems: "center", justifyContent: "center", alignSelf: "stretch"
    }, fullName                     : {
        fontSize: 20, margin: 4
    }, email                        : {
        margin: 4
    }, profileItemContainer         : {
        flexDirection: "row", alignItems: "center", borderWidth: 1, padding: 16, borderColor: "rgba(242,247,249,1)"
    }, licon                        : {
        fontSize: 20, marginRight: 16, marginLeft: 16, alignSelf: "center"
    }, label                        : {
        fontSize: 20, marginRight: 16, marginLeft: 16, flex: 1,
    }, pointicon                    : {
        fontSize: 25, marginRight: 16, marginLeft: 16, alignSelf: "center",
    }, search                       : {
        shadowRadius: 0, shadowOpacity: 0, elevation: 0, backgroundColor: "#eee", borderRadius: 50, marginLeft: 16, marginRight: 16, marginTop: 8, height: 40
    }, productTitle                 : {
        fontSize: 30, marginBottom: 4
    }, listTitle                    : {
        fontSize: 16, fontWeight: "400",
    }, domainText                   : {
        marginBottom: 2
    }, licenseText                  : {
        fontWeight: "300", fontSize: 10
    }, addOnDescription             : {
        fontWeight: "300", fontSize: 12,
    }, alignSelfFlexStart           : {
        alignSelf: "flex-start"
    }, alignItemFlexEnd             : {
        alignItems: "flex-end"
    }, alignItemStartEnd            : {
        alignItems: "flex-start"
    }, addOnPriceContainer          : {
        alignItems: "flex-end", alignSelf: "stretch"
    }, addOnPrice                   : {
        fontWeight: "700", fontSize: 14
    }, textDecorationLineThrough    : {
        textDecorationLine: "line-through"
    }, textCapitalize               : {
        textTransform: 'capitalize'
    }, hidePrint                    : {}, maxFormWidth: {
        maxWidth: isWeb ? 512 : "100%"
    }, buyButton                    : {
        alignSelf: isWeb ? "auto" : "stretch", marginTop: 16
    }, bottomView                   : {
        alignSelf: "stretch", padding: 22, height: 80
    }, listDescriptionText          : {
        color: "rgba(28, 42, 72, 0.54)"
    }, fixWidthOnWebButton          : {
        width: isWeb ? 208 : "100%"
    }, tabs                         : {
        alignSelf: "stretch", flexDirection: "row", justifyContent: "space-between"
    }, activeTab                    : {
        borderBottomWidth: 1, borderBottomColor: Colors.light.primary
    }, activeTabText                : {
        color: Colors.light.primary
    }, tab                          : {
        flex: 1, justifyContent: "center", borderBottomWidth: 1, borderBottomColor: "transparent"
    }, tabName                      : {
        color: "#121212", padding: 16, alignSelf: "center", fontSize: 18, fontWeight: "500"
    }, qntInputStyle                : {
        width: 32, marginLeft: 4, marginRight: 4, borderWidth: 1, textAlign: "center", padding: 4, borderColor: "#eee", borderRadius: 4,
    }, paymentIcon                  : {
        marginRight: 8, color: "#ccc", fontSize: 18
    }, paymentRow                   : {
        flexDirection: "row", alignItems: "center", padding: 16, justifyContent: "center"
    }, alignItemCenter              : {alignItems: "center"}, transparent: {backgroundColor: "transparent"}, chip: {
        backgroundColor: "#D9D5DC", paddingLeft: "12px", paddingRight: "12px", paddingTop: "4px", minWidth: "10px", paddingBottom: "4px", fontSize: "12px", borderRadius: "12px"
    }, chipText                     : {
        fontSize: "12px"
    }, imageWithTextContainer       : {
        cursor: "pointer"
    }, imageWithTextLabel           : {
        fontSize: "14px", fontWeight: "bold", textTransform: "capitalize", paddingTop: "4px", paddingBottom: "4px"
    }, imageWithTextImageContainer  : {
        borderWidth: "1px", borderColor: colorBorder, borderStyle: "dotted"
    }, borderBottom                 : {
        borderBottomWidth: "1px", borderColor: colorBorder, borderStyle: "solid"
    }, border                       : {
        borderWidth: "1px", borderColor: colorBorder, borderStyle: "solid", borderRadius: 5
    }, borderPrimaryColor           : {
        borderColor: Colors.light.primary,
    }, textPrimaryColor             : {
        color: "#3174de"
    }, aliasContainer               : {}, aliasText: {
        fontSize: 12
    }, dialogViewContainer          : {
        padding: "16px", borderRadius: 4,
    }, fullScreen                   : {
        height: "100%", width: "100%"
    }, small                        : {
        width: "100%", maxWidth: "300px"
    }, medium                       : {
        width: "100%", maxWidth: "500px"
    }, large                        : {
        width: "100%", maxWidth: "800px"
    }, dialogTitle                  : {
        fontWeight: "bold", fontSize: 18
    }, dialogContentView            : {}, dialogActionView: {
        flex: 1, justifyContent: "center", alignItems: "flex-end"
    }
});

export const htmlview = StyleSheet.create({
    div: {
        color: '#ffffff', // make links coloured pink
    },
});

export const captchaContentStyle = StyleSheet.create({
    div : {
        fontSize: 10, color: "#ccc", textAlign: "center"
    }, a: {
        fontSize: 10, color: Colors.light.primary
    }
});

export const registerContentStyle = StyleSheet.create({
    div  : {
        fontSize: 11, color: "#1c2a48", marginTop: 8, marginBottom: 16
    }, a : {
        fontSize: 11, color: Colors.light.primary
    }, ul: {
        marginLeft: 8,
    }
});

