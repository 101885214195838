import React, {Component} from "react";
import {ContainedButton, View} from "../components/Themed";
import {joinWorkspace} from "../libs/ServerRequest/api";
import {getSmallWidth, signOutPromise} from "../libs/function";
import {connect} from "react-redux";
import requestApi, {actions, methods, SUCCESS} from "../libs/ServerRequest";
import FullScreenMessage from "../components/FullScreenMessage";
import Class from "../constants/Style";
import {setInitData} from "../libs/Store/actions/app_info";

class JoinWorkspace extends Component<any, any> {

    a: any;
    workspace_name: any

    constructor(props: any) {
        super(props);
        const {route} = this.props;
        if (route?.params?.a) {
            this.a = route?.params?.a;
        }
        this.state = {
            workspace_name: ""
        }
    }

    componentDidMount() {
        const {authUser, navigation} = this.props;
        if (this.a) {
            joinWorkspace(undefined, {a: this.a}).then((response: any) => {
                if (response?.status === SUCCESS) {
                    this.setState({
                        workspace_name: response?.data?.workspace_name
                    })
                    if (!Boolean(authUser?.token) || Boolean(authUser?.email !== response?.data?.email)) {
                        signOutPromise().then(() => {
                            if (response?.data?.login) {
                                navigation.replace("Login", {a: this.a, email: response?.data?.email})
                            }
                            if (response?.data?.registration) {
                                navigation.replace("Register", {a: this.a, email: response?.data?.email})
                            }
                        })
                    }
                } else {
                    this.checkRedirectProcess()
                }
            })
        } else {
            this.checkRedirectProcess()
        }
    }

    render() {

        const {workspace_name} = this.state;
        let title = "Join the workspace";
        if (workspace_name) {
            title += ` "${workspace_name}"`
        }
        return (
            <FullScreenMessage title={title}>
                <View style={Class.marginTop16}>
                    <ContainedButton onPress={this._onPress}>
                        Join
                    </ContainedButton>
                </View>
            </FullScreenMessage>
        );
    }


    checkRedirectProcess = () => {
        const {authUser, navigation} = this.props;
        if (Boolean(authUser?.token)) {
            navigation.replace('WorkspaceRoot', {
                screen: 'WorkspaceNav',
                params: {
                    screen: 'Workspaces',
                },
            })

        } else {
            navigation.navigate("Login")
        }
    }

    _onPress = () => {
        const {navigation, setInitData} = this.props;

        joinWorkspace(methods.post, undefined, {a: this.a}).then((response: any) => {
            if (response?.status === SUCCESS) {
                // navigation.replace('WorkspaceRoot', {
                //     screen: 'WorkspaceNav',
                //     params: {
                //         screen: 'Workspaces',
                //     },
                // })
                requestApi({
                    method: methods.get,
                    action: actions.logininit,
                }).then((response: any) => {
                    if (response.status === SUCCESS) {
                        setInitData(response.data);
                        this.checkRedirectProcess()
                    }
                })
                // loginPromise(clone({
                //     token: response.token,
                //     ...response.data,
                // })).then(()=>{
                //     requestApi({
                //         method: methods.get,
                //         action: actions.logininit,
                //     }).then((response:any) => {
                //         if (response.status === SUCCESS) {
                //             setInitData(response.data);
                //
                //         }
                //     })
                // })
            } else {
                this.checkRedirectProcess()
            }
        })
    }


}

const mapStateToProps = (state: any) => ({
    authUser: state.app_info.authUser,
});

const mapDispatchToProps = (dispatch: any) => ({
    setInitData: (initData: any) => dispatch(setInitData(initData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JoinWorkspace);

