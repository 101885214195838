const SET_DIALOG = "SET_DIALOG";
const SET_ALERT = "SET_ALERT";
const SET_LOADER = "SET_LOADER";
const SET_BOTTOMSHEET = "SET_BOTTOMSHEET";
const SET_SNACKBAR = "SET_SNACKBAR";
const SET_POP_PAGE = "SET_POP_PAGE";


export {
    SET_DIALOG,
    SET_ALERT,
    SET_LOADER,
    SET_BOTTOMSHEET,
    SET_SNACKBAR,
    SET_POP_PAGE
};
