import {Snackbar} from "react-native-paper";
import * as React from "react";
import {setAuthUser, setInitData, setLoadingAndToken} from "../libs/Store/actions/app_info";
import {connect} from "react-redux";
import {setSnackbar} from "../libs/Store/actions/components";
import {ERROR, SUCCESS} from "../libs/ServerRequest";

const Snack = ({snackbar, setSnackbar}) => {
    const {visible, message, type} = snackbar;
    let backgroundColor = "black";
    if (type === ERROR){
        backgroundColor = "red";
    }else if (type === SUCCESS){
        backgroundColor = "green";
    }
    return <Snackbar
        style={{backgroundColor}}
        visible={visible}
        duration={3000}
        onDismiss={() => setSnackbar({visible: false, message: ""})}>
        {message}
    </Snackbar>
}

const mapStateToProps = (state) => ({
    snackbar: state.components.snackbar
});

const mapDispatchToProps = (dispatch) => ({
    setSnackbar: (snackData) => dispatch(setSnackbar(snackData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Snack);
