const tintColorLight = '#2f95dc';
const tintColorDark = '#fff';
const btnColorLight = '#fff';
export const errorColor = "#d25455";
export const grayIcon = "rgba(28, 28, 30, 0.68)";

export const lightDescription = "rgba(28,42,72,0.54)";
export const darkDescription = "rgba(235,235,235,0.54)";

export const lightTitle = "rgba(28,42,72,0.87)";
export const darkTitle = "rgba(235,235,235,0.87)";

export const largeLightHeaderColor = "#1c2a48"
export const largeDarkHeaderColor = "#fff"

export const lightChatTailUser = "rgba(232,233,235,1)";
export const darkChatTailUser = "#2f2f2f";

export const colorBorder = "#D9D5DC";

export default {
  light: {
    primary: '#3174de',
    accent: '#f1c40f',
    text: '#1c2a48',
    background: '#fff',
    tint: tintColorLight,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
    btnColorLight : btnColorLight,
    errorColor,
    disabled:"#ccc"
  },
  dark: {
    primary: '#1f50c5',
    text: '#fff',
    background: 'rgb(30,30,30)',
    tint: tintColorDark,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorDark,
    btnColorLight :btnColorLight,
    errorColor,
    disabled:"red"
  },
};
