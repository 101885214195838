import React, {Component} from "react";
import {ScrollView, Linking} from "react-native";
import {setInitData} from "../libs/Store/actions/app_info";
import {connect} from "react-redux";
import {View} from "../components/Themed";
import TitleAndSubtitle from "../components/TitleAndSubtitle";
import SupportItem from "../components/SupportItem";
import Class from "../constants/Style"
import RootPage from "../components/RootPage";
import DhruScrollView from "../components/DhruScrollView";

class SupportRequest extends Component {
    render() {
        const {navigation, support_category} = this.props;
        return (
            <RootPage navigation={navigation} headerTitle={"Support Request"}>
            <View style={[Class.container]}>
                <DhruScrollView>
                    {
                        Object.keys(support_category).map((objectKey, index) => {
                            const {name, info, icon, colour, link} = support_category[objectKey];
                            return <SupportItem
                                key={index}
                                title={name}
                                description={info}
                                icon={icon}
                                colour={colour}
                                onPress={(data) => {
                                    if (Boolean(link)) {
                                        Linking
                                            .openURL(link)
                                            .catch((err) => console.error('An error occurred', err));
                                    } else {
                                        navigation.navigate("SubcategoryScreen", {id: objectKey})
                                    }
                                }}
                            />
                        })
                    }
                </DhruScrollView>
            </View>
            </RootPage>
        );
    }
}

const mapStateToProps = (state) => ({
    support_category: state.app_info.static.support_category
});

const mapDispatchToProps = (dispatch) => ({
    setInitData: (initData) => dispatch(setInitData(initData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SupportRequest);
