import * as React from "react";
import Class from "../constants/Style";
import {IosIcon, List, Text, useThemeColor, View} from './Themed';

export default function CommonListItem({title, left, description,onPress, style, separatoryStyle,rightIconName, lightColor, darkColor, titleNumberOfLines, descriptionNumberOfLines}:any) {
    let right;
    if (rightIconName){
        right = (props)=><IosIcon style={{fontSize:32, paddingRight:8}} name={rightIconName} />
    }
    return <React.Fragment>
        <List
            title={title}
            left={left}
            style={[{
                paddingLeft:0,
                paddingRight:0,
                marginLeft:-8
            }, style]}
            description={description}
            onPress={onPress}
            right={right}
            titleNumberOfLines={titleNumberOfLines}
            descriptionNumberOfLines={descriptionNumberOfLines}
        />
        <View style={[Class.separator, Class.marginLeft0, separatoryStyle]} lightColor="#eee" darkColor="rgba(255,255,255,0.1)"/>
    </React.Fragment>
}
