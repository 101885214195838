import * as React from "react";
import {IconDefault, IosIcon as IconDefaultIos} from "./Themed";

export default function Icon(props: any) {
    return <IconDefault {...props}   />
}

export function IosIcon(props: any) {
    return <IconDefaultIos {...props}   />
}
