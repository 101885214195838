import * as React from 'react';
import {
    Dimensions,
    KeyboardAvoidingView,
    ScrollView,
    StyleSheet,
} from "react-native";
import requestApi, {
    actions,
    methods,
    SUCCESS
} from "../libs/ServerRequest";
import {
    setAuthUser,
    setInitData,
    setLoadingAndToken
} from "../libs/Store/actions/app_info";
import {connect} from "react-redux";
import Class from '../constants/Style';
import ReCaptchaV3 from "@haskkor/react-native-recaptchav3";
import {
    isIOS,
    isWeb
} from "../libs/settings";
import {withGoogleReCaptcha} from "react-google-recaptcha-v3";
import {
    ContainedButton,
    Text,
    TextInputPaper,
    View
} from "../components/Themed";
import {
    isEmpty,
    STORAGE_KEY,
    storeData
} from "../libs/function";
import {
    validEmail,
    validPassword,
    validRequired
} from "../libs/validate";
import DhruLogo from "../components/DhruLogo";
import RootPage from "../components/RootPage";
import {setLoader} from "../libs/Store/actions/components";
import LinkButton from "../components/LinkButton";

import SelectCountry from "../components/SelectCountry"
import TocView from "../components/TocView";
import DhruSelect from "../components/DhruSelect";
import {country as countryDataList} from "../libs/static";


class Register
    extends React.Component<any, any> {

    constructor(props) {
        super(props)
        let data = {
            email    : "",
            password : "",
            cpassword: ""
        };

        const {
                  route,
                  client_geo_info,
                  countryList
              }                = props;
        let disabledEmailField = false;
        if (route?.params) {
            let {email} = route.params;
            if (email) {
                data.email         = email
                disabledEmailField = true;
            }
        }

        let selectedCountry = client_geo_info?.code || "IN";

        let code = countryDataList[selectedCountry].d

        this.state = {
            profile    : {
                mobile_number: "",
                first_name   : "",
                last_name    : "",
                company_name : "",
                address1     : "",
                address2     : "",
                country      : selectedCountry,
                city         : "",
                state        : "",
                postcode     : "",
                code, ...data
            },
            captcha    : "",
            captchaCall: false,
            error      : {},
            step       : 0,
            disabledEmailField
        }

        this.inputRefs = [
            React.createRef(),
            React.createRef(),
            React.createRef(),
            React.createRef(),
            React.createRef(),
            React.createRef(),
            React.createRef(),
            React.createRef(),
            React.createRef(),
            React.createRef(),
            React.createRef(),
            React.createRef(),
        ]
    }

    componentDidMount() {
        //this.requestInit();
    }

    validate = () => {
        const {
                  profile,
                  step
              }              = this.state;
        const {fromCheckout} = this.props;
        const {
                  email,
                  password,
                  mobile_number,
                  first_name,
                  last_name,
                  company_name,
                  address1,
                  address2,
                  country,
                  city,
                  state,
                  postcode,
                  cpassword
              }              = profile;
        let error            = {};

        if (step === 0) {
            error = {...error, ...validEmail(error, "email", email)};
            error = {...error, ...validPassword(error, "password", password)};

            if (!Boolean(cpassword)) {
                error = {
                    ...error,
                    cpassword: "Please enter confirm password"
                };
            } else if (password !== cpassword) {
                error = {
                    ...error,
                    cpassword: "Confirm password not match with password"
                };
            }
        } else {
            // if (fromCheckout){
            //     error = {...error, ...validEmail(error, "email", email)};
            //     error = {...error, ...validPassword(error, "password", password)};
            //
            //     if (!Boolean(cpassword)) {
            //         error = {
            //             ...error,
            //             cpassword: "Please enter confirm password"
            //         };
            //     } else if (password !== cpassword) {
            //         error = {
            //             ...error,
            //             cpassword: "Confirm password not match with password"
            //         };
            //     }
            // }
            error = {...error, ...validRequired(error, "first_name", first_name, "Please enter first name")};
            error = {...error, ...validRequired(error, "last_name", last_name, "Please enter last name")};
            error = {...error, ...validRequired(error, "mobile_number", mobile_number, "Please enter mobile number")};
            // error = {...error, ...validRequired(error, "company_name", company_name, "Please enter company name")};
            // error = {...error, ...validRequired(error, "address1", address1, "Please enter address 1")};
            // error = {...error, ...validRequired(error, "city", city, "Please enter city")};
            // error = {...error, ...validRequired(error, "postcode", postcode, "Please enter postal code")};
            // error = {...error, ...validRequired(error, "state", state, "Please enter state")};
            error = {...error, ...validRequired(error, "country", country, "Please select country")};
        }
        // if (!isContact) {
        //
        // } else {
        //     if (fromCheckout) {
        //         error = {...error, ...validEmail(error, "email", email)};
        //         error = {...error, ...validPassword(error, "password", password)};
        //
        //         if (!Boolean(cpassword)) {
        //             error = {
        //                 ...error,
        //                 cpassword: "Please enter confirm password"
        //             };
        //         } else if (password !== cpassword) {
        //             error = {
        //                 ...error,
        //                 cpassword: "Confirm password not match with password"
        //             };
        //         }
        //     }
        //
        // }
        this.setState({error})
        return isEmpty(error)
    }

    requestRegister = async () => {
        const {
                  setAuthUser,
                  navigation,
                  setLoadingAndToken,
                  route
              } = this.props;
        let {
                profile,
                captcha
            }   = this.state;
        if (isWeb) {
            captcha = await this.props.googleReCaptchaProps.executeRecaptcha();
        }
        this.props.setLoader({show: false});


        profile = {
            ...profile,
            mobile_number: `${profile?.code}${profile?.mobile_number}`
        }

        requestApi({
            method: methods.post,
            action: actions.register,
            body  : {"g-recaptcha-response": captcha, ...profile},
        })
            .then(async (response: any) => {
                if (response.status === SUCCESS && Boolean(response.data)) {
                    const value = {
                        token: response.token, ...response.data,
                    }
                    storeData(STORAGE_KEY.USER_DATA, value)
                        .then(() => {
                            setAuthUser({
                                token: response.token, ...response.data,
                            });

                            if (route?.params?.a) {
                                setLoadingAndToken({
                                    loading        : false,
                                    token          : response.token,
                                    defaultRedirect: ""
                                })

                                let screenName = "JoinWorkspace"

                                if (Boolean(response?.data?.whatsapp_number) && !Boolean(response?.data?.whatsapp_verified)) {
                                    screenName = "VerifyWhatsApp"
                                }

                                navigation.navigate(screenName, {
                                    a: route?.params?.a
                                })
                            } else {
                                setLoadingAndToken({
                                    loading: false,
                                    token  : response.token
                                })
                            }
                        })
                }
            })
    }

    setProfile = (data) => {
        this.setState({
            profile: {
                ...this.state.profile, ...data
            }
        })
    }

    requestInit = () => {
        const {
                  navigation,
                  setInitData,
                  setLoadingAndToken
              } = this.props;
        requestApi({
            method: methods.get,
            action: actions.init,
        })
            .then((response) => {
                if (response.status === SUCCESS) {
                    setInitData(response.data);
                }
            })
    }

    onSubmit = () => {
        const {
                  isContact,
                  step
              } = this.state
        if (step === 0) {
            if (this.validate()) {
                this.setState({step: 2}, () => {
                    //   this.inputRefs[2].focus()
                })
            }
        } else {
            if (this.validate()) {
                if (isWeb) {
                    this.requestRegister();
                } else {
                    this.props.setLoader({show: true});
                    this.setState({captchaCall: true}, () => {
                        this._captchaRef.refreshToken()
                    });
                }
            }
        }
        // if (isContact) {
        //     if (this.validate()) {
        //         if (isWeb) {
        //             this.requestRegister();
        //         } else {
        //             this.props.setLoader({show: true});
        //             this.setState({captchaCall: true}, () => {
        //                 this._captchaRef.refreshToken()
        //             });
        //         }
        //     }
        // } else {
        //     if (this.validate()) {
        //         this.setState({isContact: true}, () => {
        //             this.inputRefs[2].focus()
        //         })
        //     }
        // }
    }

    ContactField = () => {

        const {
                  route,
                  navigation,
                  setInitData,
                  setLoadingAndToken,
                  countryList,
                  fromCheckout,
                  redirectPage
              } = this.props;
        const {
                  profile,
                  error,
                  captchaCall
              } = this.state
        const {
                  email,
                  password,
                  mobile_number,
                  first_name,
                  last_name,
                  company_name,
                  address1,
                  address2,
                  country,
                  city,
                  state,
                  postcode,
                  cpassword,
                  captcha,
                  code
              } = profile;


        const optionCountry = Boolean(countryList) ? Object.keys(countryList)
            .map((c) => {
                return {
                    label: countryList[c],
                    value: c
                }
            }) : [];

        return <React.Fragment>
            <TextInputPaper
                label={"First Name"}
                placeholder={"First Name"}
                value={first_name}
                autoCompleteType={"name"}
                textContentType={"name"}
                keyboardType={"default"}
                error={error.first_name}
                onChangeText={(first_name) => this.setProfile({first_name})}
                style={[Class.textbox]}
                returnKeyType="next"
                ref={ref => this.inputRefs[2] = ref}
                onSubmitEditing={() => this.inputRefs[3].focus()}
            />
            <TextInputPaper
                label={"Last Name"}
                placeholder={"Last Name"}
                value={last_name}
                autoCompleteType={"name"}
                textContentType={"name"}
                keyboardType={"default"}
                error={error.last_name}
                onChangeText={(last_name) => this.setProfile({last_name})}
                style={Class.textbox}
                returnKeyType="next"
                ref={ref => this.inputRefs[3] = ref}
                onSubmitEditing={() => this.inputRefs[4].focus()}
            />

            <DhruSelect
                label={"Country"}
                options={optionCountry}
                containerStyle={[Class.marginBottom8]}
                onChange={(value) => {
                    let code = countryDataList[value]?.d
                    this.setProfile({
                        country: value,
                        code
                    });
                }}
                value={country}
            />

            <TextInputPaper
                label={"Mobile"}
                placeholder={"Mobile"}
                value={mobile_number}
                error={error.mobile_number}
                autoCompleteType={"tel"}
                leftAffixText={`+${code} `}
                keyboardType={"phone-pad"}
                textContentType={"telephoneNumber"}
                onChangeText={(mobile_number) => this.setProfile({mobile_number})}
                style={Class.textbox}
                returnKeyType='done'
                ref={ref => this.inputRefs[4] = ref}
                onSubmitEditing={() => this.inputRefs[5].focus()}
            />
            {/*<TextInputPaper*/}
            {/*    label={"Company Name"}*/}
            {/*    placeholder={"Company Name"}*/}
            {/*    value={company_name}*/}
            {/*    error={error.company_name}*/}
            {/*    keyboardType={"default"}*/}
            {/*    autoCompleteType={"off"}*/}
            {/*    textContentType={"organizationName"}*/}
            {/*    onChangeText={(company_name) => this.setProfile({company_name})}*/}
            {/*    style={Class.textbox}*/}
            {/*    returnKeyType="next"*/}
            {/*    ref={ref => this.inputRefs[5] = ref}*/}
            {/*    onSubmitEditing={() => this.inputRefs[6].focus()}*/}
            {/*/>*/}
            {/*<TextInputPaper*/}
            {/*    label={"Address 1"}*/}
            {/*    placeholder={"Address 1"}*/}
            {/*    value={address1}*/}
            {/*    error={error.address1}*/}
            {/*    keyboardType={"default"}*/}
            {/*    textContentType={"streetAddressLine1"}*/}
            {/*    autoCompleteType={"street-address"}*/}
            {/*    onChangeText={(address1) => this.setProfile({address1})}*/}
            {/*    style={[Class.textbox]}*/}
            {/*    returnKeyType="next"*/}
            {/*    ref={ref => this.inputRefs[6] = ref}*/}
            {/*    onSubmitEditing={() => this.inputRefs[7].focus()}*/}
            {/*/>*/}
            {/*<TextInputPaper*/}
            {/*    label={"Address 2"}*/}
            {/*    placeholder={"Address 2"}*/}
            {/*    value={address2}*/}
            {/*    keyboardType={"default"}*/}
            {/*    textContentType={"streetAddressLine2"}*/}
            {/*    autoCompleteType={"street-address"}*/}
            {/*    onChangeText={(address2) => this.setProfile({address2})}*/}
            {/*    style={Class.textbox}*/}
            {/*    returnKeyType="next"*/}
            {/*    ref={ref => this.inputRefs[7] = ref}*/}
            {/*    onSubmitEditing={() => this.inputRefs[8].focus()}*/}
            {/*/>*/}
            {/*<TextInputPaper*/}
            {/*    label={"City"}*/}
            {/*    placeholder={"City"}*/}
            {/*    value={city}*/}
            {/*    error={error.city}*/}
            {/*    keyboardType={"default"}*/}
            {/*    textContentType={"addressCity"}*/}
            {/*    autoCompleteType={"off"}*/}
            {/*    onChangeText={(city) => this.setProfile({city})}*/}
            {/*    style={[Class.textbox]}*/}
            {/*    returnKeyType="next"*/}
            {/*    ref={ref => this.inputRefs[8] = ref}*/}
            {/*    onSubmitEditing={() => this.inputRefs[9].focus()}*/}
            {/*/>*/}
            {/*<TextInputPaper*/}
            {/*    label={"PIN Code"}*/}
            {/*    placeholder={"PIN Code"}*/}
            {/*    value={postcode}*/}
            {/*    error={error.postcode}*/}
            {/*    keyboardType={"default"}*/}
            {/*    textContentType={"postalCode"}*/}
            {/*    autoCompleteType={"postal-code"}*/}
            {/*    onChangeText={(postcode) => this.setProfile({postcode})}*/}
            {/*    style={Class.textbox}*/}
            {/*    returnKeyType="next"*/}
            {/*    ref={ref => this.inputRefs[9] = ref}*/}
            {/*    onSubmitEditing={() => this.inputRefs[10].focus()}*/}
            {/*/>*/}
            {/*<TextInputPaper*/}
            {/*    label={"State"}*/}
            {/*    placeholder={"State"}*/}
            {/*    value={state}*/}
            {/*    error={error.state}*/}
            {/*    keyboardType={"default"}*/}
            {/*    textContentType={"addressState"}*/}
            {/*    autoCompleteType={"off"}*/}
            {/*    onChangeText={(state) => this.setProfile({state})}*/}
            {/*    style={[Class.textbox]}*/}
            {/*    returnKeyType="done"*/}
            {/*    ref={ref => this.inputRefs[10] = ref}*/}
            {/*    onSubmitEditing={() => this.onSubmit()}*/}
            {/*/>*/}
            {/*<Picker*/}
            {/*    placeholder={{*/}
            {/*        label: 'Select Country',*/}
            {/*    }}*/}
            {/*    value={country}*/}
            {/*    onValueChange={(country) => {*/}
            {/*        this.setProfile({country})*/}
            {/*    }}*/}
            {/*    error={error.country}*/}
            {/*    items={optionCountry}*/}
            {/*/>*/}
            {/*</View>*/}
        </React.Fragment>
    }

    AccountField = () => {
        const {
                  route,
                  navigation,
                  setInitData,
                  setLoadingAndToken,
                  countryList,
                  fromCheckout,
                  redirectPage,
                  client_geo_info
              } = this.props;
        const {
                  profile,
                  error,
                  captchaCall,
                  disabledEmailField
              } = this.state
        const {
                  email,
                  password,
                  mobile_number,
                  first_name,
                  last_name,
                  company_name,
                  address1,
                  address2,
                  country,
                  city,
                  state,
                  postcode,
                  cpassword,
                  captcha
              } = profile;


        const optionCountry = Boolean(countryList) ? Object.keys(countryList)
            .map((c) => {
                return {
                    label: countryList[c],
                    value: c
                }
            }) : [];
        return <React.Fragment>

            <TextInputPaper

                label={"Email Address"}
                placeholder={"Email Address"}
                error={error.email}
                value={email}
                autoCompleteType={"email"}
                textContentType={"emailAddress"}
                keyboardType={"email-address"}
                disabled={disabledEmailField}
                onChangeText={(email) => this.setProfile({email})}
                style={Class.textbox}
                returnKeyType="next"
                onSubmitEditing={() => this.inputRefs[0].focus()}
            />
            <TextInputPaper
                label={"Password"}
                secureTextEntry={true}
                placeholder={"Password"}
                error={error.password}
                value={password}
                autoCompleteType={"password"}
                textContentType={"newPassword"}
                PasswordRules={"required: lower; required: upper; required: digit; required: [-!#$%&()*@^]; minlength: 15; maxlength: 15;"}
                keyboardType={"default"}
                onChangeText={(password) => this.setProfile({password})}
                style={Class.textbox}
                returnKeyType="next"
                ref={ref => this.inputRefs[0] = ref}
                onSubmitEditing={() => this.inputRefs[1].focus()}
            />
            <TextInputPaper
                label={"Confirm Password"}
                secureTextEntry={true}
                placeholder={"Confirm Password"}
                error={error.cpassword}
                autoCompleteType={"password"}
                textContentType={"newPassword"}
                PasswordRules={"required: lower; required: upper; required: digit; required: [-!#$%&()*@^]; minlength: 15; maxlength: 15;"}
                keyboardType={"default"}
                value={cpassword}
                onChangeText={(cpassword) => this.setProfile({cpassword})}
                style={Class.textbox}
                returnKeyType="next"
                ref={ref => this.inputRefs[1] = ref}
                onSubmitEditing={() => this.onSubmit()}
            />
        </React.Fragment>
    }

    registerView = () => {
        const {
                  navigation,
                  fromCheckout,
                  redirectPage
              }         = this.props;
        const {
                  step,
                  profile
              }         = this.state
        const {country} = profile;

        let style = {};
        if (step == 1 && !isWeb) {
            let screenHeight = Dimensions.get('window').height;
            if (fromCheckout) {
                screenHeight = screenHeight - 172;
            }
            style = {height: screenHeight}
        }

        return <View
            style={[
                Class.formContainer,
                style
            ]}
        >
            <View
                style={[
                    Class.formGroup,
                    Class.paddingBottom4
                ]}
            >
                <View
                    style={[
                        Class.alignSelfStretch,
                        Class.marginBottom16,
                        Class.flex
                    ]}
                >
                    <DhruLogo />
                    <Text style={Class.pageHeader}>Create an account</Text>
                    <View
                        style={[
                            Class.row,
                            Class.marginBottom16
                        ]}
                    >
                        <Text style={[Class.marginRight8]}>Already have an account? </Text>
                        <LinkButton
                            caption={"Sign in"}
                            onPress={() => {
                                if (Boolean(fromCheckout)) {
                                    redirectPage("Login")
                                } else {
                                    navigation.goBack()
                                }
                            }}
                            style={Class.linkStyle}
                        />
                    </View>
                    <ScrollView>
                        {step === 0 && this.AccountField()}
                        {step === 1 && <SelectCountry
                            selectedCountry={country}
                            onPress={(data: any) => {
                                this.setProfile({
                                    country: data.countrycode,
                                    code   : data.d
                                });
                                this.setState({step: 2});
                            }}
                        />}
                        {step === 2 && this.ContactField()}
                    </ScrollView>
                </View>
            </View>
        </View>
    }

    render() {
        const {
                  route,
                  navigation,
                  setInitData,
                  setLoadingAndToken,
                  countryList,
                  fromCheckout,
                  redirectPage
              } = this.props;
        const {
                  profile,
                  error,
                  isContact,
                  captchaCall,
                  step
              } = this.state
        const {
                  email,
                  password,
                  mobile_number,
                  first_name,
                  last_name,
                  company_name,
                  address1,
                  address2,
                  country,
                  city,
                  state,
                  postcode,
                  cpassword,
                  captcha
              } = profile;


        let buttonLabel = "Continue";
        if (step === 2) {
            buttonLabel = "Register"
        }

        const optionCountry = Boolean(countryList) ? Object.keys(countryList)
            .map((c) => {
                return {
                    label: countryList[c],
                    value: c
                }
            }) : [];


        let keystyle = {};
        if (step === 0) {
            keystyle = {maxHeight: isEmpty(error) ? 465 : 532}
        }
        let style = {};
        if (!fromCheckout || (step !== 0 && isWeb)) {
            let screenHeight = Dimensions.get('window').height;
            if (fromCheckout) {
                screenHeight = screenHeight - 108;
            }
            style = {height: screenHeight}
        }


        return (<RootPage navigation={navigation}>
            <View>
                {fromCheckout ? this.registerView() : <KeyboardAvoidingView
                    style={[
                        Class.container,
                        keystyle
                    ]}
                    behavior={isIOS ? "padding" : ""}
                >
                    {this.registerView()}
                </KeyboardAvoidingView>}

                <View
                    style={[
                        Class.paddingLeftRight22,
                        Class.marginBottom16,
                        {
                            width    : 375,
                            alignSelf: "center",
                            minHeight: (fromCheckout && Boolean(step === 2)) ? "300px" : "auto"
                        }
                    ]}
                >
                    {(Boolean(step === 2)) && <TocView />}
                    <View
                        style={[
                            Class.buttonRowContainer,
                            {
                                marginTop: 0,
                                height   : 40
                            }
                        ]}
                    >
                        {(Boolean(step !== 0)) ? <LinkButton
                            caption={"Back"}
                            onPress={() => this.setState({step: 0})}
                            style={[Class.linkStyle]}
                        /> : <View />}

                        {(!isWeb && captchaCall) && <ReCaptchaV3
                            ref={(ref: RecaptchaV3) => this._captchaRef = ref}
                            captchaDomain={'https://api.dhru.com'}
                            siteKey={'6LebDscZAAAAAEfuz_E-rB6r12xLHekW_XlR-Y5V'}
                            onReceiveToken={(token: string) => {
                                this.setState({
                                    captcha    : token,
                                    captchaCall: false
                                }, () => {
                                    this.requestr();
                                })
                            }}
                            action={""}
                        />}
                        {step !== 1 && <ContainedButton onPress={() => this.onSubmit()}>
                            {buttonLabel}
                        </ContainedButton>}
                    </View>
                </View>
            </View>
        </RootPage>);
    }
}


const styles = StyleSheet.create({
    screen    : {
        backgroundColor: 'lightgray',
        flex           : 1,
    },
    header    : {
        flex           : 0.33,
        backgroundColor: 'lightblue',
        justifyContent : 'center',
        alignItems     : 'center',
    },
    scrollView: {
        flex: 1,
    },
    footer    : {
        backgroundColor: 'lightpink',
        flex           : 0.33,
        alignItems     : 'center',
        justifyContent : 'center',
    },
});

const mapStateToProps = (state: any) => ({
    countryList    : state.app_info.static && state.app_info.static.country,
    client_geo_info: state.app_info.static && state.app_info.static.client_geo_info,
});

const mapDispatchToProps = (dispatch: any) => ({
    setAuthUser       : (authUser: any) => dispatch(setAuthUser(authUser)),
    setInitData       : (initData: any) => dispatch(setInitData(initData)),
    setLoadingAndToken: (data: any) => dispatch(setLoadingAndToken(data)),
    setLoader         : (loader: any) => dispatch(setLoader(loader)),
});

export default withGoogleReCaptcha(connect(mapStateToProps, mapDispatchToProps)(Register));
