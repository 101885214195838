import * as React from 'react';
import RootPage from "../components/RootPage";
import Class from "../constants/Style"
import {View, SearchText} from '../components/Themed';
import {RefreshControl, ScrollView} from "react-native";
import {connect} from "react-redux";
import OrderItem from "../components/OrderItem";
import requestApi, {actions, methods, SUCCESS} from "../libs/ServerRequest";
import {getSmallWidth, getWidth, isEmptyArray} from "../libs/function";
import EmptyPage from "../components/EmptyPage";
import {setInitDataWithObject} from "../libs/Store/actions/app_info";
import HeaderRight from "../components/HeaderRight";
import DhruScrollView, {DEFAULT_LOADER_VIEW_TIME} from "../components/DhruScrollView";

class Orders extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            filterOrder: "",
            refreshing: false
        }
    }

    componentDidMount() {
        const {navigation, route} = this.props;
        if (Boolean(route.params)) {
            const {id, from, pay} = route.params;
            if (Boolean(id) && Boolean(from) && Boolean(pay)) {
                this.onViewInvoice(id, from, pay)
            }
        }

        let nav = getSmallWidth() ?
            () => navigation.navigate("AddProductScreen"):
            () => navigation.navigate("ProductRoot",{screen: 'ProductNav', params: {screen: 'Products', params: {screen: "AddProductScreen"}}})
        navigation.setOptions({
            headerRight: () => <HeaderRight
                actions={[{
                    name: "plus",
                    caption: "Buy",
                    onPress: nav
                }]}/>
        });
    }


    onViewInvoice = (id, from, pay) => {
        this.props.navigation.navigate("ViewInvoiceScreen", {id, from, pay})
    }

    getOrders = () => {
        const {refreshing} = this.state;
        const {setInitDataWithObject} = this.props;

        requestApi({
            method: methods.get,
            action: actions.order,
            loader: !refreshing,
        }).then((response) => {

            setTimeout(() => {
                this.setState({
                    refreshing: false
                });
            }, DEFAULT_LOADER_VIEW_TIME)
            if (response.status === SUCCESS) {

                setInitDataWithObject("orders", response.data)
            }
        })
    }

    render() {
        const {navigation, orders} = this.props;
        const {filterOrder, refreshing} = this.state;
        return (
            <RootPage navigation={navigation} headerTitle={"Orders"} noBack={false}>
                <View style={Class.container}>

                    <DhruScrollView
                        refreshControl={<RefreshControl
                            tintColor={"#9e9e9e"}
                            refreshing={refreshing}
                            onRefresh={() => this.setState({refreshing: true}, () => this.getOrders())}
                        />}>


                        {
                            !isEmptyArray(orders) ?  <React.Fragment>
                                <SearchText
                                    placeholder="Search Order"
                                    onChangeText={(filterOrder) => this.setState({filterOrder})}
                                    value={filterOrder}
                                />
                                {
                                    orders
                                        .filter((order) => {
                                            return Boolean(filterOrder)
                                                ? order.order_reference_id.toString().includes(filterOrder)
                                                : true;
                                        })
                                        .map((order, index) => {
                                            return <OrderItem
                                                key={index} {...order}
                                                color={"red"}
                                                onPress={() => this.onViewInvoice(order.order_reference_id)}/>
                                        })
                                }
                            </React.Fragment>: <EmptyPage message={"You haven't any order"}/>
                        }

                    </DhruScrollView>


                </View>
            </RootPage>
        );
    }
}

const mapStateToProps = (state) => ({
    orders: state.app_info.orders,

});

const mapDispatchToProps = (dispatch) => ({
    setInitDataWithObject: (objectKey, updateData) => dispatch(setInitDataWithObject(objectKey, updateData))
});

export default connect(mapStateToProps, mapDispatchToProps)(Orders)

