import {useWindowDimensions} from "react-native";

export const LargeScreen = ({minWidth, children})=>{
    const dimensions = useWindowDimensions();
    const isLargeScreen = dimensions.width >= minWidth;
    if (!isLargeScreen){
        return null;
    }
    return children
}

export const SmallScreen = ({maxWidth, children})=>{
    const dimensions = useWindowDimensions();
    const isSmallScreen = dimensions.width < maxWidth;
    if (!isSmallScreen){
        return null;
    }
    return children
}
