import * as React from "react";
import RootPage from "../components/RootPage";
import {View} from "../components/Themed";
import Class from "../constants/Style";
import {ScrollView} from "react-native";
import {connect} from "react-redux";
import CheckoutProduct from "../components/CheckoutProduct";
import {clone} from "../libs/function";
import DhruScrollView from "../components/DhruScrollView";
import AddOnItem from "../components/AddOnItem";
import app_info from "../libs/Store/reducers/app_info";


class AddProduct extends React.Component<any, any> {
    render() {
        const {navigation, items, profile} = this.props;
        return (
            <RootPage navigation={navigation} headerTitle={"Add Product"}>
                <View style={Class.container}>
                    <DhruScrollView contentContainerStyle={Class.padding16}>
                        {
                            Object.keys(items).map((itemk, index) => {

                                const pricedata = items[itemk].price[profile.currency];
                                let addons:any;
                                if (Boolean(items[itemk].addons)){
                                    addons = Object.keys(items[itemk].addons).map((ak) => {
                                        return {
                                            addonid: ak,
                                            ...items[itemk].addons[ak],
                                            price: items[itemk].addons[ak].price[profile.currency].price,
                                            _price: items[itemk].addons[ak].price[profile.currency]._price,
                                        }
                                    })
                                }
                                return <AddOnItem
                                    onPress={() =>{
                                        if (Boolean(items[itemk].required_license_key)){
                                            navigation.navigate("LicenseKeyScreen", {
                                                product_id: itemk,
                                                addons: clone(addons)
                                            })
                                        }else if (Boolean(items[itemk].addons)){
                                            navigation.navigate("BuyAddonScreen", {
                                                product_id: itemk,
                                                addons: clone(addons)
                                            })
                                        }else {
                                            navigation.navigate("Checkout", {
                                                pid: itemk,
                                            })
                                        }
                                    }}
                                    key={index}
                                    addproduct
                                    checkout
                                    billingcycle={pricedata.billingcycle}
                                    amount={pricedata.price}
                                    amount2={pricedata._price}
                                    {...items[itemk]} />
                            })
                        }
                    </DhruScrollView>
                </View>
            </RootPage>
        );
    }
}

const mapStateToProps = (state) => ({
    items: state.app_info.static.items,
    tax: state.app_info.static.tax,
    profile: state.app_info.profile,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AddProduct);
