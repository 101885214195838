import * as React from "react";
import {TextInputPaper, View} from "../Themed";
import Class from "../../constants/Style"
import {country as countryList} from "../../libs/static"
import {ScrollView} from "react-native";
import CommonListItem from "../CommonListItem";
import {TextInput} from "react-native-paper";
import {dynamicSort} from "../../libs/function";

const Index = ({onPress, selectedCountry, listStyle}) => {
    const [filterCountry, setFilterCountry] = React.useState();
    return <View style={[Class.flex]}>
        <View style={[Class.row]}>
            <TextInputPaper
                style={[Class.flex]}
                right={<TextInput.Icon name="close" color={"rgba(167,174,183,1)"}
                                       onPress={() => setFilterCountry("")}/>}
                dense={true}
                placeholder={"Search Country"}
                value={filterCountry}
                onChangeText={(text) => setFilterCountry(text)}
            />
            {/*<IconButton onPress={()=>setFilterCountry("")}  style={[Class.buttonAttachment1, {position:"absolute", right:0}]} name={"ios-close"} />*/}
        </View>
        <View style={[[Class.flex, listStyle]]}>
            <ScrollView>
                {
                    Object.keys(countryList).map((countrycode) => {
                        return {countrycode, ...countryList[countrycode]}
                    })
                        .sort(dynamicSort("countrycode"))
                        .filter((data) => {
                            if (Boolean(filterCountry)) {
                                // let {n, d} = countryList[c];
                                const lowerilter = filterCountry.toLowerCase();
                                if (data.countrycode.toLowerCase().includes(lowerilter)) {
                                    return true;
                                } else if (data.n.toString().toLowerCase().includes(lowerilter)) {
                                    return true;
                                } else if (data.d.toString().toLowerCase().includes(lowerilter)) {
                                    return true;
                                }
                                return false;
                            }
                            return true;
                        })
                        .map((data, index) => {
                            let {e, n, d, countrycode} = data;
                            return <CommonListItem
                                rightIconName={countrycode === selectedCountry ? "ios-checkmark" : ""}
                                onPress={() => onPress({countrycode, ...data})}
                                title={`${n}`}
                                key={index}/>
                        })

                    // Object.keys(countryList)
                    //     .filter((c) => {
                    //         if (Boolean(filterCountry)) {
                    //             let {n, d} = countryList[c];
                    //             const lowerilter = filterCountry.toLowerCase();
                    //             if (c.toLowerCase().includes(lowerilter)) {
                    //                 return true;
                    //             } else if (n.toString().toLowerCase().includes(lowerilter)) {
                    //                 return true;
                    //             } else if (d.toString().toLowerCase().includes(lowerilter)) {
                    //                 return true;
                    //             }
                    //             return false;
                    //         }
                    //         return true;
                    //     })
                    //     .map((c, index) => {
                    //         let {e, n, d} = countryList[c];
                    //
                    //         return <CommonListItem
                    //             rightIconName={c===selectedCountry?"ios-checkmark":""}
                    //             onPress={()=>onPress({countrycode:c, ...countryList[c]})}
                    //             title={n}
                    //             key={index}/>
                    //     })
                }
            </ScrollView>
        </View>
    </View>
}

export default Index;
