import React from "react";
import {enableWithPropertyOrConfig} from "@expo/webpack-config/utils";
import IoniconsIcon from "react-native-vector-icons/Ionicons";
import Class from "../constants/Style";
import {useThemeColor, View} from "./Themed";

const ChatTail = ({style, lightColor, darkColor})=>{
    const color = useThemeColor({light: lightColor, dark: darkColor}, 'text');

    return  <IoniconsIcon
        name="logo-whatsapp"
        color={color}
        style={style}
    />
}

export default ChatTail;
