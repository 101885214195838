import React, {Component} from "react";
import requestApi, {actions, methods, SUCCESS} from "../libs/ServerRequest";
import DhruScrollView from "../components/DhruScrollView";
import RootPage from "../components/RootPage";
import {isAllReseller, isEmptyArray} from "../libs/function";
import {Text, View} from "../components/Themed";
import Class from "../constants/Style";
import {RefreshControl} from "react-native";
import {connect, useDispatch} from "react-redux";
import HeaderRight from "../components/HeaderRight";
import ProductItem from "../components/ProductItem";
import {setProfileCredit} from "../libs/Store/actions/app_info";
import EmptyPage from "../components/EmptyPage";
import {allResellerList} from "../libs/static";
import {setDialog} from "../libs/Store/actions/components";
import DialogView from "../components/DialogView";
import {Button} from "react-native-paper";


const ResellerTokenResponse = (props: any) => {

    const dispatch = useDispatch()

    const {response} = props;

    const closeDialog = () => {
        dispatch(setDialog({
            visible   : false,
            dialogview: undefined
        }))
    }

    return <DialogView dialogSize={"medium"} actionView={<View style={[Class.row]}>
        <Button onPress={() => closeDialog()}>OK</Button>
    </View>} title={response?.status || 'Alert'}>
        <View style={[Class.marginTop16, Class.marginBottom16]}>
            <Text style={[Class.textBold600]}>{response?.message || ''}</Text>
        </View>
    </DialogView>
}


class Wallet extends Component<any, any> {

    isFromStore = false;

    constructor(props) {
        super(props);


        this.isFromStore = Boolean(props?.from === "Store");

        this.state = {
            refreshing: false,
            data      : []
        }
    }

    componentDidMount() {
        this.changeNavigation();

        this._unsubscribe = this.props.navigation.addListener('focus', () => {
            this.loadWalletStatement();
        });


    }

    componentWillUnmount() {
        this._unsubscribe();
    }

    loadWalletStatement = () => {

        const {setProfileCredit} = this.props;
        requestApi({
            method: methods.get,
            action: actions.wallet,
        }).then((response: any) => {
            if (response.status === SUCCESS && Boolean(response?.data)) {
                let data = [];
                if (Boolean(response?.data?.statement)) {
                    data = response?.data?.statement;
                }
                this.setState({
                    data
                }, () => {
                    if (response?.data?.credit) {
                        setProfileCredit(response?.data?.credit)
                    }
                })
            }
        })
    }

    getApiAccessToken = () => {
        requestApi({
            method: methods.post,
            action: actions.reseller,
        }).then((response: any) => {
            this.props.setDialog({
                visible   : true,
                dialogview: <ResellerTokenResponse response={response}/>
            })
        })
    }

    changeNavigation = () => {
        const {
            navigation,
            from,
            profile
        } = this.props;
        if (!this.isFromStore) {

        }

        let rightOptions: any = []

        if (isAllReseller(allResellerList)) {
            rightOptions = [...rightOptions, {
                name   : "key",
                caption: "Get APi access token",
                type   : 'fontawesome',
                onPress: () => {
                    this.getApiAccessToken()
                }
            }, {
                name   : "sync",
                caption: "Transfer Credit",
                onPress: () => {
                    navigation.navigate("TransferCreditScreen")
                }
            }]
        } else {
            rightOptions = [...rightOptions, {
                name   : "plus",
                caption: "Buy Wallet Credit",
                onPress: () => {
                    navigation.navigate("Checkout", {
                        pid: "ad30debf-e0eb-4499-a07b-8da4a403c4df",
                    })
                }
            }]
        }


        navigation.setOptions({
            headerRight: () => <HeaderRight
                actions={rightOptions}/>
        });

    }

    render() {


        const {
            navigation,
            invoices,
            from,
            profile
        } = this.props;


        const {
            filterInvoice,
            refreshing,
            itemType,
            typeActive,
            data
        } = this.state;

        let title = 'Wallet';
        if (isAllReseller(allResellerList)) {
            title = 'Reseller'
        }

        return (<RootPage navigation={navigation} noBack={false} title={title} headerTitle={title}>
            <View style={Class.container}>

                <DhruScrollView
                    refreshControl={<RefreshControl
                        tintColor={"#9e9e9e"}
                        refreshing={refreshing}
                        onRefresh={() => this.setState({refreshing: true})}
                    />}>

                    {!isEmptyArray(data) ? data
                        ?.map((item, index) => {

                            return <ProductItem
                                key={index}
                                statement
                                item={item}/>
                        }) : <EmptyPage message={`No Data Found!`}/>}


                </DhruScrollView>
            </View>
        </RootPage>);
    }
}

const mapStateToProps = (state) => ({
    profile: state.app_info.profile
});

const mapDispatchToProps = (dispatch) => ({
    setProfileCredit: (credit) => dispatch(setProfileCredit(credit)),
    setDialog       : (dialog) => dispatch(setDialog(dialog))
});

export default connect(mapStateToProps, mapDispatchToProps)(Wallet)
