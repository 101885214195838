import * as React from "react";
import RootPage from "../components/RootPage";
import {SUCCESS} from "../libs/ServerRequest";
import {useThemeColor, View} from "../components/Themed";
import Class from "../constants/Style"
import DhruWebView from "../components/DhruWebView";

const Webpage = ({navigation, route}) => {

    const backColor = useThemeColor({}, 'background');
    const textColor = useThemeColor({}, 'text');

    if (route.params.link === "link") {
        navigation.goBack();
        return <View/>
    }

    return <RootPage navigation={navigation} headerTitle={"Payment method"} headerLargeTitle={false}>
        <View style={Class.container}>
            <DhruWebView
                webStyle={{alignSelf: "stretch", flex: 1}}
                uri={`${route.params.link}&html=true&token=${route.params.token}`}
                backColor={backColor}
                textColor={textColor}
                response={(response: any) => {
                    if (typeof response === "string" && response?.toLowerCase() === SUCCESS) {
                        navigation.goBack()
                    }
                }}
            />
        </View>
    </RootPage>
}

// class Webpage extends React.Component<any, any> {
//
//     // handleResponse = data => {
//     //     if (data.title === "success") {
//     //         this.props.navigation.goBack()
//     //     }
//     // };
//
//
//     render() {
//         const {navigation, route} = this.props
//         if (route.params.link === "link"){
//             navigation.goBack();
//             return <View/>
//         }
//         return (
//             <RootPage navigation={navigation} headerTitle={"Payment method"} headerLargeTitle={false}>
//                 <View style={Class.container}>
//                     <DhruWebView
//                         webStyle={{alignSelf: "stretch"}}
//                         uri={`${route.params.link}&html=true&token=${route.params.token}`}
//                         response={(response)=>{
//                             if (response.toLowerCase() === SUCCESS){
//                                 navigation.goBack()
//                             }
//                         }}
//                     />
//                 </View>
//             </RootPage>
//         );
//     }
// }


export default Webpage;
