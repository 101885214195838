import {
    SET_AUTH_USER,
    SET_INIT_DATA,
    SET_INIT_DATA_WITH_OBJECT,
    SET_LOADING_AND_TOKEN,
    SET_PROFILE_CREDIT, SET_WORKSPACES,
} from "../definitions/app_info";

export const initState = {
    closed_tickets: [],
    token: '',
    loading: true,
    defaultRedirect: ""
};

export default (state = initState, action) => {
    switch (action.type) {
        case SET_AUTH_USER:
            const {authUser} = action;
            return {
                ...state,
                authUser,
            }
        case SET_INIT_DATA:
            const {initData} = action;
            return {
                ...state,
                ...initData,
            }
        case SET_LOADING_AND_TOKEN:
            let {data} = action;
            if (!Boolean(data.token)) {
                data = {
                    ...data,
                    closed_tickets: [],
                    support_tickets: [],
                    orders: [],
                    invoices: [],
                    products: [],
                    authUser: {},
                    profile: {},
                    workspaces:[]
                }
            }
            return {
                ...state,
                ...data
            }
        case SET_INIT_DATA_WITH_OBJECT:
            const {
                objectKey,
                updateData
            } = action;
            return {
                ...state,
                [objectKey]: updateData
            }
        case SET_PROFILE_CREDIT:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    credit: action.credit
                }
            }
        case SET_WORKSPACES:
            return {
                ...state,
                workspaces:action.workspaces
            }
        default:
            return state;
    }
}
