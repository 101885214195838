import * as React from "react";
import {CaseRootStack, SupportRootStack} from "../StackNav";
import Conversation from "../../screens/Conversation";
import SupportRequest from "../../screens/SupportRequest";
import SubCategory from "../../screens/SubCategory";
import SelectProduct from "../../screens/SelectProduct";
import CreateTicket from "../../screens/CreateTicket";
import SuccessTicket from "../../screens/SuccessTicket";
import UpdateLoginDetails from "../../screens/UpdateLoginDetails";
import Support from "../../screens/Support";
import {CommonNavigation} from "../CommonNavigation";
import Products from "../../screens/Products";
import {useWindowDimensions} from "react-native";
import {LARGE_SCREEN_WIDTH} from "../../libs/settings";
import {intercomVisibility} from "../../libs/function";


const SupportRoot = () => {
    const dimensions = useWindowDimensions();
    const isLargeScreen = dimensions.width >= LARGE_SCREEN_WIDTH;
    intercomVisibility(false)
    return <SupportRootStack.Navigator >
        <SupportRootStack.Screen
            name="SupportNav"
            options={{headerShown: false}}
        >
            {props => <CommonNavigation {...props} initialRouteName={"Case"}/>}
        </SupportRootStack.Screen>
        {
            !Boolean(isLargeScreen) && <React.Fragment>
                {/*<SupportRootStack.Screen name="ConversationScreen" component={Conversation}/>*/}
                {/*<SupportRootStack.Screen name="CategoryScreen" component={SupportRequest}/>*/}
                {/*<SupportRootStack.Screen name="SubcategoryScreen" component={SubCategory}/>*/}
                {/*<SupportRootStack.Screen name="SelectProductScreen" component={Products}/>*/}
                {/*<SupportRootStack.Screen name="CreateTicketScreen" component={CreateTicket}/>*/}
                {/*<SupportRootStack.Screen name="SuccessTicketScreen" component={SuccessTicket} options={{headerShown:false}}/>*/}
                {/*<SupportRootStack.Screen name="UpdateLoginDetailsScreen" component={UpdateLoginDetails}/>*/}
            </React.Fragment>
        }

    </SupportRootStack.Navigator>
}

export default SupportRoot;







