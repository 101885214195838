import React, {Component} from "react";
import {connect} from "react-redux";
import {ActivityIndicator, StyleSheet} from "react-native";
import {isIOS} from "../../libs/settings";
import DefaultSpinner from "./DefaultSpinner";
import {View} from "./Themed";


class Spinner extends Component {

    alreadyShow = false;

    render() {
        const {show} = this.props;
        if (!show) {
            return (<View lightColor={"rgba(0,0,0,0)"} darkColor={"rgba(0,0,0,0)"}/>)
        }
        if (!Boolean(this.alreadyShow)) {
            return <DefaultSpinner/>;
        }
    }
}

const mapStateToProps = (state) => ({
    show: state.components.loader.show
});

export default connect(mapStateToProps)(Spinner);

const styles = StyleSheet.create({
    loader: {position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 999},
    screenCenter: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    h_100: {height: '100%'},
    transparent: {backgroundColor: 'transparent'},
});

