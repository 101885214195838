import {useEffect, useRef, useState} from "react";
import LinkButton from "./LinkButton";
import Class from "../constants/Style";
import {Text} from "./Themed";
import * as React from "react";

const Resend = ({onResendOTP}: any) => {
    const time = 60;

    const [counter, setCounter] = useState(time)

    let intervalId: any = useRef(null)

    const startInterval = () => {
        intervalId.current = setInterval(() => {
            setCounter((prevState) => {
                let c = prevState - 1;
                if (c == 0) {
                    clearInterval(intervalId.current);
                }
                return c
            });
        }, 1000);
    }

    useEffect(() => {

        startInterval();
        return () => clearInterval(intervalId.current)
    }, [])


    if (counter == 0) {
        return <LinkButton caption={"Resend OTP"} onPress={() => {
            setCounter(time)
            onResendOTP()
            startInterval();
        }} style={Class.linkStyle}/>
    }

    return <Text>Resend OTP After {counter} Second</Text>

}

export default Resend;
