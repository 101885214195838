import React from "react";
import AsyncStorage from "@react-native-community/async-storage";
import * as Permissions from "expo-permissions";
import {isDevelopment, isWeb, LARGE_SCREEN_WIDTH} from "./settings";
import * as DocumentPicker from "expo-document-picker";
import * as mime from "react-native-mime-types";
import requestApi, {actions, methods, SUCCESS} from "./ServerRequest";
import * as FileSystem from "expo-file-system";
import {store} from "./Store/configureStore";
import * as ImagePicker from 'expo-image-picker';
import {setAuthUser, setInitDataWithObject, setLoadingAndToken} from "./Store/actions/app_info";
import * as Linking from "expo-linking";
import {Clipboard, Dimensions, Keyboard, Platform} from "react-native";
import base64 from "react-native-base64";

import utf8 from "utf8";
import {setSnackbar} from "./Store/actions/components";
import {allResellerList} from "./static";

var Buffer = require('buffer/').Buffer

export const STORAGE_KEY = {
    USER_DATA: "user_data",
};

export const storeData = async (key, value) => {
    try {
        return await AsyncStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
        return false;
    }
};
export const retrieveData = async (key) => {
    return await AsyncStorage.getItem(key).then((data) => {
        return JSON.parse(data);
    });
};


export const isEmptyArray = (data) => {
    return !(Boolean(data) && Boolean(data.length > 0))
}

export const logData = (...data: any[]) => {
    if (isDevelopment) {
        console.info("LOG-DATA", data);
    }
}

export function dynamicSort(property) {
    var sortOrder = 1;

    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }

    return function (a, b) {
        if (sortOrder == -1) {
            return b[property].localeCompare(a[property]);
        } else if ((a[property] === "IN" || a[property] === "IN")) {
            return -1;
        } else {
            return a[property].localeCompare(b[property]);
        }
    }
}

//
// const checkPermission = async (permissions) => {
//     return await Permissions.getAsync(permissions);
// };

// const askPermission = async (permission) => {
//     return await Permissions.askAsync(permission)
// };


export function isEmpty(obj) {

    // null and undefined are "empty"
    if (obj == null) return true;

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0) return false;
    if (obj.length === 0) return true;

    // If it isn't an object at this point
    // it is empty, but it can't be anything *but* empty
    // Is it empty?  Depends on your application.
    if (typeof obj !== "object") return true;

    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // toString and valueOf enumeration bugs in IE < 9
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
    }

    return true;
}

const stringConstructor = "test".constructor;
const arrayConstructor = [].constructor;
const objectConstructor = ({}).constructor;

export const CheckPermission = async (permissionName) => {
    return await Permissions.getAsync(permissionName)
}

export const AskPermission = async (permissionName) => {
    return await Permissions.askAsync(permissionName)
}

export const CheckAndAsk = async (permissionName) => {
    let status, result;

    result = await CheckPermission(permissionName);
    status = result.status;
    if (status !== "granted") {
        result = await AskPermission(permissionName);
        status = result.status;
    }
    if (isWeb) {
        result = {status: "granted"}
    }
    return result;
}

export const DocPicker = async () => {
    return DocumentPicker.getDocumentAsync();
}

export const ImgPicker = async () => {
    return ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: false,
        quality: 1,
    })
}

export const FileRead = async (uri) => {
    const data = await FileSystem.readAsStringAsync(uri, {encoding: FileSystem.EncodingType.Base64})
    return Buffer.from(data, "base64")
}

export const uploadFile = (data) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", data.file_type);
    let requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: data.body,
        redirect: 'follow'
    };
    let status = "error", message = "";
    return fetch(data.url, requestOptions)
}

export const requestGetUploadUrl = ({file_name, file_type}) => {
    return requestApi({
        method: methods.get,
        anotherUrl: `https://apigateway.dhru.com/v1/getuploadurl?file_name=${file_name}&file_type=${file_type}`,
        otherheaders: {'Accept': '*/*'}
    });
}

export const GetFileData = async (isDoc) => {
    let returnResponse = {}, status = "error", message = "";
    const permissionResult = await CheckAndAsk(Permissions.CAMERA_ROLL)
    if (permissionResult.status === "granted") {
        let documentResponse;
        if (isDoc) {
            documentResponse = await DocPicker()
            if (documentResponse.type !== "cancel") {
                status = "success"
                const file_name = documentResponse.name;
                const file_type = mime.lookup(documentResponse.name);
                const file_uri = documentResponse.uri
                let selectedFile = "";
                if (isWeb) {
                    selectedFile = documentResponse.file
                }
                returnResponse = {
                    file_name,
                    file_type,
                    file_uri,
                    selectedFile,
                    ...returnResponse
                }
            } else {
                message = "Document Not Selected"
            }
        } else {
            documentResponse = await ImgPicker();
            if (!documentResponse.cancelled) {
                status = "success"
                const file_name_array = documentResponse.uri.toString().split("/");
                const file_name = file_name_array[file_name_array.length - 1];
                const file_uri = documentResponse.uri
                const file_type = mime.lookup(file_uri);

                let selectedFile = "";
                if (isWeb) {
                    selectedFile = documentResponse.file
                }
                returnResponse = {
                    file_name,
                    file_type,
                    file_uri,
                    selectedFile,
                    ...returnResponse
                }
            } else {
                message = "Document Not Selected"
            }
        }

    } else {
        message = "Permission not Allowed"
    }
    return {status, message, ...returnResponse}
}

export const getProfileLabel = (name) => {
    let pass;
    if (Boolean(name)) {
        name.split(" ", 2).forEach((a) => {
            if (Boolean(pass)) {
                pass += a.trim().substring(0, 1).toUpperCase();
            } else {
                pass = a.trim().substring(0, 1).toUpperCase();
            }
        });
    }
    return pass
};

export const getCurrencyCode = () => {
    const {app_info} = store.getState()
    let currency = "USD";
    if (Boolean(app_info.profile) && Boolean(app_info.profile.currency)) {
        currency = app_info.profile.currency
    }
    return currency
};

export const currencyFormat = (amount, currencyCode, disabledCurrencySign, displayCurrencyCode) => {
    if (isNaN(amount)) {
        amount = 0;
    }

    if (Platform.OS === "android") {
        return androidCurrency(amount, getCurrencyCode())
    } else {
        try {
            if (disabledCurrencySign) {
                let formattedAmount = new Intl.NumberFormat('en-IN', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(amount);
                if (displayCurrencyCode) {
                    return `${currencyCode} ${formattedAmount}`
                }
                return formattedAmount;
            } else {
                return new Intl.NumberFormat('en-IN', {
                    style: 'currency',
                    currency: getCurrencyCode(),
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(amount.toString())
            }
        } catch (e) {
            return amount;
        }
    }
};

function androidCurrency(amount, code) {
    let sign = "$", searchValue = /(\d)(?=(\d{3})+(?!\d))/g
    if (code === "INR") {
        sign = "₹";
        searchValue = /(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g
    }

    return sign + parseFloat(amount).toFixed(2).replace(searchValue, '$1,')
}


export const signOut = (clearRedirect?:boolean) => {
    storeData(STORAGE_KEY.USER_DATA, "").then(() => {
        intercomVisibility(true);
        let data:any = {loading: false, token: ""}
        if (clearRedirect){
            data ={
                ...data,
            }
        }
        store.dispatch(setLoadingAndToken(data));
        if (clearRedirect){
            if (navigationRef && navigationRef.nav && navigationRef.nav?.navigate){
                navigationRef?.nav?.navigate("Login")
            }
        }
    })
}

export const loginPromise = (data:any)=>{
    return new Promise(((resolve, reject) => {
        storeData(STORAGE_KEY.USER_DATA, data).then(() => {
            store.dispatch(setAuthUser(data));
            resolve({})
        })
    }));
}

export const signOutPromise = ()=>{
   return new Promise(((resolve, reject) => {
       storeData(STORAGE_KEY.USER_DATA, "").then(() => {
           intercomVisibility(true);
           let data:any = {loading: false, token: ""}
           store.dispatch(setLoadingAndToken(data));
           resolve({})
       })
   }));
}

export const clone = (obj) => {
    var copy;
    if (null == obj || "object" != typeof obj) return obj;
    if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }
    if (obj instanceof Array) {
        copy = [];
        for (var i = 0, len = obj.length; i < len; i++) {
            copy[i] = clone(obj[i]);
        }
        return copy;
    }
    if (obj instanceof Object) {
        copy = {};
        for (var attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr]);
        }
        return copy;
    }
    throw new Error("Unable to copy obj! Its type isn't supported.");
};

export const orderCalculate = (pid, selectedAddons) => {

    const {app_info: {token, profile, static: {tax, items}}} = store.getState();

    let currency = "USD", subtotal = "0", total = "0", itemTax = [], country = "US";

    if (Boolean(token) && Boolean(profile) && Boolean(profile.country)) {
        country = profile.country;
        if (country === "IN") {
            currency = "INR";
        }
    }
    const item = items[pid];
    item.itemid = pid;
    item.amount = item.price[currency].price

    subtotal = parseFloat(item.amount);
    // item.addons = {};

    Object.keys(item.addons).forEach((aid) => {
        let selectedAddon = item.addons[aid]
        selectedAddon.amount = selectedAddon.price[currency].price;
        selectedAddon.active = false;
        const checked = selectedAddons.filter((checkid) => checkid === aid);
        if (Boolean(checked) && Boolean(checked.length > 0)) {
            subtotal += parseFloat(selectedAddon.amount);
            selectedAddon.active = true;
        }
        item.addons[aid] = selectedAddon;
    })
    const defaultTax = tax.filter((t) => t.name === "Transaction tax")[0];
    defaultTax.taxamount = getTaxAmount(defaultTax.rate, subtotal)
    itemTax = [defaultTax]
    if (country === "IN") {
        const inTax = tax.filter((t) => t.name === "IGST")[0];
        inTax.taxamount = getTaxAmount(inTax.rate, subtotal)
        itemTax = [...itemTax, inTax]
    }
    total = subtotal;
    itemTax.forEach((t) => {
        total += parseFloat(t.taxamount);
    })
    return {item, subtotal: subtotal.toString(), total: total.toString(), itemTax};

}

export const getTaxAmount = (rate, value) => {
    return (parseFloat(rate) * parseFloat(value) / 100).toString()
}

export const browseLink = (url, target) => {
    isWeb ? window.open(url, target)
        : Linking.openURL(url).catch((err) => console.error('An error occurred', err))
}

export const hideKeyboard = () => {
    if (!isWeb) {
        Keyboard.dismiss();
    }
}

export const requestGetProducts = async (loader: any, queryString?: any, objectKey?: string, action?:any) => {
    if (!Boolean(objectKey)) {
        objectKey = "products";
    }
    if (!Boolean(action)){
        action =  actions.product
    }
    store.dispatch(setInitDataWithObject(objectKey, []))
    const response: any = await requestApi({
        method: methods.get,
        action,
        queryString,
        loader,
    });

    if (response.status === SUCCESS) {
        store.dispatch(setInitDataWithObject(objectKey, response.data))
    }
}

export const getWidth = () => {
    return Dimensions.get('window').width
}

export const getSmallWidth = () => {
    return getWidth() < LARGE_SCREEN_WIDTH
}

export const getLargeWidth = () => {
    return getWidth() >= LARGE_SCREEN_WIDTH
}

export const intercomVisibility = (hide_default_launcher) => {
    if (isWeb) {
        // window.Intercom('update', {hide_default_launcher});
    }
}

export const encodeText = (text) => {
    return base64.encode(utf8.encode(text))
}

export const decodeText = (text) => {
    return utf8.decode(base64.decode(text))
}

export const optionStucture = (label: any, value: any) => {
    return {label, value}
}

export const isReseller = () => {
    const {app_info: {profile}} = store.getState();
    return profile?.group?.toString()?.toLowerCase() === "reseller";
}

export const isAllReseller = (resellerList:any) => {
    const {app_info: {profile}} = store.getState();
    return (resellerList.some((a: string) => Boolean(profile?.group) ? profile?.group.toString().toLowerCase() == a.toString().toLowerCase() : false))
}

// function whatIsIt(object) {
//     if (object === null) {
//         return "null";
//     }
//     if (object === undefined) {
//         return "undefined";
//     }
//     if (object.constructor === stringConstructor) {
//         return "String";
//     }
//     if (object.constructor === arrayConstructor) {
//         return "Array";
//     }
//     if (object.constructor === objectConstructor) {
//         return "Object";
//     }
//     {
//         return "don't know";
//     }
// }
//
// const getCurrencyCode = () => {
//     const {app_info: {profile: {currency}}} = store.getState()
//     return currency ? currency : "USD"
// };
//
// const currencyFormat = (amount, currencyCode, disabledCurrencySign, displayCurrencyCode) => {
//     if (isNaN(amount)) {
//         amount = 0;
//     }
//
//     try {
//         if (disabledCurrencySign) {
//             let formattedAmount = new Intl.NumberFormat('en-IN', {
//                 minimumFractionDigits: 2,
//                 maximumFractionDigits: 2
//             }).format(amount);
//             if (displayCurrencyCode) {
//                 return `${currencyCode} ${formattedAmount}`
//             }
//             return formattedAmount;
//         } else {
//             return new Intl.NumberFormat('en-IN', {
//                 style: 'currency',
//                 currency: getCurrencyCode(),
//                 minimumFractionDigits: 2,
//                 maximumFractionDigits: 2
//             }).format(amount)
//         }
//     } catch (e) {
//         return amount;
//     }
// };
//
// const getSelectedServer = () => {
//     const {app_info: {server_key, servers_list}} = store.getState();
//     return servers_list[server_key];
// };
//
// const checkErrorMessage = (response) => {
//     return Boolean(response["ERROR"]) && Boolean(response["ERROR"][0]) && response["ERROR"][0].message === "Authentication Failed"
// };
//
// const navigateToEditServer = (navigation) => {
//     const {app_info: {server_key, servers_list}} = store.getState();
//     servers_list[server_key].password = "";
//     storeData(STORAGE_KEY.SERVERS_LIST, servers_list).then(r => {
//         retrieveData(STORAGE_KEY.SERVERS_LIST).then(r => {
//             store.dispatch(setServersList(r));
//             navigation.push("AddServerPage", {
//                 title: getWord("Edit Server"),
//                 editkey: server_key,
//             })
//         });
//     });
// };
//
// const getWord = (word) => {
//     return i18n.t(word)
// };
//
// const printAppName = getWord("Fusion Client");

const copyText = async (text: string) => {
    await Clipboard.setString(text);
};

export const copyData = (text: string) => {
    copyText(text).then(() => {
        store.dispatch(setSnackbar({visible: true, message: "Copied To Clipboard", type: "success"}));
    })
}

export let navigationRef:any={nav:{}};
