import React, {Component} from "react";
import {Text, View} from "./Themed";
import Class from "../constants/Style";

class TitleAndSubtitle extends Component<any, any> {
    render() {
        const {title, subtitle, content, style} = this.props;
        return (
            <View style={[Class.alignSelfStretch, Class.paddingLeftRight22, {marginTop: 8}, style]}>
                {Boolean(title) && <Text numberOfLines={1} style={Class.bigtext}>{title}</Text>}
                {
                    Boolean(subtitle) && <Text style={Class.subtext}>
                        {subtitle}
                    </Text>
                }
                {
                    Boolean(content) && <Text style={Class.contenttext}>
                        {content}
                    </Text>
                }
            </View>
        );
    }
}


export default TitleAndSubtitle;
