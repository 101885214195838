import React, {Component} from "react";
import {
    FileRead,
    GetFileData,
    getSmallWidth,
    isEmpty,
    logData,
    optionStucture,
    requestGetUploadUrl,
    uploadFile
} from "../libs/function";
import {ContainedButton, TextInputPaper, View} from "../components/Themed";
import Class, {pickerSelectStyles} from "../constants/Style";
import DhruScrollView from "../components/DhruScrollView";
import {KeyboardAvoidingView} from "react-native";
import RootPage from "../components/RootPage";
import {headerHeight, isIOS, isWeb, LARGE_SCREEN_WIDTH} from "../libs/settings";
import {LargeScreen, SmallScreen} from "../components/ScreenSize";
import RNPickerSelect from "react-native-picker-select";
import {setLoader} from "../libs/Store/actions/components";
import {connect} from "react-redux";
import ImageWithLabel from "../components/ImageWithLabel";
import {validRequired} from "../libs/validate";
import requestApi, {actions, methods, SUCCESS} from "../libs/ServerRequest";
import EmptyPage from "../components/EmptyPage";

const itemType = [
    optionStucture("Banner", "banner"),
]

let imageArray = [
    {width: 1920, height: 400, fileSize: 500},
    {width: 1920, height: 600, fileSize: 500},
    {width: 1920, height: 800, fileSize: 500},
]


class StoreItemForm extends Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            filterInvoice: "",
            refreshing: false,
            postBody: {},
            message: ""
        }
    }

    createPostBody = (data: any) => {
        this.setState({
            postBody: {
                ...this.state.postBody,
                ...data
            },
            error: {}
        })
    }

    validate = () => {
        const {postBody, isContact} = this.state;
        let error = {};
        error = {...error, ...validRequired(error, "item_type", postBody?.item_type, "Please select item type")};
        error = {...error, ...validRequired(error, "credit", postBody?.credit, "Please enter credit")};

        let url = {};
        url = {...url, ...validRequired(url, "thumb", postBody?.urls?.thumb, "Please add thumb image")};
        url = {
            ...url, ...validRequired(url, "1920_400", postBody?.urls?.["1920_400"], "Please add 1920px x 400px" +
                " image")
        };
        url = {
            ...url, ...validRequired(url, "1920_600", postBody?.urls?.["1920_600"], "Please add 1920px x 600px" +
                " image")
        };
        url = {
            ...url, ...validRequired(url, "1920_800", postBody?.urls?.["1920_800"], "Please add 1920px x 800px" +
                " image")
        };

        if (!isEmpty(url)) {
            error = {
                ...error,
                urls: url
            }
        }


        this.setState({error})
        return isEmpty(error)
    }

    requestUpdateSeller = () => {
        if (this.validate()) {
            const {postBody} = this.state;
            requestApi({
                method: methods.post,
                action: actions.seller,
                body: postBody,
                showsucess: false
            }).then((response) => {
                if (response.status === SUCCESS) {
                    this.setState({message: response.message})
                }
            })
        }
    }

    requestFileUpload = async (isDoc, fieldName) => {
        const {attachment} = this.state;
        const {setLoader} = this.props;
        const fileDataResponse = await GetFileData(isDoc);
        if (fileDataResponse.status === "success") {
            const responseUploadUrl = await requestGetUploadUrl(fileDataResponse);
            setLoader({show: true});
            let readFileResponse;
            if (isWeb) {
                readFileResponse = fileDataResponse.selectedFile;
            } else {
                readFileResponse = await FileRead(fileDataResponse.file_uri);
            }
            uploadFile({
                url: responseUploadUrl.upload_url,
                file_type: fileDataResponse.file_type,
                body: readFileResponse
            }).then((response) => {
                setLoader({show: false});
                if (response.status === 200) {
                    let {urls} = this.state.postBody;
                    if (!Boolean(urls)) {
                        urls = {};
                    }
                    this.createPostBody({
                        urls: {
                            ...urls,
                            [fieldName]: responseUploadUrl.download_url
                        }
                    })
                }
            })
        }
    }

    render() {
        const {navigation, invoices} = this.props;
        const {filterInvoice, refreshing, postBody, error, message} = this.state;

        logData("ERROR", error);

        return (
            <RootPage navigation={navigation} noBack={true} headerTitle={"Add Item"}>
                <KeyboardAvoidingView
                    keyboardVerticalOffset={headerHeight}
                    style={Class.container} behavior={isIOS ? "padding" : ""}>
                    <View style={[Class.container]}>
                        {
                            Boolean(message) ? <>
                                <EmptyPage message={message}>
                                    <View style={[Class.row, Class.marginTop16]}>
                                        <ContainedButton
                                            mode={"text"}
                                            onPress={() => navigation.goBack()}
                                            style={Class.marginRight8}>
                                            Go Back
                                        </ContainedButton>
                                        <ContainedButton
                                            onPress={() => this.setState({message: "", postBody: {}})}
                                        >
                                            Add More
                                        </ContainedButton>
                                    </View>
                                </EmptyPage>
                            </> : <>
                                <DhruScrollView contentContainerStyle={Class.paddingLeftRight22}>
                                    <View style={[Class.maxFormWidth]}>
                                        <RNPickerSelect
                                            placeholder={{
                                                label: 'Select Type',
                                            }}
                                            style={pickerSelectStyles}
                                            value={postBody?.item_type}
                                            onValueChange={(item_type) => this.createPostBody({item_type})}
                                            useNativeAndroidPickerStyle={false}
                                            items={itemType}
                                        />

                                        <TextInputPaper
                                            mode={"flat"}
                                            value={postBody?.credit}
                                            onChangeText={(credit) => this.createPostBody({credit})}
                                            label="Selling Credit"
                                            style={Class.marginBottom16}
                                            error={error?.credit}
                                            autoCompleteType={"number"}
                                            textContentType={"number"}
                                            keyboardType={"default"}
                                        />

                                        {
                                            postBody?.item_type === "banner" && <>
                                                <View style={[Class.marginTop16]}>
                                                    <ImageWithLabel
                                                        label={"Thumb Image (240px x 100px, Max. 500KB)"}
                                                        uri={postBody?.urls?.thumb}
                                                        width={240}
                                                        error={error?.urls?.thumb}
                                                        height={100}
                                                        maxFileSizeKB={500}
                                                        validateSize={{width: 240, height: 100}}
                                                        validateType={["image/png", "image/jpg", "image/jpeg"]}
                                                        getUploadResponse={(response: any) => {
                                                            let {urls} = this.state.postBody;
                                                            if (!Boolean(urls)) {
                                                                urls = {};
                                                            }
                                                            this.createPostBody({
                                                                urls: {
                                                                    ...urls,
                                                                    ["thumb"]: response.download_url
                                                                }
                                                            })

                                                        }}/>
                                                </View>

                                                <View
                                                    style={[getSmallWidth() ? "" : Class.row, Class.marginBottom16]}>

                                                    {
                                                        imageArray.map(({width, height, fileSize}) => {
                                                            return <View
                                                                style={[Class.marginRight16, Class.marginTop16]}>
                                                                <ImageWithLabel
                                                                    label={`Image (${width}px x ${height}px, Max. ${fileSize}KB)`}
                                                                    uri={postBody?.urls?.[`${width}_${height}`]}
                                                                    width={240}
                                                                    height={100}
                                                                    maxFileSizeKB={fileSize}
                                                                    error={error?.urls?.[`${width}_${height}`]}
                                                                    validateSize={{width, height}}
                                                                    validateType={["image/png", "image/jpg", "image/jpeg"]}
                                                                    getUploadResponse={(response: any) => {
                                                                        let {urls} = this.state.postBody;
                                                                        if (!Boolean(urls)) {
                                                                            urls = {};
                                                                        }
                                                                        this.createPostBody({
                                                                            urls: {
                                                                                ...urls,
                                                                                [`${width}_${height}`]: response.download_url
                                                                            }
                                                                        })

                                                                    }}/>
                                                            </View>
                                                        })
                                                    }
                                                </View>
                                            </>
                                        }


                                        <LargeScreen minWidth={LARGE_SCREEN_WIDTH}>
                                            <ContainedButton
                                                style={[Class.maxFormWidth]}
                                                onPress={() => this.requestUpdateSeller()}
                                            >
                                                Save
                                            </ContainedButton>
                                        </LargeScreen>
                                    </View>
                                </DhruScrollView>
                                <SmallScreen maxWidth={LARGE_SCREEN_WIDTH}>
                                    <View style={[Class.bottomView]}>
                                        <ContainedButton
                                            style={[Class.maxFormWidth]}
                                            onPress={() => this.requestUpdateSeller()}
                                        >
                                            Save
                                        </ContainedButton>
                                    </View>
                                </SmallScreen>
                            </>
                        }

                    </View>
                </KeyboardAvoidingView>
            </RootPage>
        );
    }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    setLoader: (loader) => dispatch(setLoader(loader)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreItemForm);

