import * as  React from "react";
import {View, Text} from "./Themed";
import Class from "../constants/Style"
import Icon from "./Icon";
import {TouchableOpacity} from "react-native";

export default (props)=>{
    const {iconName, message,children} = props;
    return <View style={[Class.flex, Class.paddingTopBottom16]}>
        <View style={Class.bothCenter}>
            {Boolean(iconName) && <Icon name={iconName} style={Class.icon}/>}
            <Text style={Class.message}>{message}</Text>
            {children}
        </View>
    </View>
}
