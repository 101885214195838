export const actions = Object.freeze({
    login: "login",
    init: "init",
    support: "support",
    profile: "profile",
    category: "category",
    product: "product",
    forgotpassword:"forgotpassword",
    logininit:"logininit",
    register:"register",
    verifyemail:"verifyemail",
    verifywhatsapp:"verifywhatsapp",
    order:"order",
    seller:"seller",
    reseller:"reseller",
    store:"store",
    wallet:"wallet",
    authorize:"authorize",
    joinworkspace:"joinworkspace",
    workspace:"workspace",
    otpcredit:"otpcredit",
    resellerproduct:"resellerproduct",
    applelogin:'applelogin'
});
