import * as React from 'react';
import {useEffect, useState} from 'react';
import {RefreshControl, TouchableOpacity} from 'react-native';
import {ContainedButton, SearchText, Switch, Text, TextInputPaper, View} from '../components/Themed';
import RootPage from "../components/RootPage";
import {connect} from "react-redux";
import ProductItem from "../components/ProductItem";
import TitleAndSubtitle from "../components/TitleAndSubtitle";
import Class from "../constants/Style"
import HeaderRight from "../components/HeaderRight";
import EmptyPage from "../components/EmptyPage";
import {browseLink, getSmallWidth, isAllReseller, isEmpty, isEmptyArray, requestGetProducts} from "../libs/function";
import {setInitDataWithObject, setWorkspaces} from "../libs/Store/actions/app_info";
import DhruScrollView, {DEFAULT_LOADER_VIEW_TIME} from "../components/DhruScrollView";
import {isIOS} from "../libs/settings";
import requestApi, {actions, methods, SUCCESS} from "../libs/ServerRequest";
import {setSnackbar} from "../libs/Store/actions/components";
import {allResellerList} from "../libs/static";

const SwitchTransferOwnership = ({
                                     defaultOwnership,
                                     onChange,
                                     smallWidth
                                 }: any) => {

    const [ownership, setOwnership] = useState<any>(Boolean(defaultOwnership))

    useEffect(() => {
        onChange(ownership)
    }, [ownership])

    let touchableOpacityStyle = [Class.marginRight16],
        textTransferOwnership = [Class.marginRight16, Class.textBold600, Class.textPrimaryColor],
        viewTransferOwnership = [Class.row, Class.border, Class.borderPrimaryColor, Class.padding6, Class.alignItemCenter];

    if (smallWidth) {
        touchableOpacityStyle = [Class.marginRight4];
        textTransferOwnership = [Class.marginRight4, Class.textBold600, Class.textPrimaryColor, {
            fontSize: 12
        }];
        viewTransferOwnership = [Class.row, Class.border, Class.borderPrimaryColor, Class.padding6, Class.alignItemCenter]
    }

    return <TouchableOpacity
        onPress={() => {
            setOwnership((prev: boolean) => !prev)
        }}
        style={touchableOpacityStyle}>
        <View style={viewTransferOwnership}>
            <Text style={textTransferOwnership}>Transfer Ownership</Text>
            <Switch value={ownership}/>
        </View>
    </TouchableOpacity>
}

class Products extends React.Component<any, any> {

    smallWidth: any;

    constructor(props: any) {
        super(props);
        const {route} = this.props;
        let isSelectProductScreen = Boolean(route.name === "SelectProductScreen");
        let isWorkspaceList = Boolean(route.name === "WorkspaceList");
        let isResellerWorkspaceList = Boolean(route.name === "ResellerWorkspaceList");
        let headerTitle = isSelectProductScreen ? "Select Product" : "Products & Services";
        let keyName = "products";
        let searchPlaceHolder = "Search Products";

        this.smallWidth = getSmallWidth();

        if (isResellerWorkspaceList) {
            headerTitle = "Reseller Workspaces";
            keyName = "resellerworkspaces";
            searchPlaceHolder = "Search Workspace";
        } else if (isWorkspaceList) {
            headerTitle = "Workspaces";
            keyName = "workspaces";
            searchPlaceHolder = "Search Workspace";
        }

        this.state = {
            filterProduct: '',
            refreshing   : false,
            isSelectProductScreen,
            isWorkspaceList,
            isResellerWorkspaceList,
            keyName,
            headerTitle,
            searchPlaceHolder,
            workspacename: "",
            ownerShip    : false
        }


    }

    componentDidMount() {
        const {
            navigation,
            profile
        } = this.props;
        const {
            isSelectProductScreen,
            isWorkspaceList,
            isResellerWorkspaceList
        } = this.state;
        if (!isSelectProductScreen && !isIOS) {

            let actions: any = [{
                name   : "plus",
                caption: isWorkspaceList ? "Create A New Workspace" : "Buy",
                onPress: this.onPressBuy
            }];

            if (isResellerWorkspaceList) {
                actions = [];
            }

            navigation.setOptions({
                headerRight: () => <HeaderRight
                    actions={actions}/>
            });
        }
        this._unsubscribe = this.props.navigation.addListener('focus', () => {
            this.getProducts();
        });
    }

    componentWillUnmount() {
        this._unsubscribe();
    }

    addWorkspace = () => {
        const {
            refreshing,
            isWorkspaceList,
            keyName,
            workspacename
        } = this.state;
        const {setWorkspaces} = this.props;
        requestApi({
            method     : methods.get,
            action     : actions.workspace,
            queryString: {workspace: workspacename},
        }).then((result: any) => {
            if (result.status === SUCCESS) {
                this.setState({workspacename: ""}, () => {
                    setWorkspaces(result.data)
                })
            }
        })
    }

    render() {
        const {
            products,
            workspaces,
            resellerworkspaces,
            navigation,
            authUser,
            setSnackbar
        } = this.props;
        const {
            filterProduct,
            refreshing,
            isSelectProductScreen,
            isWorkspaceList,
            isResellerWorkspaceList,
            headerTitle,
            searchPlaceHolder,
            workspacename
        } = this.state;
        //let isSelectProductScreen = Boolean(route.name === "SelectProductScreen")

        let data = isResellerWorkspaceList ? (isEmpty(resellerworkspaces) ? [] : resellerworkspaces?.map((data: any) => {
            return {domain: data?.name, ...data}
        })) : isWorkspaceList ? workspaces?.map((data: any) => {
            return {domain: data?.name, ...data}
        }) : products;

        let noBack = isSelectProductScreen;
        if ((isWorkspaceList || isResellerWorkspaceList) && getSmallWidth()) {
            noBack = false
        }

        const isReseller = isAllReseller(allResellerList)

        return (<RootPage navigation={navigation} noBack={noBack} headerTitle={headerTitle}>
                <View style={Class.container}>
                    {<DhruScrollView
                        contentContainerStyle={{flexGrow: 1}}
                        refreshControl={<RefreshControl
                            tintColor={"#9e9e9e"}
                            refreshing={refreshing}
                            onRefresh={() => this.setState({refreshing: true}, () => this.getProducts())}
                        />}
                    >
                        {!isEmptyArray(data) ? <React.Fragment>
                            {isSelectProductScreen && <TitleAndSubtitle
                                subtitle={"Product or Service attach to request"}
                            />}
                            <SearchText
                                placeholder={searchPlaceHolder}
                                onChangeText={(filterProduct: any) => this.setState({filterProduct})}
                                value={filterProduct}
                            />
                            {(Boolean(authUser?.staff) && Boolean(isWorkspaceList)) &&
                                <View style={[Class.marginLeft16, Class.marginRight16, Class.marginBottom8, Class.row]}>
                                    <TextInputPaper
                                        mode={"flat"}
                                        value={workspacename}
                                        onChangeText={(workspacename: any) => {
                                            this.setState({workspacename})
                                        }}
                                        label="Workspace Name"
                                        style={[Class.marginBottom4, {width: 300}]}
                                    />
                                    <ContainedButton
                                        style={[{marginTop: 20}, Class.marginLeft16]}
                                        onPress={() => {
                                            if (Boolean(workspacename.toString().trim())) {
                                                this.addWorkspace()
                                            } else {
                                                setSnackbar({
                                                    visible: true,
                                                    message: "Enter Workspace Name",
                                                    type   : "error"
                                                })
                                            }
                                        }}
                                    >
                                        Add
                                    </ContainedButton>
                                </View>}
                            {data
                                .filter((product: any) => {
                                    return Boolean(filterProduct) ? product.prodcut.toString().toLowerCase().includes(filterProduct.toString().toLowerCase()) || product.domain.toString().toLowerCase().includes(filterProduct.toString().toLowerCase()) : true;
                                })
                                .map((product: any) => <ProductItem
                                    hide_right_login={isSelectProductScreen}
                                    key={product?.product_id || product?.id}
                                    getProducts={this.getProducts}
                                    {...product}
                                    isReseller={isReseller}
                                    isWorkspaceList={isWorkspaceList}
                                    isResellerWorkspaceList={isResellerWorkspaceList}
                                    isOwnerShip={this.state?.ownerShip}
                                    navigation={navigation}
                                    onPress={(event: any) => {
                                        event?.preventDefault();
                                        if (!isResellerWorkspaceList) {
                                            if (isWorkspaceList) {
                                                if (!this.state?.ownerShip) {
                                                    browseLink(product?.workspace_login, "_blank")
                                                }
                                            } else {
                                                this.onPressProductItem(product)
                                            }
                                        }
                                    }}
                                />)}
                        </React.Fragment> : <EmptyPage message={"You don't have any active product"}/>}
                    </DhruScrollView>}

                </View>
            </RootPage>);
    }

    getProducts = () => {
        const {
            refreshing,
            isWorkspaceList,
            keyName,
            isResellerWorkspaceList
        } = this.state;
        let action = actions.product;
        if (isResellerWorkspaceList) {
            action = actions.resellerproduct
        } else if (isWorkspaceList) {
            action = actions.workspace
        }
        requestGetProducts(!refreshing, undefined, keyName, action).then(() => {
            setTimeout(() => {
                this.setState({
                    refreshing: false
                });
            }, DEFAULT_LOADER_VIEW_TIME)
        })
    }

    onPressProductItem = (product: any) => {
        const {
            navigation,
            route
        } = this.props;
        const {isSelectProductScreen} = this.state;
        if (isSelectProductScreen) {
            navigation.push("CreateTicketScreen", {
                ...route.params,
                product_id: product.product_id,
            })
        } else {
            navigation.navigate("ViewProductScreen", {id: product.product_id})
        }
    }

    onPressBuy = () => {
        const {navigation} = this.props;
        const {isWorkspaceList} = this.state;
        if (isWorkspaceList) {
            navigation.navigate("Checkout", {pid: "3d44bcb5-afbc-4153-af6a-aa3457ebe119",})
        } else {
            navigation.navigate("AddProductScreen")
        }
    }
}

const mapStateToProps = (state: any) => ({
    products          : state.app_info.products,
    workspaces        : state.app_info.workspaces,
    resellerworkspaces: state.app_info.resellerworkspaces,
    authUser          : state.app_info.authUser,
    profile           : state.app_info.profile
});

const mapDispatchToProps = (dispatch: any) => ({
    setInitDataWithObject: (objectKey: any, updateData: any) => dispatch(setInitDataWithObject(objectKey, updateData)),
    setSnackbar          : (snackData: any) => dispatch(setSnackbar(snackData)),
    setWorkspaces        : (workspaces: any) => dispatch(setWorkspaces(workspaces))
});

export default connect(mapStateToProps, mapDispatchToProps)(Products);
