import * as React from "react";
import {MerchantRootStack} from "../StackNav";
import {CommonNavigation} from "../CommonNavigation";
import {useWindowDimensions} from "react-native";
import {LARGE_SCREEN_WIDTH} from "../../libs/settings";
import {intercomVisibility} from "../../libs/function";


const MerchantRoot = () => {
    const dimensions = useWindowDimensions();
    const isLargeScreen = dimensions.width >= LARGE_SCREEN_WIDTH;
    intercomVisibility(false)
    return <MerchantRootStack.Navigator>
        <MerchantRootStack.Screen
            name="MerchantNav"
            options={{headerShown: false}}
        >
            {props => <CommonNavigation {...props} initialRouteName={"Merchant"}/>}
        </MerchantRootStack.Screen>
        {
            !Boolean(isLargeScreen) && <React.Fragment>
                {/*<ProfileRootStack.Screen name="ViewInvoiceScreen" component={ViewInvoice}/>*/}
                {/*<ProfileRootStack.Screen name="WebPageScreen" component={Webpage}/>*/}
            </React.Fragment>
        }
    </MerchantRootStack.Navigator>
}

export default MerchantRoot;




