import Class from "../../constants/Style";


export  const headerOptions = {
    headerLargeTitle: true,
    // headerTintColor: '#aaa',
    headerHideShadow: true,
    headerStyle: Class.headerStyle2,
}

// export  const headerOptions = {
//     headerLargeTitle: true,
//     headerTintColor: '#1c2a48',
//     headerHideShadow: true,
//     headerStyle: Class.headerStyle2,
// }
