import Class from "../constants/Style";
import Icon, {IosIcon} from "./Icon";
import {TouchableOpacity, useWindowDimensions} from "react-native";
import * as React from "react";
import {Link} from "@react-navigation/native"
import {AntDesign as IconAntDesign} from "@expo/vector-icons";
import {Button} from "react-native-paper";
import {LARGE_SCREEN_WIDTH} from "../libs/settings";
import {ContainedButton} from "./Themed";

export default function HeaderButton({name, onPress, style}: any) {
    return <TouchableOpacity
        style={[Class.headerRightAction, Class.marginLeft8]}
        onPress={onPress}>
        <Icon name={name} size={20}/>
    </TouchableOpacity>
}


export function HeaderButtonPlus({name,caption, type, onPress, style}: any) {
    const dimensions = useWindowDimensions();
    const isLargeScreen = dimensions.width >= LARGE_SCREEN_WIDTH;
    if (Boolean(caption) && Boolean(isLargeScreen)){
        return <ContainedButton style={[Class.marginRight16]} onPress={onPress}>{caption}</ContainedButton>
    }
    return <TouchableOpacity
        style={[Class.headerRightAction]}
        onPress={onPress}>
        {
           ( name === "plus" || name === "sync" || name == 'key') ?
                <IconAntDesign name={name} size={25} color="#3174de"/> :
                (Boolean(type) && type ==="fontawesome") ?
                    <Icon name={name} size={25} color="#3174de"/> :
                    <IosIcon name={name} size={25} color="#3174de"/>
        }
    </TouchableOpacity>
}

