import React, {Component} from "react";
import {StyleSheet, Text, TouchableOpacity, View} from "react-native";
import EntypoIcon from "react-native-vector-icons/Entypo";
import EvilIconsIcon from "react-native-vector-icons/EvilIcons";
import Icon, {IosIcon} from "./Icon";
import Class from "../constants/Style"

class AttachmentItem extends Component {
    render() {
        const {file_name, onPress, onPressName} = this.props;
        return (
            <View style={Class.attachContainer}>
                <IosIcon name="ios-attach" style={Class.clipicon}/>
                <TouchableOpacity onPress={() => Boolean(onPressName) && onPressName()}>
                    <Text ellipsizeMode={"middle"} numberOfLines={1} style={Class.attachementtext}>{file_name}</Text>
                </TouchableOpacity>
                {
                    Boolean(onPress) && <TouchableOpacity onPress={onPress}>
                        <Icon
                            name="times-circle"
                            style={Class.close}
                        />
                    </TouchableOpacity>
                }
            </View>
        );
    }
}


export default AttachmentItem;
