import {Platform} from 'react-native';

export const isDevelopment = process.env.NODE_ENV === "development";
export const isIOS = Platform.OS === 'ios';
export const isWeb = Platform.OS === 'web';
export const headerHeight = Platform.OS === 'ios' ? 140 : 0;

export const LARGE_SCREEN_WIDTH = 1280;






