export const country: any = {
    "BD": {
        "n": "Bangladesh",
        "d": "880",
        "e": "\ud83c\udde7\ud83c\udde9",
        "c": "BDT",
        "co": "AS"
    },
    "BE": {
        "n": "Belgium",
        "d": "32",
        "e": "\ud83c\udde7\ud83c\uddea",
        "c": "EUR",
        "co": "EU"
    },
    "BF": {
        "n": "Burkina Faso",
        "d": "226",
        "e": "\ud83c\udde7\ud83c\uddeb",
        "c": "XOF",
        "co": "AF"
    },
    "BG": {
        "n": "Bulgaria",
        "d": "359",
        "e": "\ud83c\udde7\ud83c\uddec",
        "c": "BGN",
        "co": "EU"
    },
    "BA": {
        "n": "Bosnia and Herzegovina",
        "d": "387",
        "e": "\ud83c\udde7\ud83c\udde6",
        "c": "BAM",
        "co": "EU"
    },
    "BB": {
        "n": "Barbados",
        "d": "1-246",
        "e": "\ud83c\udde7\ud83c\udde7",
        "c": "BBD",
        "co": "NA"
    },
    "WF": {
        "n": "Wallis and Futuna",
        "d": "681",
        "e": "\ud83c\uddfc\ud83c\uddeb",
        "c": "XPF",
        "co": "OC"
    },
    "BL": {
        "n": "Saint Barthelemy",
        "d": "590",
        "e": "\ud83c\udde7\ud83c\uddf1",
        "c": "EUR",
        "co": "NA"
    },
    "BM": {
        "n": "Bermuda",
        "d": "1-441",
        "e": "\ud83c\udde7\ud83c\uddf2",
        "c": "BMD",
        "co": "NA"
    },
    "BN": {
        "n": "Brunei",
        "d": "673",
        "e": "\ud83c\udde7\ud83c\uddf3",
        "c": "BND",
        "co": "AS"
    },
    "BO": {
        "n": "Bolivia",
        "d": "591",
        "e": "\ud83c\udde7\ud83c\uddf4",
        "c": "BOB",
        "co": "SA"
    },
    "BH": {
        "n": "Bahrain",
        "d": "973",
        "e": "\ud83c\udde7\ud83c\udded",
        "c": "BHD",
        "co": "AS"
    },
    "BI": {
        "n": "Burundi",
        "d": "257",
        "e": "\ud83c\udde7\ud83c\uddee",
        "c": "BIF",
        "co": "AF"
    },
    "BJ": {
        "n": "Benin",
        "d": "229",
        "e": "\ud83c\udde7\ud83c\uddef",
        "c": "XOF",
        "co": "AF"
    },
    "BT": {
        "n": "Bhutan",
        "d": "975",
        "e": "\ud83c\udde7\ud83c\uddf9",
        "c": "BTN",
        "co": "AS"
    },
    "JM": {
        "n": "Jamaica",
        "d": "1-876",
        "e": "\ud83c\uddef\ud83c\uddf2",
        "c": "JMD",
        "co": "NA"
    },
    "BV": {
        "n": "Bouvet Island",
        "d": "",
        "e": "\ud83c\udde7\ud83c\uddfb",
        "c": "NOK",
        "co": "AN"
    },
    "BW": {
        "n": "Botswana",
        "d": "267",
        "e": "\ud83c\udde7\ud83c\uddfc",
        "c": "BWP",
        "co": "AF"
    },
    "WS": {
        "n": "Samoa",
        "d": "685",
        "e": "\ud83c\uddfc\ud83c\uddf8",
        "c": "WST",
        "co": "OC"
    },
    "BQ": {
        "n": "Bonaire, Saint Eustatius and Saba ",
        "d": "599",
        "e": "\ud83c\udde7\ud83c\uddf6",
        "c": "USD",
        "co": "NA"
    },
    "BR": {
        "n": "Brazil",
        "d": "55",
        "e": "\ud83c\udde7\ud83c\uddf7",
        "c": "BRL",
        "co": "SA"
    },
    "BS": {
        "n": "Bahamas",
        "d": "1-242",
        "e": "\ud83c\udde7\ud83c\uddf8",
        "c": "BSD",
        "co": "NA"
    },
    "JE": {
        "n": "Jersey",
        "d": "44-1534",
        "e": "\ud83c\uddef\ud83c\uddea",
        "c": "GBP",
        "co": "EU"
    },
    "BY": {
        "n": "Belarus",
        "d": "375",
        "e": "\ud83c\udde7\ud83c\uddfe",
        "c": "BYR",
        "co": "EU"
    },
    "BZ": {
        "n": "Belize",
        "d": "501",
        "e": "\ud83c\udde7\ud83c\uddff",
        "c": "BZD",
        "co": "NA"
    },
    "RU": {
        "n": "Russia",
        "d": "7",
        "e": "\ud83c\uddf7\ud83c\uddfa",
        "c": "RUB",
        "co": "EU"
    },
    "RW": {
        "n": "Rwanda",
        "d": "250",
        "e": "\ud83c\uddf7\ud83c\uddfc",
        "c": "RWF",
        "co": "AF"
    },
    "RS": {
        "n": "Serbia",
        "d": "381",
        "e": "\ud83c\uddf7\ud83c\uddf8",
        "c": "RSD",
        "co": "EU"
    },
    "TL": {
        "n": "East Timor",
        "d": "670",
        "e": "\ud83c\uddf9\ud83c\uddf1",
        "c": "USD",
        "co": "OC"
    },
    "RE": {
        "n": "Reunion",
        "d": "262",
        "e": "\ud83c\uddf7\ud83c\uddea",
        "c": "EUR",
        "co": "AF"
    },
    "TM": {
        "n": "Turkmenistan",
        "d": "993",
        "e": "\ud83c\uddf9\ud83c\uddf2",
        "c": "TMT",
        "co": "AS"
    },
    "TJ": {
        "n": "Tajikistan",
        "d": "992",
        "e": "\ud83c\uddf9\ud83c\uddef",
        "c": "TJS",
        "co": "AS"
    },
    "RO": {
        "n": "Romania",
        "d": "40",
        "e": "\ud83c\uddf7\ud83c\uddf4",
        "c": "RON",
        "co": "EU"
    },
    "TK": {
        "n": "Tokelau",
        "d": "690",
        "e": "\ud83c\uddf9\ud83c\uddf0",
        "c": "NZD",
        "co": "OC"
    },
    "GW": {
        "n": "Guinea-Bissau",
        "d": "245",
        "e": "\ud83c\uddec\ud83c\uddfc",
        "c": "XOF",
        "co": "AF"
    },
    "GU": {
        "n": "Guam",
        "d": "1-671",
        "e": "\ud83c\uddec\ud83c\uddfa",
        "c": "USD",
        "co": "OC"
    },
    "GT": {
        "n": "Guatemala",
        "d": "502",
        "e": "\ud83c\uddec\ud83c\uddf9",
        "c": "GTQ",
        "co": "NA"
    },
    "GS": {
        "n": "South Georgia and the South Sandwich Islands",
        "d": "",
        "e": "\ud83c\uddec\ud83c\uddf8",
        "c": "GBP",
        "co": "AN"
    },
    "GR": {
        "n": "Greece",
        "d": "30",
        "e": "\ud83c\uddec\ud83c\uddf7",
        "c": "EUR",
        "co": "EU"
    },
    "GQ": {
        "n": "Equatorial Guinea",
        "d": "240",
        "e": "\ud83c\uddec\ud83c\uddf6",
        "c": "XAF",
        "co": "AF"
    },
    "GP": {
        "n": "Guadeloupe",
        "d": "590",
        "e": "\ud83c\uddec\ud83c\uddf5",
        "c": "EUR",
        "co": "NA"
    },
    "JP": {
        "n": "Japan",
        "d": "81",
        "e": "\ud83c\uddef\ud83c\uddf5",
        "c": "JPY",
        "co": "AS"
    },
    "GY": {
        "n": "Guyana",
        "d": "592",
        "e": "\ud83c\uddec\ud83c\uddfe",
        "c": "GYD",
        "co": "SA"
    },
    "GG": {
        "n": "Guernsey",
        "d": "44-1481",
        "e": "\ud83c\uddec\ud83c\uddec",
        "c": "GBP",
        "co": "EU"
    },
    "GF": {
        "n": "French Guiana",
        "d": "594",
        "e": "\ud83c\uddec\ud83c\uddeb",
        "c": "EUR",
        "co": "SA"
    },
    "GE": {
        "n": "Georgia",
        "d": "995",
        "e": "\ud83c\uddec\ud83c\uddea",
        "c": "GEL",
        "co": "AS"
    },
    "GD": {
        "n": "Grenada",
        "d": "1-473",
        "e": "\ud83c\uddec\ud83c\udde9",
        "c": "XCD",
        "co": "NA"
    },
    "GB": {
        "n": "United Kingdom",
        "d": "44",
        "e": "\ud83c\uddec\ud83c\udde7",
        "c": "GBP",
        "co": "EU"
    },
    "GA": {
        "n": "Gabon",
        "d": "241",
        "e": "\ud83c\uddec\ud83c\udde6",
        "c": "XAF",
        "co": "AF"
    },
    "SV": {
        "n": "El Salvador",
        "d": "503",
        "e": "\ud83c\uddf8\ud83c\uddfb",
        "c": "USD",
        "co": "NA"
    },
    "GN": {
        "n": "Guinea",
        "d": "224",
        "e": "\ud83c\uddec\ud83c\uddf3",
        "c": "GNF",
        "co": "AF"
    },
    "GM": {
        "n": "Gambia",
        "d": "220",
        "e": "\ud83c\uddec\ud83c\uddf2",
        "c": "GMD",
        "co": "AF"
    },
    "GL": {
        "n": "Greenland",
        "d": "299",
        "e": "\ud83c\uddec\ud83c\uddf1",
        "c": "DKK",
        "co": "NA"
    },
    "GI": {
        "n": "Gibraltar",
        "d": "350",
        "e": "\ud83c\uddec\ud83c\uddee",
        "c": "GIP",
        "co": "EU"
    },
    "GH": {
        "n": "Ghana",
        "d": "233",
        "e": "\ud83c\uddec\ud83c\udded",
        "c": "GHS",
        "co": "AF"
    },
    "OM": {
        "n": "Oman",
        "d": "968",
        "e": "\ud83c\uddf4\ud83c\uddf2",
        "c": "OMR",
        "co": "AS"
    },
    "TN": {
        "n": "Tunisia",
        "d": "216",
        "e": "\ud83c\uddf9\ud83c\uddf3",
        "c": "TND",
        "co": "AF"
    },
    "JO": {
        "n": "Jordan",
        "d": "962",
        "e": "\ud83c\uddef\ud83c\uddf4",
        "c": "JOD",
        "co": "AS"
    },
    "HR": {
        "n": "Croatia",
        "d": "385",
        "e": "\ud83c\udded\ud83c\uddf7",
        "c": "HRK",
        "co": "EU"
    },
    "HT": {
        "n": "Haiti",
        "d": "509",
        "e": "\ud83c\udded\ud83c\uddf9",
        "c": "HTG",
        "co": "NA"
    },
    "HU": {
        "n": "Hungary",
        "d": "36",
        "e": "\ud83c\udded\ud83c\uddfa",
        "c": "HUF",
        "co": "EU"
    },
    "HK": {
        "n": "Hong Kong",
        "d": "852",
        "e": "\ud83c\udded\ud83c\uddf0",
        "c": "HKD",
        "co": "AS"
    },
    "HN": {
        "n": "Honduras",
        "d": "504",
        "e": "\ud83c\udded\ud83c\uddf3",
        "c": "HNL",
        "co": "NA"
    },
    "HM": {
        "n": "Heard Island and McDonald Islands",
        "d": " ",
        "e": "\ud83c\udded\ud83c\uddf2",
        "c": "AUD",
        "co": "AN"
    },
    "VE": {
        "n": "Venezuela",
        "d": "58",
        "e": "\ud83c\uddfb\ud83c\uddea",
        "c": "VEF",
        "co": "SA"
    },
    "PR": {
        "n": "Puerto Rico",
        "d": "1-787 and 1-939",
        "e": "\ud83c\uddf5\ud83c\uddf7",
        "c": "USD",
        "co": "NA"
    },
    "PS": {
        "n": "Palestinian Territory",
        "d": "970",
        "e": "\ud83c\uddf5\ud83c\uddf8",
        "c": "ILS",
        "co": "AS"
    },
    "PW": {
        "n": "Palau",
        "d": "680",
        "e": "\ud83c\uddf5\ud83c\uddfc",
        "c": "USD",
        "co": "OC"
    },
    "PT": {
        "n": "Portugal",
        "d": "351",
        "e": "\ud83c\uddf5\ud83c\uddf9",
        "c": "EUR",
        "co": "EU"
    },
    "SJ": {
        "n": "Svalbard and Jan Mayen",
        "d": "47",
        "e": "\ud83c\uddf8\ud83c\uddef",
        "c": "NOK",
        "co": "EU"
    },
    "PY": {
        "n": "Paraguay",
        "d": "595",
        "e": "\ud83c\uddf5\ud83c\uddfe",
        "c": "PYG",
        "co": "SA"
    },
    "IQ": {
        "n": "Iraq",
        "d": "964",
        "e": "\ud83c\uddee\ud83c\uddf6",
        "c": "IQD",
        "co": "AS"
    },
    "PA": {
        "n": "Panama",
        "d": "507",
        "e": "\ud83c\uddf5\ud83c\udde6",
        "c": "PAB",
        "co": "NA"
    },
    "PF": {
        "n": "French Polynesia",
        "d": "689",
        "e": "\ud83c\uddf5\ud83c\uddeb",
        "c": "XPF",
        "co": "OC"
    },
    "PG": {
        "n": "Papua New Guinea",
        "d": "675",
        "e": "\ud83c\uddf5\ud83c\uddec",
        "c": "PGK",
        "co": "OC"
    },
    "PE": {
        "n": "Peru",
        "d": "51",
        "e": "\ud83c\uddf5\ud83c\uddea",
        "c": "PEN",
        "co": "SA"
    },
    "PK": {
        "n": "Pakistan",
        "d": "92",
        "e": "\ud83c\uddf5\ud83c\uddf0",
        "c": "PKR",
        "co": "AS"
    },
    "PH": {
        "n": "Philippines",
        "d": "63",
        "e": "\ud83c\uddf5\ud83c\udded",
        "c": "PHP",
        "co": "AS"
    },
    "PN": {
        "n": "Pitcairn",
        "d": "870",
        "e": "\ud83c\uddf5\ud83c\uddf3",
        "c": "NZD",
        "co": "OC"
    },
    "PL": {
        "n": "Poland",
        "d": "48",
        "e": "\ud83c\uddf5\ud83c\uddf1",
        "c": "PLN",
        "co": "EU"
    },
    "PM": {
        "n": "Saint Pierre and Miquelon",
        "d": "508",
        "e": "\ud83c\uddf5\ud83c\uddf2",
        "c": "EUR",
        "co": "NA"
    },
    "ZM": {
        "n": "Zambia",
        "d": "260",
        "e": "\ud83c\uddff\ud83c\uddf2",
        "c": "ZMK",
        "co": "AF"
    },
    "EH": {
        "n": "Western Sahara",
        "d": "212",
        "e": "\ud83c\uddea\ud83c\udded",
        "c": "MAD",
        "co": "AF"
    },
    "EE": {
        "n": "Estonia",
        "d": "372",
        "e": "\ud83c\uddea\ud83c\uddea",
        "c": "EUR",
        "co": "EU"
    },
    "EG": {
        "n": "Egypt",
        "d": "20",
        "e": "\ud83c\uddea\ud83c\uddec",
        "c": "EGP",
        "co": "AF"
    },
    "ZA": {
        "n": "South Africa",
        "d": "27",
        "e": "\ud83c\uddff\ud83c\udde6",
        "c": "ZAR",
        "co": "AF"
    },
    "EC": {
        "n": "Ecuador",
        "d": "593",
        "e": "\ud83c\uddea\ud83c\udde8",
        "c": "USD",
        "co": "SA"
    },
    "IT": {
        "n": "Italy",
        "d": "39",
        "e": "\ud83c\uddee\ud83c\uddf9",
        "c": "EUR",
        "co": "EU"
    },
    "VN": {
        "n": "Vietnam",
        "d": "84",
        "e": "\ud83c\uddfb\ud83c\uddf3",
        "c": "VND",
        "co": "AS"
    },
    "SB": {
        "n": "Solomon Islands",
        "d": "677",
        "e": "\ud83c\uddf8\ud83c\udde7",
        "c": "SBD",
        "co": "OC"
    },
    "ET": {
        "n": "Ethiopia",
        "d": "251",
        "e": "\ud83c\uddea\ud83c\uddf9",
        "c": "ETB",
        "co": "AF"
    },
    "SO": {
        "n": "Somalia",
        "d": "252",
        "e": "\ud83c\uddf8\ud83c\uddf4",
        "c": "SOS",
        "co": "AF"
    },
    "ZW": {
        "n": "Zimbabwe",
        "d": "263",
        "e": "\ud83c\uddff\ud83c\uddfc",
        "c": "ZWL",
        "co": "AF"
    },
    "SA": {
        "n": "Saudi Arabia",
        "d": "966",
        "e": "\ud83c\uddf8\ud83c\udde6",
        "c": "SAR",
        "co": "AS"
    },
    "ES": {
        "n": "Spain",
        "d": "34",
        "e": "\ud83c\uddea\ud83c\uddf8",
        "c": "EUR",
        "co": "EU"
    },
    "ER": {
        "n": "Eritrea",
        "d": "291",
        "e": "\ud83c\uddea\ud83c\uddf7",
        "c": "ERN",
        "co": "AF"
    },
    "ME": {
        "n": "Montenegro",
        "d": "382",
        "e": "\ud83c\uddf2\ud83c\uddea",
        "c": "EUR",
        "co": "EU"
    },
    "MD": {
        "n": "Moldova",
        "d": "373",
        "e": "\ud83c\uddf2\ud83c\udde9",
        "c": "MDL",
        "co": "EU"
    },
    "MG": {
        "n": "Madagascar",
        "d": "261",
        "e": "\ud83c\uddf2\ud83c\uddec",
        "c": "MGA",
        "co": "AF"
    },
    "MF": {
        "n": "Saint Martin",
        "d": "590",
        "e": "\ud83c\uddf2\ud83c\uddeb",
        "c": "EUR",
        "co": "NA"
    },
    "MA": {
        "n": "Morocco",
        "d": "212",
        "e": "\ud83c\uddf2\ud83c\udde6",
        "c": "MAD",
        "co": "AF"
    },
    "MC": {
        "n": "Monaco",
        "d": "377",
        "e": "\ud83c\uddf2\ud83c\udde8",
        "c": "EUR",
        "co": "EU"
    },
    "UZ": {
        "n": "Uzbekistan",
        "d": "998",
        "e": "\ud83c\uddfa\ud83c\uddff",
        "c": "UZS",
        "co": "AS"
    },
    "MM": {
        "n": "Myanmar",
        "d": "95",
        "e": "\ud83c\uddf2\ud83c\uddf2",
        "c": "MMK",
        "co": "AS"
    },
    "ML": {
        "n": "Mali",
        "d": "223",
        "e": "\ud83c\uddf2\ud83c\uddf1",
        "c": "XOF",
        "co": "AF"
    },
    "MO": {
        "n": "Macao",
        "d": "853",
        "e": "\ud83c\uddf2\ud83c\uddf4",
        "c": "MOP",
        "co": "AS"
    },
    "MN": {
        "n": "Mongolia",
        "d": "976",
        "e": "\ud83c\uddf2\ud83c\uddf3",
        "c": "MNT",
        "co": "AS"
    },
    "MH": {
        "n": "Marshall Islands",
        "d": "692",
        "e": "\ud83c\uddf2\ud83c\udded",
        "c": "USD",
        "co": "OC"
    },
    "MK": {
        "n": "Macedonia",
        "d": "389",
        "e": "\ud83c\uddf2\ud83c\uddf0",
        "c": "MKD",
        "co": "EU"
    },
    "MU": {
        "n": "Mauritius",
        "d": "230",
        "e": "\ud83c\uddf2\ud83c\uddfa",
        "c": "MUR",
        "co": "AF"
    },
    "MT": {
        "n": "Malta",
        "d": "356",
        "e": "\ud83c\uddf2\ud83c\uddf9",
        "c": "EUR",
        "co": "EU"
    },
    "MW": {
        "n": "Malawi",
        "d": "265",
        "e": "\ud83c\uddf2\ud83c\uddfc",
        "c": "MWK",
        "co": "AF"
    },
    "MV": {
        "n": "Maldives",
        "d": "960",
        "e": "\ud83c\uddf2\ud83c\uddfb",
        "c": "MVR",
        "co": "AS"
    },
    "MQ": {
        "n": "Martinique",
        "d": "596",
        "e": "\ud83c\uddf2\ud83c\uddf6",
        "c": "EUR",
        "co": "NA"
    },
    "MP": {
        "n": "Northern Mariana Islands",
        "d": "1-670",
        "e": "\ud83c\uddf2\ud83c\uddf5",
        "c": "USD",
        "co": "OC"
    },
    "MS": {
        "n": "Montserrat",
        "d": "1-664",
        "e": "\ud83c\uddf2\ud83c\uddf8",
        "c": "XCD",
        "co": "NA"
    },
    "MR": {
        "n": "Mauritania",
        "d": "222",
        "e": "\ud83c\uddf2\ud83c\uddf7",
        "c": "MRO",
        "co": "AF"
    },
    "IM": {
        "n": "Isle of Man",
        "d": "44-1624",
        "e": "\ud83c\uddee\ud83c\uddf2",
        "c": "GBP",
        "co": "EU"
    },
    "UG": {
        "n": "Uganda",
        "d": "256",
        "e": "\ud83c\uddfa\ud83c\uddec",
        "c": "UGX",
        "co": "AF"
    },
    "TZ": {
        "n": "Tanzania",
        "d": "255",
        "e": "\ud83c\uddf9\ud83c\uddff",
        "c": "TZS",
        "co": "AF"
    },
    "MY": {
        "n": "Malaysia",
        "d": "60",
        "e": "\ud83c\uddf2\ud83c\uddfe",
        "c": "MYR",
        "co": "AS"
    },
    "MX": {
        "n": "Mexico",
        "d": "52",
        "e": "\ud83c\uddf2\ud83c\uddfd",
        "c": "MXN",
        "co": "NA"
    },
    "IL": {
        "n": "Israel",
        "d": "972",
        "e": "\ud83c\uddee\ud83c\uddf1",
        "c": "ILS",
        "co": "AS"
    },
    "FR": {
        "n": "France",
        "d": "33",
        "e": "\ud83c\uddeb\ud83c\uddf7",
        "c": "EUR",
        "co": "EU"
    },
    "IO": {
        "n": "British Indian Ocean Territory",
        "d": "246",
        "e": "\ud83c\uddee\ud83c\uddf4",
        "c": "USD",
        "co": "AS"
    },
    "SH": {
        "n": "Saint Helena",
        "d": "290",
        "e": "\ud83c\uddf8\ud83c\udded",
        "c": "SHP",
        "co": "AF"
    },
    "FI": {
        "n": "Finland",
        "d": "358",
        "e": "\ud83c\uddeb\ud83c\uddee",
        "c": "EUR",
        "co": "EU"
    },
    "FJ": {
        "n": "Fiji",
        "d": "679",
        "e": "\ud83c\uddeb\ud83c\uddef",
        "c": "FJD",
        "co": "OC"
    },
    "FK": {
        "n": "Falkland Islands",
        "d": "500",
        "e": "\ud83c\uddeb\ud83c\uddf0",
        "c": "FKP",
        "co": "SA"
    },
    "FM": {
        "n": "Micronesia",
        "d": "691",
        "e": "\ud83c\uddeb\ud83c\uddf2",
        "c": "USD",
        "co": "OC"
    },
    "FO": {
        "n": "Faroe Islands",
        "d": "298",
        "e": "\ud83c\uddeb\ud83c\uddf4",
        "c": "DKK",
        "co": "EU"
    },
    "NI": {
        "n": "Nicaragua",
        "d": "505",
        "e": "\ud83c\uddf3\ud83c\uddee",
        "c": "NIO",
        "co": "NA"
    },
    "NL": {
        "n": "Netherlands",
        "d": "31",
        "e": "\ud83c\uddf3\ud83c\uddf1",
        "c": "EUR",
        "co": "EU"
    },
    "NO": {
        "n": "Norway",
        "d": "47",
        "e": "\ud83c\uddf3\ud83c\uddf4",
        "c": "NOK",
        "co": "EU"
    },
    "NA": {
        "n": "Namibia",
        "d": "264",
        "e": "\ud83c\uddf3\ud83c\udde6",
        "c": "NAD",
        "co": "AF"
    },
    "VU": {
        "n": "Vanuatu",
        "d": "678",
        "e": "\ud83c\uddfb\ud83c\uddfa",
        "c": "VUV",
        "co": "OC"
    },
    "NC": {
        "n": "New Caledonia",
        "d": "687",
        "e": "\ud83c\uddf3\ud83c\udde8",
        "c": "XPF",
        "co": "OC"
    },
    "NE": {
        "n": "Niger",
        "d": "227",
        "e": "\ud83c\uddf3\ud83c\uddea",
        "c": "XOF",
        "co": "AF"
    },
    "NF": {
        "n": "Norfolk Island",
        "d": "672",
        "e": "\ud83c\uddf3\ud83c\uddeb",
        "c": "AUD",
        "co": "OC"
    },
    "NG": {
        "n": "Nigeria",
        "d": "234",
        "e": "\ud83c\uddf3\ud83c\uddec",
        "c": "NGN",
        "co": "AF"
    },
    "NZ": {
        "n": "New Zealand",
        "d": "64",
        "e": "\ud83c\uddf3\ud83c\uddff",
        "c": "NZD",
        "co": "OC"
    },
    "NP": {
        "n": "Nepal",
        "d": "977",
        "e": "\ud83c\uddf3\ud83c\uddf5",
        "c": "NPR",
        "co": "AS"
    },
    "NR": {
        "n": "Nauru",
        "d": "674",
        "e": "\ud83c\uddf3\ud83c\uddf7",
        "c": "AUD",
        "co": "OC"
    },
    "NU": {
        "n": "Niue",
        "d": "683",
        "e": "\ud83c\uddf3\ud83c\uddfa",
        "c": "NZD",
        "co": "OC"
    },
    "CK": {
        "n": "Cook Islands",
        "d": "682",
        "e": "\ud83c\udde8\ud83c\uddf0",
        "c": "NZD",
        "co": "OC"
    },
    "XK": {
        "n": "Kosovo",
        "d": "",
        "e": "\ud83c\uddfd\ud83c\uddf0",
        "c": "EUR",
        "co": "EU"
    },
    "CI": {
        "n": "Ivory Coast",
        "d": "225",
        "e": "\ud83c\udde8\ud83c\uddee",
        "c": "XOF",
        "co": "AF"
    },
    "CH": {
        "n": "Switzerland",
        "d": "41",
        "e": "\ud83c\udde8\ud83c\udded",
        "c": "CHF",
        "co": "EU"
    },
    "CO": {
        "n": "Colombia",
        "d": "57",
        "e": "\ud83c\udde8\ud83c\uddf4",
        "c": "COP",
        "co": "SA"
    },
    "CN": {
        "n": "China",
        "d": "86",
        "e": "\ud83c\udde8\ud83c\uddf3",
        "c": "CNY",
        "co": "AS"
    },
    "CM": {
        "n": "Cameroon",
        "d": "237",
        "e": "\ud83c\udde8\ud83c\uddf2",
        "c": "XAF",
        "co": "AF"
    },
    "CL": {
        "n": "Chile",
        "d": "56",
        "e": "\ud83c\udde8\ud83c\uddf1",
        "c": "CLP",
        "co": "SA"
    },
    "CC": {
        "n": "Cocos Islands",
        "d": "61",
        "e": "\ud83c\udde8\ud83c\udde8",
        "c": "AUD",
        "co": "AS"
    },
    "CA": {
        "n": "Canada",
        "d": "1",
        "e": "\ud83c\udde8\ud83c\udde6",
        "c": "CAD",
        "co": "NA"
    },
    "CG": {
        "n": "Republic of the Congo",
        "d": "242",
        "e": "\ud83c\udde8\ud83c\uddec",
        "c": "XAF",
        "co": "AF"
    },
    "CF": {
        "n": "Central African Republic",
        "d": "236",
        "e": "\ud83c\udde8\ud83c\uddeb",
        "c": "XAF",
        "co": "AF"
    },
    "CD": {
        "n": "Democratic Republic of the Congo",
        "d": "243",
        "e": "\ud83c\udde8\ud83c\udde9",
        "c": "CDF",
        "co": "AF"
    },
    "CZ": {
        "n": "Czech Republic",
        "d": "420",
        "e": "\ud83c\udde8\ud83c\uddff",
        "c": "CZK",
        "co": "EU"
    },
    "CY": {
        "n": "Cyprus",
        "d": "357",
        "e": "\ud83c\udde8\ud83c\uddfe",
        "c": "EUR",
        "co": "EU"
    },
    "CX": {
        "n": "Christmas Island",
        "d": "61",
        "e": "\ud83c\udde8\ud83c\uddfd",
        "c": "AUD",
        "co": "AS"
    },
    "CR": {
        "n": "Costa Rica",
        "d": "506",
        "e": "\ud83c\udde8\ud83c\uddf7",
        "c": "CRC",
        "co": "NA"
    },
    "CW": {
        "n": "Curacao",
        "d": "599",
        "e": "\ud83c\udde8\ud83c\uddfc",
        "c": "ANG",
        "co": "NA"
    },
    "CV": {
        "n": "Cape Verde",
        "d": "238",
        "e": "\ud83c\udde8\ud83c\uddfb",
        "c": "CVE",
        "co": "AF"
    },
    "CU": {
        "n": "Cuba",
        "d": "53",
        "e": "\ud83c\udde8\ud83c\uddfa",
        "c": "CUP",
        "co": "NA"
    },
    "SZ": {
        "n": "Swaziland",
        "d": "268",
        "e": "\ud83c\uddf8\ud83c\uddff",
        "c": "SZL",
        "co": "AF"
    },
    "SY": {
        "n": "Syria",
        "d": "963",
        "e": "\ud83c\uddf8\ud83c\uddfe",
        "c": "SYP",
        "co": "AS"
    },
    "SX": {
        "n": "Sint Maarten",
        "d": "599",
        "e": "\ud83c\uddf8\ud83c\uddfd",
        "c": "ANG",
        "co": "NA"
    },
    "KG": {
        "n": "Kyrgyzstan",
        "d": "996",
        "e": "\ud83c\uddf0\ud83c\uddec",
        "c": "KGS",
        "co": "AS"
    },
    "KE": {
        "n": "Kenya",
        "d": "254",
        "e": "\ud83c\uddf0\ud83c\uddea",
        "c": "KES",
        "co": "AF"
    },
    "SS": {
        "n": "South Sudan",
        "d": "211",
        "e": "\ud83c\uddf8\ud83c\uddf8",
        "c": "SSP",
        "co": "AF"
    },
    "SR": {
        "n": "Suriname",
        "d": "597",
        "e": "\ud83c\uddf8\ud83c\uddf7",
        "c": "SRD",
        "co": "SA"
    },
    "KI": {
        "n": "Kiribati",
        "d": "686",
        "e": "\ud83c\uddf0\ud83c\uddee",
        "c": "AUD",
        "co": "OC"
    },
    "KH": {
        "n": "Cambodia",
        "d": "855",
        "e": "\ud83c\uddf0\ud83c\udded",
        "c": "KHR",
        "co": "AS"
    },
    "KN": {
        "n": "Saint Kitts and Nevis",
        "d": "1-869",
        "e": "\ud83c\uddf0\ud83c\uddf3",
        "c": "XCD",
        "co": "NA"
    },
    "KM": {
        "n": "Comoros",
        "d": "269",
        "e": "\ud83c\uddf0\ud83c\uddf2",
        "c": "KMF",
        "co": "AF"
    },
    "ST": {
        "n": "Sao Tome and Principe",
        "d": "239",
        "e": "\ud83c\uddf8\ud83c\uddf9",
        "c": "STD",
        "co": "AF"
    },
    "SK": {
        "n": "Slovakia",
        "d": "421",
        "e": "\ud83c\uddf8\ud83c\uddf0",
        "c": "EUR",
        "co": "EU"
    },
    "KR": {
        "n": "South Korea",
        "d": "82",
        "e": "\ud83c\uddf0\ud83c\uddf7",
        "c": "KRW",
        "co": "AS"
    },
    "SI": {
        "n": "Slovenia",
        "d": "386",
        "e": "\ud83c\uddf8\ud83c\uddee",
        "c": "EUR",
        "co": "EU"
    },
    "KP": {
        "n": "North Korea",
        "d": "850",
        "e": "\ud83c\uddf0\ud83c\uddf5",
        "c": "KPW",
        "co": "AS"
    },
    "KW": {
        "n": "Kuwait",
        "d": "965",
        "e": "\ud83c\uddf0\ud83c\uddfc",
        "c": "KWD",
        "co": "AS"
    },
    "SN": {
        "n": "Senegal",
        "d": "221",
        "e": "\ud83c\uddf8\ud83c\uddf3",
        "c": "XOF",
        "co": "AF"
    },
    "SM": {
        "n": "San Marino",
        "d": "378",
        "e": "\ud83c\uddf8\ud83c\uddf2",
        "c": "EUR",
        "co": "EU"
    },
    "SL": {
        "n": "Sierra Leone",
        "d": "232",
        "e": "\ud83c\uddf8\ud83c\uddf1",
        "c": "SLL",
        "co": "AF"
    },
    "SC": {
        "n": "Seychelles",
        "d": "248",
        "e": "\ud83c\uddf8\ud83c\udde8",
        "c": "SCR",
        "co": "AF"
    },
    "KZ": {
        "n": "Kazakhstan",
        "d": "7",
        "e": "\ud83c\uddf0\ud83c\uddff",
        "c": "KZT",
        "co": "AS"
    },
    "KY": {
        "n": "Cayman Islands",
        "d": "1-345",
        "e": "\ud83c\uddf0\ud83c\uddfe",
        "c": "KYD",
        "co": "NA"
    },
    "SG": {
        "n": "Singapore",
        "d": "65",
        "e": "\ud83c\uddf8\ud83c\uddec",
        "c": "SGD",
        "co": "AS"
    },
    "SE": {
        "n": "Sweden",
        "d": "46",
        "e": "\ud83c\uddf8\ud83c\uddea",
        "c": "SEK",
        "co": "EU"
    },
    "SD": {
        "n": "Sudan",
        "d": "249",
        "e": "\ud83c\uddf8\ud83c\udde9",
        "c": "SDG",
        "co": "AF"
    },
    "DO": {
        "n": "Dominican Republic",
        "d": "1-809 and 1-829",
        "e": "\ud83c\udde9\ud83c\uddf4",
        "c": "DOP",
        "co": "NA"
    },
    "DM": {
        "n": "Dominica",
        "d": "1-767",
        "e": "\ud83c\udde9\ud83c\uddf2",
        "c": "XCD",
        "co": "NA"
    },
    "DJ": {
        "n": "Djibouti",
        "d": "253",
        "e": "\ud83c\udde9\ud83c\uddef",
        "c": "DJF",
        "co": "AF"
    },
    "DK": {
        "n": "Denmark",
        "d": "45",
        "e": "\ud83c\udde9\ud83c\uddf0",
        "c": "DKK",
        "co": "EU"
    },
    "VG": {
        "n": "British Virgin Islands",
        "d": "1-284",
        "e": "\ud83c\uddfb\ud83c\uddec",
        "c": "USD",
        "co": "NA"
    },
    "DE": {
        "n": "Germany",
        "d": "49",
        "e": "\ud83c\udde9\ud83c\uddea",
        "c": "EUR",
        "co": "EU"
    },
    "YE": {
        "n": "Yemen",
        "d": "967",
        "e": "\ud83c\uddfe\ud83c\uddea",
        "c": "YER",
        "co": "AS"
    },
    "DZ": {
        "n": "Algeria",
        "d": "213",
        "e": "\ud83c\udde9\ud83c\uddff",
        "c": "DZD",
        "co": "AF"
    },
    "US": {
        "n": "United States",
        "d": "1",
        "e": "\ud83c\uddfa\ud83c\uddf8",
        "c": "USD",
        "co": "NA"
    },
    "UY": {
        "n": "Uruguay",
        "d": "598",
        "e": "\ud83c\uddfa\ud83c\uddfe",
        "c": "UYU",
        "co": "SA"
    },
    "YT": {
        "n": "Mayotte",
        "d": "262",
        "e": "\ud83c\uddfe\ud83c\uddf9",
        "c": "EUR",
        "co": "AF"
    },
    "UM": {
        "n": "United States Minor Outlying Islands",
        "d": "1",
        "e": "\ud83c\uddfa\ud83c\uddf2",
        "c": "USD",
        "co": "OC"
    },
    "LB": {
        "n": "Lebanon",
        "d": "961",
        "e": "\ud83c\uddf1\ud83c\udde7",
        "c": "LBP",
        "co": "AS"
    },
    "LC": {
        "n": "Saint Lucia",
        "d": "1-758",
        "e": "\ud83c\uddf1\ud83c\udde8",
        "c": "XCD",
        "co": "NA"
    },
    "LA": {
        "n": "Laos",
        "d": "856",
        "e": "\ud83c\uddf1\ud83c\udde6",
        "c": "LAK",
        "co": "AS"
    },
    "TV": {
        "n": "Tuvalu",
        "d": "688",
        "e": "\ud83c\uddf9\ud83c\uddfb",
        "c": "AUD",
        "co": "OC"
    },
    "TW": {
        "n": "Taiwan",
        "d": "886",
        "e": "\ud83c\uddf9\ud83c\uddfc",
        "c": "TWD",
        "co": "AS"
    },
    "TT": {
        "n": "Trinidad and Tobago",
        "d": "1-868",
        "e": "\ud83c\uddf9\ud83c\uddf9",
        "c": "TTD",
        "co": "NA"
    },
    "TR": {
        "n": "Turkey",
        "d": "90",
        "e": "\ud83c\uddf9\ud83c\uddf7",
        "c": "TRY",
        "co": "AS"
    },
    "LK": {
        "n": "Sri Lanka",
        "d": "94",
        "e": "\ud83c\uddf1\ud83c\uddf0",
        "c": "LKR",
        "co": "AS"
    },
    "LI": {
        "n": "Liechtenstein",
        "d": "423",
        "e": "\ud83c\uddf1\ud83c\uddee",
        "c": "CHF",
        "co": "EU"
    },
    "LV": {
        "n": "Latvia",
        "d": "371",
        "e": "\ud83c\uddf1\ud83c\uddfb",
        "c": "EUR",
        "co": "EU"
    },
    "TO": {
        "n": "Tonga",
        "d": "676",
        "e": "\ud83c\uddf9\ud83c\uddf4",
        "c": "TOP",
        "co": "OC"
    },
    "LT": {
        "n": "Lithuania",
        "d": "370",
        "e": "\ud83c\uddf1\ud83c\uddf9",
        "c": "LTL",
        "co": "EU"
    },
    "LU": {
        "n": "Luxembourg",
        "d": "352",
        "e": "\ud83c\uddf1\ud83c\uddfa",
        "c": "EUR",
        "co": "EU"
    },
    "LR": {
        "n": "Liberia",
        "d": "231",
        "e": "\ud83c\uddf1\ud83c\uddf7",
        "c": "LRD",
        "co": "AF"
    },
    "LS": {
        "n": "Lesotho",
        "d": "266",
        "e": "\ud83c\uddf1\ud83c\uddf8",
        "c": "LSL",
        "co": "AF"
    },
    "TH": {
        "n": "Thailand",
        "d": "66",
        "e": "\ud83c\uddf9\ud83c\udded",
        "c": "THB",
        "co": "AS"
    },
    "TF": {
        "n": "French Southern Territories",
        "d": "",
        "e": "\ud83c\uddf9\ud83c\uddeb",
        "c": "EUR",
        "co": "AN"
    },
    "TG": {
        "n": "Togo",
        "d": "228",
        "e": "\ud83c\uddf9\ud83c\uddec",
        "c": "XOF",
        "co": "AF"
    },
    "TD": {
        "n": "Chad",
        "d": "235",
        "e": "\ud83c\uddf9\ud83c\udde9",
        "c": "XAF",
        "co": "AF"
    },
    "TC": {
        "n": "Turks and Caicos Islands",
        "d": "1-649",
        "e": "\ud83c\uddf9\ud83c\udde8",
        "c": "USD",
        "co": "NA"
    },
    "LY": {
        "n": "Libya",
        "d": "218",
        "e": "\ud83c\uddf1\ud83c\uddfe",
        "c": "LYD",
        "co": "AF"
    },
    "VA": {
        "n": "Vatican",
        "d": "379",
        "e": "\ud83c\uddfb\ud83c\udde6",
        "c": "EUR",
        "co": "EU"
    },
    "VC": {
        "n": "Saint Vincent and the Grenadines",
        "d": "1-784",
        "e": "\ud83c\uddfb\ud83c\udde8",
        "c": "XCD",
        "co": "NA"
    },
    "AE": {
        "n": "United Arab Emirates",
        "d": "971",
        "e": "\ud83c\udde6\ud83c\uddea",
        "c": "AED",
        "co": "AS"
    },
    "AD": {
        "n": "Andorra",
        "d": "376",
        "e": "\ud83c\udde6\ud83c\udde9",
        "c": "EUR",
        "co": "EU"
    },
    "AG": {
        "n": "Antigua and Barbuda",
        "d": "1-268",
        "e": "\ud83c\udde6\ud83c\uddec",
        "c": "XCD",
        "co": "NA"
    },
    "AF": {
        "n": "Afghanistan",
        "d": "93",
        "e": "\ud83c\udde6\ud83c\uddeb",
        "c": "AFN",
        "co": "AS"
    },
    "AI": {
        "n": "Anguilla",
        "d": "1-264",
        "e": "\ud83c\udde6\ud83c\uddee",
        "c": "XCD",
        "co": "NA"
    },
    "VI": {
        "n": "U.S. Virgin Islands",
        "d": "1-340",
        "e": "\ud83c\uddfb\ud83c\uddee",
        "c": "USD",
        "co": "NA"
    },
    "IS": {
        "n": "Iceland",
        "d": "354",
        "e": "\ud83c\uddee\ud83c\uddf8",
        "c": "ISK",
        "co": "EU"
    },
    "IR": {
        "n": "Iran",
        "d": "98",
        "e": "\ud83c\uddee\ud83c\uddf7",
        "c": "IRR",
        "co": "AS"
    },
    "AM": {
        "n": "Armenia",
        "d": "374",
        "e": "\ud83c\udde6\ud83c\uddf2",
        "c": "AMD",
        "co": "AS"
    },
    "AL": {
        "n": "Albania",
        "d": "355",
        "e": "\ud83c\udde6\ud83c\uddf1",
        "c": "ALL",
        "co": "EU"
    },
    "AO": {
        "n": "Angola",
        "d": "244",
        "e": "\ud83c\udde6\ud83c\uddf4",
        "c": "AOA",
        "co": "AF"
    },
    "AQ": {
        "n": "Antarctica",
        "d": "",
        "e": "\ud83c\udde6\ud83c\uddf6",
        "c": "",
        "co": "AN"
    },
    "AS": {
        "n": "American Samoa",
        "d": "1-684",
        "e": "\ud83c\udde6\ud83c\uddf8",
        "c": "USD",
        "co": "OC"
    },
    "AR": {
        "n": "Argentina",
        "d": "54",
        "e": "\ud83c\udde6\ud83c\uddf7",
        "c": "ARS",
        "co": "SA"
    },
    "AU": {
        "n": "Australia",
        "d": "61",
        "e": "\ud83c\udde6\ud83c\uddfa",
        "c": "AUD",
        "co": "OC"
    },
    "AT": {
        "n": "Austria",
        "d": "43",
        "e": "\ud83c\udde6\ud83c\uddf9",
        "c": "EUR",
        "co": "EU"
    },
    "AW": {
        "n": "Aruba",
        "d": "297",
        "e": "\ud83c\udde6\ud83c\uddfc",
        "c": "AWG",
        "co": "NA"
    },
    "IN": {
        "n": "India",
        "d": "91",
        "e": "\ud83c\uddee\ud83c\uddf3",
        "c": "INR",
        "co": "AS"
    },
    "AX": {
        "n": "Aland Islands",
        "d": "358-18",
        "e": "\ud83c\udde6\ud83c\uddfd",
        "c": "EUR",
        "co": "EU"
    },
    "AZ": {
        "n": "Azerbaijan",
        "d": "994",
        "e": "\ud83c\udde6\ud83c\uddff",
        "c": "AZN",
        "co": "AS"
    },
    "IE": {
        "n": "Ireland",
        "d": "353",
        "e": "\ud83c\uddee\ud83c\uddea",
        "c": "EUR",
        "co": "EU"
    },
    "ID": {
        "n": "Indonesia",
        "d": "62",
        "e": "\ud83c\uddee\ud83c\udde9",
        "c": "IDR",
        "co": "AS"
    },
    "UA": {
        "n": "Ukraine",
        "d": "380",
        "e": "\ud83c\uddfa\ud83c\udde6",
        "c": "UAH",
        "co": "EU"
    },
    "QA": {
        "n": "Qatar",
        "d": "974",
        "e": "\ud83c\uddf6\ud83c\udde6",
        "c": "QAR",
        "co": "AS"
    },
    "MZ": {
        "n": "Mozambique",
        "d": "258",
        "e": "\ud83c\uddf2\ud83c\uddff",
        "c": "MZN",
        "co": "AF"
    }
}


export const allResellerList = ["RESELLER", "RESELLER L1", "RESELLER L2"];
export const reseller12List = ["RESELLER L1", "RESELLER L2"];
