import * as React from 'react';
import {Avatar} from "react-native-paper"
import {Chip, Text, View} from '../components/Themed';
import RootPage from "../components/RootPage";
import Class from "../constants/Style"
import {connect} from "react-redux";
import {isAllReseller, signOut} from "../libs/function";
import ProfileItem from "../components/ProfileItem";
import {setLoadingAndToken} from "../libs/Store/actions/app_info";
import DhruScrollView from "../components/DhruScrollView";
import {allResellerList} from "../libs/static";


class Profile extends React.Component<any, any> {

    constructor(props) {
        super(props);
        const {
            navigation,
            setLoadingAndToken,
            profile
        } = props;
        this.ProfileOptions = [// {label: "Invoices", icon: "file-text-o", onPress: () => {
            //         navigation.navigate("InvoicesScreen")
            //     }},

            {
                label  : `${isAllReseller(allResellerList) ? 'Reseller' : 'Wallet'} ( Credits : ${profile?.credit} )`,
                icon   : "superpowers",
                onPress: () => {
                    navigation.navigate("WalletScreen")
                }
            }, {
                label  : "OTP Services",
                icon   : "briefcase",
                onPress: () => {
                    navigation.navigate("OTPServices")
                }
            }, // {
            //     label: "Banners & Themes Store", icon: "photo", onPress: () => {
            //         navigation.navigate("StoreScreen")
            //     }
            // },
            {
                label  : "Update Profile",
                icon   : "edit",
                onPress: () => {
                    navigation.navigate("UpdateProfileScreen")
                }
            }, {
                label  : "Reset Password",
                icon   : "lock",
                onPress: () => {
                    navigation.navigate("ChangePasswordScreen")
                }
            }, {
                label  : "Sign out",
                icon   : "sign-out",
                onPress: async () => signOut(),
                hide   : true
            },]

        // if (isReseller()){
        //     this.ProfileOptions = [
        //         {
        //             label: "Seller", icon: "file-text-o", onPress: () => {
        //                 navigation.navigate("StoreItemScreen")
        //             }
        //         },
        //         ...this.ProfileOptions
        //     ]
        // }
    }


    render() {
        const {
            profile,
            navigation
        } = this.props;
        const {
            full_name,
            email
        } = profile;
        return (<RootPage navigation={navigation} noBack={false} title={"Profile"}>
                <View style={Class.container}>
                    <View style={Class.profileHeader}>
                        {/*<Avatar.Text size={56} label={getProfileLabel(full_name)}/>*/}
                        <Avatar.Image size={56} source={{uri: profile.avatar_url}}/>
                        <Text lightColor={"rgba(74,74,74,1)"} style={Class.fullName}>{full_name}</Text>
                        <Text lightColor={"rgba(74,74,74,1)"} style={Class.email}>{email}</Text>
                        <View style={[Class.row]}>
                            <Chip style={[Class.marginTop4]}>{profile.group}</Chip>
                        </View>
                    </View>
                    <DhruScrollView>
                        <View style={[Class.separator, {marginLeft: 0}]} lightColor="#eee"
                              darkColor="rgba(255,255,255,0.1)"/>
                        {this.ProfileOptions.map((item, index) => {
                            if (index == 0) {
                                if (isAllReseller(allResellerList)) {
                                    item.label = `Reseller ( Credits : ${profile?.credit} )`;
                                } else {
                                    item.label = `Wallet ( Credits : ${profile?.credit} )`;
                                }

                            }
                            return <ProfileItem
                                key={index}
                                {...item}
                            />
                        })}
                    </DhruScrollView>
                </View>
            </RootPage>);
    }
}

const mapStateToProps = (state) => ({
    profile: state.app_info.profile
});

const mapDispatchToProps = (dispatch) => ({
    setLoadingAndToken: (data: any) => dispatch(setLoadingAndToken(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);

