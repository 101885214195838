import React from "react";
import {Text, View} from "./Themed";
import Class from '../constants/Style';
import "../constants/theme.css"
import {country} from "../libs/static";


interface DhruSelectProps {
    value: any,
    onChange: (value: any) => void,
    label: string,
    options: any[],
    style?: any,
    containerStyle?:any[]
}

const DhruSelect = ({value, onChange, label, options, containerStyle,...rest}: DhruSelectProps) => {

        console.log(value)

    return <View style={[Class.dropDownContainer, ...containerStyle]}>
        <select
            {...rest}
            value={value}
            onChange={event => onChange(event.target.value || undefined)}>
            <option value="">
                Select
            </option>
            {options.map(({label, value}: any) => (
                <option key={value} value={value}>
                    {label}
                </option>
            ))}
        </select>
        <Text style={{
            position: "absolute",
            backgroundColor: "#fff",
            top: -12,
            left: 10,
            padding: 4,
            color: "rgb(204, 204, 204)",
            fontSize: 12
        }}>{label}</Text>
    </View>
}

export default DhruSelect;
