import React, {useState} from "react";

import Class from "../constants/Style";
import {ContainedButton, List, Text, TextInputPaper, View} from "./Themed";
import SquareIcon from "./SquareIcon";
import {connect, useDispatch} from "react-redux";
import {browseLink, isEmpty} from "../libs/function";
import {isDevelopment} from "../libs/settings";
import {lightDescription} from "../constants/Colors";
import requestApi, {actions, methods, SUCCESS} from "../libs/ServerRequest";
import {setDialog, setLoader, setSnackbar} from "../libs/Store/actions/components";
import {TouchableOpacity} from "react-native";
import DialogView from "./DialogView";
import {Button} from "react-native-paper";

const AliasDialogView = (props: any) => {

    const dispatch = useDispatch()

    const {alias, product_id, getProducts, domain} = props;

    const [errors, setErrors] = useState<any>({})

    const [aliasName, setAliasName] = useState<any>(alias)

    const closeDialog = () => {
        dispatch(setDialog({
            visible: false, dialogview: undefined
        }))
    }

    const onSaveAlias = () => {
        if (isEmpty(aliasName)) {
            errorHandler("aliasName", 'Please Enter Alias', false)
        } else {
            requestApi({
                method       : methods.put, action: actions.product, body: {
                    product_id, "alias": aliasName
                }, showsucess: true
            }).then((response: any) => {
                if (response.status === SUCCESS) {
                    getProducts();
                    dispatch(setDialog({
                        visible: false, dialogview: undefined
                    }))
                }
            })
        }
    }

    const errorHandler = (keyName: any, message: any, value: any) => {
        if (Boolean(value)) {
            message = undefined
        }
        setErrors((prevState: any) => ({...prevState, [keyName]: message}))
    }

    return <DialogView dialogSize={"small"} actionView={<View style={[Class.row]}>
        <Button onPress={() => closeDialog()}>Cancel</Button>
        <View style={[Class.marginRight4]}/>
        <Button onPress={onSaveAlias}>Save</Button>
    </View>} title={Boolean(alias) ? `${domain} - Update Alias` : `${domain} - Set Alias`}>
        <View style={[Class.marginTop16, Class.marginBottom16]}>
            <TextInputPaper
                value={aliasName}
                onChangeText={(aliasName: any) => {
                    errorHandler("aliasName", 'Please Enter Alias', aliasName)
                    setAliasName(aliasName)
                }}
                label={"Alias Name"}
                placeholder={"Alias Name"}
                error={errors?.aliasName}
            />
        </View>
    </DialogView>
}

const WorkspaceItem = (props: any) => {

    const dispatch = useDispatch()

    const {
        domain, combine, check, right, onPress, alias, id, getProducts
    } = props;

    return <React.Fragment>
        <List descriptionNumberOfLines={5}
              title={<div>{domain} - <TouchableOpacity onPress={() => {
                  dispatch(setDialog({
                      visible: true, dialogview: <AliasDialogView
                          domain={domain}
                          getProducts={getProducts}
                          product_id={id}
                          alias={alias}
                      />
                  }))
              }}>
                  <View
                      style={[Class.aliasContainer, Class.flex, Class.row, Class.justifySpaceBetween]}
                  >
                      <Text style={[Class.textPrimaryColor, Class.aliasText]}>{alias || 'Set Alias'}</Text>
                      <SquareIcon
                          style={Class.margin0}
                          lightColor={"#3174de"}
                          darkColor={"#3174de"}
                          name={'pencil'} backgroundColor={`#fff`}/></View>
              </TouchableOpacity>
              </div>}
              description={combine}
              left={(props: any) => <SquareIcon name={check.icon} backgroundColor={`#${check.colour}`}/>}
              right={right}
              onPress={(event: any) => {
                  onPress(event)
              }}
        />
        <View style={Class.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)"/>

    </React.Fragment>
}

function ProductItem(props: any) {
    let {
        getProducts, prodcut, license_key, domain, onPress, products, statement, item, workspace_login, hide_right_login, workspace_login_io, status, isWorkspaceList, isResellerWorkspaceList, isOwnerShip, navigation, id, setSnackbar, isReseller, alias
    } = props;

    console.log("props", props)

    let check = products[prodcut];
    if (!Boolean(check)) {
        check = products["default"];
    }
    let combine = "";
    if (Boolean(prodcut)) {
        combine += prodcut;
    }
    if (Boolean(status) && (isWorkspaceList || isResellerWorkspaceList)) {
        combine += `- ${status}`;
    }
    if (Boolean(license_key)) {
        if (Boolean(combine)) {
            combine += " - "
        }
        combine += license_key;
    }
    if (statement) {
        check.icon = item.icon;
        check.colour = item.colour;
        domain = item.amount;
        combine = item.info;
    }
    let right;

    if (isWorkspaceList) {
        if ((Boolean(workspace_login) || Boolean(workspace_login_io)) && !hide_right_login) {
            right = () => <View style={[Class.row, Class.alignItemCenter]}>
                {isReseller && <View style={[Class.row, Class.alignItemCenter, {height: "100%"}]}>
                    <ContainedButton
                        mode={"text"}
                        icon={"swap-horizontal"}
                        style={{alignSelf: "center"}}
                        onPress={() => {
                            navigation.navigate("MoveLicenseScreen", {product_id: id})
                        }}>
                        Transfer
                    </ContainedButton>
                    <View style={[Class.vSeparator]} lightColor="#eee"
                          darkColor="rgba(255,255,255,0.1)"/>
                </View>}

                {Boolean(workspace_login_io) && <View style={[Class.row, Class.alignItemCenter, {height: "100%"}]}>
                    <ContainedButton
                        mode={"text"}
                        style={{alignSelf: "center"}}
                        onPress={() => {
                            browseLink(workspace_login_io.replace('dhru.io','dhru.in'), "_blank")
                        }}>
                        Login to V2
                    </ContainedButton>

                    <View style={[Class.vSeparator, Class.marginRight8]} lightColor="#eee"
                          darkColor="rgba(255,255,255,0.1)"/>

                    <ContainedButton
                        mode={"text"}
                        style={{alignSelf: "center"}}
                        onPress={() => {
                            if (isDevelopment) {
                                workspace_login_io = workspace_login_io.replace("https", "http").replace("dhru.io", "localhost:3000")
                            }
                            browseLink(workspace_login_io, "_blank")
                        }}>
                        Login To Workspace (DEV)
                    </ContainedButton>
                    <View style={[Class.vSeparator, Class.marginRight8]} lightColor="#eee"
                          darkColor="rgba(255,255,255,0.1)"/>
                </View>}

                {Boolean(workspace_login) && <SquareIcon name={"chevron-right"} iconStyle={{color: lightDescription}}/>}
            </View>
        }
    } else if (isResellerWorkspaceList) {
        right = () => <View style={[Class.row, Class.alignItemCenter, {height: "100%"}]}>
            <ContainedButton
                mode={"text"}
                style={{alignSelf: "center"}}
                onPress={() => {
                    requestApi({
                        method: methods.get, action: actions.resellerproduct, queryString: {workspace: domain},
                    }).then((result: any) => {
                        if (result?.status == SUCCESS && !isEmpty(result?.data)) {
                            if (Boolean(result?.data?.workspace_login)) {
                                browseLink(result?.data?.workspace_login, "_blank")
                            } else {
                                setSnackbar({
                                    visible: true, message: "Link not available", color: "#f44336"
                                })
                            }
                        } else if (isEmpty(result?.data)) {
                            setSnackbar({
                                visible: true, message: "Something went wrong", color: "#f44336"
                            })
                        }
                    })
                }}>
                Login
            </ContainedButton>
            <View style={[Class.vSeparator, Class.marginRight8]} lightColor="#eee" darkColor="rgba(255,255,255,0.1)"/>
            <SquareIcon name={"chevron-right"} iconStyle={{color: lightDescription}}/>
        </View>
    }

    if (isWorkspaceList) {
        return <WorkspaceItem {...{
            domain, combine, check, right, onPress, alias, id, getProducts
        }} />
    }

    return <React.Fragment>
        <List descriptionNumberOfLines={5}
              title={domain}
              description={combine}
              left={(props: any) => <SquareIcon name={check.icon} backgroundColor={`#${check.colour}`}/>}
              right={right}
              onPress={onPress}
        />
        <View style={Class.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)"/>
    </React.Fragment>
}

const mapStateToProps = (state: any) => ({
    products: state?.app_info?.static?.products
});

const mapDispatchToProps = (dispatch: any) => ({
    setSnackbar: (snackData: any) => setSnackbar(setLoader(snackData))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductItem);
