import * as React from 'react';
import {RefreshControl, ScrollView, TouchableOpacity} from 'react-native';
import Class from '../constants/Style';
import {List, View} from '../components/Themed';
import {connect} from "react-redux";
import {setInitData, setLoadingAndToken} from "../libs/Store/actions/app_info";
import {setPopPage} from "../libs/Store/actions/components";
import TicketItem from "../components/TicketItem";
import DropdownMenu from "../components/DropdownMenu";
import requestApi, {actions, methods, SUCCESS} from "../libs/ServerRequest";
import HeaderRight from "../components/HeaderRight";
import RootPage from "../components/RootPage";
import {isIOS, isWeb} from "../libs/settings";
import EmptyPage from "../components/EmptyPage";
import {browseLink, isEmptyArray} from "../libs/function";
import DhruScrollView, {DEFAULT_LOADER_VIEW_TIME} from "../components/DhruScrollView";

class Support extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            active: this.props.route.params.status,
            lastActive: "Open",
            refreshing: false,
            showDropDown: false,
            options: ["open", "resolved"]
        }
    }

    componentDidMount() {
        this.changeNavigation();
        const {initdata, navigation, defaultRedirect, setLoadingAndToken, linkTo} = this.props;
        const {support_tickets, closed_tickets} = initdata;

        if ((!Boolean(closed_tickets) || closed_tickets.length < 1) && this.state.active.toString().toLowerCase() === "resolved") {
            this.getSupportTicket()
        }

        // if (isWeb) {
        //     if (Boolean(initdata.defaultRedirect)) {
        //         // let spilit = initdata.defaultRedirect.split("http://localhost:19006")
        //         // if (Boolean(spilit[1])) {
        //         //     console.log("CALL REDIRECT", spilit[1]);
        //         //
        //         // }
        //         browseLink(initdata.defaultRedirect,"_self");
        //         setLoadingAndToken({loading: false, token:initdata.token, defaultRedirect: ""})
        //     }
        // }
    }

    changeNavigation = () => {
        const {navigation} = this.props;


        navigation.setOptions({
            headerLeft: () => <DropdownMenu label={this.state.active}
                                            onPress={() => this.setState({showDropDown: !this.state.showDropDown})}/>,
            headerRight: () => <HeaderRight
                actions={[{
                    name: "plus",
                    caption: "Create Case",
                    onPress: () => navigation.navigate("CategoryScreen")
                }]}/>
        });
    }

    getSupportTicket = () => {
        const {active, refreshing} = this.state;
        const {setInitData, initdata} = this.props;

        let status = active.toString().toLowerCase();
        if (active.toString().toLowerCase() === "resolved") {
            status = "closed";
        }

        requestApi({
            method: methods.get,
            action: actions.support,
            queryString: {status},
            loader: !refreshing,
        }).then((response) => {
            this.setState({
                refreshing: false
            });
            if (response.status === SUCCESS) {

                if (active === "Open") {
                    setInitData({
                        ...initdata,
                        support_tickets: response.data,
                    })
                } else {
                    setInitData({
                        ...initdata,
                        closed_tickets: response.data,
                    })
                }
            }
        })
    }


    render() {
        const {initdata, navigation} = this.props;
        const {support_tickets, closed_tickets} = initdata;
        const {active, options, showDropDown, refreshing} = this.state;

        let activeTicketsList = Boolean(active === "open") ? support_tickets : closed_tickets;
        if (!Boolean(activeTicketsList)) {
            activeTicketsList = [];
        }
        return (
            <RootPage navigation={navigation} noBack={false} title={"Support Cases"} headerTitle={isIOS ? "Case" : ""}>
                <View style={Class.container}>
                    {
                        Boolean(showDropDown) && <View style={Class.dropdownContainer}>
                            {
                                options
                                    .filter((opt) => opt !== active)
                                    .map((opt, index) => {
                                        return <List
                                            key={index}
                                            titleStyle={Class.textCapitalize}
                                            title={opt}
                                            onPress={() => {
                                                navigation.replace('SupportRoot', {
                                                    screen: 'SupportNav',
                                                    params: {
                                                        screen: 'Cases',
                                                        params: {
                                                            screen: 'CaseList',
                                                            params: {
                                                                status: opt
                                                            }
                                                        }
                                                    },
                                                });
                                            }}/>
                                    })
                            }
                        </View>
                    }
                    <View style={Class.container}>
                        {
                            <DhruScrollView
                                refreshControl={<RefreshControl
                                    tintColor={"#9e9e9e"}
                                    refreshing={refreshing}
                                    onRefresh={() => this.setState({refreshing: true}, () => this.getSupportTicket())}
                                />}>
                                {
                                    !isEmptyArray(activeTicketsList) ?

                                        activeTicketsList.map((ticket, index) => {
                                            const {Subject: title, ticket_id} = ticket;
                                            return <TicketItem
                                                key={index}
                                                {...{title, ...ticket}}
                                                onPress={() => {
                                                    navigation.navigate("ConversationScreen", {id: ticket_id})
                                                }}
                                            />
                                        })
                                        :
                                        <EmptyPage message={`You don't have any ${active} cases`}/>
                                }
                            </DhruScrollView>
                        }
                    </View>
                </View>
            </RootPage>
        );
    }
}

const mapStateToProps = (state) => ({
    initdata: state.app_info,
});

const mapDispatchToProps = (dispatch) => ({
    setInitData: (initData) => dispatch(setInitData(initData)),
    setPopPage: (popuppage) => dispatch(setPopPage(popuppage)),
    setLoadingAndToken: (data: any) => dispatch(setLoadingAndToken(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Support);

