import React, {Component} from "react";

// import {currencyFormat} from "../libs/function";
import Class from "../constants/Style";
import SquareIcon from "./SquareIcon";
import {List, Text, View} from "./Themed";
import {currencyFormat} from "../libs/function";
import Colors, {darkDescription, lightDescription} from "../constants/Colors";

class AddOnItem extends Component {

    render() {
        const {
            name,
            description,
            price,
            billingcycle,
            addonid,
            active,
            icon,
            colour,
            addproduct,
            onPress,
            checkout,
            amount,
            amount2,
            _price
        } = this.props
        let checkOutStyle, style = Class.paddingLeftRight0;
        if (checkout) {
            checkOutStyle = Class.marginLeft0
            style = Class.paddingLeftRight0
        }

        let left = <SquareIcon
            name={Boolean(active) ? 'check-square' : 'square-o'}
            style={{marginTop: 4, marginLeft: 0, marginRight: 0}}
            lightColor={Colors.light.primary}
            darkColor={Colors.dark.primary}
            iconStyle={{fontSize: 24}}
        />

        if (icon) {
            let iconStyle = {marginLeft: 0};
            if (Boolean(addproduct)) {
                iconStyle = {};
            }
            left = <SquareIcon style={iconStyle} name={icon} backgroundColor={`#${colour}`}/>
        }

        let visibleStrike = false;
        if (Boolean(amount2) && Boolean(amount2 !== amount)) {
            visibleStrike = true;
        } else if (Boolean(_price) && Boolean(price !== _price)) {
            visibleStrike = true;
        }

        return <React.Fragment>
            <List
                style={style}
                title={name}
                description={props => {
                    return <View style={[Class.row, Class.transparent, Class.alignItemsFlexStart]} onPress={onPress}>
                        <View style={[Class.flex, Class.marginRight16, Class.transparent]}>
                            <Text lightColor={lightDescription} darkColor={darkDescription}
                                  style={Class.addOnDescription}>{description}</Text>
                        </View>
                        <View style={[Class.addOnPriceContainer, Class.transparent]}>
                            <Text lightColor={"rgba(74,74,74,1)"}
                                  style={Class.addOnPrice}>{currencyFormat(Boolean(checkout) ? amount : price)}</Text>
                            {
                                Boolean(visibleStrike) && <Text lightColor={"rgba(74,74,74,1)"}
                                                                style={[Class.addOnPrice, Class.textDecorationLineThrough]}>{currencyFormat(Boolean(checkout) ? amount2 : _price)}</Text>
                            }

                            <Text lightColor={lightDescription} darkColor={darkDescription}
                                  style={[Class.addOnDescription]}>{billingcycle}</Text>
                        </View>
                    </View>
                }}
                left={props => left}
                onPress={onPress}
            />
            <View style={[Class.separator, Class.marginLeft0]} lightColor="#eee" darkColor="rgba(255,255,255,0.1)"/>
        </React.Fragment>

        // return (
        //     <TouchableOpacity style={styles.rect3} onPress={onPress}>
        //
        //         <View style={styles.group1}>
        //             <Text style={styles.customMobileApp1}>{name}</Text>
        //             <Text style={styles.loremIpsum2}>{description}</Text>
        //         </View>
        //         <View style={styles.rect4}>
        //             <Text style={styles.loremIpsum1}>{price}</Text>
        //             <Text style={styles.oneTime1}>{billingcycle}</Text>
        //         </View>
        //     </TouchableOpacity>
        // );
    }
}

export default AddOnItem;
