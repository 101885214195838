import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import requestApi, {actions, ERROR, methods, SUCCESS} from "../libs/ServerRequest";
import {setAuthUser, setInitData, setLoadingAndToken} from "../libs/Store/actions/app_info";
import {connect} from "react-redux";
import Class from '../constants/Style';
import ReCaptchaV3 from "@haskkor/react-native-recaptchav3";
import {isDevelopment, isWeb} from "../libs/settings";
import {withGoogleReCaptcha} from "react-google-recaptcha-v3";
import {browseLink, isEmpty, STORAGE_KEY, storeData} from "../libs/function";
import {ContainedButton, Text, View} from "../components/Themed";
import {validEmail} from "../libs/validate";
import DhruLogo from "../components/DhruLogo";
import RootPage from "../components/RootPage";
import {setLoader} from "../libs/Store/actions/components";
import LinkButton from "../components/LinkButton";
import RecaptchaContent from "../components/RecaptchaContent";
import OtpInput from 'react-otp-input';
import {RadioButton} from 'react-native-paper';
import {TouchableOpacity} from "react-native";
import Resend from "../components/Resend";
import OTPTextBox from "../components/OTPTextBox";



class VerifyOTP extends React.Component<any, any> {

    constructor(props) {
        super(props);

        console.log(props);

        const {route} = props;

        this.state = {
            otp: "",
            channel: "email",
            captcha: "",
            captchaCall: false,
            error: {},
            message: route?.params?.message,
            formVisible: true
        }
    }

    componentDidMount() {
    }

    validate = () => {
        const {otp} = this.state;
        let error = {};
        error = {...error, ...validEmail(error, "otp", otp)};
        this.setState({error})
        return isEmpty(error)
    }

    requestLogin = async () => {
        const {setAuthUser, navigation, fromCheckout, redirectPage, route} = this.props;
        let {otp, captcha} = this.state;
        if (isWeb) {
            captcha = await this.props.googleReCaptchaProps.executeRecaptcha();
        }
        this.props.setLoader({show: false});

        const {method, action, body, isAuthorizeScreen} = route?.params;

        requestApi({
            method,
            action,
            body: {
                ...body,
                otp, "g-recaptcha-response": captcha
            }
        }).then(async (response: any) => {
            if (response.status === SUCCESS && response?.data) {
                if (isAuthorizeScreen) {
                    if (isDevelopment && response?.data?.redirect_uri.includes("localhost")) {
                        response.data.redirect_uri = response.data.redirect_uri.toString().replace("localhost", "localhost:3000");
                    }
                    browseLink(response?.data?.redirect_uri, "_SELF")
                } else {
                    const value = {
                        token: response.token,
                        ...response.data,
                    }
                    storeData(STORAGE_KEY.USER_DATA, value).then(() => {
                        setAuthUser({
                            token: response.token,
                            ...response.data,
                        });
                         this.requestInit(response.token);

                    })
                }
            }

        })
    }

    requestInit = (token) => {
        const {navigation, setInitData, setLoadingAndToken, route} = this.props;
        requestApi({
            method: methods.get,
            action: actions.logininit,
        }).then((response) => {
            if (response.status === SUCCESS) {
                setInitData(response.data);

                if (route?.params?.a) {
                    setLoadingAndToken({loading: false, token, defaultRedirect: ""})
                    navigation.navigate("JoinWorkspace", {
                        a: route?.params?.a
                    })
                } else {
                    setLoadingAndToken({loading: false, token})
                }
            }
        })
    }

    onSubmit = () => {
        if (isWeb) {
            this.requestLogin()
        } else {
            this.props.setLoader({show: true});
            this.setState({captchaCall: true}, () => {
                this._captchaRef.refreshToken()
            });
        }
    }

    onResendOTP = () => {
        const {route} = this.props;

        console.log("route?.params?.data?.temp_token", route?.params?.data?.temp_token)

        requestApi({
            method: methods.get,
            action: actions.login,
            queryString: {
                channel: this.state?.channel
            },
            token: route?.params?.data?.temp_token
        }).then(async (response: any) => {
            this.setState({message: response.message, status: response?.status})
        })
    }

    otpHandler = (otp: any) => {
        this.setState({otp})
    }

    channelHandler = (channel: any) => {
        this.setState({channel})
    }

    render() {
        const {otp, onPress, error, captchaCall, message, formVisible, channel, status} = this.state;
        const {navigation, fromCheckout, redirectPage, route, from} = this.props;
        let isAuthorizeScreen = Boolean(route?.params?.authorize)
        return (
            <RootPage navigation={navigation} title={"Verify OTP"}>
                <View style={Class.container}>
                    <View style={Class.formContainer}>
                        <View style={Class.formGroup}>
                            <View style={[Class.alignSelfStretch, Class.marginBottom16]}>
                                <DhruLogo/>
                                <Text style={Class.pageHeader}>Verify OTP</Text>
                                <View style={[Class.row, Class.marginBottom16, {

                                }]}>
                                    <Text>{message}</Text>
                                </View>

                                <RadioButton.Group onValueChange={channel => this.channelHandler(channel)}
                                                   value={channel}>
                                    <View style={[Class.marginBottom16]}>
                                        {
                                            route?.params?.data?.email &&
                                            <TouchableOpacity style={[Class.row]}
                                                              onPress={() => this.channelHandler("email")}>
                                                <RadioButton value="email"/>
                                                <Text>{route?.params?.data?.email} </Text>
                                            </TouchableOpacity>
                                        }

                                        {
                                            route?.params?.data?.whatsapp && <TouchableOpacity style={[Class.row]}
                                                                                               onPress={() => this.channelHandler("whatsapp")}>
                                                <RadioButton value="whatsapp"/>
                                                <Text>{route?.params?.data?.whatsapp}</Text>
                                            </TouchableOpacity>
                                        }

                                    </View>
                                </RadioButton.Group>

                                {
                                    Boolean(formVisible) ? <React.Fragment>
                                            <OTPTextBox
                                                value={otp}
                                                onChange={this.otpHandler}
                                            />

                                            <View style={Class.buttonRowContainer}>
                                                <Resend onResendOTP={this.onResendOTP}/>
                                                {
                                                    (!isWeb && captchaCall) &&
                                                    <ReCaptchaV3
                                                        ref={(ref: RecaptchaV3) => this._captchaRef = ref}
                                                        captchaDomain={'https://api.dhru.com'}
                                                        siteKey={'6LebDscZAAAAAEfuz_E-rB6r12xLHekW_XlR-Y5V'}
                                                        onReceiveToken={(token: string) => {
                                                            this.setState({captcha: token, captchaCall: false}, () => {
                                                                this.requestLogin()
                                                            })
                                                        }}
                                                        action={""}/>
                                                }
                                                <ContainedButton
                                                    onPress={() => this.onSubmit()}
                                                    disabled={otp?.length != 6}
                                                >
                                                    Next
                                                </ContainedButton>

                                            </View>
                                        </React.Fragment> :
                                        <LinkButton caption={`Back to ${isAuthorizeScreen ? "authorize" : "login"}`}
                                                    onPress={() => {
                                                        if (fromCheckout) {
                                                            redirectPage("Login")
                                                        } else {
                                                            navigation.goBack()
                                                        }

                                                    }} style={Class.linkStyle}/>
                                }


                            </View>
                            <RecaptchaContent/>
                        </View>
                    </View>
                </View>
            </RootPage>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    setAuthUser: (authUser) => dispatch(setAuthUser(authUser)),
    setInitData: (initData) => dispatch(setInitData(initData)),
    setLoadingAndToken: (data) => dispatch(setLoadingAndToken(data)),
    setLoader: (loader) => dispatch(setLoader(loader)),
});


export default withGoogleReCaptcha(connect(mapStateToProps, mapDispatchToProps)(VerifyOTP));
