import * as React from "react";
import Class, {captchaContentStyle} from "../constants/Style";
import {Text} from "./Themed";
import DhruWebView from "./DhruWebView";
import {StyleSheet} from "react-native";
import Colors from "../constants/Colors";

export default ()=>{

    const content = `Protected by reCAPTCHA and subject to the Google <a target="_blank" href="https://policies.google.com/privacy?hl=en">Privacy Policy</a> and <a target="_blank" href="https://policies.google.com/terms?hl=en">Terms of Service</a>.`

    return <DhruWebView  htmlview html={content} stylesheet={captchaContentStyle} />
}


