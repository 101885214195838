import {ProductRootStack2, ProductRootStack} from "../StackNav"
import {createBottomTabNavigator} from "@react-navigation/bottom-tabs";
import {createDrawerNavigator} from "@react-navigation/drawer";
import DhruDrawer from "../DhruDrawer";
import {useWindowDimensions} from "react-native";
import ViewProduct from "../../screens/ViewProduct";
import Buyaddons from "../../screens/BuyAddon";
import Support from "../../screens/Support";
import * as React from "react";
import Orders from "../../screens/Orders";
import Profile from "../../screens/Profile";
import TabBarIcon from "../../components/TabBarIcon";
import Products from "../../screens/Products";
import {Text} from "../../components/Themed";
import {CommonNavigation} from "../CommonNavigation";
import {LARGE_SCREEN_WIDTH} from "../../libs/settings";
import AddProduct from "../../screens/AddProduct";
import {intercomVisibility, logData} from "../../libs/function";



const ProductRoot = (rootProps:any) => {


    const dimensions = useWindowDimensions();
    const isLargeScreen = dimensions.width >= LARGE_SCREEN_WIDTH;
    intercomVisibility(false)
    return <ProductRootStack.Navigator>
        <ProductRootStack.Screen
            name={rootProps.nextScreen}
            options={{headerShown: false}}
        >
            {props => <CommonNavigation {...props} initialRouteName={rootProps.nextInitRoot}/>}
        </ProductRootStack.Screen>
        {
            !Boolean(isLargeScreen) && <React.Fragment>
                {/*<ProductRootStack.Screen name="AddProductScreen" component={AddProduct}/>*/}
                {/*<ProductRootStack.Screen name="ViewProductScreen" component={ViewProduct}/>*/}
                {/*<ProductRootStack.Screen name="BuyAddonScreen" component={Buyaddons}/>*/}
            </React.Fragment>
        }
    </ProductRootStack.Navigator>
}

export default ProductRoot;


// export const CommonNav = ({initialRouteName, ...otherProps}) => {
//     const dimensions = useWindowDimensions();
//     const isLargeScreen = dimensions.width >= 1280;
//
//     if (isLargeScreen) {
//         const DrawerNavStack = createDrawerNavigator();
//         return <DrawerNavStack.Navigator
//             drawerType={"permanent"}
//             initialRouteName={initialRouteName}
//             drawerContent={props => <DhruDrawer {...props} />}
//         >
//             <DrawerNavStack.Screen name="Cases" component={CaseStack}/>
//             <DrawerNavStack.Screen name="Products" component={ProductStack}/>
//             <DrawerNavStack.Screen name="Orders" component={Orders}/>
//             <DrawerNavStack.Screen name="Profile" component={Profile}/>
//         </DrawerNavStack.Navigator>
//     }
//     const SupportBottomTabStack = createBottomTabNavigator();
//     return <SupportBottomTabStack.Navigator initialRouteName={initialRouteName}>
//         <SupportBottomTabStack.Screen
//             name="Cases"
//             component={CaseStack}
//             options={{
//                 tabBarIcon: ({color}) => <TabBarIcon name="life-ring" color={color}/>,
//             }}
//         />
//         <SupportBottomTabStack.Screen
//             name="Products"
//             {...otherProps}
//             component={ProductStack}
//             listeners={({navigation, route}) => ({
//                 tabPress: e => {
//                     e.preventDefault();
//                     navigation.replace('ProductRoot', {
//                         screen: 'ProductNav',
//                         params: {
//                             screen: 'Products',
//                         },
//                     });
//                 },
//             })}
//             options={{
//                 tabBarIcon: ({color}) => <TabBarIcon name="folder" color={color}/>,
//             }}
//         />
//         <SupportBottomTabStack.Screen
//             name="Orders"
//             component={Orders}
//             options={{
//                 tabBarIcon: ({color}) => <TabBarIcon name="shopping-cart" color={color}/>,
//             }}
//         />
//         <SupportBottomTabStack.Screen
//             name="Profile"
//             component={Profile}
//             options={{
//                 tabBarIcon: ({color}) => <TabBarIcon name="user-circle" color={color}/>,
//             }}
//         />
//     </SupportBottomTabStack.Navigator>
// }
