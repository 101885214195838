import * as React from 'react';
import requestApi, {actions, methods, SUCCESS} from "../libs/ServerRequest";
import {setAuthUser, setInitData, setLoadingAndToken} from "../libs/Store/actions/app_info";
import {connect} from "react-redux";
import Class from '../constants/Style';
import {ContainedButton, Text, TextInputPaper, View} from "../components/Themed";
import {isEmpty, retrieveData, STORAGE_KEY, storeData} from "../libs/function";
import {validEmail} from "../libs/validate";
import DhruLogo from "../components/DhruLogo";
import RootPage from "../components/RootPage";
import LinkButton from "../components/LinkButton";
import {setDialog} from "../libs/Store/actions/components";
import OtpInput from "react-otp-input";
import OTPTextBox from "../components/OTPTextBox";
import Resend from "../components/Resend";


class VerifyEmail extends React.Component<any, any> {

    constructor(props) {
        super(props);
        const {authUser, route, fromCheckout} = this.props;
        let code = "", codeVisible = true;
        if (this.codeAvailable()) {
            code = route.params.code;
            codeVisible = false
        }

        this.state = {
            email: authUser.email,
            new_email: "",
            error: {},
            code,
            disabled: true,
            codeVisible,
            title: "Verify Your E-Mail"
        }
    }

    codeAvailable = () => {
        return !(Boolean(this.props.fromCheckout)) && Boolean(this.props.route.params) && Boolean(this.props.route.params.code)
    }

    componentDidMount() {
        const {route} = this.props;
        if (this.codeAvailable()) {
            this.requestGetCode({
                method: methods.post,
                body: {code: this.state.code}
            })
        }
    }

    validate = () => {
        const {new_email} = this.state;
        let error = {};
        error = {...error, ...validEmail(error, "new_email", new_email)};
        this.setState({error})
        return isEmpty(error)
    }

    requestGetCode = (call) => {
        const {setAuthUser, authUser} = this.props;
        const {email, password, new_email, codeVisible} = this.state;
        requestApi({
            ...call,
            action: actions.verifyemail,
        }).then(async (response) => {
            if (response.status === SUCCESS) {
                if (call.method === methods.put) {
                    storeData(STORAGE_KEY.USER_DATA, {
                        ...authUser,
                        email: new_email,
                    }).then(() => {
                        retrieveData(STORAGE_KEY.USER_DATA).then((authUser) => {
                            setAuthUser(authUser);
                            this.setState({email: new_email, visible: false, title: "Verify Your E-Mail"})
                        })
                    })

                } else if (call.method === methods.post) {
                    this.verificationSuccess(false);
                }
            } else {
                if (!codeVisible) {
                    this.setState({title: "Verification failed", responseMesssage: response.message})
                }
            }
        })
    }

    verificationSuccess = (fromInit)=>{
        const {setAuthUser, authUser} = this.props;
        storeData(STORAGE_KEY.USER_DATA, {
            ...authUser,
            email_verified: true,
        }).then(() => {
            if (fromInit){
                retrieveData(STORAGE_KEY.USER_DATA).then((authUser) => {
                    setAuthUser(authUser)
                })
            }else {
                this.setState({codeVisible: false, title: "Verification Successful"})
            }
        })
    }

    onUpdate = () => {
        if (this.validate()) {
            this.requestGetCode({
                method: methods.put,
                body: {new_email: this.state.new_email}
            })
        }
    }

    onVerify = () => {
        this.requestGetCode({
            method: methods.post,
            body: {code: this.state.code}
        })
    }

    requestInit = () => {
        const {route, authUser, setAuthUser, setInitData} = this.props;
        requestApi({
            method: methods.get,
            action: actions.logininit,
        }).then((response) => {
            if (response.status === SUCCESS) {
                setInitData(response.data);
                if (response.data.profile.email_verified){
                    this.verificationSuccess(true)
                }
            }
        })
    }

    render() {
        const {email, disabled, code, visible, new_email, codeVisible, error, title, responseMesssage} = this.state;
        const {navigation, route, authUser, setAuthUser} = this.props;

        let message = "Your Registered Email";
        if (visible) {
            message = "";
        }

        return (
            <RootPage navigation={navigation} title={"Verify Email"}>
                <View style={Class.container}>
                    <View style={Class.formContainer}>
                        <View style={Class.formGroup}>
                            <View style={[Class.alignSelfStretch, Class.marginBottom16]}>
                                <DhruLogo/>
                                <Text style={Class.pageHeader}>{title}</Text>
                                {Boolean(message) && <Text>{message}</Text>}
                                {
                                    Boolean(visible) ? <React.Fragment>
                                        <TextInputPaper
                                            label={"Email"}
                                            placeholder={"Email"}
                                            keyboardType={"email-address"}
                                            error={error.new_email}
                                            autoCompleteType={"email"}
                                            textContentType={"emailAddress"}
                                            value={new_email}
                                            onChangeText={(new_email) => this.setState({new_email})}
                                            style={Class.textbox}
                                            returnKeyType="done"
                                            onSubmitEditing={() => this.onUpdate()}
                                        />
                                        <View style={Class.buttonRowContainer}>
                                            <LinkButton
                                                caption={"Cancel"}
                                                onPress={() => this.setState({
                                                    visible: false,
                                                    title: "Verify Your E-Mail"
                                                })}
                                            />

                                            <ContainedButton
                                                onPress={() => this.onUpdate()}
                                            >
                                                Update
                                            </ContainedButton>
                                        </View>


                                    </React.Fragment> : <React.Fragment>
                                        {
                                            Boolean(codeVisible) ? <View>
                                                <View style={[Class.row, Class.marginBottom16, Class.marginTop8]}>
                                                    <Text style={[Class.marginRight8]}>{email}</Text>
                                                    <LinkButton
                                                        caption={"- Change Email"}
                                                        onPress={() => {
                                                            this.setState({visible: true, title: "New Email"})
                                                        }}
                                                    />
                                                </View>
                                                <OTPTextBox
                                                    value={code}
                                                    onChange={(code) => this.setState({code})}
                                                />
                                                <View style={Class.buttonRowContainer}>

                                                    <Resend onResendOTP={()=>{
                                                        this.requestGetCode({method: methods.get})
                                                    }}/>

                                                    <ContainedButton
                                                        onPress={() => this.onVerify()}
                                                    >
                                                        Verify
                                                    </ContainedButton>
                                                </View>
                                            </View> : <React.Fragment>
                                                <View style={[Class.row, Class.marginBottom16, Class.marginTop8]}>
                                                    <Text style={[Class.marginRight8]}>{email} {title}</Text>
                                                </View>
                                                {Boolean(responseMesssage) && <Text>{responseMesssage}</Text>}
                                                <View style={Class.buttonRowContainer}>
                                                    <View/>

                                                    {
                                                        !Boolean(responseMesssage) && <ContainedButton
                                                            mode="contained"
                                                            onPress={() => this.requestInit()}
                                                        >
                                                            Continue
                                                        </ContainedButton>
                                                    }

                                                </View>
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                                }
                            </View>
                        </View>
                    </View>

                    {
                        (Boolean(codeVisible) && !Boolean(visible)) && <View style={[[Class.alignItemCenter, Class.marginBottom16]]}>
                            <LinkButton
                                caption={"Refresh"}
                                onPress={() => this.requestInit()}
                                style={[Class.padding16]}
                            />
                        </View>
                    }

                </View>
            </RootPage>
        );
    }
}


const mapStateToProps = (state) => ({
    authUser: state.app_info.authUser
});

const mapDispatchToProps = (dispatch) => ({
    setAuthUser: (authUser) => dispatch(setAuthUser(authUser)),
    setInitData: (initData) => dispatch(setInitData(initData)),
    setLoadingAndToken: (data) => dispatch(setLoadingAndToken(data)),
    setDialog: (data) => dispatch(setDialog(data)),

});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
