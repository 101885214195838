import React, {Component, Fragment} from "react";
import {Camera} from "expo-camera";
import {TextButton, View} from "../components/Themed";
import {logData} from "../libs/function";
import {Image} from "react-native";
import {setLoader} from "../libs/Store/actions/components";
import {connect} from "react-redux";
import {connectActionSheet} from "@expo/react-native-action-sheet";
import Class from "../constants/Style"
import IconButton from "../components/IconButton";
import {BackButton2} from "../components/BackButton";

class CameraView extends Component {

    constructor(props) {
        super(props);
        this.state = {hasPermission: null, type: Camera.Constants.Type.back, captureImage: true, uri: undefined};
    }


    async componentDidMount() {
        const {status} = await Camera.requestPermissionsAsync();
        this.setState({hasPermission: status === 'granted'})
    }

    render() {
        const {navigation, setLoader, route} = this.props;
        const {hasPermission, type, captureImage, uri} = this.state;
        if (hasPermission === false) {
            navigation.goBack();
        }
        return <View style={[Class.container, {position: "relative"}]}>
            {
                Boolean(captureImage) && <Fragment>
                    <Camera ref={ref => {
                        this.camera = ref;
                    }} style={{flex: 1}} type={type}/>
                    <View style={[{
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                        left: 0,
                        justifyContent: "center",
                        backgroundColor: "transparent"
                    }]}>
                        <IconButton
                            style={{width: 56, height: 56, alignSelf: "center", marginBottom: 32}}
                            iconStyle={{fontSize: 64}}
                            name={"ios-radio-button-on"}
                            onPress={async () => {
                                setLoader({show: true});
                                if (this.camera) {
                                    let photo = await this.camera.takePictureAsync({
                                        quality: 0
                                    });
                                    setLoader({show: false});
                                    if (Boolean(photo) && Boolean(photo.uri)) {
                                        this.setState({uri: photo.uri, captureImage: false})
                                    }
                                }
                            }}>
                            Capture
                        </IconButton>
                    </View>
                </Fragment>
            }
            {
                Boolean(uri) && <Fragment>
                    <Image style={{flex: 1}} source={{uri}}/>
                    <View style={[Class.row, {
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                        left: 0,
                        justifyContent: "center",
                        backgroundColor: "rgba(0,0,0,0.5)"
                    }]}>
                        <TextButton lightColor={"#fff"} buttonStyle={{height: 64}} style={[Class.flex]}
                                    onPress={() => {
                                        this.setState({captureImage: true, uri: undefined})
                                    }}>Cancel</TextButton>
                        <TextButton lightColor={"#fff"} buttonStyle={{height: 64}} style={[Class.flex]}
                                    onPress={() => {
                                        navigation.navigate(route.params.name, {uri})
                                    }}>Save</TextButton>
                    </View>
                </Fragment>
            }
            <View lightColor={"rgba(0,0,0,0.5)"} darkColor={"rgba(0,0,0,0.5)"} style={{
                height: 88,
                top: 0,
                left: 0, right: 0,
                paddingTop:40,
                position: "absolute"
            }}>
                <BackButton2 goBack={navigation.goBack} style={{marginLeft:16, alignSelf:"flex-start"}} />
            </View>
        </View>
    }
}

const mapStateToProps = (state) => ({
    show: state.components.loader.show
});

const mapDispatchToProps = (dispatch) => ({
    setLoader: (loader) => dispatch(setLoader(loader)),
});

export default connect(mapStateToProps, mapDispatchToProps)(connectActionSheet(CameraView));

