import * as React from "react";
import {OTPRootStack} from "../StackNav";
import {CommonNavigation} from "../CommonNavigation";
import {useWindowDimensions} from "react-native";
import {LARGE_SCREEN_WIDTH} from "../../libs/settings";
import {intercomVisibility} from "../../libs/function";


const OTPServiceRoot = () => {
    const dimensions = useWindowDimensions();
    const isLargeScreen = dimensions.width >= LARGE_SCREEN_WIDTH;
    intercomVisibility(false)
    return <OTPRootStack.Navigator>
        <OTPRootStack.Screen
            name="OTPServiceNav"
            options={{headerShown: false}}
        >
            {props => <CommonNavigation {...props} initialRouteName={"OTPService"}/>}
        </OTPRootStack.Screen>
    </OTPRootStack.Navigator>
}

export default OTPServiceRoot;




