import Class from "../constants/Style";
import {Text, View} from "./Themed";
import ChatTail from "./ChatTail";
import {lightChatTailUser} from "../constants/Colors";
import Icon from "./Icon";
import HtmlEncrypted from "./HtmlEncrypted";
import AttachedItem from "./AttachedItem";
import * as React from "react";


export const UserConversation = ({by, text, attachments, date, l}) => {
    return <View style={Class.chatContainer}>
        <View style={Class.chatContent}>
            <ChatTail
                lightColor={lightChatTailUser}
                darkColor={"#2f2f2f"}
                style={Class.chatUserIcon}
            />
            <View style={[Class.row, Class.marginBottom4]}>
                <Icon
                    lightColor={"rgba(22,45,78,1)"}
                    name="user-circle-o"
                    style={Class.userIcon}
                />
                <Text lightColor={"rgba(22,45,78,1)"}
                      style={Class.userInfo}>You, {date}</Text>
            </View>
            <View lightColor={"rgb(232,233,235)"} darkColor={"#2f2f2f"}
                  style={Class.conversationText}>
                <HtmlEncrypted encryptedData={text}/>
                {
                    (Boolean(attachments) && attachments.length > 0) && attachments.map((attachment, index) => {
                        return <AttachedItem
                            key={index} {...attachment}/>
                    })
                }
            </View>
        </View>
    </View>
}

export const StaffConversation = ({by, text, attachments, date, l}) => {
    return <View style={[Class.chatContainer, {alignItems: "flex-end"}]}>
        <View style={[Class.chatContent, {alignSelf: "flex-end",}]}>
            <ChatTail
                style={[[Class.chartResponseIcon, {color: Boolean(l) ? "#ffe279" : "#3d86f6"}]]}
            />
            <View
                style={[Class.row, Class.marginBottom4, {alignSelf: "flex-end"}]}>
                <Text style={Class.userInfo}>Dhru, {date}</Text>
                <Icon
                    lightColor={"rgba(22,45,78,1)"}
                    name="user-circle"
                    style={Class.userIcon}
                />
            </View>
            <View
                style={[Class.conversationText, {backgroundColor: Boolean(l) ? "#ffe279" : "#3d86f6"}]}>
                <HtmlEncrypted
                    lightTextColor={Boolean(l) ? "#2f2f2f" : "#fff"}
                    darkTextColor={Boolean(l) ? "#2f2f2f" : "#fff"}
                    encryptedData={text}/>

                {
                    (Boolean(attachments) && attachments.length > 0) && attachments.map((attachment, index) => {
                        return <AttachedItem
                            key={index} {...attachment}/>
                    })
                }
            </View>
        </View>
    </View>
}

export const ChatConversation = (props) => {
    const {by} = props;
    if (Boolean(by.toString().toLowerCase() === "user")) {
        return <UserConversation {...props} />
    }
    return <StaffConversation {...props}/>
}
