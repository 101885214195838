import React from "react";
import {TouchableOpacity} from "react-native";
import Colors from "../constants/Colors";
import SquareIcon from "./SquareIcon";
import {Text} from "./Themed";
import Class from "../constants/Style"

export default ({checked, label, onPress, style, textStyle})=>{
    return <TouchableOpacity onPress={onPress} style={[Class.row, style]}>
        <SquareIcon
            name={Boolean(checked) ? 'check-square' : 'square-o'}
            style={{marginTop:4, marginLeft :0, marginRight:0}}
            lightColor={Colors.light.primary}
            darkColor={Colors.dark.primary}
            iconStyle={{fontSize:24, alignSelf:"flex-start"}}
        />
        <Text style={[Class.marginLeft8,{fontSize:16, marginTop:10},textStyle]}>
            {label}
        </Text>
    </TouchableOpacity>
}
