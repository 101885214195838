import * as React from "react";
import Class from "../constants/Style";
import {List, Text, useThemeColor, View} from './Themed';
import FontAwesomeIcon from "react-native-vector-icons/FontAwesome";
import Icon from "./Icon";
import SquareIcon from "./SquareIcon";
import Colors, {darkDescription, lightDescription} from "../constants/Colors";


export default function TicketItem(props:any) {
    const {title, ticket_id, status, onPress, created, priority} = props
    let color = "rgba(212,87,67,1)";
    if (priority.toString().toLowerCase() === "low") {
        color = "rgba(96,173,227,1)";
    } else if (priority.toString().toLowerCase() === "medium") {
        color = "rgba(118,183,78,1)";
    }
    let statusIcon = "clock-o", statusIconColor = "rgba(167,174,183,1)";
    if (status.toString().toLowerCase() === "awaiting your response") {
        statusIcon = "exclamation-circle";
        statusIconColor = "rgba(230,131,57,1)";
    } else if (status.toString().toLowerCase() === "resolved") {
        statusIcon = "check-circle";
        statusIconColor = "rgba(118,183,78,1)";
    }

    return <React.Fragment>
        <List
            title={title}
            description={props => {
                return <View style={[Class.rowWrap, Class.transparent]} >
                    <Text lightColor={lightDescription} darkColor={darkDescription} style={[Class.marginRight8]}>#{ticket_id}</Text>
                    <View style={[Class.rowWrap, Class.marginRight8, Class.transparent]}>
                        <FontAwesomeIcon style={[Class.marginRight4, {color: statusIconColor}]} name={statusIcon}/>
                        <Text lightColor={lightDescription} darkColor={darkDescription} style={[]}>{status}</Text>
                    </View>
                    <Text lightColor={lightDescription} darkColor={darkDescription} style={[]}>{created}</Text>
                </View>
            }}
            left={props => <SquareIcon name={"bookmark"} backgroundColor={color}/>}
            onPress={onPress}
        />
        <View style={Class.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)"/>
    </React.Fragment>
}
