import React, {Component} from "react";
import requestApi, {actions, methods, SUCCESS} from "../libs/ServerRequest";
import RootPage from "../components/RootPage";
import Class from "../constants/Style";
import {ContainedButton, List, Text, View} from "../components/Themed";
import CommonListItem from "../components/CommonListItem";
import * as Linking from 'expo-linking';
import DhruScrollView from "../components/DhruScrollView";
import {isIOS, isWeb} from "../libs/settings";
import {connect} from "react-redux";
import {copyData} from "../libs/function";

class ViewProduct extends Component<any, any> {

    constructor(props: any) {
        super(props);


        const {route, navigation, servers_list} = props;
        let defaultData = {}

        this.state = {
            error: {},
            productdetail: {
                product_id: route.params.id
            },
            reissuesEnable: false
        }

        this.inputRefs = [
            React.createRef(),
            React.createRef(),
            React.createRef(),
            React.createRef(),
            React.createRef(),
            React.createRef(),
            React.createRef(),
        ]

    }

    componentDidMount() {
        this.getProductDetail();
    }

    getProductDetail = () => {

        const {productdetail} = this.state;
        const {product_id} = productdetail;
        requestApi({
            method: methods.get,
            action: actions.product,
            queryString: {id: product_id}
        }).then((response) => {
            if (response.status === SUCCESS) {
                const data = response.data[0];
                this.setState({
                    productdetail: response.data[0]
                })

                this.props.navigation.setOptions({headerTitle: data.prodcut});
            }
        })
    }

    setProductDetail = (value) => {
        const {productdetail} = this.state;
        this.setState({
            productdetail: {
                ...productdetail,
                ...value
            }
        })
    }

    callRequest = (otherPath, reisssue) => {
        requestApi({
            method: methods.get,
            otherPath,
            showsucess: true
        }).then((response) => {
            if (response.status === SUCCESS) {
                if (reisssue) {
                    this.setState({reissuesEnable: true})
                }
            }
        })
    }

    render() {
        const {navigation, profile} = this.props;
        const {productdetail, error, reissuesEnable} = this.state;



        const {
            prodcut,
            license_key,
            domain,
            validip,
            registration_date,
            download_url,
            addons,
            product_id,
            reissue_url,
            download_req_url,
            download,
            cloud_login_url,
            product_token
        } = productdetail;

        let isWorkSpace = Boolean(cloud_login_url);

        return (
            <RootPage navigation={navigation} headerTitle={"Product"}>
                <View style={Class.container}>
                    <DhruScrollView>
                        <View style={[Class.paddingTopBottom16, Class.paddingLeftRight22]}>

                            <CommonListItem
                                title={domain}
                                description={isWorkSpace ? "Workspace Name" : "Domain Name"}
                            />

                            <CommonListItem
                                title={license_key}
                                description={"License Key"}
                                onPress={() => {
                                    copyData(license_key)
                                }}
                            />

                            {
                                product_token && <CommonListItem
                                    title={"Product Token"}
                                    description={product_token}
                                    descriptionNumberOfLines={25}
                                    onPress={() => {
                                        copyData(product_token)
                                    }}
                                />
                            }

                            {
                                Boolean(validip) && <View style={[Class.row, Class.marginBottom16]}>
                                    <View style={Class.flex}>
                                        <List
                                            title={validip}
                                            style={{
                                                paddingLeft: 0,
                                                paddingRight: 0,
                                                marginLeft: -8
                                            }}
                                            description={"IP"}
                                            right={(props) => {
                                                return <ContainedButton
                                                    style={{
                                                        alignSelf: "center"
                                                    }}
                                                    mode={"contained"}
                                                    disabled={reissuesEnable}
                                                    onPress={() => this.callRequest(reissue_url, true)}
                                                >
                                                    Change IP
                                                </ContainedButton>
                                            }}
                                        />
                                        <View style={[Class.separator, Class.marginLeft0]} lightColor="#eee"
                                              darkColor="rgba(255,255,255,0.1)"/>
                                    </View>
                                </View>
                            }

                            {
                                Boolean(registration_date) && <CommonListItem
                                    title={registration_date}
                                    description={"Registration Date"}
                                    separatoryStyle={Class.marginBottom16}
                                />
                            }

                            {
                                (Boolean(addons) && Boolean(addons.activated)) && <View style={Class.marginBottom16}>
                                    <Text style={[Class.title, Class.marginBottom8, Class.marginTop12]}>Activated
                                        Addons</Text>
                                    {
                                        addons.activated.map((addon, index) => {
                                            let description = addon.billingcycle;
                                            if (Boolean(addon.next_due_date)) {
                                                description += ` ( Next due date ${addon.next_due_date} )`
                                            }
                                            return <CommonListItem
                                                key={index}
                                                title={addon.name}
                                                description={description}
                                            />
                                        })
                                    }
                                </View>
                            }
                            <View>
                                {
                                    ((Boolean(addons) &&
                                        Boolean(addons.available_for_purchase) &&
                                        addons.available_for_purchase.length > 0) && !isIOS) &&
                                    <ContainedButton
                                        style={[Class.marginBottom16, Class.fixWidthOnWebButton]}
                                        onPress={() => {

                                            navigation.navigate("BuyAddonScreen", {
                                                product_id,
                                                domain,
                                                addons: addons.available_for_purchase
                                            })
                                        }}
                                    >
                                        Buy Addons
                                    </ContainedButton>
                                }
                                {
                                    Boolean(download_req_url) && <ContainedButton
                                        style={[Class.marginBottom16, Class.fixWidthOnWebButton]}
                                        onPress={() => this.callRequest(download_req_url)}
                                    >
                                        Download Request
                                    </ContainedButton>
                                }

                                {
                                    isWorkSpace && <ContainedButton
                                        style={[Class.marginBottom16, Class.fixWidthOnWebButton]}
                                        onPress={() => {
                                            if (isWeb) {
                                                window.open(cloud_login_url, '_blank');
                                            } else {
                                                Linking
                                                    .openURL(cloud_login_url)
                                                    .catch((err) => console.error('An error' +
                                                        ' occurred', err));
                                            }

                                        }}
                                    >
                                        Login To Workspace
                                    </ContainedButton>
                                }

                                {
                                    (Boolean(download) && Boolean(download.name) && Boolean(download.url))
                                    && <ContainedButton
                                        style={[Class.marginBottom16, Class.fixWidthOnWebButton]}
                                        onPress={() => {
                                            Linking
                                                .openURL(download.url)
                                                .catch((err) => console.error('An error' +
                                                    ' occurred', err));
                                        }}
                                    >
                                        {download.name}
                                    </ContainedButton>
                                }

                                {
                                    (Boolean(profile.group) && Boolean(profile.group === "RESELLER" || profile.group === "RESELLER L1" || profile.group === "RESELLER L2"))
                                    && <ContainedButton
                                        style={[Class.marginBottom16, Class.fixWidthOnWebButton]}
                                        onPress={() => navigation.navigate("MoveLicenseScreen", {product_id})}
                                    >
                                        Transfer Ownership
                                    </ContainedButton>
                                }
                            </View>
                        </View>
                    </DhruScrollView>
                </View>
            </RootPage>
        );
    }
}

const mapStateToProps = (state) => ({
    profile: state.app_info.profile
})

export default connect(mapStateToProps)(ViewProduct);
