import Class from "../constants/Style";
import * as WebBrowser from "expo-web-browser";
import {IosIcon} from "./Icon";
import {Text} from "./Themed";
import {TouchableOpacity} from "react-native";
import * as React from "react";
import {Linking} from "expo";
import {browseLink} from "../libs/function";

export default ({download_url, file_name}) => {
    return <TouchableOpacity
        style={Class.attachmentContainer}
        onPress={() => {

            browseLink(download_url, "_blank");

            // Linking
            //     .openURL(download_url)
            //     .catch((err) => console.error('An error' +
            //         ' occurred', err));
        }}
    >
        <IosIcon lightColor={"rgba(22,45,78,1)"} darkColor={"#fff"} name="md-download" style={Class.attachmentIcon}/>
        <Text lightColor={"rgba(22,45,78,1)"} style={Class.attachmentText}>{file_name}</Text>
    </TouchableOpacity>
}
