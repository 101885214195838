import React, {Component} from "react";
import {StyleSheet, ScrollView, Platform, KeyboardAvoidingView} from "react-native";
import {View, Text, TextInputPaper, Picker, ContainedButton} from "../components/Themed";
import requestApi, {actions, methods, SUCCESS} from "../libs/ServerRequest";
import RNPickerSelect from "react-native-picker-select";
import {connect} from "react-redux";
import {headerHeight, isIOS, isWeb} from "../libs/settings";
import Class, {pickerSelectStyles} from "../constants/Style"
import TitleAndSubtitle from "../components/TitleAndSubtitle";
import {Button} from "react-native-paper";
import RootPage from "../components/RootPage";
import DhruScrollView from "../components/DhruScrollView";
import {intercomVisibility} from "../libs/function";

class UpdateLoginDetails extends Component {

    constructor(props) {
        super(props);
        const {route: {params}} = props;
        if (Boolean(params)) {
            const {product} = params;
            this.state = {
                product,
                login_details: {
                    hosting_url: "",
                    hosting_user: "",
                    hosting_pass: "",
                    admin_htaccess_user: "",
                    admin_htaccess_pass: "",
                    admin_user: "",
                    admin_pass: "",
                    extrainfo: "",
                    hostvalue:""
                },
                hide: true,
                error: {}
            }
        }
    }

    componentDidMount() {
        intercomVisibility(true)
    }

    componentWillUnmount() {
        intercomVisibility(true)
    }

    updateProduct = () => {
        const {product, login_details} = this.state;
        requestApi({
            method: methods.put,
            action: actions.product,
            body: {
                product_id: product.product_id,
                login_details
            }
        }).then((response) => {
            if (response.status === SUCCESS) {
                this.props.navigation.goBack();
            }
        })
    }

    setLoginDetail = (data) => {
        const {login_details} = this.state;
        this.setState({
            login_details: {
                ...login_details,
                ...data
            }
        })
    }

    render() {
        const {navigation, hosting_urls} = this.props;
        const {product, login_details, hide, error} = this.state;

        const optionSupportCategory = Object.keys(hosting_urls).map((hu) => {
            return {label: hosting_urls[hu].name, value: hu}
        })

        return (
            <RootPage navigation={navigation} headerTitle={"Login credentials"}>
                <KeyboardAvoidingView
                    keyboardVerticalOffset={headerHeight}
                    style={Class.container} behavior={isIOS ? "padding" : ""}>
                    <DhruScrollView>
                        <TitleAndSubtitle
                            subtitle={product.domain}
                            content={product.license_key}
                        />
                        <View
                            style={[Class.paddingLeftRight22, Class.marginTop8, Class.alignSelfStretch, Class.marginBottom16]}>
                            <Text style={Class.loginLabel}>Hosting</Text>
                            <Picker
                                placeholder={{
                                    label: 'Select Hosting',
                                }}
                                value={login_details.hostvalue}
                                onValueChange={(selectedSupportCategory) => {
                                    if (Boolean(selectedSupportCategory) && Boolean(selectedSupportCategory !== "other")) {
                                        this.setLoginDetail({
                                            hosting_url: hosting_urls[selectedSupportCategory].login_url,
                                            hostvalue:selectedSupportCategory,
                                            hide: true
                                        })
                                        this.setState({hide: true})
                                    } else if (Boolean(selectedSupportCategory === "other")) {
                                        this.setLoginDetail({hosting_url: "", hostvalue: selectedSupportCategory})
                                        this.setState({hide: false})
                                    } else {
                                        this.setState({hide: true})
                                    }
                                }}
                                error={error.country}
                                items={optionSupportCategory}
                            />
                            {/*<RNPickerSelect*/}
                            {/*    style={pickerSelectStyles}*/}
                            {/*    onValueChange={(selectedSupportCategory) => {*/}
                            {/*        if (Boolean(selectedSupportCategory) && Boolean(selectedSupportCategory !== "other")) {*/}
                            {/*            this.setLoginDetail({*/}
                            {/*                hosting_url: hosting_urls[selectedSupportCategory].login_url,*/}
                            {/*                hide: true*/}
                            {/*            })*/}
                            {/*            this.setState({hide: true})*/}
                            {/*        } else if (Boolean(selectedSupportCategory === "other")) {*/}
                            {/*            this.setLoginDetail({hosting_url: ""})*/}
                            {/*            this.setState({hide: false})*/}
                            {/*        } else {*/}
                            {/*            this.setState({hide: true})*/}
                            {/*        }*/}
                            {/*    }}*/}
                            {/*    useNativeAndroidPickerStyle={false}*/}
                            {/*    items={optionSupportCategory}*/}
                            {/*/>*/}
                            {
                                !Boolean(hide) && <TextInputPaper
                                    mode={"flat"}
                                    label={"Specify Other"}
                                    value={login_details.hosting_url}
                                    onChangeText={(hosting_url) => this.setLoginDetail({hosting_url})}
                                    style={[Class.marginBottom8, Class.alignSelfStretch]}
                                />
                            }

                            <TextInputPaper
                                mode={"flat"}
                                label={"Hosting Username"}
                                value={login_details.hosting_user}
                                error={error.hosting_user}
                                onChangeText={(hosting_user) => this.setLoginDetail({hosting_user})}
                                style={[Class.marginBottom8, Class.alignSelfStretch]}
                            />
                            <TextInputPaper
                                mode={"flat"}
                                label={"Hosting Password"}
                                value={login_details.hosting_pass}
                                error={error.hosting_pass}
                                onChangeText={(hosting_pass) => this.setLoginDetail({hosting_pass})}
                                style={[Class.marginBottom8, Class.alignSelfStretch]}
                            />
                        </View>

                        <View style={[Class.paddingLeftRight22, Class.alignSelfStretch, Class.marginBottom16]}>
                            <Text style={Class.loginLabel}>
                                Admin First Login / Htaccess
                            </Text>
                            <TextInputPaper
                                mode={"flat"}
                                label={"Username"}
                                error={error.admin_htaccess_user}
                                value={login_details.admin_htaccess_user}
                                onChangeText={(admin_htaccess_user) => this.setLoginDetail({admin_htaccess_user})}
                                style={[Class.marginBottom8, Class.alignSelfStretch]}
                            />
                            <TextInputPaper
                                mode={"flat"}
                                label={"Password"}
                                error={error.admin_htaccess_pass}
                                value={login_details.admin_htaccess_pass}
                                onChangeText={(admin_htaccess_pass) => this.setLoginDetail({admin_htaccess_pass})}
                                style={[Class.marginBottom8, Class.alignSelfStretch]}
                            />
                        </View>

                        <View style={[Class.paddingLeftRight22, Class.alignSelfStretch, Class.marginBottom16]}>
                            <Text style={Class.loginLabel}>Admin/Backend Login</Text>
                            <TextInputPaper
                                mode={"flat"}
                                label={"Username"}
                                value={login_details.admin_user}
                                error={error.admin_user}
                                onChangeText={(admin_user) => this.setLoginDetail({admin_user})}
                                style={[Class.marginBottom8, Class.alignSelfStretch]}
                            />
                            <TextInputPaper
                                mode={"flat"}
                                label={"Password"}
                                value={login_details.admin_pass}
                                error={error.admin_pass}
                                onChangeText={(admin_pass) => this.setLoginDetail({admin_pass})}
                                style={[Class.marginBottom8, Class.alignSelfStretch]}
                            />
                            <TextInputPaper
                                mode={"flat"}
                                label={"Extra notes, like ssh port, server IP, domain registrant access or specify any other login credentials"}
                                value={login_details.extrainfo}
                                onChangeText={(extrainfo) => this.setLoginDetail({extrainfo})}
                                multiline={true}
                                textStyle={{minHeight:isWeb?200:100}}
                                numberOfLines={4}
                                style={Class.multiline}
                            />
                        </View>

                        <Text style={[Class.paddingLeftRight22]}>
                            {"Login credentials are needed for our support team to resolve technical issues. By" +
                            " providing login credentials you agree to our TOC. https://www.dhru.com/toc.\n\nYour" +
                            " details are saved securely with PCI DSS standards.\n\n If you have firewall or IP" +
                            " authorization then please allow these ips, Our team will only access from below listed" +
                            " IPs.\n\n" +
                            "- 150.107.254.230\n" +
                            "- 117.247.85.188\n" +
                            "- 117.247.85.155\n" +
                            "- 15.206.73.143"}
                        </Text>
                    </DhruScrollView>
                    <View style={[Class.bottomView]}>
                        <ContainedButton
                            onPress={() => {
                                this.updateProduct()
                            }}
                        >
                            Update
                        </ContainedButton>
                    </View>
                </KeyboardAvoidingView>
            </RootPage>
        );
    }
}


const mapStateToProps = (state) => ({
    hosting_urls: state.app_info.static.hosting_urls
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateLoginDetails);
