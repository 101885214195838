import * as React from "react";
import {Platform} from "react-native";
import {WebView} from 'react-native-webview';
import {connect} from "react-redux";
import {setLoader} from "../libs/Store/actions/components";
import HTMLView from "react-native-htmlview";


interface NativeWebViewProps {
    uri: string;
    html: string;
    response: () => {},
    webStyle:{}
    phoneStyle:{}
}

const DhruWebView = (props: NativeWebViewProps): JSX.Element => {
    if (Boolean(props.htmlview)){
        return <HTMLView value={`<div>${props.html}</div>`} stylesheet={props.stylesheet} />
    }
    if (Platform.OS === 'web') {
        window.addEventListener('message', function (e) {
            if (e.data.source === 'dhru-message') {
                if (e.data.payload.event === 'payment') {
                    receivePaymentWebViewmessage(e.data.payload.payload);
                }
            }
        });
    }
    function receivePaymentWebViewmessage(data) {
        props.response(data);
    }
    if (Platform.OS === 'web') {
        props.setLoader({show: true})
        return <iframe
            src={props.uri}
            onLoad={() => props.setLoader({show: false})}
            srcDoc={props.html}
            frameBorder={0}
            style={props.webStyle}/>;
    }


    return <WebView
        automaticallyAdjustContentInsets={false}
        scrollEnabled={false}
        injectedJavaScript={`document.body.style.backgroundColor = '${props.backColor}';document.body.style.color = '${props.textColor}';`}
        scalesPageToFit={false}
        javaScriptEnabled={true}
        style={[{height:100,  opacity: 0.99 },props.phoneStyle]}
        source={{uri: props.uri, html: props.html}}
        onLoadStart={() => props.setLoader({show: true})}
        onLoadEnd={() => props.setLoader({show: false})}
        onMessage={(event) => {
            receivePaymentWebViewmessage(event.nativeEvent.data);
        }}
    />;
};


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    setLoader: (loader) => dispatch(setLoader(loader))
});

export default connect(mapStateToProps, mapDispatchToProps)(DhruWebView);


