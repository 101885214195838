import React, {Component, Fragment} from "react";
import {StyleSheet, View, Text} from "react-native";
import {connect} from "react-redux";

function Dialog(props) {
    const {visible, dialogview} = props.dialog;

    return <Fragment>
        {
            (Boolean(visible) && Boolean(dialogview))
                ?
                <View style={styles.container}>
                    <View style={styles.rect15}>
                        {dialogview}
                    </View>
                </View>
                :
                <View/>
        }

    </Fragment>
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: "rgba(35,28,28,0.5)",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        zIndex: 99999
    },
    rect15: {
        flex:1,
        justifyContent: "center",
        alignItems: "center",
        width : "100%"
    },
    alertView: {
        height: 144,
        alignSelf: "stretch",
        borderRadius: 24,
        overflow: "hidden",
        backgroundColor: "rgba(255,255,255,1)"
    },
    rect17: {
        alignSelf: "stretch",
        padding: 8,
        height: 42
    },
    title: {
        fontFamily: "roboto-700",
        color: "#121212",
        fontSize: 22,
        alignSelf: "center"
    },
    rect18: {
        alignSelf: "stretch",
        flex: 1
    },
    rect19: {
        height: 42,
        alignSelf: "stretch",
        flexDirection: "row",
        borderWidth: 1,
        borderColor: "rgba(225,225,225,1)"
    },
    cupertinoButtonBlueTextColor2: {
        height: 42,
        flex: 1
    },
    rect20: {
        width: 1,
        backgroundColor: "#E6E6E6",
        alignSelf: "stretch"
    },
    cupertinoButtonBlueTextColor1: {
        height: 42,
        flex: 1
    }
});

const mapStateToProps = (state) => ({
    dialog: state.components.dialog,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Dialog);

