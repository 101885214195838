import React from "react";
import {registerContentStyle} from "../constants/Style";
import DhruWebView from "./DhruWebView";
import {useThemeColor} from "./Themed";
import {StyleSheet} from "react-native";
import Colors from "../constants/Colors";

export default ({lightColor, darkColor})=>{
    let htmlContent = `By clicking Create account, I agree that:<ul>
<li>I have read and accepted the<a target="_blank" href="https://www.dhru.com/privacypolicy">Terms of Use</a>.</li><li>We (DHRU) may keep me informed with emails about products and services.</li></ul>
See our <a target="_blank" href="https://www.dhru.com/privacypolicy">Privacy Policy</a> for more details or to opt-out at any time.`
    const color = useThemeColor({light: lightColor, dark: darkColor}, 'text');
    return <DhruWebView
        htmlview html={htmlContent}
        stylesheet={StyleSheet.create({
            div:{
                fontSize:11,
                color,
                marginTop:8,
                marginBottom:16
            },
            a:{
                fontSize:11,
                color:Colors.light.primary
            },
            ul:{
                marginLeft:8,
            }
        })}/>
}
