import {
    SET_ALERT,
    SET_BOTTOMSHEET,
    SET_DIALOG,
    SET_LOADER,
    SET_POP_PAGE,
    SET_SNACKBAR
} from "../definitions/components";
import React, {Fragment} from 'react';
import {clone} from "../../function";


const initState = {
    dialog: {visible: true},
    popuppage: {showpage: false},
    alert: {message: '', variant: 'success', visible: false},
    loader: {show: true},
    snackbar: {visible: false},
    bottomSheet: {snapPoint: [0, 0, 0], title: '', component: () => <Fragment/>},
};

export default (state = initState, action) => {
    switch (action.type) {
        case SET_DIALOG:
            return {
                ...state,
                dialog: {
                    ...state.dialog,
                    ...action.dialog
                }
            };
        case SET_POP_PAGE:
            return {
                ...state,
                popuppage: {
                    ...state.popuppage,
                    ...action.popuppage
                }
            };
        case SET_BOTTOMSHEET:
            return {
                ...state,
                bottomSheet: {
                    ...state.bottomSheet,
                    ...action.bottomsheet
                }
            };
        case SET_ALERT:
            return {
                ...state,
                alert: {
                    ...state.alert,
                    ...action.alert
                }
            };
        case SET_LOADER:
            return {
                ...state,
                loader: {
                    ...state.loader,
                    ...action.loader
                }
            };
        case SET_SNACKBAR:
            return {
                ...state,
                snackbar: {
                    ...state.snackbar,
                    ...clone(action.snackData),
                }
            };
        default:
            return state;
    }
}
