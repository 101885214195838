import {View} from "./Themed";
import Class from "../constants/Style";
import Icon from "./Icon";
import * as React from "react";

export default function RoundIcon({name, backgroundColor}) {
    return <View style={[Class.iconCircle,Class.iconRound,{backgroundColor}]}>
        <Icon lightColor={"#fff"} darkColor={"#fff"} name={name} style={Class.mainIcon}/>
    </View>
}
