import Class from "../constants/Style";
import SquareIcon from "./SquareIcon";
import {Text, View} from "./Themed";
import {currencyFormat} from "../libs/function";
import {TouchableOpacity} from "react-native";
import * as React from "react";
import {isWeb} from "../libs/settings";

export default ({onPress, name, colour, description, billingcycle, icon, amount, addproduct})=>{
    const itemFlex = isWeb ? Class.flex3 : Class.flex;
    let rootStyle, nameStyle, iconStyle = {marginLeft:0};
    if (Boolean(addproduct)){
        rootStyle = Class.padding8;
        nameStyle = Class.marginLeft8
        iconStyle = Class.marginTop0;
    }
    if (Boolean(billingcycle)){
        name = `${name} - (${billingcycle})`
    }
    return <View>
        <TouchableOpacity onPress={onPress} style={[[Class.row,rootStyle]]}>
        <SquareIcon  style={iconStyle} name={icon} backgroundColor={`#${colour}`}/>
        <View style={[itemFlex, nameStyle]}>
            <Text style={{fontWeight: "700"}}>{name}</Text>
            <Text style={[Class.marginTop4]}>{description}</Text>
        </View>
        <View style={[Class.flex, Class.alignItemFlexEnd]}>
            <Text style={{fontWeight: "700"}}>{currencyFormat(amount)}</Text>
            <Text >{billingcycle}</Text>
        </View>
        </TouchableOpacity>
        {
            Boolean(addproduct) && <View style={Class.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)"/>
        }
    </View>
}
