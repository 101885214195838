import React, {Component} from "react";
import {StyleSheet, TouchableOpacity} from "react-native";
import {View, Text, ContainedButton} from "../components/Themed";
import {NavigationActions, StackActions} from 'react-navigation';
import Icon from "../components/Icon";
import {Button} from "react-native-paper";
import Class from "../constants/Style"
import RootPage from "../components/RootPage";

class SuccessTicket extends Component {

    render() {
        // const resetAction = StackActions.reset({
        //     index: 0,
        //     actions: [NavigationActions.navigate({ routeName: 'Main' })],
        // });
        const {route: {params}, navigation} = this.props;
        navigation.addListener("beforeRemove", () => {
        });
        let mTicketId, mDisplayNote, mRequestLoginDetail, mDomain, mLkey, mpid;
        if (Boolean(params)) {
            const {product} = params;
            mTicketId = product.ticket_id;
            mDisplayNote = product.display_note;
            mRequestLoginDetail = product.request_login_details;
            mDomain = product.domain;
            mLkey = product.license_key;
            mpid = product.product_id
        }
        return (
            <RootPage navigation={navigation} title={"Success"}>
            <View style={Class.container}>
                <View style={Class.successContainer}>
                    <View style={Class.successSubContainer}>
                        <Icon
                            name="check-circle"
                            style={Class.iconCheck}
                        />
                        <Text style={Class.marginBottom4}>Successfully new created case</Text>
                        <Text>#{mTicketId}</Text>
                        <Text style={Class.displayNote}>
                            {mDisplayNote}
                        </Text>
                        {
                            Boolean(mRequestLoginDetail) && <ContainedButton
                                onPress={() => {
                                    navigation.navigate("UpdateLoginDetailsScreen", {product: params.product})
                                }}
                            >
                                Update Login Details
                            </ContainedButton>
                        }
                        <TouchableOpacity style={styles.group} onPress={() => {
                            navigation.popToTop();
                        }}>
                            <View style={styles.icon2Row}>
                                <Icon
                                    name="angle-left"
                                    style={styles.icon2}
                                />
                                <Text style={styles.back}>Back</Text>
                            </View>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
            </RootPage>
        );
    }
}

const styles = StyleSheet.create({

    group: {
        width: 44,
        height: 20,
        marginTop: 16,
        flexDirection: "row"
    },
    icon2: {
        color: "rgba(0,122,255,1)",
        fontSize: 18
    },
    back: {
        color: "rgba(0,122,255,1)",
        marginLeft: 6,
        marginTop: 2
    },
    icon2Row: {
        height: 20,
        flexDirection: "row",
        flex: 1
    }
});

export default SuccessTicket;
