import React, {Component} from "react";
import {ScrollView, StyleSheet, Text,  TouchableOpacity} from "react-native";
import FontAwesomeIcon from "react-native-vector-icons/FontAwesome";
import EntypoIcon from "react-native-vector-icons/Entypo";
import OcticonsIcon from "react-native-vector-icons/Octicons";
import Icon from "./Icon";
import Class from "../constants/Style"
import SquareIcon from "./SquareIcon";
import {List, View} from "./Themed";
import {grayIcon} from "../constants/Colors";

class ProfileItem extends Component {


    render() {
        const {label, icon, icontype, onPress, hide} = this.props;

        let right = undefined;
        if (!Boolean(hide)){
            right = props => <Icon lightColor={grayIcon}  {...props}  name={"angle-right"} style={Class.pointicon} />
        }

        return <View>
            <List
                title={label}
                titleStyle={Class.label}
                left={props => <Icon {...props} name={icon} style={Class.licon}/>}
                right={right}
                onPress={onPress}
            />
            <View style={[Class.separator,{marginLeft:0}]} lightColor="#eee" darkColor="rgba(255,255,255,0.1)"/>
        </View>
    }
}

export default ProfileItem;
