import * as React from "react";
import {Text, View} from "./Themed";
import Class from "../constants/Style";
import {currencyFormat} from "../libs/function";
import {isWeb} from "../libs/settings";
import SquareIcon from "./SquareIcon";
import {TouchableOpacity} from "react-native";
import AddOnItem from "./AddOnItem";
import CheckoutProduct from "./CheckoutProduct";

export default ({name, icon, amount, colour, addons, editmode, setEditMode, onAddAddon}) => {

    const itemFlex = isWeb ? Class.flex3 : Class.flex

    return <View>
        <View  style={[Class.paddingTopBottom8]}>
            <CheckoutProduct onPress={() => setEditMode()} name={name} icon={icon} colour={colour} amount={amount} />
            {
                Boolean(addons) && <React.Fragment>
                    {
                        Boolean(editmode) ?
                            <View>
                                {
                                    Object.keys(addons)
                                        .map((addon,index) => {
                                            return <AddOnItem key={index} onPress={()=>onAddAddon(addon)} checkout {...addons[addon]} />
                                        })
                                }
                            </View>:
                            <TouchableOpacity>
                                {
                                    Object.keys(addons)
                                        .filter((addon) => addons[addon].active)
                                        .map((addon, index) => {
                                            return <View key={index} style={[[Class.row, Class.paddingTopBottom4]]}>
                                                <SquareIcon
                                                    backgroundColor={`#${colour}`} style={{height: 0, marginLeft:0}}/>
                                                <View style={itemFlex}>
                                                    <Text>{addons[addon].name} </Text>
                                                </View>
                                                <View style={[Class.flex, Class.alignItemFlexEnd]}>
                                                    <Text>{currencyFormat(addons[addon].amount)}</Text>
                                                </View>
                                            </View>
                                        })
                                }
                            </TouchableOpacity>
                    }
                </React.Fragment>
            }

        </View>
        {
            !Boolean(editmode) && <View style={[Class.separator, Class.marginLeft0]} lightColor="#eee"
                                       darkColor="rgba(255,255,255,0.1)"/>
        }

    </View>
}
