import * as React from "react";
import Class from "../constants/Style";
import {Text, View} from "./Themed";
import {currencyFormat} from "../libs/function";
import {TouchableOpacity} from "react-native";

class BillItem extends React.Component<any, any> {
    render() {
        const {item, price, itemStyle, priceStyle, isHeading, hideLine} = this.props;
        let textStyle={};
        if (isHeading){
            textStyle = {fontWeight:"700"}
        }
        return (
            <React.Fragment>
                <TouchableOpacity style={[Class.row]}>
                    <View style={[Class.flex8, Class.paddingTopBottom16, itemStyle]}>{Boolean(item) && <Text style={[textStyle]}>{item}</Text>}</View>
                    <View style={[Class.flex4,  priceStyle]}>{Boolean(price) && <Text style={[Class.textRight, textStyle]}>{isNaN(price)?price:currencyFormat(price)}</Text>}</View>
                </TouchableOpacity>
                {
                    !hideLine && <View style={[Class.separator, Class.marginLeft0]} lightColor="#eee" darkColor="rgba(255,255,255,0.1)"/>
                }
            </React.Fragment>
        );
    }
}

export default BillItem;
