import * as React from 'react';
import requestApi, {actions, ERROR, methods, SUCCESS} from "../libs/ServerRequest";
import {setAuthUser, setInitData, setLoadingAndToken} from "../libs/Store/actions/app_info";
import {connect, useDispatch} from "react-redux";
import Class from '../constants/Style';
import ReCaptchaV3 from "@haskkor/react-native-recaptchav3";
import {isDevelopment, isWeb} from "../libs/settings";
import {withGoogleReCaptcha} from "react-google-recaptcha-v3";
import {ContainedButton, Text, TextInputPaper, View} from "../components/Themed";
import {browseLink, isEmpty, signOut, STORAGE_KEY, storeData} from "../libs/function";
import {validEmail, validRequired} from "../libs/validate";
//import DhruLogo from "../components/DhruLogo";
import {setDialog, setLoader, setSnackbar} from "../libs/Store/actions/components";
import RootPage from "../components/RootPage";
import LinkButton from "../components/LinkButton";
import RecaptchaContent from "../components/RecaptchaContent";
import Icon from "../components/Icon";
import {Button, Divider} from "react-native-paper";
import DialogView from "../components/DialogView";


const DhruLogo = React.lazy(() => import(`../components/DhruLogo`))

// import Toast from 'react-native-toast-message'

const Message = (props: any) => {

    const dispatch = useDispatch()

    const {message} = props;

    const closeDialog = () => {
        dispatch(setDialog({
            visible   : false,
            dialogview: undefined
        }))
    }

    return <DialogView dialogSize={"medium"} actionView={<View style={[Class.row]}>
        <Button onPress={() => closeDialog()}>OK</Button>
    </View>} title={'Alert'}>
        <View style={[Class.marginTop16, Class.marginBottom16]}>
            <Text style={[Class.textBold600]}>{message}</Text>
        </View>
    </DialogView>
}

class Login extends React.Component<any, any> {


    constructor(props: any) {
        super(props);

        let data = {
            email   : "",
            password: ""
        };

        const {route} = props;


        let paramsData = {};
        let disabledEmailField = false;
        if (route?.params) {
            let {
                client_id,
                response_type,
                redirect_uri,
                email
            } = route.params;
            paramsData = {
                client_id,
                response_type,
                redirect_uri
            }
            if (email) {
                data.email = email
                disabledEmailField = true;
            }
        }

        let isAuthorizeScreen = route?.name === "Authorize";

        if (isAuthorizeScreen) {
            signOut();
        }


        this.state = {
            captcha    : "",
            captchaCall: false, ...data,
            error      : {},
            isAuthorizeScreen,
            paramsData,
            disabledEmailField,
            getparams  : false
        }
    }

    componentDidMount() {
        const {
            navigation,
            route,
            setAuthUser,
            setLoadingAndToken,
            setDialog
        } = this.props;
        const {
            isAuthorizeScreen,
            paramsData
        } = this.state;
        if (isAuthorizeScreen) {
            this.requestGetAuthorize()
        }

        let token = route?.params?.token
        if (Boolean(token)) {
            setLoadingAndToken({
                loading: false,
                token
            })
            this.requestInit(token, true)
        }
        let message = route?.params?.message

        if (message) {
            setDialog({
                visible   : true,
                dialogview: <Message message={message}/>
            })
        }
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        const {route} = this.props;
        if (route?.params && !this.state?.getparams) {
            let {email} = route.params;
            if (email) {
                this.setState({
                    disabledEmailField: true,
                    email,
                    getparams         : true
                })
            }
        }
    }

    requestGetAuthorize = () => {
        const {paramsData} = this.state;
        const {navigation} = this.props;
        let {
            client_id,
            response_type,
            redirect_uri
        } = paramsData;
        requestApi({
            action     : actions.authorize,
            method     : methods.get,
            queryString: {
                client_id,
                response_type,
                redirect_uri
            }
        })
            .then((response: any) => {
                if (response.status === SUCCESS) {
                    this.setState({
                        authorizeData: response?.data
                    })
                } else {
                    navigation.reset({
                        index : 0,
                        routes: [{
                            name  : 'Error',
                            params: {message: response?.message}
                        }],
                    });
                }
            })
    }

    validate = () => {
        const {
            email,
            password
        } = this.state;
        let error = {};
        error = {...error, ...validEmail(error, "email", email)};
        error = {...error, ...validRequired(error, "password", password, "Please enter password")};
        this.setState({error})
        return isEmpty(error)
    }

    requestLogin = async () => {
        const {
            setAuthUser,
            navigation,
            route,
            fromCheckout,
            redirectPage
        } = this.props;
        let {
            email,
            password,
            captcha,
            isAuthorizeScreen,
            paramsData,
            authorizeData
        } = this.state;
        if (isWeb && this.props?.googleReCaptchaProps?.executeRecaptcha) {
            captcha = await this.props?.googleReCaptchaProps?.executeRecaptcha();
        }
        this.props.setLoader({show: false});

        let body: any = {
            email,
            password,
            "g-recaptcha-response": captcha
        };
        let action = actions.login;
        if (isAuthorizeScreen) {
            action = actions.authorize;
            body = {
                ...body,
                client_id    : paramsData?.client_id,
                response_type: authorizeData?.response_type,
                redirect_uri : authorizeData?.redirect_uri,
            }
        }

        requestApi({
            method: methods.post,
            action,
            body
        })
            .then(async (response: any) => {
                if (response.status === SUCCESS && response?.data) {
                    if (isAuthorizeScreen) {
                        if (isDevelopment && response?.data?.redirect_uri.includes("localhost")) {
                            response.data.redirect_uri = response.data.redirect_uri.toString()
                                .replace("localhost", "localhost:3000");
                        }
                        browseLink(response?.data?.redirect_uri, "_SELF")
                    } else {
                        const value = {
                            token: response.token, ...response.data,
                        }
                        storeData(STORAGE_KEY.USER_DATA, value)
                            .then(() => {
                                setAuthUser({
                                    token: response.token, ...response.data,
                                });
                                this.requestInit(response.token);

                            })
                    }
                } else if (response?.status === ERROR) {
                    if (response?.code == 201) {
                        if (Boolean(fromCheckout)) {
                            redirectPage("VerifyOTP", {
                                route: {
                                    params: {
                                        body,
                                        action,
                                        method: methods.post,
                                        isAuthorizeScreen, ...response
                                    }
                                }
                            })
                        } else {
                            navigation.navigate("VerifyOTP", {
                                body,
                                action,
                                method: methods.post,
                                isAuthorizeScreen, ...response
                            })
                        }

                    }
                }
            })
    }

    requestInit = (token: any, fromParams: boolean = false) => {
        const {
            navigation,
            setInitData,
            setLoadingAndToken,
            route,
            setAuthUser
        } = this.props;

        let requestApiData: any = {
            method: methods.get,
            action: actions.logininit,
        }

        if (fromParams) {
            requestApiData = {
                ...requestApiData,
                token
            }
        }

        requestApi(requestApiData)
            .then((response: any) => {
                if (response.status === SUCCESS) {
                    if (fromParams) {
                        const value = {
                            token, ...response.data.profile,
                        }
                        storeData(STORAGE_KEY.USER_DATA, value)
                            .then(() => setAuthUser(value))
                    }
                    setInitData(response.data);

                    if (route?.params?.a) {
                        setLoadingAndToken({
                            loading        : false,
                            token,
                            defaultRedirect: ""
                        })
                        navigation.navigate("JoinWorkspace", {
                            a: route?.params?.a
                        })
                    } else {
                        setLoadingAndToken({
                            loading: false,
                            token
                        })
                    }
                }
            })
    }

    onSubmit = () => {
        if (this.validate()) {
            if (isWeb) {
                this.requestLogin()
            } else {
                this.props.setLoader({show: true});
                this.setState({captchaCall: true}, () => {
                    this._captchaRef.refreshToken()
                });
            }
        }
    }

    onClickSignInWithApple = () => {

        let {
            email,
            password,
            captcha,
            isAuthorizeScreen,
            paramsData,
            authorizeData
        } = this.state;

        let queryString = {};

        if (isAuthorizeScreen) {
            queryString = {
                redirect_uri: authorizeData?.redirect_uri
            }
        }

        requestApi({
            method: methods.get,
            action: actions.applelogin,
            queryString
        }).then((response: any) => {
            if (response.status == SUCCESS && !isEmpty(response?.data)) {
                browseLink(response?.data[0].redirect, "_SELF")
            }
        })
    }

    render() {
        let {
            email,
            password,
            onPress,
            error,
            captcha,
            captchaCall,
            isAuthorizeScreen,
            authorizeData,
            authorizeErrorMessage,
            disabledEmailField
        } = this.state;
        const {
            navigation,
            fromCheckout,
            redirectPage,
            token,
            route
        } = this.props;


        return (<RootPage navigation={navigation}>
            <View style={Class.container}>
                <View style={Class.formContainer}>
                    <View style={Class.formGroup}>
                        <View
                            style={[Class.alignSelfStretch, Class.marginBottom16]}
                        >
                            <DhruLogo/>
                            <Text style={Class.pageHeader}>{isAuthorizeScreen ? "Authorize" : "Sign in"}</Text>
                            <View
                                style={[Class.row, Class.marginBottom16]}
                            >
                                {isAuthorizeScreen ? <>
                                    {authorizeData?.app_name &&
                                        <Text>Use your DHRU ID to sign in {authorizeData?.app_name}</Text>}
                                </> : <>
                                    <Text style={[Class.marginRight8]}>New user?</Text>
                                    <LinkButton
                                        caption={"Create an account"}
                                        onPress={() => {
                                            if (Boolean(fromCheckout)) {
                                                redirectPage("Register")
                                            } else {
                                                navigation.navigate("Register")
                                            }
                                        }}
                                        style={Class.linkStyle}
                                    />
                                </>}
                            </View>

                            <TextInputPaper
                                label={"Email Address"}
                                placeholder={"Email Address"}
                                value={email}
                                disabled={disabledEmailField}
                                error={error.email}
                                autoCompleteType={"username"}
                                textContentType={"username"}
                                keyboardType={"email-address"}
                                onChangeText={(email) => this.setState({email})}
                                style={[Class.margin0]}
                                returnKeyType="next"
                                onSubmitEditing={() => this.passwordInput.focus()}
                                blurOnSubmit={false}
                            />

                            <TextInputPaper
                                returnKeyType="done"
                                ref={ref => this.passwordInput = ref}
                                label={"Password"}
                                secureTextEntry={true}
                                placeholder={"Password"}
                                value={password}
                                error={error.password}
                                autoCompleteType={"password"}
                                textContentType={"password"}
                                keyboardType={"default"}
                                onSubmitEditing={() => this.onSubmit()}
                                onChangeText={(password) => this.setState({password})}
                                style={[Class.margin0, Class.marginTop16]}
                            />
                            <View style={Class.buttonRowContainer}>
                                <LinkButton
                                    caption={"Reset you password"}
                                    onPress={() => {
                                        if (Boolean(fromCheckout)) {
                                            redirectPage("ForgotPassword")
                                        } else {
                                            let params = {};
                                            if (isAuthorizeScreen) {
                                                params = {authorize: isAuthorizeScreen}
                                            }
                                            navigation.navigate("ForgotPassword", params)
                                        }

                                    }}
                                    style={Class.linkStyle}
                                />
                                {(!isWeb && captchaCall) && <ReCaptchaV3
                                    ref={(ref: RecaptchaV3) => this._captchaRef = ref}
                                    captchaDomain={'https://api.dhru.com'}
                                    siteKey={'6LebDscZAAAAAEfuz_E-rB6r12xLHekW_XlR-Y5V'}
                                    onReceiveToken={(token: string) => {
                                        this.setState({
                                            captcha    : token,
                                            captchaCall: false
                                        }, () => {
                                            this.requestLogin()
                                        })
                                    }}
                                    action={""}
                                />}

                                <ContainedButton
                                    onPress={() => this.onSubmit()}
                                >
                                    Continue
                                </ContainedButton>

                            </View>

                            {(isAuthorizeScreen && authorizeErrorMessage) && <View>
                                <Text style={Class.errorText}>{authorizeErrorMessage}</Text>
                            </View>}


                            {!fromCheckout && <>
                                <View style={[Class.display_flex, Class.row, {position: 'relative'}]}>
                                    <View style={[Class.width100, {position: "absolute"}]}>
                                        <Divider/>
                                    </View>
                                    <Text style={[{
                                        position       : "absolute",
                                        left           : "45%",
                                        backgroundColor: "white",
                                        paddingLeft    : 10,
                                        fontWeight     : "600",
                                        paddingRight   : 10
                                    }]}>OR</Text>
                                </View>

                                <ContainedButton
                                    style={{
                                        marginTop: 18,
                                    }}
                                    buttonStyle={{
                                        backgroundColor:'#000'
                                    }}
                                    onPress={this.onClickSignInWithApple}
                                >
                                    <Icon
                                        name="apple"
                                        lightColor={'white'}
                                        size={16}
                                        style={{
                                            marginRight: 10
                                        }}
                                    />
                                    Sign in with Apple
                                </ContainedButton>
                            </>}


                            {Boolean(isDevelopment) && <ContainedButton
                                style={{
                                    marginTop: "12px"
                                }}
                                onPress={() => navigation.navigate("Checkout", {
                                    pid : "b152adfc-5829-4c3c-a3b7-6df8e4a3cf52",
                                    aids: "c9e19240-1533-430b-9607-9e55300e7cdf,f0e22dd5-eef5-4ef3-996c-48c17b53b90b,f0e22dd5-eef5-4ef3-996c-48c17b53b90b",
                                })}
                            >
                                Checkout
                            </ContainedButton>}

                        </View>
                        <RecaptchaContent/>
                    </View>
                </View>
            </View>
        </RootPage>);
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    setAuthUser       : (authUser) => dispatch(setAuthUser(authUser)),
    setInitData       : (initData) => dispatch(setInitData(initData)),
    setLoadingAndToken: (data) => dispatch(setLoadingAndToken(data)),
    setSnackbar       : (snackData) => dispatch(setSnackbar(snackData)),
    setLoader         : (loader) => dispatch(setLoader(loader)),
    setDialog         : (dialog) => dispatch(setDialog(dialog))
});

export default withGoogleReCaptcha(connect(mapStateToProps, mapDispatchToProps)(Login));
