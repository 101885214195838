import {StatusBar} from 'expo-status-bar';
import React, {Suspense} from 'react';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';

import {store} from "./libs/Store/configureStore";
import {Provider} from 'react-redux';
import {DefaultTheme, Provider as PaperProvider} from 'react-native-paper';
import {ActionSheetProvider} from "@expo/react-native-action-sheet";
import {isWeb} from "./libs/settings";
import {css, Global} from '@emotion/core';
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import DefaultSpinner from "./components/DefaultSpinner";


import Navigation from './navigation';
// const Navigation = React.lazy(()=>import('./navigation'))

const toastConfig = {};

export default function App() {
    const isLoadingComplete = useCachedResources();
    const colorScheme = useColorScheme();

    const theme = {
        ...DefaultTheme,
        roundness: 5,
        colors: {
            ...DefaultTheme.colors,
            primary: '#1c55c5',
            accent: '#f1c40f',
            background: '#FFF',
            text: '#1c2a48',
            surface: '#FFF',
            error: '#d25455',
            onBackground: '#000000',
            onSurface: '#000000',
            disabled: '#CCC',
            placeholder: '#CCC',
            backdrop: '#CCC',
            notification: '#000',
        },
        Textbox: {
            marginBottom: 16,
            borderBottomWidth: 0,
            backgroundColor: "#FFF"
        },
    };


    if (!isLoadingComplete) {
        return null;
    } else {
        if (isWeb) {
            return (
                <SafeAreaProvider>
                    <Global styles={css`
                            input:focus,textarea:focus,select:focus {
                                outline: none !important;
                            }
                            
                            .grecaptcha-badge {
                                display: none;
                            }
                                                   
                            input:-webkit-autofill,
                            input:-webkit-autofill:hover, 
                            input:-webkit-autofill:focus,
                            input:-webkit-autofill:active
                            textarea:-webkit-autofill,
                            textarea:-webkit-autofill:hover,
                            textarea:-webkit-autofill:focus,
                            select:-webkit-autofill,
                            select:-webkit-autofill:hover,
                            select:-webkit-autofill:focus,
                            input:-internal-autofill-selected {
                                
                              -webkit-text-fill-color: #1f50c5;
                              -webkit-box-shadow: 0px inset;
                              -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
                              -webkit-transition-delay: 9999s;
                            }
                            
                            select { 
                            font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
                            height: 64px;
                            padding: 24px 0px 4px;
                            vertical-align: middle;
                            outline: none;
                            font-size: 16px;
                            color: rgb(28, 42, 72);
                            position: relative; 
                            font-family: inherit;
                            background-color: transparent;
                            border-radius: 0;
                            border: none; 
                            border-bottom: 1px solid rgba(0,0,0, 0.12);
                            }
                            select:focus { outline: none;  }
                         `}
                    />

                    <GoogleReCaptchaProvider
                        reCaptchaKey="6LebDscZAAAAAEfuz_E-rB6r12xLHekW_XlR-Y5V"
                    >
                        <Provider store={store}>
                            <ActionSheetProvider>
                                <PaperProvider theme={theme}>
                                    <Suspense fallback={<DefaultSpinner/>}>
                                        <Navigation colorScheme={colorScheme}/>
                                        <StatusBar/>
                                    </Suspense>
                                </PaperProvider>
                            </ActionSheetProvider>
                        </Provider>
                    </GoogleReCaptchaProvider>
                </SafeAreaProvider>
            );
        } else {
            return (
                <SafeAreaProvider>
                    <Provider store={store}>
                        <ActionSheetProvider>
                            <PaperProvider theme={theme}>
                                <Suspense fallback={<DefaultSpinner/>}>
                                    <Navigation colorScheme={colorScheme}/>
                                    <StatusBar/>
                                </Suspense>
                            </PaperProvider>
                        </ActionSheetProvider>
                    </Provider>
                </SafeAreaProvider>
            );
        }

    }
}
