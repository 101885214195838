import * as React from "react";
import {ContainedButton, Text, TextInputPaper, View} from "../components/Themed";
import RootPage from "../components/RootPage";
import {headerHeight, isIOS, LARGE_SCREEN_WIDTH} from "../libs/settings";
import Class from "../constants/Style";
import DhruScrollView from "../components/DhruScrollView";
import {LargeScreen, SmallScreen} from "../components/ScreenSize";
import {KeyboardAvoidingView} from "react-native";
import CheckBox from "../components/CheckBox";
import {validEmail, validRequired} from "../libs/validate";
import {isEmpty} from "../libs/function";
import requestApi, {actions, methods, SUCCESS} from "../libs/ServerRequest";
import Icon from "../components/Icon";
import {setInitDataWithObject} from "../libs/Store/actions/app_info";
import {connect} from "react-redux";
import {BackButton2} from "../components/BackButton";

class TransferCredit extends React.Component<any, any> {

    constructor(props) {
        super(props);

        this.state = {
            transfer_to_email: "",
            credits:"",
            error: {},
            verify: false,
            moved: false,
            displayMessage: ""
        }
    }

    componentDidMount() {
        this.call();
        this.props.navigation.addListener('dismiss', e => {
            if (Boolean(this.state.moved)) {
                this.props.navigation.popToTop()
            }
        });
    }

    // componentWillUnmount() {
    //     const {navigation} = this.props;
    //     const {moved} = this.state;
    //     if (Boolean(moved)) {
    //         navigation.popToTop()
    //     }
    // }

    call = () => {
        const {navigation} = this.props;
        const {moved} = this.state;
        if (Boolean(navigation)) {
            navigation.setOptions({
                headerLeft: props => <BackButton2 goBack={() => {
                    Boolean(moved) ? navigation.popToTop() : navigation.goBack()
                }} {...props}/>
            });
        }
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        this.call();
    }

    validate = () => {
        const {transfer_to_email, credits} = this.state;
        let error = {};
        error = {...error, ...validEmail(error, "transfer_to_email", transfer_to_email)};
        error = {...error, ...validRequired(error, "credits", credits, "Please enter credit")};
        this.setState({error})
        return isEmpty(error)
    }

    requestChangeOwner = () => {
        let {navigation, products, setInitDataWithObject} = this.props;
        let {transfer_to_email, credits} = this.state;
        requestApi({
            method: methods.post,
            action: actions.wallet, showsucess: true,
            body: {transfer_to_email, credits}
        }).then(async (response) => {
            if (response.status === SUCCESS) {

            }
        })
    }

    onSubmit = () => {
        if (this.validate()) {
            this.requestChangeOwner()
        }
    }

    render() {
        const {navigation} = this.props;
        const {transfer_to_email, error, verify,  moved, displayMessage, credits} = this.state;
        return (
            <RootPage navigation={navigation} noBack={false} headerTitle={"Transfer Credit"}>
                {
                    isIOS && <View style={[Class.row, Class.paddingTopBottom16]}>
                        <View style={[Class.flex, {alignItems: "flex-start"}]}>
                            <BackButton2 goBack={() => {
                                Boolean(moved) ? navigation.popToTop() : navigation.goBack()
                            }}
                                         style={{paddingLeft: 18, paddingRight: 18}}/>
                        </View>
                        <View style={[Class.flex2, Class.alignItemCenter]}>
                            <Text style={{fontSize: 18, fontWeight: "600"}}>Transfer Credit</Text>
                        </View>
                        <View style={Class.flex}/>
                    </View>
                }

                <KeyboardAvoidingView
                    keyboardVerticalOffset={headerHeight}
                    style={Class.container} behavior={isIOS ? "padding" : ""}>
                    <View style={[Class.container]}>
                        <DhruScrollView contentContainerStyle={Class.paddingLeftRight22}>
                            <View style={[Class.maxFormWidth]}>
                                <TextInputPaper
                                    label={"Email Address"}
                                    placeholder={"Email Address"}
                                    error={error.transfer_to_email}
                                    value={transfer_to_email}
                                    autoCompleteType={"email"}
                                    textContentType={"emailAddress"}
                                    keyboardType={"email-address"}
                                    onChangeText={(transfer_to_email) => this.setState({transfer_to_email})}
                                    style={Class.textbox}
                                    returnKeyType="done"
                                    onSubmitEditing={() => this.onSubmit()}
                                />

                                <TextInputPaper
                                    value={credits}
                                    onChangeText={(credits) => {
                                        credits = credits.replace(/[^0-9]/, '')
                                        this.setState({credits})
                                    }}
                                    label={"Credit"}
                                    error={error.credits}
                                    placeholder={"Credit"}/>

                                <CheckBox
                                    onPress={() => {
                                        this.setState({verify: !verify})
                                    }}
                                    checked={verify}
                                    label={"After Transfer credit can no be revert ."}
                                    style={[Class.marginTop16, Class.marginBottom16]}
                                    textStyle={[Class.marginTop0]}
                                />

                                <LargeScreen minWidth={LARGE_SCREEN_WIDTH}>
                                    <ContainedButton
                                        disabled={!verify}
                                        style={[Class.maxFormWidth]}
                                        onPress={() => this.onSubmit()}
                                    >
                                        Transfer Credit
                                    </ContainedButton>
                                </LargeScreen>
                            </View>
                        </DhruScrollView>
                        <SmallScreen maxWidth={LARGE_SCREEN_WIDTH}>
                            <View style={[Class.bottomView]}>
                                <ContainedButton
                                    disabled={!verify}
                                    style={[Class.maxFormWidth]}
                                    onPress={() => this.onSubmit()}
                                >
                                    Transfer Credit
                                </ContainedButton>
                            </View>
                        </SmallScreen>
                    </View>
                </KeyboardAvoidingView>
            </RootPage>
        );
    }
}

const mapStateToProps = (state) => ({
    products: state.app_info.products,
});

const mapDispatchToProps = (dispatch) => ({
    setInitDataWithObject: (objectKey, updateData) => dispatch(setInitDataWithObject(objectKey, updateData))
});

export default connect(mapStateToProps, mapDispatchToProps)(TransferCredit)

