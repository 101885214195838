import {StackScreenProps} from '@react-navigation/stack';
import * as React from 'react';
import {StyleSheet, Text, TouchableOpacity} from 'react-native';

import {RootStackParamList} from '../types';
import {connect} from "react-redux";
import FullScreenMessage from "../components/FullScreenMessage";

function NotFoundScreen(props: StackScreenProps<RootStackParamList, 'NotFound'>) {

    let {navigation, token, route} = props;

    if (route?.name === "Error") {
        return (
            <FullScreenMessage title={route?.params?.message || "Something Wrong!"}>

            </FullScreenMessage>
        );
    }
    return (
        <FullScreenMessage title={"404"} subtitle={"The page you are trying to reach doesn't exist."}>
            <TouchableOpacity onPress={() => {
                let page = Boolean(token) ? 'SupportRoot' : "Login"
                navigation.replace(page)
            }} style={styles.link}>
                <Text style={styles.linkText}>Go to home !</Text>
            </TouchableOpacity>
        </FullScreenMessage>
    );
}

const mapStateToProps = (state) => ({
    token: state.app_info.token,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NotFoundScreen)

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    link: {
        marginTop: 15,
        paddingVertical: 15,
    },
    linkText: {
        fontSize: 14,
        color: '#2e78b7',
    },
});
