import * as React from "react";
import {
    ContainedButton,
    Text,
    TextInputPaper,
    View
} from "../components/Themed";
import RootPage from "../components/RootPage";
import Class from "../constants/Style"
import {
    KeyboardAvoidingView,
    ScrollView,
    StyleSheet,
    TouchableOpacity
} from "react-native";
import {
    headerHeight,
    isIOS,
    isWeb,
    LARGE_SCREEN_WIDTH
} from "../libs/settings";
import {
    getTaxAmount,
    isEmpty,
    signOut
} from "../libs/function";
import CheckoutItem from "../components/CheckoutItem";
import BillItem from "../components/BillItem";
import {
    setAuthUser,
    setInitData,
    setLoadingAndToken
} from "../libs/Store/actions/app_info";
import {
    setLoader,
    setSnackbar
} from "../libs/Store/actions/components";
import {withGoogleReCaptcha} from "react-google-recaptcha-v3";
import {connect} from "react-redux";
import Login from "./Login";
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import VerifyEmail from "./VerifyEmail";
import {
    LargeScreen,
    SmallScreen
} from "../components/ScreenSize";
import Icon from "../components/Icon";
import requestApi, {
    actions,
    methods,
    SUCCESS
} from "../libs/ServerRequest";
import {validRequired} from "../libs/validate";
import {BackButton2} from "../components/BackButton";
import * as WebBrowser from 'expo-web-browser';
import VerifyWhatsApp from "./VerifyWhatsApp";
import {TextInput} from "react-native-paper";
import VerifyOTP from "./VerifyOTP";

class Checkout
    extends React.Component<any, any> {

    constructor(props) {
        super(props);
        const {navigation, route, items} = props;
        let selectedAddons               = [];

        if (Boolean(route.params) && Boolean(route.params.aids)) {
            selectedAddons = route.params.aids.split(",")
        }

        this.state = {
            page: Boolean(route?.params?.newuser && route?.params?.newuser == "true") ? "Register" : "Login", item: {}, subtotal: "0", total: "0", itemTax: [], selectedAddons, editmode: false, domain: "", error: {}
        }


    }

    componentDidMount() {
        const {navigation, route, token} = this.props;
        navigation.setOptions({
            headerLeft: props => <BackButton2
                goBack={() => {
                    if (Boolean(route.params) && Boolean(route.params.page)) {
                        WebBrowser.openBrowserAsync(route.params.page);
                    } else if (navigation.canGoBack()) {
                        navigation.goBack()
                    } else {
                        const page = Boolean(token) ? "ProductRoot" : "Login"
                        navigation.replace(page);
                    }
                }} {...props} />
        });
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
    }

    calculate = () => {
        const {navigation, route, items, tax, token, profile} = this.props;
        const {selectedAddons, domain}                        = this.state;
        if (Boolean(route.params) && Boolean(route.params.pid) && Boolean(items)) {
            let currency = "USD", subtotal = "0", total = "0", itemTax = [], country = "US";

            if (Boolean(this.props.token) && Boolean(profile) && Boolean(profile.country)) {
                country  = profile.country;
                currency = profile.currency;
            }


            const {navigation, countryList, token, items, route} = this.props;
            const item                                           = items[route.params.pid];
            item.itemid                                          = route.params.pid;
            // const allAddons = items[route.params.pid].addons;
            item.amount                                          = item.price[currency].price;
            if (this.getLabel() === "Credits" && Boolean(domain)) {
                item.amount = domain;
            }

            subtotal = parseFloat(item.amount);
            // item.addons = {};
            if (Boolean(item.addons)) {
                Object.keys(item.addons)
                    .forEach((aid) => {
                        let selectedAddon    = item.addons[aid]
                        selectedAddon.amount = selectedAddon.price[currency].price;
                        selectedAddon.active = false;
                        const checked        = selectedAddons.filter((checkid) => checkid === aid);
                        if (Boolean(checked) && Boolean(checked.length > 0)) {
                            subtotal += parseFloat(selectedAddon.amount);
                            selectedAddon.active = true;
                        }
                        item.addons[aid] = selectedAddon;
                    })
            }
            const defaultTax     = tax.filter((t) => t.name === "Transaction tax")[0];
            defaultTax.taxamount = getTaxAmount(defaultTax.rate, subtotal)
            itemTax              = [defaultTax]
            if (country === "IN") {

                const inTax     = tax.filter((t) => t.name === "IGST")[0];
                inTax.taxamount = getTaxAmount(inTax.rate, subtotal)
                itemTax         = [
                    ...itemTax,
                    inTax
                ]
            }
            total = subtotal;
            itemTax.forEach((t) => {
                total += parseFloat(t.taxamount);
            })
            return {item, subtotal: subtotal.toString(), total: total.toString(), itemTax};
        }
    }
    //
    // getTaxAmount = (rate, value) => {
    //     return (parseFloat(rate) * parseFloat(value) / 100).toString()
    // }

    UserPage = () => {
        const {profile} = this.props;
        let address     = "";
        if (Boolean(profile.address1)) {
            address += profile.address1 + ", ";
        }
        if (Boolean(profile.address2)) {
            address += profile.address2 + ", ";
        }
        if (Boolean(profile.city)) {
            address += profile.city + ", ";
        }
        if (Boolean(profile.state)) {
            address += profile.state + ", ";
        }
        if (Boolean(profile.country)) {
            address += profile.country + ", ";
        }
        if (Boolean(profile.postcode)) {
            address += profile.postcode;
        }
        return <TouchableOpacity style={[Class.formContainer]}>
            <View style={[Class.formGroup]}>
                <View style={{marginTop: 48}}>
                    <Text style={Class.title}>{profile.full_name}</Text>
                    <Text>{profile.email}</Text>
                    {Boolean(profile.company_name) && <Text>{profile.company_name}</Text>}
                    {Boolean(address) && <Text>{address}</Text>}
                    <ContainedButton
                        style={[Class.marginTop12]}
                        onPress={() => {
                            this.setState({page: "", otherData: {}}, () => {
                                signOut()
                            })
                        }}
                        buttonAlign={"right"}
                    >Sign
                        out</ContainedButton>
                </View>
            </View>
        </TouchableOpacity>
    };

    redirectPage = (page, otherData) => {
        this.setState({page, otherData})
    }


    PageComponent = () => {
        const {page, otherData} = this.state;
        const {token, authUser} = this.props;


        if (Boolean(token)) {
            if (!Boolean(authUser.email_verified)) {
                return <VerifyEmail
                    fromCheckout
                    redirectPage={this.redirectPage}
                />
            }
            if (!Boolean(authUser.whatsapp_verified)) {
                return <VerifyWhatsApp
                    fromCheckout
                    redirectPage={this.redirectPage}
                />
            }
            return this.UserPage();
        }
        if (page === "ForgotPassword") {
            return <ForgotPassword
                fromCheckout
                redirectPage={this.redirectPage}
            />
        } else if (page === "Register") {
            return <Register
                fromCheckout
                redirectPage={this.redirectPage}
            />
        } else if (page === "ResetPassword") {
            return <ResetPassword
                fromCheckout
                redirectPage={this.redirectPage}
            />
        } else if (page === "VerifyOTP") {
            return <VerifyOTP
                fromCheckout {...otherData}
                redirectPage={this.redirectPage}
            />
        }
        return <Login
            fromCheckout
            redirectPage={this.redirectPage}
        />
    }

    leftContainer = () => {
        return <View style={styles.leftContainer}>
            <View style={{marginTop: -114}}>
                {this.PageComponent()}
            </View>
        </View>
    }

    onAddAddon = (newAddon) => {
        const {selectedAddons} = this.state;
        let setNew             = [];
        let check              = selectedAddons.find(oa => oa === newAddon)
        if (Boolean(check)) {
            setNew = selectedAddons.filter((a) => a !== newAddon)

        } else {
            setNew = [
                ...selectedAddons,
                newAddon
            ]
        }
        this.setState({selectedAddons: setNew})
    }

    validate = () => {
        const {domain}         = this.state;
        let error              = {};
        let errorDomainMessage = `Please Enter ${this.getLabel()}`;
        error                  = {...error, ...validRequired(error, "domain", domain, errorDomainMessage)};
        this.setState({error})
        return isEmpty(error)
    }

    isWorkSpace = () => {
        const {items, route} = this.props;
        const item           = items[route.params.pid];
        return item?.itemid === "3d44bcb5-afbc-4153-af6a-aa3457ebe119";
    }

    getLabel = () => {
        const {items, route} = this.props;
        const item           = items[route.params.pid];
        if (item?.itemid === "3d44bcb5-afbc-4153-af6a-aa3457ebe119") {
            return "Workspace"
        } else if (item?.itemid === "ad30debf-e0eb-4499-a07b-8da4a403c4df") {
            return "Credits"
        }
        return "Domain";
    }

    onPutOrder = (item) => {
        const {domain}     = this.state;
        const {navigation} = this.props;
        if (this.validate()) {
            let body = {
                itemid: item.itemid, domain,
            };
            if (Boolean(item.addons)) {
                body.addons = Object.keys(item.addons)
                    .filter((addonid) => item.addons[addonid].active)
                    .map((addonid) => {
                        return {addonid}
                    });
            }
            requestApi({
                method: methods.post, action: actions.order, body
            })
                .then((response) => {
                    if (response.status === SUCCESS) {
                        const data = response.data[0];
                        navigation.replace('OrdersRoot', {
                            screen: 'OrdersNav', params: {
                                screen: 'Orders', params: {
                                    screen: "OrdersList", params: {
                                        id: data.order_reference_id, pay: data.payment_url, from: "checkout"
                                    }
                                },
                            },
                        })
                    }
                })
        }
    }

    rightContainer = () => {
        const {navigation, countryList, token, items, route, authUser} = this.props;
        const {editmode, domain, selectedAddons, error}                = this.state;
        const {item, subtotal, total, itemTax}                         = this.calculate();
        let label                                                      = this.getLabel()
        let domainLabel                                                = label === "Credits" ? "Credits ( Minimum 50 )" : label;
        return <View style={styles.rightContainer}>
            <View style={[{width: isWeb ? 375 : "100%", alignSelf: "center"}]}>
                <View style={[[Class.paddingLeftRight16]]}>
                    <View>
                        <Text style={[Class.pageHeader]}>Items</Text>
                        <View
                            style={[
                                Class.separator,
                                Class.marginLeft0
                            ]}
                            lightColor="#eee"
                            darkColor="rgba(255,255,255,0.1)"
                        />
                    </View>
                </View>
                <View style={[Class.paddingLeftRight16]}>

                    <CheckoutItem
                        {...item}
                        editmode={editmode}
                        onAddAddon={this.onAddAddon}
                        setEditMode={() => this.setState({editmode: !editmode})}
                    />
                    <BillItem
                        itemStyle={Class.alignItemFlexEnd}
                        isHeading
                        item={"Subtotal"}
                        price={subtotal}
                    />
                    {(Boolean(itemTax) && Boolean(itemTax.length > 0)) && itemTax.map((it, index) => {
                        return <BillItem
                            key={index}
                            itemStyle={Class.alignItemFlexEnd}
                            isHeading
                            item={`${it.name} ( ${it.rate}% )`}
                            price={it.taxamount}
                        />
                    })}

                    <BillItem
                        itemStyle={Class.alignItemFlexEnd}
                        isHeading
                        item={"Total"}
                        hideLine={true}
                        price={total}
                    />

                    <TextInputPaper
                        value={domain}
                        onChangeText={(domain) => {
                            if (label === "Credits") {
                                domain = domain.replace(/[^0-9.]/, '')
                            }
                            this.setState({domain})
                        }}

                        label={domainLabel}
                        right={domainLabel === "Workspace" && <TextInput.Affix text=".dhru.com" />}
                        error={error.domain}
                        placeholder={domainLabel}
                    />
                </View>
                <View
                    style={[
                        Class.padding16,
                        Class.alignItemFlexEnd
                    ]}
                >
                    {(Boolean(token) && Boolean(authUser?.email_verified) && Boolean(authUser?.whatsapp_verified)) &&
                        <ContainedButton
                            onPress={() => this.onPutOrder(item)}
                            style={Class.fixWidthOnWebButton}
                        >Process to payment</ContainedButton>}
                </View>
            </View>
        </View>
    }

    render() {
        const {navigation, countryList, token, items, route} = this.props;
        if (!Boolean(route.params) || !Boolean(route.params.pid)) {
            navigation.navigate("Login")
            return <View />;
        }

        return (<RootPage
                navigation={navigation}
                headerTitle={"Cart"}
                headerLeft={false}
            >
                <View style={Class.container}>
                    <KeyboardAvoidingView
                        behavior={isIOS ? "padding" : ""}
                        style={Class.flex}
                        keyboardVerticalOffset={headerHeight}
                    >
                        <View style={Class.flex}>
                            <ScrollView>
                                <View style={[Class.container]}>
                                    <LargeScreen minWidth={LARGE_SCREEN_WIDTH}>
                                        <View style={{marginTop: 108}}>
                                            <View
                                                style={[
                                                    Class.row,
                                                    {maxWidth: 1024, alignSelf: "center", flex: 1}
                                                ]}
                                            >
                                                {this.leftContainer()}
                                                <View
                                                    lightColor={"#ccc"}
                                                    style={{
                                                        height: "100%", width: 1, marginRight: 42, marginLeft: 48, backgroundColor: "#ccc"
                                                    }}
                                                />

                                                {this.rightContainer()}

                                            </View>
                                        </View>
                                    </LargeScreen>
                                    <SmallScreen maxWidth={LARGE_SCREEN_WIDTH}>
                                        <View
                                            style={[
                                                Class.column,
                                                {alignSelf: "center", flex: 1}
                                            ]}
                                        >
                                            <View style={{marginTop: 56, padding: 8}}>
                                                {this.leftContainer()}

                                                {this.rightContainer()}
                                            </View>
                                        </View>
                                    </SmallScreen>
                                </View>
                            </ScrollView>
                        </View>
                    </KeyboardAvoidingView>
                    <View style={[Class.paymentRow]}>
                        <Icon
                            name={"cc-mastercard"}
                            type={"fontawesome5"}
                            style={[Class.paymentIcon]}
                        />
                        <Icon
                            name={"cc-visa"}
                            type={"fontawesome5"}
                            style={[Class.paymentIcon]}
                        />
                        <Icon
                            name={"cc-paypal"}
                            type={"fontawesome5"}
                            style={[Class.paymentIcon]}
                        />
                        <Icon
                            name={"wallet"}
                            type={"fontawesome5"}
                            style={[
                                Class.paymentIcon,
                                {marginRight: 0}
                            ]}
                        />
                    </View>
                </View>
            </RootPage>);
    }
}

const styles = StyleSheet.create({
    container        : {
        flexDirection: "row", justifyContent: "space-between",
    }, leftContainer : {
        alignSelf: "stretch", flex: 1, justifyContent: "space-between",
    }, tabs          : {
        height: 100, alignSelf: "stretch", flexDirection: "row", justifyContent: "space-between"
    }, tab1          : {
        height: 100, flex: 1, justifyContent: "center"
    }, login         : {
        color: "#121212", alignSelf: "center"
    }, tab2          : {
        height: 100, flex: 1, justifyContent: "center"
    }, register      : {
        color: "#121212", alignSelf: "center"
    }, page          : {
        alignSelf: "stretch", flex: 1, marginTop: 32
    }, rightContainer: {
        alignSelf: "stretch", flex: 1,
    }
});

const mapStateToProps = (state) => ({
    token: state.app_info.token, authUser: state.app_info.authUser, profile: state.app_info.profile, items: state.app_info.static.items, tax: state.app_info.static.tax,
});

const mapDispatchToProps = (dispatch) => ({
    setAuthUser: (authUser) => dispatch(setAuthUser(authUser)), setInitData: (initData) => dispatch(setInitData(initData)), setLoadingAndToken: (data) => dispatch(setLoadingAndToken(data)), setSnackbar: (snackData) => dispatch(setSnackbar(snackData)), setLoader: (loader) => dispatch(setLoader(loader)),
});

export default withGoogleReCaptcha(connect(mapStateToProps, mapDispatchToProps)(Checkout));

