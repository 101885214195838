import {List, Text, View} from "./Themed";
import Class from "../constants/Style";
import FontAwesomeIcon from "react-native-vector-icons/FontAwesome";
import SquareIcon from "./SquareIcon";
import * as React from "react";
import {currencyFormat} from "../libs/function";
import {connect} from "react-redux";
import {darkDescription, lightDescription} from "../constants/Colors";


class OrderItem extends React.Component<any, any>{

    render() {
        const {order_reference_id, order_total, status, order_date, color, onPress, order_status} = this.props;

        let check = order_status[status];

        return <React.Fragment>
            <List
                onPress={onPress}
                title={`#${order_reference_id}`}
                description={props=><View style={[Class.transparent]}><Text lightColor={lightDescription} darkColor={darkDescription} style={[]}>{currencyFormat(order_total)} {status} {order_date}</Text></View>}
                left={props => <SquareIcon  name={check.icon} backgroundColor={`#${check.colour}`}/>}
            />
            <View style={[Class.separator]} lightColor="#eee" darkColor="rgba(255,255,255,0.1)"/>
        </React.Fragment>
    }
}

const mapStateToProps = (state) => ({
    order_status: state.app_info.static.order_status
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(OrderItem);
