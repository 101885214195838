import {createStackNavigator} from "@react-navigation/stack";
import {ProductsParamList} from "../../types";


export const Stack = createStackNavigator<ProductsParamList>();

// export const SupportStack = createStackNavigator<SupportParamList>();

// export const ProductsStack = createStackNavigator<ProductsParamList>();

// export const OrdersStack = createStackNavigator<OrdersParamList>();

export const SupportRootStack = createStackNavigator();
export const CaseRootStack = createStackNavigator();

export const ProductRootStack = createStackNavigator();
export const ProductRootStack2 = createStackNavigator();

export const ResellerProductRootStack = createStackNavigator();
export const ResellerProductRootStack2 = createStackNavigator();

export const OrdersRootStack = createStackNavigator();
export const OrdersRootStack2 = createStackNavigator();

export const MerchantRootStack = createStackNavigator();
export const MerchantRootStack2 = createStackNavigator();

export const WalletRootStack = createStackNavigator();
export const WalletRootStack2 = createStackNavigator();

export const StoreRootStack = createStackNavigator();
export const StoreRootStack2 = createStackNavigator();

export const ProfileRootStack = createStackNavigator();
export const ProfileRootStack2 = createStackNavigator();
export const InvoiceRootStack = createStackNavigator();
export const UpdateProfileRootStack = createStackNavigator();

export const OTPRootStack = createStackNavigator();
export const OTPRootStack2 = createStackNavigator();
