import * as React from "react";
import {List, Text, View} from "./Themed";
import {currencyFormat} from "../libs/function";
import Class from "../constants/Style";
import Icon, {IosIcon} from "./Icon";
import {darkDescription, lightDescription} from "../constants/Colors";

class InvoiceItem extends React.Component<any, any>{

    render() {
        const {tax_invoice_id, invoice_total, status, onPress, invoice_date} = this.props;
        return <React.Fragment>
            <List
                title={`#${tax_invoice_id}`}
                description={props=><View style={Class.transparent}><Text lightColor={lightDescription} darkColor={darkDescription}>{status} {currencyFormat(invoice_total)} {invoice_date}</Text></View>}
                right={props => <IosIcon  name={"md-download"} style={Class.pointicon} />}
                onPress={onPress}
            />
            <View style={[Class.separator,Class.marginLeft0]} lightColor="#eee" darkColor="rgba(255,255,255,0.1)"/>
        </React.Fragment>;
    }
}

export default InvoiceItem;
