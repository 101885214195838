import * as React from "react";
import {OrdersRootStack, ProfileRootStack} from "../StackNav";
import Conversation from "../../screens/Conversation";
import SupportRequest from "../../screens/SupportRequest";
import SubCategory from "../../screens/SubCategory";
import SelectProduct from "../../screens/SelectProduct";
import CreateTicket from "../../screens/CreateTicket";
import SuccessTicket from "../../screens/SuccessTicket";
import UpdateLoginDetails from "../../screens/UpdateLoginDetails";
import Support from "../../screens/Support";
import {CommonNavigation} from "../CommonNavigation";
import ViewInvoice from "../../screens/ViewInvoice";
import Webpage from "../../screens/Webpage";
import {useWindowDimensions} from "react-native";
import {LARGE_SCREEN_WIDTH} from "../../libs/settings";
import {intercomVisibility} from "../../libs/function";


const OrdersRoot = () => {
    const dimensions = useWindowDimensions();
    const isLargeScreen = dimensions.width >= LARGE_SCREEN_WIDTH;
    intercomVisibility(false)
    return <OrdersRootStack.Navigator>
        <OrdersRootStack.Screen
            name="OrdersNav"
            options={{headerShown: false}}
        >
            {props => <CommonNavigation {...props} initialRouteName={"Orders"}/>}
        </OrdersRootStack.Screen>
        {
            !Boolean(isLargeScreen) && <React.Fragment>
                {/*<ProfileRootStack.Screen name="ViewInvoiceScreen" component={ViewInvoice}/>*/}
                {/*<ProfileRootStack.Screen name="WebPageScreen" component={Webpage}/>*/}
            </React.Fragment>
        }
    </OrdersRootStack.Navigator>
}

export default OrdersRoot;




