import React, {Component} from "react";
import {Text, View} from "./Themed";
import {Image} from "react-native";
import {FileRead, GetFileData, logData, requestGetUploadUrl, uploadFile} from "../libs/function";
import {isWeb} from "../libs/settings";
import {setLoader, setSnackbar} from "../libs/Store/actions/components";
import {connect} from "react-redux";

import Class from "../constants/Style";
import {HelperText} from "react-native-paper";

class ImageWithLabel extends Component<any, any> {

    constructor(props: any) {
        super(props);

        const {uri} = this.props;

        this.state = {uri}
    }


    requestFileUpload = async (isDoc, fieldName) => {
        const {setLoader, getUploadResponse, validateType, validateSize, maxFileSizeKB, setSnackbar} = this.props;
        const fileDataResponse = await GetFileData(isDoc);

        if (fileDataResponse.status === "success") {
            let validateTrue = true, message = "";
            if (validateType) {
                if (fileDataResponse?.file_type) {
                    validateTrue = validateType.some((type: any) => type === fileDataResponse.file_type.toString().toLowerCase())
                } else {
                    validateTrue = false
                }
                if (!validateTrue) {
                    message = "Invalid Image Type";
                    setSnackbar({
                        visible: true,
                        message: message,
                        color: "#f44336"
                    })
                    return;
                }
            }

            if (maxFileSizeKB && validateTrue) {
                let kbsise = fileDataResponse?.selectedFile?.size;
                let maxUploadSize = maxFileSizeKB * 1000;
                if (kbsise > maxUploadSize) {
                    validateTrue = false;
                    message = `Image size is more than ${maxFileSizeKB}kb`;
                    setSnackbar({
                        visible: true,
                        message: message,
                        color: "#f44336"
                    })
                }
            }


            Image.getSize(fileDataResponse.file_uri, async (width, height) => {
                if (validateSize && validateTrue) {
                    validateTrue = validateSize?.width === width && validateSize?.height === height

                    if (!validateTrue) {
                        message = "Invalid Image Size"
                    }
                }

                if (!validateTrue) {
                    setSnackbar({
                        visible: true,
                        message: message,
                        color: "#f44336"
                    })
                    return;
                }


                const responseUploadUrl = await requestGetUploadUrl(fileDataResponse);
                setLoader({show: true});
                let readFileResponse;
                if (isWeb) {
                    readFileResponse = fileDataResponse.selectedFile;
                } else {
                    readFileResponse = await FileRead(fileDataResponse.file_uri);
                }
                uploadFile({
                    url: responseUploadUrl.upload_url,
                    file_type: fileDataResponse.file_type,
                    body: readFileResponse
                }).then((response) => {
                    setLoader({show: false});
                    if (response.status === 200) {
                        this.setState({uri: responseUploadUrl.download_url})
                        getUploadResponse(responseUploadUrl)
                    }
                })
            })

        }
    }

    render() {
        const {label, style, height, width, error} = this.props;
        const {uri,} = this.state;
        return (
            <View style={Class.imageWithTextContainer} onClick={async () => {
                await this.requestFileUpload(true, "thumb")
            }}>
                <View style={Class.imageWithTextLabel}><Text>{label}</Text></View>
                <View>
                    <Image style={[Class.imageWithTextImageContainer, {height, width}]} source={{uri: uri}}/>
                    {
                        Boolean(error) &&
                        <HelperText type="error" style={[{padding: 0, paddingHorizontal: 0}]} visible={error}>
                            {error}
                        </HelperText>
                    }
                </View>
            </View>
        );
    }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    setLoader: (loader) => dispatch(setLoader(loader)),
    setSnackbar: (snackData) => dispatch(setSnackbar(snackData))
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageWithLabel);

