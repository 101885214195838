import * as React from 'react';
import {Text as DefaultText, TextInput as DefaultTextInput, View as DefaultView, Switch as DefaultSwitch} from 'react-native';
import {
    Button as PaperButton,
    Checkbox as PaperCheckBox,
    HelperText,
    List as CommonList,
    Searchbar,
    TextInput as PaperText,
    Switch as PaperSwitch
} from 'react-native-paper';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import {FontAwesome, FontAwesome5 as FA5, Ionicons} from "@expo/vector-icons";
import Class, {pickerSelectStyles} from "../constants/Style"
import RNPickerSelect from "react-native-picker-select";
import {hideKeyboard} from "../libs/function";

export function useThemeColor(
    props: { light?: string; dark?: string },
    colorName: keyof typeof Colors.light & keyof typeof Colors.dark
) {
    const theme = useColorScheme();
    const colorFromProps = props[theme];

    if (colorFromProps) {
        return colorFromProps;
    } else {
        return Colors[theme][colorName];
    }
}

type ThemeProps = {
    lightColor?: string;
    darkColor?: string;
};

export type TextProps = ThemeProps & DefaultText['props'];
export type ViewProps = ThemeProps & DefaultView['props'];
export type TextInputProps = ThemeProps & DefaultTextInput['props'];
export type SwitchProps = ThemeProps & DefaultSwitch['props'];

export function Text(props: TextProps) {
    let {style, lightColor, darkColor, ...otherProps} = props;
    if (!Boolean(lightColor)) {
        lightColor = "rgba(23,43,77,1)"
    }
    if (!Boolean(darkColor)) {
        darkColor = "rgb(255,255,255)"
    }
    const color = useThemeColor({light: lightColor, dark: darkColor}, 'text');

    return <DefaultText style={[{color}, style]} {...otherProps} />;
}

export function View(props: ViewProps) {
    let {style, lightColor, darkColor, ...otherProps} = props;
    if (!Boolean(lightColor)) {
        lightColor = "rgba(255,255,255,1)"
    }
    if (!Boolean(darkColor)) {
        darkColor = "rgb(30,30,30)"
    }
    const backgroundColor = useThemeColor({light: lightColor, dark: darkColor}, 'background');

    return <DefaultView style={[{backgroundColor}, style]} {...otherProps} />;
}

export function TextInput(props: ViewProps) {
    const {style, lightColor, darkColor, ...otherProps} = props;
    const backgroundColor = useThemeColor({light: lightColor, dark: darkColor}, 'background');
    const color = useThemeColor({light: lightColor, dark: darkColor}, 'text');
    return <DefaultTextInput placeholderTextColor={color} style={[{backgroundColor, color}, style]} {...otherProps} />;
}

export const TextInputPaper = React.forwardRef((props, ref) => {
    const {style, lightColor, darkColor, error, info, passref, textStyle, leftAffixText, ...otherProps} = props;
    const backgroundColor = useThemeColor({light: lightColor, dark: darkColor}, 'background');
    const color = useThemeColor({light: lightColor, dark: darkColor}, 'text');
    const errorColor = useThemeColor({light: Colors.light.errorColor, dark: Colors.dark.errorColor}, 'errorColor');
    const left = Boolean(leftAffixText) && <PaperText.Affix text={leftAffixText} theme={{colors: {text: color}}}/>
    return <View style={style}>
        <PaperText
            ref={ref}
            left={left}
            mode={"outlined"}
            theme={{colors: {text: color}}}
            error={Boolean(error)}
            style={[{backgroundColor}, {
                padding: 0, paddingHorizontal: 0,
                paddingVertical: 0
            }, textStyle]}
            {...otherProps}
            placeholder={""}
        />
        {
            Boolean(error) && <HelperText type="error" style={[{padding: 0, paddingHorizontal: 0}]} visible={error}>
                {error}
            </HelperText>
        }
        {
            Boolean(info) && <HelperText style={[{padding: 0, paddingHorizontal: 0}]} type="info" visible={info}>
                {info}
            </HelperText>
        }
    </View>
});

export function IconDefault(props: ViewProps) {
    let {lightColor, darkColor, ...otherProps} = props;
    if (!Boolean(lightColor)) {
        lightColor = Colors.light.primary;
    }
    if (!Boolean(darkColor)) {
        darkColor = Colors.dark.primary;
    }
    const color = useThemeColor({light: lightColor, dark: darkColor}, 'background');
    if (props.type === "fontawesome5") {
        return <FA5 color={color} {...otherProps} />
    }
    return <FontAwesome color={color} {...otherProps} />
}

export function IosIcon(props: ViewProps) {
    let {lightColor, darkColor, ...otherProps} = props;
    if (!Boolean(lightColor)) {
        lightColor = Colors.light.primary;
    }
    if (!Boolean(darkColor)) {
        darkColor = Colors.dark.primary;
    }
    const color = useThemeColor({light: lightColor, dark: darkColor}, 'background');
    return <Ionicons color={color} {...otherProps} />
}

export function Picker(props: ViewProps) {
    const {style, lightColor, darkColor, error, info, ...otherProps} = props;
    const errorColor = useThemeColor({light: Colors.light.errorColor, dark: Colors.dark.errorColor}, 'color');
    return <View>
        <RNPickerSelect
            style={pickerSelectStyles}
            {...otherProps}
        />

        {
            Boolean(error) && <HelperText type="error" style={[{padding: 0, paddingHorizontal: 0}]} visible={error}>
                {error}
            </HelperText>
        }
        {
            Boolean(info) && <HelperText style={[{padding: 0, paddingHorizontal: 0}]} type="info" visible={info}>
                {info}
            </HelperText>
        }
    </View>
}

export function CheckBox(props: ViewProps) {
    const {style, ...otherProps} = props;
    const color = useThemeColor({light: Colors.light.primary, dark: Colors.dark.primary}, 'color');
    return <PaperCheckBox color={color}  {...otherProps}  />;
}

export function SearchText(props: TextInputProps) {
    const {style, ...otherProps} = props;
    const backgroundColor = useThemeColor({light: "#f5f5f5", dark: '#121212'}, 'background');
    const text = useThemeColor({light: "", dark: ""}, 'text');
    return <Searchbar
        {...otherProps}
        theme={{colors: {text}}}
        style={[Class.search, {backgroundColor}, style]}
    />
}

export function ContainedButton(props: ViewProps) {
    const {buttonStyle, style, children, buttonAlign, onPress, mode, ...otherProps} = props;
    let align;
    if (buttonAlign === "right") {
        align = Class.alignItemStartEnd;
    } else if (buttonAlign === "left") {
        align = Class.alignItemFlexEnd;
    }
    return <View style={[style, align]}>
        <PaperButton
            onPress={() => {
                if (Boolean(onPress)) {
                    onPress();
                    hideKeyboard();
                }
            }}
            {...otherProps}
            mode={mode || "contained"}
            style={[{elevation: 0}, buttonStyle]}
            uppercase={false}
        >
            {children}
        </PaperButton>
    </View>
}

export function TextButton(props: any) {
    const {buttonStyle, lightColor, darkColor, style, labelStyle=[], children, onPress, ...otherProps} = props;
    const color = useThemeColor({light: lightColor, dark: darkColor}, 'text');
    return <View style={[{backgroundColor: "transparent"}, style]}>
        <PaperButton
            {...otherProps}
            onPress={() => {
                if (Boolean(onPress)) {
                    onPress();
                    hideKeyboard();
                }
            }}
            mode="text"
            labelStyle={[{color},...labelStyle]}
            style={[{elevation: 0}, buttonStyle]}
            uppercase={false}
        >
            {children}
        </PaperButton>
    </View>
}

export function List(props: any) {
    let {lightColor, darkColor, ...otherProps} = props;
    const text = useThemeColor({light: lightColor, dark: darkColor}, 'text');
    return <CommonList.Item
        theme={{colors: {text}}}
        {...otherProps}
    />
}

export function TextAffix(props: ViewProps) {
    return <PaperText.Affix  {...props} />
}

export function Chip({children, style, ...props}: ViewProps) {
    return <View {...props} style={[Class.chip, style]}><Text style={[Class.chipText]}>{children}</Text></View>
}

export function Switch({style, ...props}: SwitchProps) {
    return  <PaperSwitch color={"#3174de"} style={[style]} {...props}/>
}

