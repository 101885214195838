import * as React from "react";
import {Text, View} from "./Themed";
import Icon from "./Icon";
import Class from "../constants/Style"
import {TouchableOpacity} from "react-native";

class DropdownMenu extends React.Component<any, any> {

    render() {
        const {label, onPress} = this.props;
        return (
            <TouchableOpacity
                onPress={onPress}
                style={Class.headerDropView}>
                <Text style={[Class.headerStatusText, Class.textCapitalize]}>{label}</Text>
                <Icon name={"angle-down"} size={20}/>
            </TouchableOpacity>
        );
    }
}

export default DropdownMenu;
