import React, {Component} from "react";
import {KeyboardAvoidingView, View} from "react-native";
import {connect} from "react-redux";
import requestApi, {actions, methods, SUCCESS} from "../libs/ServerRequest";
import {setInitData} from "../libs/Store/actions/app_info";
import RootPage from "../components/RootPage";
import Class from "../constants/Style";
import {ContainedButton, TextInputPaper} from "../components/Themed";
import {headerHeight, isIOS, LARGE_SCREEN_WIDTH} from "../libs/settings";
import {validRequired} from "../libs/validate";
import {dynamicSort, getSmallWidth, isEmpty} from "../libs/function";
import {LargeScreen, SmallScreen} from "../components/ScreenSize";
import DhruScrollView from "../components/DhruScrollView";
import RNPickerSelect from "react-native-picker-select";
import {country as countryList} from "../libs/static";


class UpdateProfile extends Component<any, any> {

    constructor(props: any) {
        super(props);
        const {initdata} = props;
        let profile: any = initdata.profile;
        if (Boolean(profile?.country) && !Boolean(profile?.code)) {
            let code = countryList[profile?.country]?.d;
            profile.code = code;
        }

        this.state = {
            profile,
            error: {}
        }

        this.inputRefs = [
            React.createRef(),
            React.createRef(),
            React.createRef(),
            React.createRef(),
            React.createRef(),
            React.createRef(),
            React.createRef(),
        ]
    }

    validate = () => {
        const {profile, isContact} = this.state;
        const {first_name, last_name, company_name, address1, address2, city, state, postcode} = profile;
        let error = {};
        error = {...error, ...validRequired(error, "first_name", first_name, "Please enter first name")};
        error = {...error, ...validRequired(error, "last_name", last_name, "Please enter last name")};
        error = {...error, ...validRequired(error, "company_name", company_name, "Please enter company name")};
        error = {...error, ...validRequired(error, "address1", address1, "Please enter address 1")};
        error = {...error, ...validRequired(error, "city", city, "Please enter city")};
        error = {...error, ...validRequired(error, "state", state, "Please enter state")};
        error = {...error, ...validRequired(error, "postcode", postcode, "Please enter postal code")};
        this.setState({error})
        return isEmpty(error)
    }

    requestUpdateProfile = () => {
        if (this.validate()) {
            const {profile} = this.state;
            const {setInitData, initdata, navigation} = this.props;
            requestApi({
                method: methods.put,
                action: actions.profile,
                body: profile,
                showsucess: true
            }).then((response) => {
                if (response.status === SUCCESS) {
                    setInitData({...initdata, profile: response.data});
                    if (getSmallWidth()) {
                        navigation.goBack();
                    }
                }
            })
        }
    }

    setProfileState = (value) => {
        const {profile} = this.state;
        this.setState({
            profile: {
                ...profile,
                ...value
            }
        })
    }

    render() {
        const {navigation} = this.props;
        const {
            error,
            profile: {
                first_name,
                last_name,
                company_name,
                address1,
                address2,
                city,
                state,
                postcode,
                group,
                seller_name,
                seller_website,
                country,
                mobile_number,
                code
            }
        } = this.state;
        return (
            <RootPage navigation={navigation} noBack={getSmallWidth()} headerTitle={"Update Profile"}>
                <KeyboardAvoidingView
                    keyboardVerticalOffset={headerHeight}
                    style={Class.container} behavior={isIOS ? "padding" : ""}>
                    <View style={[Class.container]}>
                        <DhruScrollView contentContainerStyle={Class.paddingLeftRight22}>
                            <View style={[Class.maxFormWidth]}>

                                <TextInputPaper
                                    value={first_name}
                                    onChangeText={(first_name) => this.setProfileState({first_name})}
                                    label="First Name"
                                    style={[Class.marginBottom16, Class.marginTop16]}
                                    error={error.first_name}
                                    autoCompleteType={"name"}
                                    textContentType={"name"}
                                    keyboardType={"default"}
                                    returnKeyType="next"
                                    onSubmitEditing={() => this.inputRefs[0].focus()}
                                />
                                <TextInputPaper
                                    value={last_name}
                                    onChangeText={(last_name) => this.setProfileState({last_name})}
                                    label="Last Name"
                                    style={Class.marginBottom16}
                                    error={error.last_name}
                                    autoCompleteType={"name"}
                                    textContentType={"familyName"}
                                    keyboardType={"default"}
                                    returnKeyType="next"
                                    ref={ref => this.inputRefs[0] = ref}
                                    onSubmitEditing={() => this.inputRefs[1].focus()}
                                />
                                <TextInputPaper
                                    value={company_name}
                                    onChangeText={(company_name) => this.setProfileState({company_name})}
                                    label="Company Name"
                                    style={Class.marginBottom16}
                                    error={error.company_name}
                                    autoCompleteType={"name"}
                                    textContentType={"organizationName"}
                                    keyboardType={"default"}
                                    returnKeyType="next"
                                    ref={ref => this.inputRefs[1] = ref}
                                    onSubmitEditing={() => this.inputRefs[2].focus()}
                                />
                                {/*<RNPickerSelect*/}
                                {/*    placeholder={{*/}
                                {/*        label: 'Select Country',*/}
                                {/*    }}*/}
                                {/*    value={country}*/}
                                {/*    itemKey={"countrycode"}*/}
                                {/*    onValueChange={(country) => {*/}
                                {/*        let code = countryList[country]?.d;*/}
                                {/*        this.setProfileState({country, code})*/}
                                {/*    }}*/}
                                {/*    style={{*/}
                                {/*        inputWeb: {*/}
                                {/*            paddingTop: 4,*/}
                                {/*            height: 48*/}
                                {/*        }*/}
                                {/*    }}*/}
                                {/*    useNativeAndroidPickerStyle={false}*/}
                                {/*    items={Object.keys(countryList).map((countrycode: any) => {*/}
                                {/*        let country = countryList[countrycode];*/}
                                {/*        return {countrycode, ...country, value: countrycode, label: country.n}*/}
                                {/*    }).sort(dynamicSort("countrycode"))}*/}
                                {/*/>*/}
                                <TextInputPaper
                                    label={"Mobile"}
                                    placeholder={"Mobile"}
                                    value={mobile_number}
                                    error={error.mobile_number}
                                    autoCompleteType={"tel"}
                                    leftAffixText={`+${code} `}
                                    keyboardType={"phone-pad"}
                                    textContentType={"telephoneNumber"}
                                    onChangeText={(mobile_number: any) => this.setProfileState({mobile_number})}
                                    style={Class.textbox}
                                    returnKeyType='done'
                                    ref={ref => this.inputRefs[4] = ref}
                                    onSubmitEditing={() => this.inputRefs[5].focus()}
                                />
                                <TextInputPaper
                                    value={address1}
                                    onChangeText={(address1) => this.setProfileState({address1})}
                                    label="Address 1"
                                    style={Class.marginBottom16}
                                    error={error.address1}
                                    autoCompleteType={"street-address"}
                                    textContentType={"streetAddressLine1"}
                                    keyboardType={"default"}
                                    returnKeyType="next"
                                    ref={ref => this.inputRefs[2] = ref}
                                    onSubmitEditing={() => this.inputRefs[3].focus()}
                                />
                                <TextInputPaper
                                    value={address2}
                                    onChangeText={(address2) => this.setProfileState({address2})}
                                    label="Address 2"
                                    style={Class.marginBottom16}
                                    error={error.address2}
                                    autoCompleteType={"street-address"}
                                    textContentType={"streetAddressLine2"}
                                    keyboardType={"default"}
                                    returnKeyType="next"
                                    ref={ref => this.inputRefs[3] = ref}
                                    onSubmitEditing={() => this.inputRefs[4].focus()}
                                />
                                <TextInputPaper
                                    value={city}
                                    onChangeText={(city) => this.setProfileState({city})}
                                    label="City"
                                    style={Class.marginBottom16}
                                    error={error.city}
                                    autoCompleteType={"street-address"}
                                    textContentType={"addressCity"}
                                    keyboardType={"default"}
                                    returnKeyType="next"
                                    ref={ref => this.inputRefs[4] = ref}
                                    onSubmitEditing={() => this.inputRefs[5].focus()}
                                />
                                <TextInputPaper
                                    value={state}
                                    onChangeText={(state) => this.setProfileState({state})}
                                    label="State"
                                    style={Class.marginBottom16}
                                    error={error.state}
                                    autoCompleteType={"street-address"}
                                    textContentType={"addressState"}
                                    keyboardType={"default"}
                                    returnKeyType="next"
                                    ref={ref => this.inputRefs[5] = ref}
                                    onSubmitEditing={() => this.inputRefs[6].focus()}
                                />
                                <TextInputPaper
                                    value={postcode}
                                    onChangeText={(postcode) => this.setProfileState({postcode})}
                                    label="Postcode"
                                    style={Class.marginBottom16}
                                    error={error.postcode}
                                    autoCompleteType={"postal-code"}
                                    textContentType={"postalCode"}
                                    keyboardType={"default"}
                                    returnKeyType="done"
                                    ref={ref => this.inputRefs[6] = ref}
                                    onSubmitEditing={() => this.requestUpdateProfile()}
                                />

                                {
                                    group.toString().toLowerCase() === "reseller" && <>
                                        <TextInputPaper
                                            value={seller_name}
                                            onChangeText={(seller_name) => this.setProfileState({seller_name})}
                                            label="Seller Name"
                                            style={Class.marginBottom16}
                                            autoCompleteType={"name"}
                                            textContentType={"name"}
                                            keyboardType={"default"}
                                            returnKeyType="done"
                                            ref={ref => this.inputRefs[6] = ref}
                                        />
                                        <TextInputPaper
                                            value={seller_website}
                                            onChangeText={(seller_website) => this.setProfileState({seller_website})}
                                            label="Seller Web-site"
                                            style={Class.marginBottom16}
                                            autoCompleteType={"name"}
                                            textContentType={"name"}
                                            keyboardType={"default"}
                                            returnKeyType="done"
                                            ref={ref => this.inputRefs[6] = ref}
                                        />
                                    </>
                                }
                                <LargeScreen minWidth={LARGE_SCREEN_WIDTH}>
                                    <ContainedButton
                                        style={[Class.maxFormWidth]}
                                        onPress={() => this.requestUpdateProfile()}
                                    >
                                        Update
                                    </ContainedButton>
                                </LargeScreen>
                            </View>
                        </DhruScrollView>
                        <SmallScreen maxWidth={LARGE_SCREEN_WIDTH}>
                            <View style={[Class.bottomView]}>
                                <ContainedButton
                                    style={[Class.maxFormWidth]}
                                    onPress={() => this.requestUpdateProfile()}
                                >
                                    Update
                                </ContainedButton>
                            </View>
                        </SmallScreen>
                    </View>
                </KeyboardAvoidingView>
            </RootPage>
        );
    }
}


const mapStateToProps = (state) => ({
    initdata: state.app_info
});

const mapDispatchToProps = (dispatch) => ({
    setInitData: (initData) => dispatch(setInitData(initData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProfile);
