import requestApi, {actions, methods} from "./index";

export const joinWorkspace = (method?: any, queryString?: any, body?: any) => {
    return requestApi({
        method: method || methods.get,
        action: actions.joinworkspace,
        queryString,
        body
    })
}

