import {DarkTheme, DefaultTheme, NavigationContainer, useLinking} from '@react-navigation/native';
import {ColorSchemeName, useWindowDimensions} from 'react-native';
import * as React from 'react';
// import BottomTabNavigator, {SupportStack} from './BottomTabNavigator';
import {linkingLarge, linkingSmall} from './LinkingConfiguration';
import {connect} from "react-redux";
import {setAuthUser, setInitData, setLoadingAndToken} from "../libs/Store/actions/app_info";
import requestApi, {actions, methods, SUCCESS} from "../libs/ServerRequest";
import Spinner from "../components/Spinner";
import {setLoader} from "../libs/Store/actions/components";
import {intercomVisibility, retrieveData, STORAGE_KEY} from "../libs/function";
import {isWeb, LARGE_SCREEN_WIDTH} from "../libs/settings";
import {enableScreens} from 'react-native-screens';
import {
    MerchantRootStack,
    ProductRootStack,
    ProductRootStack2,
    ProfileRootStack,
    Stack,
    StoreRootStack,
    SupportRootStack,
    WalletRootStack
} from './StackNav';
import DefaultSpinner from "../components/DefaultSpinner";

// LAZY PAGE
import Login from "../screens/Login";
// const Login = React.lazy(()=>import(`../screens/Login`));
import ForgotPassword from "../screens/ForgotPassword";
// const ForgotPassword = React.lazy(()=>import(`../screens/ForgotPassword`));
import Register from "../screens/Register";
// const Register = React.lazy(()=>import(`../screens/Register`));
import ResetPassword from "../screens/ResetPassword";
// const ResetPassword = React.lazy(()=>import(`../screens/ResetPassword`));
import NotFoundScreen from '../screens/NotFoundScreen';
// const NotFoundScreen = React.lazy(()=>import(`../screens/NotFoundScreen`));
import VerifyEmail from "../screens/VerifyEmail";
// const VerifyEmail = React.lazy(()=>import(`../screens/VerifyEmail`));
import SupportRoot from "./AllRoot/SupportRoot";
// const SupportRoot = React.lazy(() => import('./AllRoot/SupportRoot'));
import ProductRoot from "./AllRoot/ProductRoot";
// const ProductRoot = React.lazy(() => import('./AllRoot/ProductRoot'));
import OrdersRoot from "./AllRoot/OrdersRoot";
// const OrdersRoot = React.lazy(() => import('./AllRoot/OrdersRoot'));
import ProfileRoot from "./AllRoot/ProfileRoot";
// const ProfileRoot = React.lazy(() => import('./AllRoot/ProfileRoot'));
import Checkout from "../screens/Checkout";
import Conversation from "../screens/Conversation";
import SupportRequest from "../screens/SupportRequest";
import SubCategory from "../screens/SubCategory";
import Products from "../screens/Products";
import CreateTicket from "../screens/CreateTicket";
import SuccessTicket from "../screens/SuccessTicket";
import UpdateLoginDetails from "../screens/UpdateLoginDetails";
import AddProduct from "../screens/AddProduct";
import ViewProduct from "../screens/ViewProduct";
import Buyaddons from "../screens/BuyAddon";
import ViewInvoice from "../screens/ViewInvoice";
import Webpage from "../screens/Webpage";
import UpdateProfile from "../screens/UpdateProfile";
import Invoices from "../screens/Invoices";
import ChangePassword from "../screens/ChangePassword";
import Colors, {largeDarkHeaderColor, largeLightHeaderColor} from "../constants/Colors";
import {useThemeColor} from "../components/Themed";
import CameraView from "../screens/CameraView";
import MoveLicense from "../screens/MoveLicense";
import Merchant from '../screens/Merchant';
import StoreItemForm from "../screens/StoreItemForm";
import MerchantRoot from "./AllRoot/MerchantRoot";
import StoreRoot from "./AllRoot/StoreRoot";
import WalletRoot from "./AllRoot/WalletRoot";
import Wallet from "../screens/Wallet";
import JoinWorkspace from '../screens/JoinWorkspace';
import OTPServiceRoot from "./AllRoot/OTPServiceRoot";
import OTPServiceScreen from "../screens/OTPServiceScreen";
import LicenseKey from "../screens/LicenseKey";
import TransferCredit from "../screens/TransferCredit";
import VerifyOTP from "../screens/VerifyOTP";
import VerifyWhatsApp from "../screens/VerifyWhatsApp";
import Dialog from "../components/Dialog";
// const Checkout = React.lazy(()=>import(`../screens/Checkout`))


enableScreens();

const DarkThemeStyle = {
    ...DarkTheme,
    dark: true,
    colors: {
        ...DarkTheme.colors,
        primary: Colors.dark.primary,
        background: Colors.dark.background,
        card: Colors.dark.background,
        text: Colors.dark.text,
    },
};


// If you are not familiar with React Navigation, we recommend going through the
// "Fundamentals" guide: https://reactnavigation.org/docs/getting-started
function Navigation({
                        colorScheme,
                        token,
                        loading,
                        defaultRedirect,
                        email_verified,
                        whatsapp_verified,
                        setLoadingAndToken,
                        setLoader,
                        setInitData,
                        setAuthUser,
                        profile,
                        products
                    }: {
    colorScheme: ColorSchemeName,
}) {

    const ref = React.useRef();

    const dimensions = useWindowDimensions();
    const isLargeScreen = dimensions.width >= LARGE_SCREEN_WIDTH;
    const linking = isLargeScreen ? linkingLarge : linkingSmall;
    const {getInitialState} = useLinking(ref, linking);

    const [isReady, setIsReady] = React.useState(false);
    const [initialState, setInitialState] = React.useState();

    React.useEffect(() => {
        getInitialState()
            .catch(() => {
            })
            .then(state => {
                if (state !== undefined) {
                    setInitialState(state);
                }
                setIsReady(true);
            });
        const bootstrapAsync = async () => {
            let userToken: any;
            try {
                userToken = await retrieveData(STORAGE_KEY.USER_DATA);
            } catch (e) {
                // Restoring token failed
            }
            // After restoring token, we may need to validate it in production apps

            // This will switch to the App screen or Auth screen and this loading
            // screen will be unmounted and thrown away.
            if (Boolean(userToken)) {
                setAuthUser(userToken)
                requestApi({
                    method: methods.get,
                    action: actions.logininit,
                }).then((response) => {
                    if (response.status === SUCCESS) {
                        setInitData(response.data);
                        setLoadingAndToken({loading: false, token: userToken.token});
                    }
                })
            } else {
                requestApi({
                    method: methods.get,
                    action: actions.init,
                }).then((response) => {
                    if (response.status === SUCCESS) {
                        setInitData(response.data);

                        let defaultRedirect = isWeb ? window.location.href : "";
                        if (defaultRedirect.includes("login") ||
                            defaultRedirect.includes("register") ||
                            defaultRedirect.includes("join-workspace") ||
                            defaultRedirect.includes("authorize")) {
                            defaultRedirect = "";
                        }

                        setLoadingAndToken({
                            loading: false,
                            token: "",
                            defaultRedirect
                        });
                    }
                }).then((e: any) => {
                    setLoadingAndToken({
                        loading: false,
                        token: "",
                        defaultRedirect: "login"
                    });
                })
            }
        };
        bootstrapAsync();
    }, [getInitialState]);

    if (!isReady) {
        return null;
    }

    if (Boolean(loading)) {
        return <Spinner/>
    }


    return (

        <NavigationContainer
            fallback={<DefaultSpinner/>}
            initialState={initialState} ref={ref}
            theme={colorScheme === 'dark' ? DarkThemeStyle : DefaultTheme}>
            <RootNavigator profile={profile}
                           products={products}
                           userToken={token}
                           email_verified={email_verified}
                           whatsapp_verified={whatsapp_verified}
                           defaultRedirect={defaultRedirect}/>
            <Spinner/>
            <Dialog/>
        </NavigationContainer>

    );
}

const mapStateToProps = (state: any) => ({
    token: state.app_info.token,
    defaultRedirect: state.app_info.defaultRedirect,
    email_verified: state.app_info.authUser && state.app_info.authUser.email_verified,
    whatsapp_verified: state.app_info.authUser && state.app_info.authUser.whatsapp_verified,
    profile: state.app_info.profile && state.app_info.profile,
    products: state.app_info.products,
    loading: state.app_info.loading
});

const mapDispatchToProps = (dispatch: any) => ({
    setLoadingAndToken: (data: any) => dispatch(setLoadingAndToken(data)),
    setLoader: (data: any) => dispatch(setLoader(data)),
    setInitData: (data: any) => dispatch(setInitData(data)),
    setAuthUser: (data: any) => dispatch(setAuthUser(data)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Navigation)

// export const Stack = createStackNavigator<RootStackParamList>();


function RootNavigator(props) {
    const {userToken, email_verified, whatsapp_verified, defaultRedirect, profile, products} = props;
    const dimensions = useWindowDimensions();
    const isLargeScreen = dimensions.width >= LARGE_SCREEN_WIDTH;
    const headerTintColor = useThemeColor({light: largeLightHeaderColor, dark: largeDarkHeaderColor}, 'text');
    if (isWeb && Boolean(userToken) && Boolean(profile)) {
        let active_prodcuts = 0;
        if (Boolean(products)) {
            active_prodcuts = products.length
        }

        // console.log("profile", profile);

        // window.fcWidget.user.setProperties({
        //     firstName: profile?.first_name,
        //     email: profile.email,
        //     cf_group: profile?.group
        // });

        if (window?.fwcrm && window?.fwcrm?.on){
            window.fwcrm.on("user:created", function () {

                window?.fcWidget?.setExternalId(profile?.clientid);

                window?.fcWidget?.user.setFirstName(profile?.first_name);

                window?.fcWidget?.user?.setEmail(profile.email);
                window?.fcWidget?.user?.setProperties({
                    cf_group: profile?.group                 // meta property 1
                })
            });
        }




        // window.Intercom("boot", {
        //     app_id: "d97pzwkh",
        //     name: profile.full_name,
        //     email: profile.email,
        //     active_prodcuts,
        //     is_cp_user: "Yes",
        //     company_name: profile.company_name,
        //     cp: `https://cp.dhru.com/submit/intercom/${profile.clientid}`,
        //     user_hash: profile.user_hash,
        //     hide_default_launcher: false
        // });
    }
    return (
        <Stack.Navigator
            screenOptions={{headerShown: true, headerTintColor}}>
            {
                !Boolean(userToken) ?
                    <React.Fragment>
                        <Stack.Screen name="Login" component={Login} options={{headerShown: false}}/>
                        <Stack.Screen name="VerifyOTP" component={VerifyOTP}
                                      options={{headerShown: false}}/>
                        <Stack.Screen name="ForgotPassword" component={ForgotPassword}
                                      options={{headerShown: false}}/>
                        <Stack.Screen name="ResetPassword" component={ResetPassword}
                                      options={{headerShown: false}}/>
                        <Stack.Screen name="Register" component={Register}
                                      options={{headerShown: false}}/>
                    </React.Fragment> :
                    <React.Fragment>
                        {
                            !Boolean(email_verified) ?
                                <Stack.Screen name="VerifyEmail" component={VerifyEmail}
                                              options={{headerShown: false}}/> :
                                !Boolean(whatsapp_verified) ?
                                    <Stack.Screen name="VerifyWhatsApp"
                                                  component={VerifyWhatsApp}
                                                  options={{headerShown: false}}/> :

                                    <React.Fragment>
                                        <Stack.Screen
                                            options={{
                                                headerShown: false,
                                                stackAnimation: "none",
                                                animationEnabled: false
                                            }}
                                            name={"SupportRoot"}
                                            component={SupportRoot}/>
                                        <Stack.Screen
                                            options={{
                                                headerShown: false,
                                                stackAnimation: "none",
                                                animationEnabled: false
                                            }}
                                            name={"ProductRoot"}>
                                            {(props: any) => <ProductRoot {...props} nextScreen={"ProductNav"}
                                                                          nextInitRoot={"Products"}/>}
                                        </Stack.Screen>
                                        <Stack.Screen
                                            options={{
                                                headerShown: false,
                                                stackAnimation: "none",
                                                animationEnabled: false
                                            }}
                                            name={"WorkspaceRoot"}>
                                            {(props: any) => <ProductRoot {...props} nextScreen={"WorkspaceNav"}
                                                                          nextInitRoot={"Workspaces"}/>}
                                        </Stack.Screen>
                                        <Stack.Screen
                                            options={{
                                                headerShown: false,
                                                stackAnimation: "none",
                                                animationEnabled: false
                                            }}
                                            name={"ResellerWorkspaceRoot"}>
                                            {(props: any) => <ProductRoot {...props} nextScreen={"ResellerWorkspaceNav"}
                                                                          nextInitRoot={"ResellerWorkspaces"}/>}
                                        </Stack.Screen>
                                        <Stack.Screen
                                            options={{
                                                headerShown: false,
                                                stackAnimation: "none",
                                                animationEnabled: false
                                            }}
                                            name={"OrdersRoot"}
                                            component={OrdersRoot}/>
                                        <Stack.Screen
                                            options={{
                                                headerShown: false,
                                                stackAnimation: "none",
                                                animationEnabled: false
                                            }}
                                            name={"MerchantRoot"}
                                            component={MerchantRoot}/>
                                        <Stack.Screen
                                            options={{
                                                headerShown: false,
                                                stackAnimation: "none",
                                                animationEnabled: false
                                            }}
                                            name={"WalletRoot"}
                                            component={WalletRoot}/>
                                        <Stack.Screen
                                            options={{
                                                headerShown: false,
                                                stackAnimation: "none",
                                                animationEnabled: false
                                            }}
                                            name={"StoreRoot"}
                                            component={StoreRoot}/>
                                        <Stack.Screen
                                            options={{
                                                headerShown: false,
                                                stackAnimation: "none",
                                                animationEnabled: false
                                            }}
                                            name={"ProfileRoot"}
                                            component={ProfileRoot}/>
                                        <Stack.Screen
                                            options={{
                                                headerShown: false,
                                                stackAnimation: "none",
                                                animationEnabled: false
                                            }}
                                            name={"OTPServiceRoot"}
                                            component={OTPServiceRoot}/>
                                        {
                                            !Boolean(isLargeScreen) && <React.Fragment>
                                                <SupportRootStack.Screen
                                                    name="ConversationScreen"
                                                >
                                                    {props => {
                                                        intercomVisibility(true)
                                                        return <Conversation {...props}/>
                                                    }}
                                                </SupportRootStack.Screen>
                                                <SupportRootStack.Screen name="CategoryScreen" component={SupportRequest}/>
                                                <SupportRootStack.Screen name="SubcategoryScreen" component={SubCategory}/>
                                                <SupportRootStack.Screen name="SelectProductScreen" component={Products}/>
                                                <SupportRootStack.Screen name="CreateTicketScreen"
                                                                         component={CreateTicket}/>
                                                <SupportRootStack.Screen name="SuccessTicketScreen"
                                                                         component={SuccessTicket}
                                                                         options={{headerShown: false}}/>
                                                <SupportRootStack.Screen name="UpdateLoginDetailsScreen"
                                                                         component={UpdateLoginDetails}/>
                                                <SupportRootStack.Screen name="CameraScreen" component={CameraView}
                                                                         options={{headerShown: false}}/>

                                                <ProductRootStack.Screen name="AddProductScreen" component={AddProduct}/>
                                                <ProductRootStack.Screen name="ViewProductScreen" component={ViewProduct}/>
                                                <ProductRootStack.Screen name="BuyAddonScreen" component={Buyaddons}/>
                                                <ProductRootStack2.Screen name="LicenseKeyScreen" component={LicenseKey}/>
                                                <ProductRootStack.Screen name="MoveLicenseScreen" component={MoveLicense}
                                                                         options={{
                                                                             stackPresentation: "modal"
                                                                         }}/>
                                                <MerchantRootStack.Screen name="StoreItemScreen" component={StoreItemForm}/>
                                                <MerchantRootStack.Screen name="TransferCreditScreen"
                                                                          component={TransferCredit}/>
                                                <ProfileRootStack.Screen name="ViewInvoiceScreen" component={ViewInvoice}/>
                                                <ProfileRootStack.Screen name="WebPageScreen" component={Webpage}/>
                                                <WalletRootStack.Screen name="WalletScreen" component={Wallet}/>


                                                <StoreRootStack.Screen name={"StoreScreen"}>
                                                    {merchantProps => <Merchant {...merchantProps} from={"Store"}/>}
                                                </StoreRootStack.Screen>


                                                <ProfileRootStack.Screen name="OTPServices"
                                                                         component={OTPServiceScreen}/>


                                                <ProfileRootStack.Screen name="UpdateProfileScreen"
                                                                         component={UpdateProfile}/>
                                                <ProfileRootStack.Screen name="InvoicesScreen" component={Invoices}/>

                                                <ProfileRootStack.Screen name="ChangePasswordScreen"
                                                                         component={ChangePassword}/>
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                        }
                    </React.Fragment>
            }
            <Stack.Screen name="JoinWorkspace" component={JoinWorkspace}
                          options={{headerShown: false}}/>
            <Stack.Screen name="Authorize" component={Login}
                          options={{headerShown: false}}/>
            <Stack.Screen name={"Checkout"} component={Checkout}/>
            <Stack.Screen name={"Error"} component={NotFoundScreen} options={{title: "Error"}}/>
            <Stack.Screen name="NotFound" component={NotFoundScreen} options={{title: '404!'}}/>
        </Stack.Navigator>
    );


}
