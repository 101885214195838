import {TouchableOpacity, View} from "react-native";
import {methods} from "../libs/ServerRequest";
import {Text} from "react-native-paper";
import Class from "../constants/Style";
import * as React from "react";

export default function ({caption, onPress, style}){
    return  <TouchableOpacity onPress={onPress} style={style}>
        <Text style={Class.linkStyle}>{caption}</Text>
    </TouchableOpacity>
}
