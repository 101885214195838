import React, {Component} from "react";
import {ScrollView,} from "react-native";
import AddOnItem from "../components/AddOnItem";
import {currencyFormat, getTaxAmount} from "../libs/function";
import {ContainedButton, View} from "../components/Themed";
import Class from "../constants/Style";
import RootPage from "../components/RootPage";
import TitleAndSubtitle from "../components/TitleAndSubtitle";
import BillItem from "../components/BillItem";
import {withGoogleReCaptcha} from "react-google-recaptcha-v3";
import {connect} from "react-redux";
import requestApi, {actions, methods, SUCCESS} from "../libs/ServerRequest";
import DhruScrollView from "../components/DhruScrollView";

class Buyaddons extends Component {

    constructor(props) {
        super(props);

        const {route, navigation, servers_list} = props;
        let domain;
        if (Boolean(route.params.domain)){
            domain= route.params.domain;
        }
        this.state = {
            product_id: route.params.product_id,
            domain,
            available_for_purchase: route.params.addons,
            selectedAddons: []
        }
    }

    getAmount = () => {
        let amount = 0;
        const {available_for_purchase} = this.state;
        available_for_purchase.forEach(({price, active}) => {
            if (Boolean(active)) {
                amount += parseFloat(price);
            }
        })
        return currencyFormat(amount);
    }

    onAddAddon = (newAddon) => {
        const {selectedAddons} = this.state;
        let setNew = [];
        let check = selectedAddons.find(oa => oa === newAddon)
        if (Boolean(check)) {
            setNew = selectedAddons.filter((a) => a !== newAddon)

        } else {
            setNew = [...selectedAddons, newAddon]
        }
        this.setState({selectedAddons: setNew})
    }

    calculate = () => {
        const {tax, profile} = this.props;
        const {available_for_purchase, domain} = this.state;
        let subtotal = 0, total = 0, itemTax = [], country = "US";

        if (Boolean(domain)){
            if (Boolean(profile) && Boolean(profile.country)) {
                country = profile.country;
            }
            available_for_purchase.forEach((aid) => {
                if (Boolean(aid.active)) {
                    subtotal += parseFloat(aid.price);
                }
            })
            const defaultTax = tax.filter((t) => t.name === "Transaction tax")[0];
            defaultTax.taxamount = getTaxAmount(defaultTax.rate, subtotal)
            itemTax = [defaultTax]
            if (country === "IN") {
                const inTax = tax.filter((t) => t.name === "IGST")[0];
                inTax.taxamount = getTaxAmount(inTax.rate, subtotal)
                itemTax = [...itemTax, inTax]
            }
            total = subtotal;
            itemTax.forEach((t) => {
                total += parseFloat(t.taxamount);
            })
        }


        return {subtotal: subtotal.toString(), total: total.toString(), itemTax};
    }

    onPutOrder = () => {
        const {product_id, domain, available_for_purchase} = this.state;
        const {navigation} = this.props;
        let body = {
            productid: product_id,
            domain,
        };
        body.addons = available_for_purchase
            .filter((addonid) => addonid.active)
            .map((addonid) => {
                return {addonid: addonid.addonid}
            });
        requestApi({
            method: methods.post,
            action: actions.order,
            body
        }).then((response) => {
            if (response.status === SUCCESS) {
                const data = response.data[0];
                navigation.replace('OrdersRoot', {
                    screen: 'OrdersNav',
                    params: {
                        screen: 'Orders',
                        params: {
                            screen: "OrdersList",
                            params: {
                                id: data.order_reference_id,
                                pay: data.payment_url,
                                from: "checkout"
                            }
                        },
                    },
                })
            }
        })
    }

    render() {
        const {navigation, route} = this.props;
        const {product_id, domain, available_for_purchase, amount, selectedAddons} = this.state;


        if (!Boolean(route.params) ||
            !Boolean(route.params.addons) ||
            Boolean(route.params.addons === "add")) {
            navigation.goBack();
            return <View/>;
        }
        const {subtotal, total, itemTax} = this.calculate();
        let enablePayment = !Boolean(available_for_purchase.some((item:any)=>item.active));
        return (
            <RootPage navigation={navigation} headerTitle={"Add on"}>
                <View style={Class.container}>
                    <DhruScrollView contentContainerStyle={Class.padding16}>
                        {
                            Boolean(domain) && <TitleAndSubtitle subtitle={domain} style={{marginLeft:-18, marginRight:-18}}/>
                        }

                        {
                            available_for_purchase.map((purchaseAddon, index) => {


                                return <AddOnItem
                                    key={index}
                                    {...purchaseAddon}
                                    onPress={() => {
                                        purchaseAddon.active = !Boolean(purchaseAddon.active);
                                        this.forceUpdate();
                                    }}/>
                            })
                        }
                        {
                            Boolean(domain) && <React.Fragment>
                                <BillItem itemStyle={Class.alignItemFlexEnd} isHeading
                                          item={"Subtotal"}
                                          price={subtotal}/>
                                {
                                    (Boolean(itemTax) && Boolean(itemTax.length > 0)) && itemTax.map((it, index) => {
                                        return <BillItem
                                            key={index}
                                            itemStyle={Class.alignItemFlexEnd}
                                            isHeading item={`${it.name} ( ${it.rate}% )`}
                                            price={it.taxamount}/>
                                    })
                                }

                                <BillItem itemStyle={Class.alignItemFlexEnd} isHeading
                                          item={"Total"}
                                          price={total}/>
                            </React.Fragment>
                        }
                    </DhruScrollView>
                    <View style={[Class.bottomView]}>
                        <ContainedButton
                            disabled={Boolean(domain)?enablePayment:false}
                            onPress={() => {
                                if (Boolean(domain)){
                                    this.onPutOrder()
                                }else {

                                    let aids;
                                    available_for_purchase.forEach((item)=>{
                                        if (Boolean(item.active)){
                                            if (Boolean(aids)){
                                                aids+=","+item.addonid
                                            }else {
                                                aids = item.addonid;
                                            }
                                        }
                                    })
                                    navigation.popToTop();
                                    navigation.navigate("Checkout", {
                                        pid: product_id,
                                        aids
                                    })
                                }
                            }}
                        >
                            {Boolean(domain)?"Process to payment":"Checkout"}
                        </ContainedButton>
                    </View>
                </View>
            </RootPage>
        );
    }
}

const mapStateToProps = (state) => ({
    profile: state.app_info.profile,
    tax: state.app_info.static.tax,
});

const mapDispatchToProps = (dispatch) => ({});

export default withGoogleReCaptcha(connect(mapStateToProps, mapDispatchToProps)(Buyaddons));
