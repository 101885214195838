import React, {Component} from "react";
import {KeyboardAvoidingView,} from "react-native";
import {currencyFormat, getTaxAmount, isEmpty} from "../libs/function";
import {ContainedButton, TextInputPaper, View} from "../components/Themed";
import Class from "../constants/Style";
import RootPage from "../components/RootPage";
import {withGoogleReCaptcha} from "react-google-recaptcha-v3";
import {connect} from "react-redux";
import requestApi, {actions, methods, SUCCESS} from "../libs/ServerRequest";
import DhruScrollView from "../components/DhruScrollView";
import {headerHeight, isIOS, LARGE_SCREEN_WIDTH} from "../libs/settings";
import {LargeScreen, SmallScreen} from "../components/ScreenSize";
import {validRequired} from "../libs/validate";

class LicenseKey extends Component<any, any> {

    constructor(props) {
        super(props);

        const {route, navigation, servers_list} = props;
        let domain;
        if (Boolean(route.params.domain)) {
            domain = route.params.domain;
        }
        this.state = {
            product_id: route.params.product_id,
            license_key: "",
            domain,
            available_for_purchase: route.params.addons,
            selectedAddons: [],
            error: {},
        }
    }

    getAmount = () => {
        let amount = 0;
        const {available_for_purchase} = this.state;
        available_for_purchase.forEach(({price, active}) => {
            if (Boolean(active)) {
                amount += parseFloat(price);
            }
        })
        return currencyFormat(amount);
    }

    onAddAddon = (newAddon) => {
        const {selectedAddons} = this.state;
        let setNew = [];
        let check = selectedAddons.find(oa => oa === newAddon)
        if (Boolean(check)) {
            setNew = selectedAddons.filter((a) => a !== newAddon)

        } else {
            setNew = [...selectedAddons, newAddon]
        }
        this.setState({selectedAddons: setNew})
    }

    calculate = () => {
        const {tax, profile} = this.props;
        const {available_for_purchase, domain} = this.state;
        let subtotal = 0, total = 0, itemTax = [], country = "US";

        if (Boolean(domain)) {
            if (Boolean(profile) && Boolean(profile.country)) {
                country = profile.country;
            }
            available_for_purchase.forEach((aid) => {
                if (Boolean(aid.active)) {
                    subtotal += parseFloat(aid.price);
                }
            })
            const defaultTax = tax.filter((t) => t.name === "Transaction tax")[0];
            defaultTax.taxamount = getTaxAmount(defaultTax.rate, subtotal)
            itemTax = [defaultTax]
            if (country === "IN") {
                const inTax = tax.filter((t) => t.name === "IGST")[0];
                inTax.taxamount = getTaxAmount(inTax.rate, subtotal)
                itemTax = [...itemTax, inTax]
            }
            total = subtotal;
            itemTax.forEach((t) => {
                total += parseFloat(t.taxamount);
            })
        }


        return {subtotal: subtotal.toString(), total: total.toString(), itemTax};
    }

    onPutOrder = () => {
        const {product_id, domain, available_for_purchase} = this.state;
        const {navigation} = this.props;
        let body = {
            productid: product_id,
            domain,
        };
        body.addons = available_for_purchase
            .filter((addonid) => addonid.active)
            .map((addonid) => {
                return {addonid: addonid.addonid}
            });
        requestApi({
            method: methods.post,
            action: actions.order,
            body
        }).then((response) => {
            if (response.status === SUCCESS) {
                const data = response.data[0];
                navigation.replace('OrdersRoot', {
                    screen: 'OrdersNav',
                    params: {
                        screen: 'Orders',
                        params: {
                            screen: "OrdersList",
                            params: {
                                id: data.order_reference_id,
                                pay: data.payment_url,
                                from: "checkout"
                            }
                        },
                    },
                })
            }
        })
    }

    validate = () => {
        const {license_key} = this.state;
        let error = {};
        error = {...error, ...validRequired(error, "license_key", license_key, "Please Enter License Key")};
        this.setState({error})
        return isEmpty(error)
    }


    onSubmit = () => {
        if (this.validate()) {
            const {license_key} = this.state;
            const {navigation, route} = this.props;
            requestApi({
                method: methods.get,
                action: actions.reseller,
                queryString: {license_key}
            }).then((response: any) => {
                if (response.status === SUCCESS && !isEmpty(response?.data)) {
                    if (!isEmpty(route.params.addons)) {

                        console.log("response?.data?.productid", response?.data?.productid);
                        console.log("response?.data?.domain", response?.data?.domain);
                        console.log("route.params.addons", route.params.addons);

                        navigation.navigate("BuyAddonScreen", {
                            product_id: response?.data?.productid,
                            domain: response?.data?.domain,
                            addons: route.params.addons
                        })
                    } else {
                        navigation.navigate("Checkout", {
                            pid: response?.data?.productid,
                        })
                    }
                } else if (Boolean(response.message)) {
                    this.setState({
                        error: {
                            license_key: response.message
                        }
                    })
                }
            })
        }
    }

    render() {

        console.log("this.props", this.props);

        const {navigation, route} = this.props;
        const {product_id, domain, available_for_purchase, amount, selectedAddons, license_key, error} = this.state;


        if (!Boolean(route.params) ||
            !Boolean(route.params.addons) ||
            Boolean(route.params.addons === "add")) {
            navigation.goBack();
            return <View/>;
        }
        const {subtotal, total, itemTax} = this.calculate();
        let enablePayment = !Boolean(available_for_purchase.some((item: any) => item.active));
        return (
            <RootPage navigation={navigation} headerTitle={"License Key"}>
                <KeyboardAvoidingView
                    keyboardVerticalOffset={headerHeight}
                    style={Class.container} behavior={isIOS ? "padding" : ""}>
                    <View style={[Class.container]}>
                        <DhruScrollView contentContainerStyle={Class.paddingLeftRight22}>
                            <View style={[Class.maxFormWidth]}>
                                <TextInputPaper
                                    label={"License Key"}
                                    placeholder={"License Key"}
                                    error={error.license_key}
                                    value={license_key}
                                    autoCompleteType={"email"}
                                    textContentType={"emailAddress"}
                                    keyboardType={"email-address"}
                                    onChangeText={(license_key: any) => this.setState({license_key})}
                                    style={Class.textbox}
                                    returnKeyType="done"
                                    onSubmitEditing={() => this.onSubmit()}
                                />

                                <LargeScreen minWidth={LARGE_SCREEN_WIDTH}>
                                    <ContainedButton
                                        style={[Class.maxFormWidth]}
                                        onPress={() => this.onSubmit()}
                                    >
                                        Check License
                                    </ContainedButton>
                                </LargeScreen>
                            </View>
                        </DhruScrollView>
                        <SmallScreen maxWidth={LARGE_SCREEN_WIDTH}>
                            <View style={[Class.bottomView]}>
                                <ContainedButton
                                    style={[Class.maxFormWidth]}
                                    onPress={() => this.onSubmit()}
                                >
                                    Check License
                                </ContainedButton>
                            </View>
                        </SmallScreen>
                    </View>
                </KeyboardAvoidingView>
            </RootPage>
        );
    }
}

const mapStateToProps = (state) => ({
    profile: state.app_info.profile,
    tax: state.app_info.static.tax,
});

const mapDispatchToProps = (dispatch) => ({});

export default withGoogleReCaptcha(connect(mapStateToProps, mapDispatchToProps)(LicenseKey));
