import * as React from "react";
import {StyleSheet} from "react-native";
import {ContainedButton, Text, View} from "../components/Themed";
import Class from "../constants/Style";
import RootPage from "../components/RootPage";
import BillItem from "../components/BillItem";
import {connect} from "react-redux";
import requestApi, {actions, methods, SUCCESS} from "../libs/ServerRequest";
import {browseLink, isEmpty, requestGetProducts} from "../libs/function";
import TitleAndSubtitle from "../components/TitleAndSubtitle";
import {setInitDataWithObject} from "../libs/Store/actions/app_info";
import {LargeScreen, SmallScreen} from "../components/ScreenSize";
import {isDevelopment, LARGE_SCREEN_WIDTH} from "../libs/settings";
import SquareIcon from "../components/SquareIcon";
import DhruScrollView from "../components/DhruScrollView";

class ViewInvoice extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            data: {}
        }

    }

    componentDidMount() {
        this._unsubscribe = this.props.navigation.addListener('focus', () => {
            this.requestGetOrder();
        });
        const {route} = this.props;
        if (Boolean(route.params)) {
            const {from, pay} = route.params;
            if (Boolean(from) && Boolean(pay)) {
                this.onPay(pay)
            }
        }
    }

    componentWillUnmount() {
        this._unsubscribe();
    }

    requestGetOrder = () => {
        const {route, setInitDataWithObject, orders} = this.props;
        requestApi({
            method: methods.get,
            action: actions.order,
            queryString: {id: route.params.id}
        }).then((response) => {
            if (response.status === SUCCESS) {
                const data = response.data[0];
                this.setState({data}, () => {
                    let updateData;
                    const orderAvailable = orders.filter((o) => o.order_reference_id === data.order_reference_id);
                    if (Boolean(orderAvailable) && Boolean(orderAvailable.length > 0)) {
                        if (orderAvailable[0].status !== data.status) {
                            requestGetProducts(true).then(() => {
                            })
                        }
                        updateData = orders.map((order) => {
                            if (order.order_reference_id === data.order_reference_id) {
                                return {...order, status: data.status}
                            }
                            return order;
                        })
                    } else {
                        updateData = [data, ...orders]
                    }
                    setInitDataWithObject("orders", updateData);
                });
            }
        })
    }

    onPay = (link) => {
        const {navigation, route, token} = this.props;
        navigation.navigate("WebPageScreen", {link, token})
    }

    paymentButton = (style) => {
        const {data} = this.state;
        return <React.Fragment>{
            data.status.toLowerCase() === "unpaid" && <ContainedButton
                onPress={() => this.onPay(data.payment_url)}
                style={[style]}>
                Pay Now!
            </ContainedButton>
        }
        </React.Fragment>
    }

    render() {
        const {navigation, route, token, order_status} = this.props;
        const {data} = this.state;
        return <RootPage navigation={navigation} headerTitle={`Order #${route.params.id}`}>
            {
                !isEmpty(data) && <View style={Class.container}>
                    <DhruScrollView>
                        <View style={[Class.container, Class.alignItemCenter]}>
                            <View style={[{
                                borderWidth: 0,
                                borderColor: "#eee",
                                borderRadius: 4,
                                maxWidth: 1024,
                                width: "100%",
                                minHeight: "100%",
                                alignItems: "center"
                            }]}>
                                <View
                                    style={[Class.alignSelfStretch, Class.column, Class.alignItemStartEnd]}>
                                    <TitleAndSubtitle
                                        subtitle={`Order Date : ${data.order_date}`}
                                    />
                                    <View style={[Class.row, {marginLeft: 11}]}>
                                        <SquareIcon
                                            style={{height: 20, width: 20}}
                                            name={order_status[data.status].icon}
                                            backgroundColor={`#${order_status[data.status].colour}`}/>
                                        <Text style={{color: "rgba(167,174,183,1)",}}>{data.status}</Text>
                                    </View>
                                </View>

                                <View
                                    style={[Class.alignSelfStretch, Class.paddingLeftRight22, Class.paddingTopBottom8, Class.paddingBottom0]}>
                                    <BillItem isHeading={true} item={"Item"} price={"Price"}/>
                                    {
                                        Boolean(data.items) && data.items.map(({name, value}, index) => {
                                            return <BillItem key={index} item={name} price={value}/>
                                        })
                                    }
                                    {
                                        Boolean(data.order_subtotal) &&
                                        <BillItem
                                            isHeading={true}
                                            itemStyle={Class.alignItemFlexEnd}
                                            item={"Subtotal"}
                                            price={data.order_subtotal}
                                        />
                                    }
                                    {
                                        Boolean(data.tax) &&
                                        <BillItem
                                            isHeading={true}
                                            itemStyle={Class.alignItemFlexEnd}
                                            item={`Tax ( ${data.tax_rate}% )`}
                                            price={data.tax}
                                        />
                                    }
                                    {
                                        (Boolean(data.tax2) && (parseFloat(data.tax2) > 0)) &&
                                        <BillItem
                                            isHeading={true}
                                            itemStyle={Class.alignItemFlexEnd}
                                            item={`IGST ( ${data.tax_rate2}% )`}
                                            price={data.tax2}
                                        />
                                    }
                                    {
                                        Boolean(data.order_total) &&
                                        <BillItem
                                            isHeading={true}
                                            itemStyle={Class.alignItemFlexEnd}
                                            item={"Total"}
                                            price={data.order_total}
                                        />
                                    }

                                    {
                                        (Boolean(data.workspace) && Boolean(data.workspace_login)) &&
                                        <ContainedButton
                                            style={[{alignSelf: "center",}, Class.marginTop16]}
                                            onPress={() => {
                                                browseLink(data.workspace_login, "_blank")
                                            }}>
                                            Access Workspace
                                        </ContainedButton>
                                    }
                                    {
                                        (Boolean(data.workspace) && Boolean(data.workspace_login_io)) && <ContainedButton
                                            mode={"text"}
                                            style={{alignSelf: "center"}}
                                            onPress={() => {
                                                if (isDevelopment) {
                                                    data.workspace_login_io = data.workspace_login_io.replace("https://fusionpro.dhru.io/", "http://localhost:3000/")
                                                }
                                                browseLink(data.workspace_login_io, "_blank")
                                            }}>
                                            Access Workspace (DEV)
                                        </ContainedButton>
                                    }
                                </View>
                                <View
                                    style={[Class.alignSelfStretch, Class.paddingLeftRight22, Class.row, Class.alignItemStartEnd]}>
                                    <LargeScreen minWidth={LARGE_SCREEN_WIDTH}>
                                        <View
                                            style={[Class.flex, Class.alignItemFlexEnd]}>
                                            {this.paymentButton([Class.buyButton, Class.marginBottom16])}

                                        </View>
                                    </LargeScreen>
                                </View>
                            </View>
                        </View>
                    </DhruScrollView>
                    <SmallScreen maxWidth={LARGE_SCREEN_WIDTH}>
                        <View style={[Class.bottomView]}>
                            {this.paymentButton()}
                        </View>
                    </SmallScreen>
                </View>
            }
        </RootPage>;
    }
}

const mapStateToProps = (state) => ({
    token: state.app_info.token,
    orders: state.app_info.orders,
    order_status: state.app_info.static.order_status
});

const mapDispatchToProps = (dispatch) => ({
    setInitDataWithObject: (objectKey, updateData) => dispatch(setInitDataWithObject(objectKey, updateData))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewInvoice);

const styles = StyleSheet.create({
    rect: {
        justifyContent: "flex-start",
        flex: 1
    },
    rect6: {
        alignSelf: "stretch",
        alignItems: "flex-end",
        padding: 16
    },
    rect3: {
        alignSelf: "stretch",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    rect5: {
        flex: 1,
    },
    rect4: {
        flex: 1
    },
    rect2: {
        alignSelf: "stretch"
    }
});

