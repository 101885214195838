
export const regExpJson = {
    email: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
    leastOneDigit: /.*[0-9].*/,
    leastOneCAPS: /.*[A-Z].*/,
    leastOneSymbol: /.*[\W+].*/,
};

export const validEmail = (errorObject:any, key:string, value: string) => {
    let error:any = undefined;
    if (!Boolean(value)) {
        errorObject = validRequired(errorObject, key, value, "Please enter email");
    } else if (!regExpJson.email.test(value)) {
        errorObject[key] = "Please enter valid email";
    }
    return errorObject;
}


export const validPassword = (errorObject:any, key:string, value: string, isNew?: boolean) => {
    let error:any = undefined;
    if (!Boolean(value)) {
        let message: string;
        message = isNew ? "Please enter new password" : "Please enter password";
        errorObject = validRequired(errorObject, key, value, message);
    } else if (value.length < 8) {
        errorObject[key] = "Password should be at least 8 characters in length.";
    } else if (!regExpJson.leastOneCAPS.test(value)) {
        errorObject[key] = "Password must include at least one CAPS!";
    } else if (!regExpJson.leastOneDigit.test(value)) {
        errorObject[key] = "Password must include at least one number!";
    } else if (!regExpJson.leastOneSymbol.test(value)) {
        errorObject[key] = "Password must include at least one symbol!";
    }
    return errorObject;
}


export const validRequired = (errorObject:any, key:any, value: string, errorMessage:string) => {
    let error:any = undefined;
    if (!Boolean(value)) {
        errorObject[key] = errorMessage;
    }
    return errorObject;
}
