import {
    SET_ALERT,
    SET_BOTTOMSHEET,
    SET_DIALOG,
    SET_LOADER,
    SET_POP_PAGE,
    SET_SNACKBAR,
} from "../definitions/components";

const setDialog = (dialog) => ({
    type: SET_DIALOG,
    dialog
});


const setPopPage = (popuppage) => ({
    type: SET_POP_PAGE,
    popuppage
})

const setBottomSheet = (bottomsheet) => ({
    type: SET_BOTTOMSHEET,
    bottomsheet
});

const setAlert = (alert) => ({
    type: SET_ALERT,
    alert
});


const setLoader = (loader) => ({
    type: SET_LOADER,
    loader
});

const setSnackbar = (snackData) => ({
    type: SET_SNACKBAR,
    snackData
});


export {setDialog, setAlert, setLoader, setBottomSheet, setSnackbar, setPopPage};
