import {useWindowDimensions} from "react-native";
import {createDrawerNavigator} from "@react-navigation/drawer";
import DhruDrawer from "./DhruDrawer";
import Orders from "../screens/Orders";
import Profile from "../screens/Profile";
import {createBottomTabNavigator} from "@react-navigation/bottom-tabs";
import TabBarIcon from "../components/TabBarIcon";
import Products from "../screens/Products";
import * as React from "react";
import {
    CaseRootStack,
    InvoiceRootStack, MerchantRootStack,
    OrdersRootStack2,
    ProductRootStack,
    MerchantRootStack2,
    ProductRootStack2, ProfileRootStack,
    ProfileRootStack2, SupportRootStack,
    UpdateProfileRootStack, WalletRootStack2, OTPRootStack2
} from "./StackNav";
import Support from "../screens/Support";
import Class from "../constants/Style"
import Invoices from "../screens/Invoices";
import UpdateProfile from "../screens/UpdateProfile";
import ChangePassword from "../screens/ChangePassword";
import SupportRequest from "../screens/SupportRequest";
import SubCategory from "../screens/SubCategory";
import {isIOS, isWeb, LARGE_SCREEN_WIDTH} from "../libs/settings";
import Conversation from "../screens/Conversation";
import CreateTicket from "../screens/CreateTicket";
import SuccessTicket from "../screens/SuccessTicket";
import UpdateLoginDetails from "../screens/UpdateLoginDetails";
import ViewProduct from "../screens/ViewProduct";
import Buyaddons from "../screens/BuyAddon";
import ViewInvoice from "../screens/ViewInvoice";
import Webpage from "../screens/Webpage";
import AddProduct from "../screens/AddProduct";
import {useLinkTo} from "@react-navigation/native";
import {headerTintColor} from "./index";
import {useThemeColor} from "../components/Themed";
import {largeDarkHeaderColor, largeLighHeaderColor, largeLightHeaderColor} from "../constants/Colors";
import CameraView from "../screens/CameraView";
import MoveLicense from "../screens/MoveLicense";
import Merchant from "../screens/Merchant";
import StoreItemForm from "../screens/StoreItemForm";
import {isReseller, logData} from "../libs/function";
import Wallet from "../screens/Wallet";
import OTPServiceScreen from "../screens/OTPServiceScreen";
import LicenseKey from "../screens/LicenseKey";
import TransferCredit from "../screens/TransferCredit";

export const CommonNavigation = ({initialRouteName, ...otherProps}) => {
    const dimensions = useWindowDimensions();
    const isLargeScreen = dimensions.width >= LARGE_SCREEN_WIDTH;
    if (isLargeScreen) {
        const DrawerNavStack = createDrawerNavigator();
        return <DrawerNavStack.Navigator
            drawerType={"permanent"}
            initialRouteName={initialRouteName}
            drawerContent={props => <DhruDrawer {...props} />}
        >
            <DrawerNavStack.Screen name="Cases" component={CaseStack}/>
            <DrawerNavStack.Screen name="Products">
                {screenProps => <ProductStack {...screenProps} from={"ProductList"} />}
            </DrawerNavStack.Screen>

            <DrawerNavStack.Screen name="Orders" component={OrdersStack}/>

            {
                isReseller() && <DrawerNavStack.Screen name="Merchant" >
                    {storeProps => <MerchantStack {...storeProps} from={"Seller"} />}
                </DrawerNavStack.Screen>
            }

            <DrawerNavStack.Screen name="Wallet" component={WalletStack}/>
            <DrawerNavStack.Screen name="OTPService" component={OTPServiceStack}/>
            <DrawerNavStack.Screen name="Workspaces">
                {screenProps => <ProductStack {...screenProps} from={"WorkspaceList"} />}
            </DrawerNavStack.Screen>
            <DrawerNavStack.Screen name="ResellerWorkspaces">
                {screenProps => <ProductStack {...screenProps} from={"ResellerWorkspaceList"} />}
            </DrawerNavStack.Screen>
            <DrawerNavStack.Screen name="Store" >
                {storeProps => <MerchantStack {...storeProps} from={"Store"} />}
            </DrawerNavStack.Screen>
            <DrawerNavStack.Screen name="Invoices" component={InvoiceStack}/>
            <DrawerNavStack.Screen name="UpdateProfile" component={UpdateProfileStack}/>
            {/*<DrawerNavStack.Screen name="Profile" component={ProfileStack}/>*/}
        </DrawerNavStack.Navigator>
    }
    const SupportBottomTabStack = createBottomTabNavigator();
    return <SupportBottomTabStack.Navigator initialRouteName={initialRouteName}>
        <SupportBottomTabStack.Screen
            name="Cases"
            component={CaseStack}
            listeners={({navigation, route}) => ({
                tabPress: e => {
                    if (isWeb) {
                        e.preventDefault();
                        navigation.dangerouslyGetParent().replace('SupportRoot', {
                            screen: 'SupportNav',
                            params: {
                                screen: 'Cases',
                            },
                        });
                    }
                },
            })}
            options={{
                tabBarLabel: "Support",
                tabBarIcon: ({color}) => <TabBarIcon name="ios-help-buoy" color={color}/>,
            }}
        />
        <SupportBottomTabStack.Screen
            name="Workspaces"
            listeners={({navigation, route}) => ({
                tabPress: e => {
                    if (isWeb) {
                        e.preventDefault();
                        navigation.dangerouslyGetParent().replace('WorkspaceRoot', {
                            screen: 'WorkspaceNav',
                            params: {
                                screen: 'Workspaces',
                            },
                        });
                    }
                },
            })}
            options={{
                tabBarIcon: ({color}) => <TabBarIcon name="ios-albums" color={color}/>,
            }}
        >
            {screenProps => <ProductStack {...screenProps} from={"WorkspaceList"} />}
        </SupportBottomTabStack.Screen>
        <SupportBottomTabStack.Screen
            name="Products"
            listeners={({navigation, route}) => ({
                tabPress: e => {
                    if (isWeb) {
                        e.preventDefault();
                        navigation.dangerouslyGetParent().replace('ProductRoot', {
                            screen: 'ProductNav',
                            params: {
                                screen: 'Products',
                            },
                        });
                    }
                },
            })}
            options={{
                tabBarIcon: ({color}) => <TabBarIcon name="md-folder" color={color}/>,
            }}
        >
            {screenProps => <ProductStack {...screenProps} from={"ProductList"} />}
        </SupportBottomTabStack.Screen>

        {
            !isIOS && <SupportBottomTabStack.Screen
                name="Orders"
                component={OrdersStack}
                listeners={({navigation, route}) => ({
                    tabPress: e => {
                        if (isWeb) {
                            e.preventDefault();
                            navigation.dangerouslyGetParent().replace('OrdersRoot', {
                                screen: 'OrdersNav',
                                params: {
                                    screen: 'Orders',
                                },
                            });
                        }
                    },
                })}
                options={{
                    tabBarIcon: ({color}) => <TabBarIcon name="ios-cart" color={color}/>,
                }}
            />
        }

        <SupportBottomTabStack.Screen
            name="Profile"
            component={ProfileStack}
            listeners={({navigation, route}) => ({
                tabPress: e => {
                    if (isWeb) {
                        e.preventDefault();
                        navigation.dangerouslyGetParent().replace('ProfileRoot', {
                            screen: 'ProfileNav',
                            params: {
                                screen: 'Profile',
                            },
                        });
                    }
                },
            })}
            options={{
                tabBarIcon: ({color}) => <TabBarIcon name="person-circle-outline" color={color}/>,
            }}
        />
    </SupportBottomTabStack.Navigator>
}

export const CaseStack = (props) => {
    const dimensions = useWindowDimensions();
    const isLargeScreen = dimensions.width >= LARGE_SCREEN_WIDTH;
    const linkTo = useLinkTo();
    const headerTintColor = useThemeColor({light: largeLightHeaderColor, dark: largeDarkHeaderColor}, 'text');
    return <CaseRootStack.Navigator initialRouteName={"cases/list/open"} screenOptions={{headerTintColor}}>

        <CaseRootStack.Screen
            name={"CaseList"}
            initialParams={{status: "open"}}
        >
            {props1 => <Support {...props1} linkTo={linkTo}/>}
        </CaseRootStack.Screen>

        {
            Boolean(isLargeScreen) &&
            <React.Fragment>
                <CaseRootStack.Screen name="ConversationScreen" component={Conversation}/>
                <CaseRootStack.Screen name="CategoryScreen" component={SupportRequest}/>
                <CaseRootStack.Screen name="SubcategoryScreen" component={SubCategory}/>
                <CaseRootStack.Screen name="SelectProductScreen" component={Products}/>
                <CaseRootStack.Screen name="CreateTicketScreen" component={CreateTicket}/>
                <CaseRootStack.Screen name="SuccessTicketScreen" component={SuccessTicket}
                                      options={{headerShown: false}}/>
                <CaseRootStack.Screen name="UpdateLoginDetailsScreen" component={UpdateLoginDetails}/>
                <CaseRootStack.Screen name="CameraScreen" component={CameraView} options={{headerShown:false}}/>
            </React.Fragment>
        }
    </CaseRootStack.Navigator>
}

export const ProductStack = (props:any) => {

    const dimensions = useWindowDimensions();
    const isLargeScreen = dimensions.width >= LARGE_SCREEN_WIDTH;
    const headerTintColor = useThemeColor({light: largeLightHeaderColor, dark: largeDarkHeaderColor}, 'text');
    return <ProductRootStack2.Navigator screenOptions={{headerTintColor}}>
        <ProductRootStack2.Screen name={props.from||"ProductList"} component={Products}/>
        {
            Boolean(isLargeScreen) && <React.Fragment>
                <ProductRootStack2.Screen name="AddProductScreen" component={AddProduct}/>
                <ProductRootStack2.Screen name="ViewProductScreen" component={ViewProduct}/>
                <ProductRootStack2.Screen name="BuyAddonScreen" component={Buyaddons}/>
                <ProductRootStack2.Screen name="LicenseKeyScreen" component={LicenseKey}/>
                <ProductRootStack2.Screen name="MoveLicenseScreen" component={MoveLicense}/>
            </React.Fragment>
        }
    </ProductRootStack2.Navigator>
}

export const OrdersStack = (props) => {
    const dimensions = useWindowDimensions();
    const isLargeScreen = dimensions.width >= LARGE_SCREEN_WIDTH;
    const headerTintColor = useThemeColor({light: largeLightHeaderColor, dark: largeDarkHeaderColor}, 'text');
    return <OrdersRootStack2.Navigator screenOptions={{headerTintColor}}>
        <OrdersRootStack2.Screen name={"OrdersList"} component={Orders}/>
        {
            Boolean(isLargeScreen) && <React.Fragment>
                <ProfileRootStack.Screen name="ViewInvoiceScreen" component={ViewInvoice}/>
                <ProfileRootStack.Screen name="WebPageScreen" component={Webpage}/>
            </React.Fragment>
        }
    </OrdersRootStack2.Navigator>
}

export const ProfileStack = (props) => {
    const headerTintColor = useThemeColor({light: largeLightHeaderColor, dark: largeDarkHeaderColor}, 'text');
    return <ProfileRootStack2.Navigator screenOptions={{headerTintColor}}>
        <ProfileRootStack2.Screen name={"ProfileOptions"} component={Profile}
                                  options={{headerTitle: "", headerStyle: Class.headerStyle}}/>
    </ProfileRootStack2.Navigator>
}

export const InvoiceStack = (props) => {
    const headerTintColor = useThemeColor({light: largeLightHeaderColor, dark: largeDarkHeaderColor}, 'text');
    return <InvoiceRootStack.Navigator screenOptions={{headerTintColor}}>
        <InvoiceRootStack.Screen name={"InvoicesList"} component={Invoices}
                                 options={{headerTitle: "", headerStyle: Class.headerStyle}}/>
    </InvoiceRootStack.Navigator>
}

export const MerchantStack = (props) => {
    const dimensions = useWindowDimensions();
    const isLargeScreen = dimensions.width >= LARGE_SCREEN_WIDTH;
    const headerTintColor = useThemeColor({light: largeLightHeaderColor, dark: largeDarkHeaderColor}, 'text');
    return <MerchantRootStack2.Navigator screenOptions={{headerTintColor}}>
        <MerchantRootStack2.Screen name={"MerchantStack"} >
            {merchantProps=><Merchant {...merchantProps} from={props.from} />}
        </MerchantRootStack2.Screen>
        {
            Boolean(isLargeScreen) && <React.Fragment>
                <MerchantRootStack2.Screen name={"StoreItemScreen"} component={StoreItemForm}/>
            </React.Fragment>
        }
    </MerchantRootStack2.Navigator>
}

export const WalletStack = (props) => {
    const dimensions = useWindowDimensions();
    const isLargeScreen = dimensions.width >= LARGE_SCREEN_WIDTH;
    const headerTintColor = useThemeColor({light: largeLightHeaderColor, dark: largeDarkHeaderColor}, 'text');
    return <WalletRootStack2.Navigator screenOptions={{headerTintColor}}>
        <MerchantRootStack2.Screen name={"WalletStack"} component={Wallet}/>
        {
            Boolean(isLargeScreen) && <React.Fragment>
                <MerchantRootStack2.Screen name={"StoreItemScreen"} component={StoreItemForm}/>
                <MerchantRootStack2.Screen name={"TransferCreditScreen"} component={TransferCredit}/>
            </React.Fragment>
        }
    </WalletRootStack2.Navigator>
}

export const UpdateProfileStack = (props) => {
    const headerTintColor = useThemeColor({light: largeLightHeaderColor, dark: largeDarkHeaderColor}, 'text');
    return <UpdateProfileRootStack.Navigator screenOptions={{headerTintColor}}>
        <UpdateProfileRootStack.Screen name={"UpdateProfileScreen"} component={UpdateProfile}
                                       options={{headerTitle: "", headerStyle: Class.headerStyle}}/>
        <UpdateProfileRootStack.Screen name={"ChangePasswordScreen"} component={ChangePassword}
                                       options={{headerTitle: "", headerStyle: Class.headerStyle}}/>
    </UpdateProfileRootStack.Navigator>
}


export const OTPServiceStack = (props) => {
    const dimensions = useWindowDimensions();
    const isLargeScreen = dimensions.width >= LARGE_SCREEN_WIDTH;
    const headerTintColor = useThemeColor({light: largeLightHeaderColor, dark: largeDarkHeaderColor}, 'text');
    return <OTPRootStack2.Navigator screenOptions={{headerTintColor}}>
        <OTPRootStack2.Screen name={"OTPServiceScreen"} component={OTPServiceScreen}/>
    </OTPRootStack2.Navigator>
}
