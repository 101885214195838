import React from "react";
import DhruWebView from "./DhruWebView";
import base64 from "react-native-base64";
import {useThemeColor} from "./Themed";
import {StyleSheet} from "react-native";
import Colors from "../constants/Colors";
import utf8 from "utf8";
import {decodeText} from "../libs/function";

const HtmlEncrypted = ({encryptedData, lightTextColor, darkTextColor}) => {
    const color = useThemeColor({light: lightTextColor, dark: darkTextColor}, 'text');
    return <DhruWebView
        htmlview={true}
        html={decodeText(encryptedData)}
        stylesheet={StyleSheet.create({
            div: {
                color,
            },
        })}
    />
}

export default HtmlEncrypted;
