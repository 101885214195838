import * as React from "react";
import { WalletRootStack} from "../StackNav";
import {CommonNavigation} from "../CommonNavigation";
import {useWindowDimensions} from "react-native";
import {LARGE_SCREEN_WIDTH} from "../../libs/settings";
import {intercomVisibility} from "../../libs/function";


const WalletRoot = () => {
    const dimensions = useWindowDimensions();
    const isLargeScreen = dimensions.width >= LARGE_SCREEN_WIDTH;
    intercomVisibility(false)
    return <WalletRootStack.Navigator>
        <WalletRootStack.Screen
            name="WalletNav"
            options={{headerShown: false}}
        >
            {props => <CommonNavigation {...props} initialRouteName={"Wallet"}/>}
        </WalletRootStack.Screen>
        {
            !Boolean(isLargeScreen) && <React.Fragment>
                {/*<ProfileRootStack.Screen name="ViewInvoiceScreen" component={ViewInvoice}/>*/}
                {/*<ProfileRootStack.Screen name="WebPageScreen" component={Webpage}/>*/}
            </React.Fragment>
        }
    </WalletRootStack.Navigator>
}

export default WalletRoot;




