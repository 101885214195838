import * as React from 'react';
import {KeyboardAvoidingView, RefreshControl, ScrollView, StyleSheet, TouchableOpacity} from 'react-native';
import Class from '../constants/Style';
import {Text, TextButton, TextInput, View} from '../components/Themed';
import {connect} from "react-redux";
import {setInitData} from "../libs/Store/actions/app_info";
import {setLoader, setPopPage} from "../libs/Store/actions/components";
import requestApi, {actions, methods, SUCCESS} from "../libs/ServerRequest";
import FontAwesomeIcon from "react-native-vector-icons/FontAwesome";
import base64 from "react-native-base64";
import IconButton from "../components/IconButton";
import {headerHeight, isIOS, isWeb} from "../libs/settings";
import moment from "moment";
import AttachmentItem from "../components/AttachmentItem";
import * as FileSystem from "expo-file-system";
import HeaderRight from "../components/HeaderRight";
import {connectActionSheet} from "@expo/react-native-action-sheet";
import RootPage from "../components/RootPage";
import {encodeText, FileRead, GetFileData, intercomVisibility, requestGetUploadUrl, uploadFile} from "../libs/function";
import AttachedItem from "../components/AttachedItem";
import HtmlEncrypted from "../components/HtmlEncrypted";
import * as mime from "react-native-mime-types";
import {ChatConversation} from "../components/ConversationView";

export const hairlineWidth = StyleSheet.hairlineWidth
var Buffer = require('buffer/').Buffer

class Conversation extends React.Component<any, any> {


    constructor(props) {
        super(props);
        const {navigation} = props;
        this.state = {
            loading: true,
            refreshing: false,
            error: false,
            attachment: []
        }
    }

    componentDidMount() {
        this.requestGetTicketDetails()
        intercomVisibility(true)
    }
    //screen damage
    componentWillUnmount() {
        intercomVisibility(false)
    }

    requestGetTicketDetails = () => {
        const {refreshing} = this.state;
        requestApi({
            method: methods.get,
            action: actions.support,
            queryString: {id: this.props.route.params.id},
            loader: !refreshing,
        }).then((response) => {
            let data = {loading: false, refreshing: false, error: response.status !== SUCCESS};
            if (response.status === SUCCESS) {

                data = {...data, ...response.data[0]}
            }
            this.setState(data, () => this.setMenu())
        })
    }

    setMenu = () => {
        const {resolved_url, product} = this.state;
        const {navigation} = this.props;
        let options: any = [];
        if (Boolean(product) && Boolean(product.product_id)) {
            options = [
                ...options,
                {
                    label: "Update Login Detail",
                    onSelect: (data: any) => this.navigateLoginDetails()
                }
            ]
        }
        if (Boolean(resolved_url)) {
            options = [
                ...options,
                {
                    label: "Close Case",
                    alert: true,
                    onSelect: (data) => this._onOpenActionSheetSub(resolved_url)
                }
            ]
        }
        if (Boolean(options) && options.length > 0) {
            navigation.setOptions({
                headerRight: () => <HeaderRight
                    actions={[{name: "ios-more", onPress: () => this._onOpenActionSheet(options)}]}/>
            });
        }
    }

    navigateLoginDetails = () => {
        const {product} = this.state;
        const {navigation} = this.props;
        navigation.push("UpdateLoginDetailsScreen", {product})
    }

    requestGetUploadUrl = ({file_name, file_type, uri}) => {
        let {attachment} = this.state;
        let {setLoader} = this.props;
        requestApi({
            method: methods.get,
            anotherUrl: `https://apigateway.dhru.com/v1/getuploadurl?file_name=${file_name}&file_type=${file_type}`,
            otherheaders: {'Accept': '*/*'}
        }).then((responsegetuploadurl) => {
            if (responsegetuploadurl.status === SUCCESS) {
                setLoader({show: true});
                FileSystem
                    .readAsStringAsync(uri, {encoding: FileSystem.EncodingType.Base64})
                    .then((body) => {
                        let myHeaders = new Headers();
                        myHeaders.append("Content-Type", file_type);
                        let requestOptions = {
                            method: 'PUT',
                            headers: myHeaders,
                            body: Buffer.from(body, "base64"),
                            redirect: 'follow'
                        }, url = responsegetuploadurl.upload_url;

                        fetch(url, requestOptions)
                            .then(response => {
                                if (response.status === 200) {
                                    this.setState({
                                        attachment: [
                                            ...attachment,
                                            {
                                                download_url: responsegetuploadurl.download_url,
                                                file_name: responsegetuploadurl.original_file_name,
                                            }
                                        ]
                                    });
                                }
                                setLoader({show: false});
                                return response.text()
                            })
                            .catch(error => {
                                setLoader({show: false});
                                console.log('error', error)
                            });
                    })
                    .catch((e) => {
                        alert(e);
                        setLoader({show: false});
                    });
            }
        })
    }

    requestAddConversation = () => {
        let {ticket_id, Subject, type, status, created, conversation, keyboardHeight, reply, attachment} = this.state;
        if (!Boolean(conversation)) {
            conversation = []
        }
        const {navigation, setDialog, initdata, setInitData} = this.props;
        let body = {ticket_id, text: encodeText(reply), attachment};
        if (Boolean(attachment) && attachment.length > 0) {
            body = {
                ...body,
                attachments: attachment
            }
        }
        requestApi({
            method: methods.put,
            action: actions.support,
            body
        }).then((response) => {
            if (response.status === SUCCESS) {
                this.setState({
                    status: response.data.status,
                    conversation: [
                        ...conversation,
                        {
                            by: "user",
                            text: encodeText(reply),
                            date: moment().format('YYYY-MM-DD hh:mm:ss'),
                            attachments: attachment
                        }],
                    reply: '',
                    attachment: []
                });
                let changeTicket = initdata.support_tickets.map((st) => {
                    if (st.ticket_id === ticket_id) {
                        return {...st, status: response.data.status}
                    }
                    return st;
                });
                setInitData({
                    ...initdata,
                    support_tickets: changeTicket,
                })
            }
        })
    }

    _onOpenActionSheet = (optionsList) => {
        // Same interface as https://facebook.github.io/react-native/docs/actionsheetios.html
        let options = [...optionsList.map((o) => o.label), 'Cancel'];
        const destructiveButtonIndex = optionsList.findIndex((a) => Boolean(a.alert));
        const cancelButtonIndex = options.length - 1;
        this.props.showActionSheetWithOptions(
            {options, cancelButtonIndex, destructiveButtonIndex},
            buttonIndex => {
                if (cancelButtonIndex !== buttonIndex) {
                    optionsList[buttonIndex].onSelect()
                }
            },
        );
    };

    requestResolveCase = (otherPath) => {
        const {navigation, setDialog, initdata, setInitData} = this.props;
        requestApi({
            method: methods.get,
            otherPath
        }).then((response) => {
            if (response.status === SUCCESS) {
                let filter_tickets = initdata.support_tickets.filter((st) => st.ticket_id !== this.state.ticket_id)
                setInitData({
                    ...initdata,
                    support_tickets: filter_tickets,
                })
                navigation.goBack();
            }
        })
    }

    _onOpenActionSheetSub = (url) => {
        // Same interface as https://facebook.github.io/react-native/docs/actionsheetios.html
        let options = ["Yes", 'No'];
        const destructiveButtonIndex = 0;
        const cancelButtonIndex = options.length - 1;

        this.props.showActionSheetWithOptions(
            {
                title: "Confirm",
                message: "Are you sure you want a close case?",
                options,
                cancelButtonIndex,
                destructiveButtonIndex,
            },
            buttonIndex => {
                if (cancelButtonIndex !== buttonIndex) {
                    this.requestResolveCase(url);
                }
            },
        );
    };

    requestFileUpload = async (isDoc) => {
        const {attachment} = this.state;
        const {setLoader} = this.props;
        const fileDataResponse = await GetFileData(isDoc);
        if (fileDataResponse.status === "success") {
            const responseUploadUrl = await requestGetUploadUrl(fileDataResponse);
            if (responseUploadUrl.status === SUCCESS) {
                setLoader({show: true});
                let readFileResponse;
                if (isWeb) {
                    readFileResponse = fileDataResponse.selectedFile;
                } else {
                    readFileResponse = await FileRead(fileDataResponse.file_uri);
                }
                uploadFile({
                    url: responseUploadUrl.upload_url,
                    file_type: fileDataResponse.file_type,
                    body: readFileResponse
                }).then((response) => {
                    setLoader({show: false});
                    if (response.status === 200) {
                        this.setState({
                            attachment: [
                                ...attachment,
                                {
                                    download_url: responseUploadUrl.download_url,
                                    file_name: responseUploadUrl.original_file_name,
                                }
                            ]
                        });
                    }
                })
            }
        }
    }

    uploadCameraPicture = async (uri) => {
        const file_name_array = uri.toString().split("/");
        const file_name = file_name_array[file_name_array.length - 1];
        const file_uri = uri
        const file_type = mime.lookup(file_uri);
        const {attachment} = this.state;
        const {setLoader} = this.props;
        const responseUploadUrl = await requestGetUploadUrl({file_name, file_type});
        if (responseUploadUrl.status === SUCCESS) {
            setLoader({show: true});
            let readFileResponse;
            readFileResponse = await FileRead(file_uri);
            uploadFile({
                url: responseUploadUrl.upload_url,
                file_type,
                body: readFileResponse
            }).then((response) => {
                setLoader({show: false});
                if (response.status === 200) {
                    this.setState({
                        attachment: [
                            ...attachment,
                            {
                                download_url: responseUploadUrl.download_url,
                                file_name: responseUploadUrl.original_file_name,
                            }
                        ]
                    });
                }
            })
        }
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        const {route} = this.props;
        if (Boolean(route) && Boolean(route.params.uri)) {
            this.uploadCameraPicture(route.params.uri)
            this.props.route.params.uri = undefined;
        }
    }

    render() {

        const {setLoader, navigation, route} = this.props;

        const {ticket_id, loading, Subject, status, created_date, created, type, priority, description, conversation, attachment, focused, reply, resolved_url, product, refreshing, attachments, error} = this.state;

        if (Boolean(loading) || Boolean(error)) {
            return <RootPage navigation={navigation} headerTitle={`#${route.params.id}`}/>
        }


        let color = "rgba(212,87,67,1)";
        if (Boolean(priority)) {
            if (priority.toString().toLowerCase() === "low") {
                color = "rgba(96,173,227,1)";
            } else if (priority.toString().toLowerCase() === "medium") {
                color = "rgba(118,183,78,1)";
            }
        }

        let statusIcon = "clock-o", statusIconColor = "rgba(167,174,183,1)";
        if (status.toString().toLowerCase() === "awaiting your response") {
            statusIcon = "exclamation-circle";
            statusIconColor = "rgba(230,131,57,1)";
        } else if (status.toString().toLowerCase() === "resolved") {
            statusIcon = "check-circle";
            statusIconColor = "rgba(118,183,78,1)";
        }


        return <RootPage navigation={navigation} headerTitle={`#${ticket_id}`}>
            <KeyboardAvoidingView
                keyboardVerticalOffset={headerHeight}
                behavior={isIOS ? "padding" : ""} style={Class.container}>
                <ScrollView
                    ref="scrollView"
                    refreshControl={<RefreshControl
                        tintColor={"#9e9e9e"}
                        refreshing={refreshing}
                        onRefresh={() => {
                            this.setState({refreshing: true}, () => this.requestGetTicketDetails());
                        }}
                    />}
                    onContentSizeChange={(width, height) => this.refs.scrollView.scrollToEnd()}
                >
                    <View style={[Class.paddingLeftRight22, Class.marginTop8]}>
                        <Text lightColor={"rgba(22,45,78,1)"} style={[Class.title, Class.marginBottom4]}>
                            {Subject}
                        </Text>
                        <View style={[Class.row, Class.marginBottom4]}>
                            <FontAwesomeIcon
                                name={statusIcon}
                                style={[Class.marginRight4, {color: statusIconColor}]}
                            />
                            <Text lightColor={"rgba(167,174,183,1)"}>{status}</Text>
                        </View>
                        <Text lightColor={"rgba(167,174,183,1)"} style={[Class.marginBottom4]}>
                            Created on date {created_date}
                        </Text>
                        <Text lightColor={"rgba(167,174,183,1)"} style={[Class.marginBottom4]}>Last
                            action {created}</Text>
                        <Text lightColor={"rgba(167,174,183,1)"} style={[Class.marginBottom4]}>{type}</Text>
                        <Text lightColor={"rgba(22,45,78,1)"}
                              style={[Class.title, Class.marginTop8]}>Description</Text>

                        <View lightColor={"#f8f8f8"} darkColor={"#2f2f2f"} style={Class.description}>
                            <HtmlEncrypted encryptedData={description}/>
                            {
                                (Boolean(attachments) && attachments.lengtA7AEB7FFh > 0) &&
                                attachments.map((attachment, index) => {
                                    return <AttachedItem key={index} {...attachment}/>
                                })
                            }
                        </View>
                    </View>
                    {
                        Boolean(conversation) && <View>
                            <Text lightColor={"rgba(22,45,78,1)"}
                                  style={[Class.title, Class.marginTop12, Class.marginBottom8, Class.paddingLeftRight22]}>Conversation</Text>
                            {
                                conversation.map((c, index) => {
                                    let {by, text, attachments, date, l} = c;
                                    if (Boolean(l)) {
                                        return <TouchableOpacity
                                            key={index}
                                            onPress={() => {
                                                if (Boolean(l)) {
                                                    this.navigateLoginDetails()
                                                }
                                            }}
                                        >
                                            <ChatConversation {...c}/>
                                        </TouchableOpacity>
                                    }
                                    return <View key={index}>
                                        <ChatConversation {...c}/>
                                    </View>
                                })
                            }
                        </View>
                    }
                </ScrollView>
                {
                    (status.toString().toLowerCase() !== "resolved") &&
                    <View style={[Class.keyboard]}>
                        <View style={[Class.separator, Class.marginLeft0]} lightColor="#eee"
                              darkColor="rgba(255,255,255,0.1)"/>
                        <TextInput
                            mode={"flat"}
                            value={reply}
                            onKeyPress={(e) => {
                                if ((e.keyCode == 13 &&
                                    (e.metaKey))
                                    && Boolean(reply)) {
                                    this.requestAddConversation();
                                }
                            }}
                            onChangeText={(reply) => this.setState({reply})}
                            onBlur={() => this.setState({focused: false})}
                            onFocus={() => this.setState({focused: true})}
                            placeholder="Reply Conversation"
                            textBreakStrategy="simple"
                            clearButtonMode="while-editing"
                            autoCapitalize="sentences"
                            multiline={true}
                            style={Class.materialUnderlineTextbox1}
                        />
                        {
                            (Boolean(attachment) && attachment.length > 0) && <View style={Class.attachedListView}>
                                {
                                    attachment.map((a, index) => {
                                        return <AttachmentItem
                                            {...a}
                                            key={index}
                                            onPress={() => {
                                                const attachments = attachment.filter((a, i) => i !== index);
                                                this.setState({attachment: attachments})
                                            }}
                                        />
                                    })
                                }
                            </View>
                        }
                        {
                            <View style={[Class.rply]}>
                                <View style={[Class.optionsButtons]}>
                                    <IconButton
                                        name="ios-attach"
                                        onPress={async () => await this.requestFileUpload(true)}
                                        style={Class.buttonAttachment1}
                                    />
                                    {
                                        !isWeb && <IconButton
                                            name="ios-image"
                                            onPress={async () => await this.requestFileUpload(false)}
                                            style={Class.buttonAttachment1}
                                        />
                                    }
                                    {
                                        !isWeb && <IconButton
                                            name="ios-camera"
                                            onPress={async () => navigation.navigate("CameraScreen", {name: route.name})}
                                            style={Class.buttonAttachment1}
                                        />
                                    }

                                </View>
                                <TextButton
                                    disabled={!Boolean(reply)}
                                    onPress={() => this.requestAddConversation()}
                                    mode="text"
                                    style={Class.cupertinoButtonBlueTextColor1}
                                >
                                    Reply
                                </TextButton>
                            </View>
                        }
                    </View>
                }
            </KeyboardAvoidingView>
            <View style={Class.paddingTopBottom4} lightColor={"#fff"}/>
        </RootPage>
    }
}

const mapStateToProps = (state) => ({
    initdata: state.app_info
});

const mapDispatchToProps = (dispatch) => ({
    setInitData: (initData) => dispatch(setInitData(initData)),
    setLoader: (loader) => dispatch(setLoader(loader)),
    setPopPage: (popuppage) => dispatch(setPopPage(popuppage)),
});

export default connect(mapStateToProps, mapDispatchToProps)(connectActionSheet(Conversation));



