import {Image} from "react-native";
import * as React from "react";
import Class from "../constants/Style"
import {View, Text} from "./Themed";

export default function (){
    return <View style={[Class.row, Class.marginBottom16]}>
        <Image
            source={require("../assets/images/app_icon_2020.png")}
            resizeMode="contain"
            style={[Class.loginlogo]}
        />
        <Text style={Class.dhru}>Dhru</Text>
    </View>
}
