import * as React from "react";
import {Text, View} from "./Themed";
import Icon from "./Icon";
import Class from "../constants/Style"
import {TouchableOpacity} from "react-native";
import {HeaderButtonPlus} from "./HeaderButton";

class HeaderRight extends React.Component<any, any> {

    render() {
        const {actions} = this.props;
        return (
            <View style={Class.row}>
                {
                    actions.map(({name, caption, onPress, type, isCustom, component: Component}: any, index:number) => {
                        if (isCustom){
                            return <Component/>;
                        }
                        return <HeaderButtonPlus key={index} caption={caption} name={name} type={type} onPress={onPress}/>
                    })
                }
            </View>
        );
    }
}

export default HeaderRight;
