import * as React from 'react';
import {StyleSheet, Image} from "react-native";
import requestApi, {actions, methods, SUCCESS} from "../libs/ServerRequest";
import {setAuthUser, setInitData, setLoadingAndToken} from "../libs/Store/actions/app_info";
import {connect} from "react-redux";
import AsyncStorage from '@react-native-community/async-storage';
import {Button, HelperText, TextInput} from 'react-native-paper';
import Class from '../constants/Style';
import ReCaptchaV3 from "@haskkor/react-native-recaptchav3";
import {isWeb} from "../libs/settings";
import {GoogleReCaptcha, withGoogleReCaptcha} from "react-google-recaptcha-v3";
import {Link} from "@react-navigation/native";
import CaptchaProvider from "../components/CaptchaProvider";
import {isEmpty} from "../libs/function";
import {ContainedButton, TextInputPaper, Text, View} from "../components/Themed";
import {regExpJson, validEmail, validPassword} from "../libs/validate";
import DhruLogo from "../components/DhruLogo";
import RootPage from "../components/RootPage";
import {setLoader} from "../libs/Store/actions/components";
import LinkButton from "../components/LinkButton";
import RecaptchaContent from "../components/RecaptchaContent";


class ForgotPassword extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            captcha:"",
            captchaCall:false,
            error: {},
            message:"Enter a valid e-mail to receive instructions on how to reset your password.",
            formVisible:true
        }
    }

    componentDidMount() {
    }

    validate = () => {
        const {email} = this.state;
        let error = {};
        error = {...error, ...validEmail(error, "email", email)};
        this.setState({error})
        return isEmpty(error)
    }

    requestForgotPassword =  async () => {
        const {setAuthUser, navigation, fromCheckout, redirectPage} = this.props;
        let {email, captcha} = this.state;
        if (isWeb) {
            captcha = await this.props.googleReCaptchaProps.executeRecaptcha();
        }
        this.props.setLoader({show: false});
        requestApi({
            method: methods.post,
            action: actions.forgotpassword,
            body: {email, "g-recaptcha-response": captcha}
        }).then(async (response) => {
            if (response.status === SUCCESS) {
                // if (fromCheckout){
                //     redirectPage("ResetPassword")
                // }else {
                //     navigation.replace("ResetPassword")
                // }
                if(Boolean(response.message)){
                    this.setState({message:response.message, formVisible:false})
                }
            }

        })
    }

    onSubmit = ()=>{
        if (this.validate()) {
            if (isWeb){
                this.requestForgotPassword()
            }else {
                this.props.setLoader({show: true});
                this.setState({captchaCall:true},()=>{
                    this._captchaRef.refreshToken()
                });
            }
        }
    }

    render() {
        const {email, onPress, error, captchaCall, message, formVisible, } = this.state;
        const {navigation, fromCheckout, redirectPage, route, from} = this.props;
        let isAuthorizeScreen = Boolean(route?.params?.authorize)
        return (
            <RootPage navigation={navigation} title={"Forgot Password"}>
                <View style={Class.container}>
                    <View style={Class.formContainer}>
                        <View style={Class.formGroup}>
                            <View style={[Class.alignSelfStretch, Class.marginBottom16]}>
                                <DhruLogo/>
                                <Text style={Class.pageHeader}>Reset your password</Text>
                                <View style={[Class.row, Class.marginBottom16]}>
                                    <Text>{message}</Text>
                                </View>
                                {
                                    Boolean(formVisible) ? <React.Fragment>
                                        <TextInputPaper
                                            label={"Email Address"}
                                            placeholder={"Email Address"}
                                            value={email}
                                            error={error.email}
                                            textContentType={"emailAddress"}
                                            autoCompleteType={"email"}
                                            keyboardType={"email-address"}
                                            returnKeyType="done"
                                            onSubmitEditing={() => this.onSubmit()}
                                            onChangeText={(email) => this.setState({email})}
                                            style={Class.textbox}
                                        />
                                        <View style={Class.buttonRowContainer}>
                                            <LinkButton caption={isAuthorizeScreen?"Authorize":"Sign In"} onPress={()=> {
                                                if (fromCheckout){
                                                    redirectPage("Login")
                                                }else {
                                                    navigation.goBack()
                                                }

                                            }} style={Class.linkStyle}/>
                                            {
                                                (!isWeb && captchaCall) &&
                                                <ReCaptchaV3
                                                    ref={(ref: RecaptchaV3) => this._captchaRef = ref}
                                                    captchaDomain={'https://api.dhru.com'}
                                                    siteKey={'6LebDscZAAAAAEfuz_E-rB6r12xLHekW_XlR-Y5V'}
                                                    onReceiveToken={(token: string) => {
                                                        this.setState({captcha: token, captchaCall:false},()=>{
                                                            this.requestForgotPassword()
                                                        })
                                                    }}
                                                    action={""}/>
                                            }
                                            <ContainedButton
                                                onPress={() =>this.onSubmit()}
                                            >
                                                Send
                                            </ContainedButton>

                                        </View>
                                    </React.Fragment>:<LinkButton caption={`Back to ${isAuthorizeScreen?"authorize":"login"}`} onPress={()=> {
                                        if (fromCheckout){
                                            redirectPage("Login")
                                        }else {
                                            navigation.goBack()
                                        }

                                    }} style={Class.linkStyle}/>
                                }



                            </View>
                            <RecaptchaContent/>
                        </View>
                    </View>
                </View>
            </RootPage>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    setAuthUser: (authUser) => dispatch(setAuthUser(authUser)),
    setInitData: (initData) => dispatch(setInitData(initData)),
    setLoadingAndToken: (data) => dispatch(setLoadingAndToken(data)),
    setLoader: (loader) => dispatch(setLoader(loader)),
});


export default withGoogleReCaptcha(connect(mapStateToProps, mapDispatchToProps)(ForgotPassword));
