import OtpInput from "react-otp-input";
import * as React from "react";

const OTPTextBox = (props:any) => {
 return <OtpInput
     numInputs={6}
     inputStyle={{
         padding: 10,
         borderRadius:5,
         border:"1px solid #CCCCCCFF"
     }}
     isInputNum={true}
     focusStyle={{
         border:"1px solid #1C55C5FF"
     }}
     containerStyle={{
         justifyContent: "space-between"
     }}
     separator={<span></span>}
     {...props}
 />
}


export default OTPTextBox;
