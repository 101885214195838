import {OTPServiceStack} from "./CommonNavigation";

export const prefixes = ["https://cp.dhru.io/", "cpdhruio://", "http://localhost:19006/"];

const commonLinking = {
    supportLink: {
        ConversationScreen: "view-case",
        UpdateLoginDetailsScreen: "update-login",
        CategoryScreen: "open-case",
        SubcategoryScreen: "open-case-step-2",
        SelectProductScreen: "open-case-step-3",
        CreateTicketScreen: "open-case-step-4",
        SuccessTicketScreen: "open-case-done",
        CameraScreen: {
            path: "camera",
            parse: {
                uri: uri => uri,
            },
            stringify: {
                uri: uri => undefined,
            },
        }
    },
    productLink: {
        AddProductScreen: "add-product",
        ViewProductScreen: "view-product",
        BuyAddonScreen: {
            path: "add-on",
            parse: {
                addons: addons => addons,
            },
            stringify: {
                addons: addons => undefined,
            },
        },
        MoveLicenseScreen: "move-license",
        LicenseKeyScreen:{
            path: "license-key",
            parse: {
                addons: addons => addons,
            },
            stringify: {
                addons: addons => undefined,
            },
        },
    },
    orderLink: {
        ViewInvoiceScreen: {
            path: "view-order",
            parse: {
                from: from => from,
                pay: pay => pay,
            },
            stringify: {
                from: from => undefined,
                pay: pay => undefined,
            },
        },
        WebPageScreen: {
            path: "payment",
            parse: {
                link: (link) => link,
                token: (token) => token,
            },
            stringify: {
                link: (link) => "link",
                token: (token) => "data",
            },
        }
    },
    merchantLink: {
        StoreItemScreen: "storeitem",
    },
    walletLink: {
        TransferCreditScreen: "transfer-credit",
    },
    profileLink: {
        UpdateProfileScreen: "update-profile",
        ChangePasswordScreen: "change-password"
    }
}

export const linkingLarge = {
    prefixes,
    config: {
        initialRouteName: "Login",
        screens: {
            Login: "login",
            Authorize: "authorize",
            VerifyEmail: "verify-email",
            VerifyWhatsApp:"verify-whats-app",
            ForgotPassword: {
                path:"forgot-password",
            },
            VerifyOTP: {
                path:"verify-otp",
                parse: {
                    body: (body) => body,
                    action: (action) => action,
                    method: (method) => method,
                    isAuthorizeScreen: (isAuthorizeScreen) => isAuthorizeScreen,
                },
                stringify: {
                    body: (body) => undefined,
                    action: (action) => undefined,
                    method: (method) => undefined,
                    isAuthorizeScreen: (isAuthorizeScreen) => undefined,
                }
            },
            ResetPassword: "reset-password",
            Register: "register",
            SupportRoot: {
                initialRouteName: "SupportNav",
                screens: {
                    SupportNav: {
                        screens: {
                            Cases: {
                                initialRouteName: "CaseList",
                                screens: {
                                    CaseList: {
                                        path: "support-cases",
                                        params: {status: "open"}
                                    },
                                    ...commonLinking.supportLink
                                }
                            },
                        }
                    },
                }
            },
            ProductRoot: {
                initialRouteName: "ProductNav",
                screens: {
                    ProductNav: {
                        screens: {
                            Products: {
                                initialRouteName: "ProductList",
                                screens: {
                                    ProductList: "products",
                                    ...commonLinking.productLink
                                }
                            }
                        }
                    },
                }
            },
            WorkspaceRoot: {
                initialRouteName: "WorkspaceNav",
                screens: {
                    WorkspaceNav: {
                        screens: {
                            Workspaces: {
                                initialRouteName: "WorkspaceList",
                                screens: {
                                    WorkspaceList: "workspaces",
                                }
                            }
                        }
                    },
                }
            },
            ResellerWorkspaceRoot: {
                initialRouteName: "ResellerWorkspaceNav",
                screens: {
                    ResellerWorkspaceNav: {
                        screens: {
                            ResellerWorkspaces: {
                                initialRouteName: "ResellerWorkspaceList",
                                screens: {
                                    ResellerWorkspaceList: "reseller-workspaces",
                                }
                            }
                        }
                    },
                }
            },
            OrdersRoot: {
                initialRouteName: "OrdersNav",
                screens: {
                    OrdersNav: {
                        screens: {
                            Orders: {
                                initialRouteName: "OrdersList",
                                screens: {
                                    OrdersList: {
                                        path: "orders",
                                        parse: {
                                            id: (id) => id,
                                            from: from => from,
                                            pay: pay => pay,
                                        },
                                        stringify: {
                                            id: (id) => undefined,
                                            from: from => undefined,
                                            pay: pay => undefined,
                                        },
                                    },
                                    ...commonLinking.orderLink
                                }
                            }
                        }
                    },
                }
            },
            MerchantRoot: {
                initialRouteName: "MerchantNav",
                screens: {
                    MerchantNav: {
                        screens: {
                            Merchant: {
                                initialRouteName: "MerchantStack",
                                screens: {
                                    MerchantStack: "seller",
                                    ...commonLinking.merchantLink
                                }
                            }
                        }
                    },
                }
            },
            WalletRoot: {
                initialRouteName: "WalletNav",
                screens: {
                    WalletNav: {
                        screens: {
                            Wallet: {
                                initialRouteName: "WalletStack",
                                screens: {
                                    WalletStack: "wallet",
                                    ...commonLinking.walletLink
                                }
                            }
                        }
                    },
                }
            },
            OTPServiceRoot: {
                initialRouteName: "OTPServiceNav",
                screens: {
                    OTPServiceNav: {
                        screens: {
                            OTPService: {
                                initialRouteName: "OTPServiceScreen",
                                screens: {
                                    OTPServiceScreen: "otpservice",
                                }
                            }
                        }
                    },
                }
            },
            StoreRoot: {
                initialRouteName: "StoreNav",
                screens: {
                    StoreNav: {
                        screens: {
                            Store: {
                                initialRouteName: "MerchantStack",
                                screens: {
                                    MerchantStack: "store",
                                }
                            }
                        }
                    },
                }
            },
            ProfileRoot: {
                initialRouteName: "ProfileNav",
                screens: {
                    ProfileNav: {
                        initialRouteName: "Profile",
                        screens: {
                            Profile: {
                                screens: {
                                    ProfileOptions: {
                                        path: "settings"
                                    }
                                }
                            },
                            Invoices: {
                                screens: {
                                    InvoicesList: "invoices"
                                }
                            },
                            Merchant: {
                                screens: {
                                    MerchantStack: "merchant"
                                }
                            },
                            UpdateProfile: {
                                screens: {
                                    ...commonLinking.profileLink
                                }
                            }
                        }
                    },
                }
            },
            JoinWorkspace: "join-workspace",
            Checkout: "checkout",
            Error: "error",
            NotFound: '*',
        },
    },
};

export const linkingSmall = {
    prefixes,
    config: {
        initialRouteName: "Login",
        screens: {
            Login: "login",
            Authorize: "authorize",
            VerifyEmail: "verify-email",
            VerifyWhatsApp:"verify-whats-app",
            ForgotPassword: {
                path:"forgot-password",
            },
            VerifyOTP: "verify-otp",
            ResetPassword: "reset-password",
            Register: "register",
            SupportRoot: {
                initialRouteName: "SupportNav",
                screens: {
                    SupportNav: {
                        screens: {
                            Cases: {
                                screens: {
                                    CaseList: {
                                        path: "support-cases",
                                        params: {status: "open"}
                                    },
                                }
                            },
                        }
                    },
                }
            },
            WorkspaceRoot: {
                initialRouteName: "WorkspaceNav",
                screens: {
                    WorkspaceNav: {
                        screens: {
                            Workspaces: {
                                initialRouteName: "WorkspaceList",
                                screens: {
                                    WorkspaceList: "workspaces",
                                }
                            }
                        }
                    },
                }
            },
            ProductRoot: {
                initialRouteName: "ProductNav",
                screens: {
                    ProductNav: {
                        screens: {
                            Products: {
                                screens: {
                                    ProductList: "products",
                                }
                            }
                        }
                    },
                    // ...commonLinking.productLink
                }
            },
            OrdersRoot: {
                initialRouteName: "OrdersNav",
                screens: {
                    OrdersNav: {
                        screens: {
                            Orders: {
                                screens: {
                                    OrdersList: {
                                        path: "orders",
                                        parse: {
                                            id: (id) => id,
                                            from: from => from,
                                            pay: pay => pay,
                                        },
                                        stringify: {
                                            id: (id) => undefined,
                                            from: from => undefined,
                                            pay: pay => undefined,
                                        },
                                    },
                                }
                            }
                        }
                    },
                    // ...commonLinking.orderLink
                }
            },
            ProfileRoot: {
                initialRouteName: "ProfileNav",
                screens: {
                    ProfileNav: {
                        initialRouteName: "Profile",
                        screens: {
                            Profile: {
                                screens: {
                                    ProfileOptions: {
                                        path: "profile-setting"
                                    }
                                }
                            },
                        }
                    },

                }
            },
            ...commonLinking.supportLink,
            ...commonLinking.productLink,
            ...commonLinking.orderLink,
            InvoicesScreen: "invoices",
            WalletScreen: "wallet",
            StoreScreen: "store",
            OTPServices:"otpservice",
            ...commonLinking.profileLink,
            ...commonLinking.walletLink,
            JoinWorkspace: "join-workspace",
            Checkout: "checkout",
            Error: "error",
            NotFound: '*',
        },
    },
};

export default {
    prefixes: ["dhrucp://"],
    config: {
        initialRouteName: "Login",
        screens: {
            Login: {
                path: "login"
            },
            VerifyEmail: {
                path: "verify-email/:code?"
            },
            VerifyWhatsApp:"verify-whats-app",
            ForgotPassword: {
                path: "forgot-password",
            },
            VerifyOTP: {
                path: "verify-otp"
            },
            ResetPassword: {
                path: "reset-password/:code?"
            },
            Register: "register",
            SupportRoot: {
                path: "support",
                initialRouteName: "SupportNav",
                screens: {
                    SupportNav: {
                        path: "cases",
                        screens: {
                            Cases: {
                                path: "list",
                                screens: {
                                    CaseList: {
                                        path: ":status"
                                    },
                                }
                            },
                        }
                    },
                    ConversationScreen: {
                        path: "view/:ticket_id",
                    },
                    UpdateLoginDetailsScreen: {
                        path: "updatelogin/:product",
                    },
                    CategoryScreen: {
                        path: "category"
                    },
                    SubcategoryScreen: {
                        path: "category/:id"
                    },
                    SelectProductScreen: {
                        path: "category/:id/:subid"
                    },
                    CreateTicketScreen: {
                        path: "category/:id/:subid/:product_id?"
                    },
                    SuccessTicketScreen: {
                        path: "category/:product"
                    }
                }
            },
            ProductRoot: {
                path: "product",
                initialRouteName: "ProductNav",
                screens: {
                    ProductNav: {
                        path: "list",
                        screens: {
                            Products: {
                                screens: {
                                    ProductList: {
                                        path: "all"
                                    }
                                }
                            }
                        }
                    },
                    ViewProductScreen: {
                        path: ":product_id"
                    },
                    BuyAddonScreen: {
                        path: ":product_id/addon"
                    }
                }
            },
            OrdersRoot: {
                path: "orders",
                initialRouteName: "OrdersNav",
                screens: {
                    OrdersNav: {
                        path: "list",
                        screens: {
                            Orders: {
                                screens: {
                                    OrdersList: {
                                        path: "all"
                                    }
                                }
                            }
                        }
                    },
                    ViewInvoiceScreen: {
                        path: "view/:id",
                    },
                    WebPageScreen: {
                        path: ":link/:token",
                        parse: {
                            link: (link) => link,
                            token: (token) => token,
                        },
                        stringify: {
                            link: (link) => "link",
                            token: (token) => "data",
                        },
                    }
                }
            },
            ProfileRoot: {
                path: "profile",
                initialRouteName: "ProfileNav",
                screens: {
                    ProfileNav: {
                        initialRouteName: "Profile",
                        screens: {
                            Profile: {
                                screens: {
                                    ProfileOptions: {
                                        path: "settings"
                                    }
                                }
                            },
                            Invoices: {
                                path: "invoices",
                                screens: {
                                    InvoicesList: {
                                        path: "list"
                                    }
                                }
                            },
                            Merchant: {
                                screens: {
                                    MerchantStack: "merchant"
                                }
                            },
                            UpdateProfile: {
                                path: "updateprofile",
                                screens: {
                                    UpdateProfileScreen: {
                                        path: "update"
                                    },
                                    ChangePasswordScreen: "change-password"
                                }
                            }
                        }
                    },
                    UpdateProfileScreen: {
                        path: "update"
                    },
                    InvoicesScreen: {
                        path: "invoices"
                    },
                    ChangePasswordScreen: "change-password"
                }
            },
            // Root: {
            //   screens: {
            //     Support: {
            //       screens: {
            //         SupportScreen: {
            //           path: 'support',
            //         },
            //       },
            //     },
            //     Products: {
            //       screens: {
            //         ProductsScreen: 'products',
            //       },
            //     },
            //     Orders: {
            //       screens: {
            //         OrdersScreen: 'orders',
            //       },
            //     },
            //     Invoices: {
            //       screens: {
            //         InvoicesScreen: 'invoices',
            //       },
            //     },
            //     Profile: {
            //       screens: {
            //         ProfileScreen: 'profile',
            //       },
            //     },
            //   },
            // },
            // ConversationScreen: {
            //   path:"support/ticket/:ticket_id",
            // },
            // SupportRequestScreen: {
            //   path:"support/request/",
            // },
            // SubCategoryScreen: {
            //   path:"support/request/:id",
            // },
            // SelectProductScreen: {
            //   path:"support/request/:id/:subid",
            // },
            // CreateTicketScreen: {
            //   path:"support/request/:id/:subid/:product_id?",
            // },
            // SuccessTicketScreen: {
            //   path:"support/request/ticketcreated/:product",
            // },
            // UpdateLoginDetailsScreen: {
            //   path:"support/request/updatelogin/:product",
            // },
            // UpdateProfileScreen:{
            //   path:"profile/updateprofile",
            // },
            // ViewProductScreen:{
            //   path:"product/:product_id",
            // },
            // BuyAddonScreen:{
            //   path:"product/:product_id/addon",
            // },
            NotFound: '*',
        },
    },
};
