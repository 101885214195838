import React, {Component} from "react";
import {StyleSheet, ScrollView, Text, Platform, Linking} from "react-native";

import Svg, {Ellipse} from "react-native-svg";
import IoniconsIcon from "react-native-vector-icons/Ionicons";
import CupertinoHeaderWithLargeTitle4 from "../components/CupertinoHeaderWithLargeTitle4";
import {setInitData} from "../libs/Store/actions/app_info";
import {connect} from "react-redux";
import SupportCategoryItem from "../components/SupportCategoryItem";
import BodyContainer from "../components/BodyContainer";
import {View} from "../components/Themed";
import Class from "../constants/Style"
import SupportItem from "../components/SupportItem";
import TitleAndSubtitle from "../components/TitleAndSubtitle";
import RootPage from "../components/RootPage";
import DhruScrollView from "../components/DhruScrollView";

class SubCategory extends Component {


    render() {
        const {navigation, support_category, route} = this.props;
        if (!Boolean(route.params.id)) {
            navigation.goBack()
        }

        const {name, info, colour, product_req, categorys} = support_category[route.params.id];

        return (
            <RootPage navigation={navigation} headerTitle={name}>
            <View style={Class.container}>
                <DhruScrollView>
                    <TitleAndSubtitle
                        subtitle={info}
                    />
                    {
                        Object.keys(categorys)
                            .map((objectKey, index) => {
                                const {name, info, icon} = categorys[objectKey];
                                return <SupportItem
                                    key={index}
                                    title={name}
                                    description={info}
                                    icon={icon}
                                    colour={colour}
                                    onPress={() => {
                                        if (Boolean(product_req)) {
                                            navigation.push("SelectProductScreen", {
                                                id: route.params.id,
                                                subid: objectKey
                                            })
                                        } else {
                                            navigation.push("CreateTicketScreen", {
                                                id: route.params.id,
                                                subid: objectKey
                                            })
                                        }
                                    }}
                                />
                            })
                    }
                </DhruScrollView>
            </View>
            </RootPage>
        );
    }
}

const mapStateToProps = (state) => ({
    support_category: state.app_info.static.support_category
});

const mapDispatchToProps = (dispatch) => ({
    setInitData: (initData) => dispatch(setInitData(initData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubCategory);
