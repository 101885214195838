import * as React from "react";
import Class from "../constants/Style";
import {List, Text, View} from './Themed';
import FontAwesomeIcon from "react-native-vector-icons/FontAwesome";
import Icon from "./Icon";
import RoundIcon from "./RoundIcon";


export default function SupportItem({title, description,icon,colour, onPress}) {
    return <React.Fragment>
        <List
            title={title}
            description={description}
            left={props => <RoundIcon name={icon} backgroundColor={`#${colour}`}/>}
            onPress={onPress}
        />
        <View style={[Class.separator, Class.separatormargin]} lightColor="#eee" darkColor="rgba(255,255,255,0.1)"/>
    </React.Fragment>
}
