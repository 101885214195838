import React, {Component} from "react";
import requestApi, {actions, methods, SUCCESS} from "../libs/ServerRequest";
import DhruScrollView from "../components/DhruScrollView";
import RootPage from "../components/RootPage";
import {getSmallWidth, logData} from "../libs/function";
import {ContainedButton, View} from "../components/Themed";
import Class from "../constants/Style";
import {RefreshControl, Text, TouchableOpacity} from "react-native";
import {connect} from "react-redux";
import HeaderRight from "../components/HeaderRight";
import StoreItem from "../components/StoreItem";
import FullScreenMessage from "../components/FullScreenMessage";

class Merchant extends Component<any, any> {

    isFromStore = false;

    constructor(props) {
        super(props);


        this.isFromStore = Boolean(props?.from === "Store");

        this.state = {
            filterInvoice: "",
            refreshing: false,
            itemType: {
                banner: "Banner",
                template: "Template"
            },
            typeActive: "banner",
            data: []
        }
    }

    componentDidMount() {
        this.changeNavigation();
        this.loadClientProduct();
    }

    loadClientProduct = () => {
        const {typeActive} = this.state;

        if (!this.isFromStore ){
            const action = this.isFromStore ? actions.store : actions.seller;
            requestApi({
                method: methods.get,
                action,
                queryString: {type: typeActive},
            }).then((response: any) => {
                if (response.status === SUCCESS && Boolean(response?.data)) {
                    this.setState({
                        data: response.data
                    })
                }
            })
        }

    }

    changeNavigation = () => {
        const {navigation, from} = this.props;
        if (!this.isFromStore) {
            navigation.setOptions({
                headerRight: () => <HeaderRight
                    actions={[{
                        name: "plus",
                        caption: "Create Store Item",
                        onPress: () => navigation.navigate("StoreItemScreen")
                    }]}/>
            });
        }

    }

    render() {
        let {navigation, invoices, from} = this.props;
        const {filterInvoice, refreshing, itemType, typeActive, data} = this.state;


        if (from === "Store"){
            from = "Banners & Themes Store"
        }

        return (
            <RootPage navigation={navigation} noBack={getSmallWidth()} title={from} headerTitle={from}>
                {
                    this.isFromStore ?
                        <View style={Class.container}>
                            <FullScreenMessage
                                title={"Banners and Theme store now available in Your Dhru Fusion admin panel, NOW directly purchase and auto install."}
                            />
                        </View>  : <View style={Class.container}>

                            <View style={[Class.padding16, Class.row]}>
                                {
                                    Object.keys(itemType).map((type) => {
                                        return <ContainedButton
                                            style={[Class.marginRight16]}
                                            mode={typeActive === type ? "contained" : "text"}
                                            onPress={() => this.setState({typeActive: type, data: []}, this.loadClientProduct)}>
                                            {itemType[type]}
                                        </ContainedButton>
                                    })
                                }

                            </View>

                            <DhruScrollView
                                refreshControl={<RefreshControl
                                    tintColor={"#9e9e9e"}
                                    refreshing={refreshing}
                                    onRefresh={() => this.setState({refreshing: true})}
                                />}>

                                <View style={[Class.rowWrap]}>
                                    {
                                        data?.map((item: any) => {
                                            return <StoreItem item={item} isStore={this.isFromStore}/>
                                        })
                                    }
                                </View>

                            </DhruScrollView>
                        </View>
                }

            </RootPage>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Merchant)
