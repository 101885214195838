import React from "react";
import {StyleSheet, Text, View} from "react-native";

const FullScreenMessage = ({title, subtitle, children}: any) => {
    return <View style={styles.container}>
        <Text style={styles.title}>{title}</Text>
        {subtitle && <Text style={styles.title}>{subtitle}</Text>}
        {children}
    </View>
}

export default FullScreenMessage;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
    }
});
