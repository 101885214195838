import Class from "../constants/Style";
import Icon from "./Icon";
import {TouchableOpacity} from "react-native";
import * as React from "react";
import {Ionicons} from '@expo/vector-icons';
import {canGoBack} from "../navigation/RootNavigation";

export default function BackButton({name, onPress, style}: any) {
    return <TouchableOpacity
        style={[Class.headerRightAction, style]}
        onPress={onPress}>
        <Ionicons name="ios-arrow-back" size={25} color="#3174de"/>
    </TouchableOpacity>
}

export function BackButton2(props: any) {
    const {name, style, onPress, goBack, ...others} = props
    return <TouchableOpacity
        onPress={goBack}
        style={[Class.headerRightAction, style]}>
        <Ionicons name="ios-arrow-back" size={25} color="#3174de"/>
    </TouchableOpacity>
}
