import {View} from "./Themed";
import Class from "../constants/Style";
import Icon from "./Icon";
import * as React from "react";

export default function SquareIcon({name, backgroundColor, style, iconStyle, lightColor,darkColor }:any) {
    if (!Boolean(lightColor)){
        lightColor="rgba(255,255,255,1)"
    }
    if (!Boolean(darkColor)){
        darkColor="rgba(255,255,255,1)"
    }
    return <View style={[Class.iconSquareBg,  {backgroundColor}, style]}>
        {Boolean(name) && <Icon lightColor={lightColor} darkColor={darkColor} name={name} style={[Class.mainIcon, iconStyle]}/>}
    </View>
}
