import * as React from "react";
import {ContainedButton, Text, TextInputPaper, View} from "../components/Themed";
import {headerHeight, isIOS, isWeb, LARGE_SCREEN_WIDTH} from "../libs/settings";
import {KeyboardAvoidingView, ScrollView} from "react-native";
import Class from "../constants/Style";
import {Button} from "react-native-paper";
import RootPage from "../components/RootPage";
import {LargeScreen, SmallScreen} from "../components/ScreenSize";
import {validEmail, validPassword, validRequired} from "../libs/validate";
import {getSmallWidth, isEmpty} from "../libs/function";
import requestApi, {actions, methods, SUCCESS} from "../libs/ServerRequest";
import DhruScrollView from "../components/DhruScrollView";

class ChangePassword extends React.Component<any, any>{

    constructor(props) {
        super(props);
        this.state = {
            updateFields:{password: "",new_password: "",new_cpassword:""},
            error: {}
        }

        this.inputRefs = [
            React.createRef(),
            React.createRef(),
            React.createRef(),
        ]
    }

    validate = () => {
        const {updateFields} = this.state;
        let error = {};
        error = {...error, ...validRequired(error, "password", updateFields.password, "Please enter password")};
        error = {...error, ...validPassword(error, "new_password", updateFields.new_password, true)};

        if (!Boolean(updateFields.new_cpassword)) {
            error = {...error, ...validRequired(error, "new_cpassword", updateFields.new_cpassword, "Please enter" +
                    " new confirm password")};
        } else if (updateFields.new_password !== updateFields.new_cpassword) {
            error.new_cpassword = "Confirm password not match with new password";
        }
        this.setState({error})
        return isEmpty(error)
    }

    requestChangePassword = async () => {
        let {updateFields} = this.state;
        requestApi({
            method: methods.put,
            action: actions.login,
            body: {...updateFields},
            showsucess:true
        }).then(async (response) => {
            if (response.status === SUCCESS) {

            }
        })
    }


    onSubmit = ()=>{
        if (this.validate()) {
            this.requestChangePassword()
        }
    }

    setPasswordFields = (value) => {
        const {updateFields} = this.state;
        this.setState({
            updateFields: {
                ...updateFields,
                ...value
            }
        })
    }

    render() {
        const {navigation} = this.props;
        const {error, updateFields: {password,new_password,new_cpassword}} = this.state;
        return <RootPage navigation={navigation} noBack={getSmallWidth()} headerTitle={"Change Password"}>
            <KeyboardAvoidingView
                keyboardVerticalOffset={headerHeight}
                style={Class.container} behavior={isIOS ? "padding" : ""}>
                <View style={[Class.container]}>
                    <DhruScrollView contentContainerStyle={Class.paddingLeftRight22}>
                        <View style={[Class.maxFormWidth]}>
                            <TextInputPaper
                                value={password}
                                onChangeText={(password) => this.setPasswordFields({password})}
                                label="Old password"
                                secureTextEntry={true}
                                style={[Class.marginBottom16, Class.marginTop16]}
                                error={error.password}
                                autoCompleteType={"password"}
                                textContentType={"password"}
                                keyboardType={"default"}
                                returnKeyType="next"
                                onSubmitEditing={() => this.inputRefs[0].focus()}
                            />
                            <TextInputPaper
                                value={new_password}
                                onChangeText={(new_password) => this.setPasswordFields({new_password})}
                                label="New password"
                                style={Class.marginBottom16}
                                error={error.new_password}
                                secureTextEntry={true}
                                autoCompleteType={"password"}
                                textContentType={"newPassword"}
                                PasswordRules={"required: lower; required: upper; required: digit; required: [-!#$%&()*@^]; minlength: 15; maxlength: 15;"}
                                keyboardType={"default"}
                                returnKeyType="next"
                                ref={ref => this.inputRefs[0] = ref}
                                onSubmitEditing={() => this.inputRefs[1].focus()}
                            />
                            <TextInputPaper
                                value={new_cpassword}
                                onChangeText={(new_cpassword) => this.setPasswordFields({new_cpassword})}
                                label="Confirm new password"
                                secureTextEntry={true}
                                style={Class.marginBottom16}
                                error={error.new_cpassword}
                                autoCompleteType={"password"}
                                textContentType={"newPassword"}
                                PasswordRules={"required: lower; required: upper; required: digit; required: [-!#$%&()*@^]; minlength: 15; maxlength: 15;"}
                                keyboardType={"default"}
                                returnKeyType="done"
                                ref={ref => this.inputRefs[1] = ref}
                                onSubmitEditing={() => this.onSubmit()}
                            />
                            <LargeScreen minWidth={LARGE_SCREEN_WIDTH}>
                                <ContainedButton
                                    style={[Class.maxFormWidth]}
                                    onPress={() => this.onSubmit()}
                                >
                                    Save
                                </ContainedButton>
                            </LargeScreen>
                        </View>
                    </DhruScrollView>

                    <SmallScreen maxWidth={LARGE_SCREEN_WIDTH}>
                        <View style={[Class.bottomView]}>
                            <ContainedButton
                                style={[Class.maxFormWidth]}
                                onPress={() => this.onSubmit()}
                            >
                                Save
                            </ContainedButton>
                        </View>
                    </SmallScreen>

                </View>
            </KeyboardAvoidingView>
        </RootPage>
    }
}
export default ChangePassword;
