import React, { Component } from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import Icon, {IosIcon} from "./Icon";
import Class from "../constants/Style";

function IconButton(props) {
  return (
    <TouchableOpacity onPress={props.onPress} style={[Class.iconButtonContainer, props.style]}>
      <IosIcon name={props.name} style={[Class.buttonIcon, props.iconStyle]}/>
    </TouchableOpacity>
  );
}

export default IconButton;
