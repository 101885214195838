import {methods} from "./methods";
import {actions} from "./actions";
import {DEFAULT_MESSAGE, ERROR, SUCCESS} from "./status";
import {store} from "../Store/configureStore";
import {setLoader, setSnackbar} from "../Store/actions/components";
import {isDevelopment} from "../settings";
import {clone, loginPromise, retrieveData, signOut, STORAGE_KEY} from "../function";


const requestApi = async ({
                              method,
                              action,
                              queryString,
                              body,
                              otherPath,
                              showsucess,
                              anotherUrl,
                              otherheaders,
                              token,
                              loader = true
                          }: any) => {

    const {app_info: {authUser}} = store.getState();
    const responseJson = {};
    const isMethod = method in methods;
    //const servername = server;
    if (!isMethod) {
        let msg = "Set ";
        if (!isMethod) {
            msg += "method"
        }
        if (!action || !action.trim()) {
            if (msg) {
                msg += " and "
            }
            msg += "action";
        }
        responseJson.status = ERROR;
        responseJson.msg = msg;

        return responseJson;
    }
    // let url = other && other.url ? other.url : `http://${servername}/api/pos.php/`;

    let serverAddress = "https://api.dhru.com";

    let url = `${serverAddress}/client/api/v1/`;
    if (action) {
        url += `${action}`;
    }

    if (queryString) {
        queryString = {
            ...queryString,
        };
        url += jsonToQueryString(queryString);
    } else if (otherPath) {
        url = `${serverAddress}${otherPath}`;
    } else if (anotherUrl) {
        url = anotherUrl;
    }
    // OLD
    // let headers = {
    //     'Accept': 'application/json',
    //     'Content-Type': 'application/json'
    // };

    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };

    if (Boolean(otherheaders)) {
        headers = {
            ...otherheaders
        }
    }


    if (Boolean(authUser) && Boolean(authUser.token)) {
        headers = {
            ...headers,
            'Authorization': 'Bearer ' + authUser.token,
        };
    }

    if (token){
        headers = {
            ...headers,
            'Authorization': 'Bearer ' + token,
        };
    }


    //let controller = new AbortController();
    //let signal = controller.signal;

    const init = {
        method,
        headers: new Headers(headers),
        timeout: 5000,

    };
    if (body) {
        init.body = JSON.stringify(body);
    }

    // setTimeout(() => {
    //     controller.abort();
    // }, 10000);


    store.dispatch(setLoader({
        show: loader
    }));

    return fetch(url, init)
        .then((result) => {
            return result.json()
        })
        .then(async (data) => {
            store.dispatch(setLoader({
                show: false
            }));
            if (isDevelopment) {
                console.log(
                    "==========",
                    "\napi url : ", url,
                    "\nmethod : ", method,
                    "\naction : ", action,
                    "\nqueryString : ", queryString,
                    //"\nheaders : ",JSON.stringify(headers),
                    "\nbody : ", JSON.stringify(body),
                    "\nresponse : ", data,
                    "\n=========="
                );
            }
            let callback = '';


            if (Boolean(data?.token)) {
                const userData = await retrieveData(STORAGE_KEY.USER_DATA);
                loginPromise(clone({
                    ...userData,
                    token: data.token,
                })).then()
            }


            callback = data;

            if (callback.status === ERROR) {
                if (callback.code === 401) {
                    signOut()
                }
                if (Boolean(callback.message) && callback.code !== 401) {
                    store.dispatch(setSnackbar({visible: true, message: callback.message, type: callback.status}));
                }
            } else if (showsucess) {
                store.dispatch(setSnackbar({visible: true, message: callback.message, type: callback.status}));
            }
            return callback;
        })
        .catch((error) => {
            store.dispatch(setLoader({
                show: false
            }));
            if (isDevelopment) {
                console.log(`${action} ERROR..`);
                console.log('catch : ', action, error.message, error);
                console.log('catch : ', error.message);
            }
            responseJson.status = ERROR;

            // message && Toast.show(message);


            store.dispatch(setSnackbar({
                visible: true,
                message: error?.message,
                color: "#f44336"
            }));

            /*store.dispatch(setAlert({
                message: responseJson.msg,
                variant: ERROR,
                visible: true
            }));*/
            return responseJson
        })
};


const jsonToQueryString = (json) => {
    if (!json) {
        return undefined;
    }
    return '?' + Object.keys(json).map((key) => {
        return `${key}=${json[key]}`
    }).join('&');
};

const queryStringToJson = (string) => {
    var pairs = string.slice(1).split('&');

    var result = {};
    pairs.forEach(function (pair) {
        pair = pair.split('=');
        result[pair[0]] = decodeURIComponent(pair[1] || '');
    });

    return JSON.parse(JSON.stringify(result));
};

export {
    methods,
    actions,
    ERROR,
    SUCCESS,
    DEFAULT_MESSAGE,
    jsonToQueryString,
    queryStringToJson,
};

export default requestApi;
