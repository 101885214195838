import {
    SET_AUTH_USER, SET_INIT_DATA, SET_INIT_DATA_WITH_OBJECT, SET_LOADING_AND_TOKEN, SET_PROFILE_CREDIT, SET_WORKSPACES,
} from "../definitions/app_info";

const setAuthUser = (authUser) => ({
    type: SET_AUTH_USER,
    authUser
});

const setInitData = (initData) => ({
    type: SET_INIT_DATA,
    initData
});

const setLoadingAndToken = (data) => ({
    type: SET_LOADING_AND_TOKEN,
    data
});

const setInitDataWithObject = (objectKey, updateData)=>({
    type:SET_INIT_DATA_WITH_OBJECT,
    objectKey,
    updateData
})

const setProfileCredit = (credit) => ({
    type:SET_PROFILE_CREDIT,
    credit,
});

const setWorkspaces = (workspaces:any) => ({
    type:SET_WORKSPACES,
    workspaces
})

export {setAuthUser, setInitData, setLoadingAndToken, setInitDataWithObject, setProfileCredit, setWorkspaces}
