import React, {Component} from "react";
import Class from "../constants/Style";
import {Card} from "react-native-paper";
import {ContainedButton, IconDefault, Text, View} from "./Themed";
import requestApi, {actions, methods, SUCCESS} from "../libs/ServerRequest";
import {browseLink, isEmpty} from "../libs/function";
import {connectActionSheet} from "@expo/react-native-action-sheet";


class StoreItem extends Component<any, any> {

    constructor(props: any) {
        super(props);

        this.state = {
            download_url: props?.item?.download_links
        }
    }

    actionBuy = (store_item_id) => {
        requestApi({
            method: methods.post,
            action: actions.store,
            body: {store_item_id},
            showsucess: true
        }).then((response: any) => {
            if (response.status === SUCCESS && Boolean(response?.data)) {
                if (response?.data?.download_links) {
                    this.setState({
                        download_url: response.data.download_links
                    })
                }
            }
        })
    }


    _onOpenActionSheet = () => {
        // Same interface as https://facebook.github.io/react-native/docs/actionsheetios.html

        const {download_url} = this.state;


        let options = [...Object.keys(download_url), 'Cancel'];
        const destructiveButtonIndex = options.findIndex((a) => Boolean(a.alert));
        const cancelButtonIndex = options.length - 1;
        this.props.showActionSheetWithOptions(
            {options, cancelButtonIndex, destructiveButtonIndex},
            buttonIndex => {
                if (cancelButtonIndex !== buttonIndex) {
                    browseLink(download_url[options[buttonIndex]], "_blank")
                }
            },
        );
    };

    render() {

        const {item, isStore} = this.props;
        const {download_url} = this.state;


        return <Card style={[Class.margin16,]} elevation={2}>
            <Card.Cover source={{uri: item?.thumb}} style={{width: 240, height: 100}}/>

            {
                isStore ?
                    <Card.Content style={[, Class.justifySpaceBetween, Class.paddingTop10]}>
                        <View>
                            <View style={[Class.row, Class.width100, Class.justifySpaceBetween]}>
                                <View>
                                    {
                                        !isEmpty(download_url) ?
                                            <><ContainedButton onPress={() => this._onOpenActionSheet()}>
                                                <IconDefault name={"download"} lightColor={"#fff"}/>
                                            </ContainedButton></> :
                                            <ContainedButton
                                                onPress={() => this.actionBuy(item?.store_item_id)}>Buy</ContainedButton>
                                    }

                                </View>
                                <View style={[Class.textRight]}>
                                    <Text>Credit : {item?.credit}</Text>
                                    <Text style={[Class.textSmall, Class.textDarkGrey]} onPress={()=>{
                                        browseLink(`https://${item?.seller_profile?.seller_website}`,"_blank")
                                    }}>{item?.seller_profile?.seller_name}</Text>
                                </View>
                            </View>
                            <View>

                                {/*<Text>{item?.seller_profile?.seller_website}</Text>*/}

                            </View>
                        </View>
                    </Card.Content> :
                    <Card.Content style={[Class.row, Class.justifySpaceBetween, Class.paddingTop10]}>
                        <View><Text>{item?.status}</Text></View>
                        <View><Text>{item?.credit}</Text></View>
                    </Card.Content>
            }


        </Card>;
    }
}

export default connectActionSheet(StoreItem);
