const SET_AUTH_USER = "SET_AUTH_USER";
const SET_INIT_DATA = "SET_INIT_DATA";
const SET_LOADING_AND_TOKEN = "SET_LOADING_AND_TOKEN";
const SET_INIT_DATA_WITH_OBJECT = "SET_INIT_DATA_WITH_OBJECT";
const SET_PROFILE_CREDIT = "SET_PROFILE_CREDIT";
const SET_WORKSPACES = "SET_WORKSPACES";

export {
    SET_AUTH_USER,
    SET_INIT_DATA,
    SET_LOADING_AND_TOKEN,
    SET_INIT_DATA_WITH_OBJECT,
    SET_PROFILE_CREDIT,
    SET_WORKSPACES
};
