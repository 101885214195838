import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import thunk from "redux-thunk";
import app_info from "./reducers/app_info";
import components from "./reducers/components";


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = () => {
    const reducers = {
        app_info,
        components
    };
    const store = createStore(
        combineReducers(reducers),
        composeEnhancers(applyMiddleware(thunk))
    );
    return store;
}


export const store = configureStore();
